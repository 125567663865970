import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useEffect, useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { PageErrorBoundary } from "../PageErrorBoundary";
import { skeletonify } from "../skeleton";

import { MtsLogTabList } from "./MtsLogTabList";
import TicketMtsLogTabQuery, {
  TicketMtsLogTabQuery$variables,
  TicketMtsLogTabQuery as TicketMtsLogTabQueryType,
} from "./__generated__/TicketMtsLogTabQuery.graphql";

const ActualComponent: FC<{
  query: PreloadedQuery<TicketMtsLogTabQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query TicketMtsLogTabQuery($input: QueryTicketInput!) {
        ticket(input: $input) {
          ... on QueryTicketSuccess {
            data {
              equipment {
                ...MtsLogTabList_mould
              }
            }
          }
        }
      }
    `,
    queryRef,
  );

  const equipment = data.ticket?.data?.equipment;
  return <MtsLogTabList.Suspense mould={equipment} />;
};

const SkeletonComponent: FC = () => <FillWidthLoading />;

const TicketMtsLogTabPage = skeletonify("TicketMtsLogTabPage", ActualComponent, SkeletonComponent);

export const TicketMtsLogTab: FC = () => {
  const { id } = useParams() as { id: string };
  const [queryRef, loadQuery] = useQueryLoader<TicketMtsLogTabQueryType>(TicketMtsLogTabQuery);

  const queryInput: TicketMtsLogTabQuery$variables = useMemo(() => {
    return {
      input: { ticketNumber: Number.parseInt(id) },
    };
  }, [id]);

  useEffect(() => loadQuery(queryInput), [id, loadQuery, queryInput]);

  return (
    <Container maxWidth="xl">
      <PageErrorBoundary onReset={() => loadQuery(queryInput)}>
        {queryRef ? <TicketMtsLogTabPage.Suspense query={queryRef} /> : <TicketMtsLogTabPage.Skeleton />}
      </PageErrorBoundary>
    </Container>
  );
};
