import { ReactComponent as ConstructionWorker } from "./../assets/figures/constructionWorker.svg";
import { ReactComponent as Electrician } from "./../assets/figures/electrictian.svg";
import { ReactComponent as Scaphander } from "./../assets/figures/scaphander.svg";
import { ReactComponent as SearchingUpwards } from "./../assets/figures/searching-upwards.svg";
import { ReactComponent as Searching } from "./../assets/figures/searching.svg";
import { ReactComponent as SpaceMan } from "./../assets/figures/space-man.svg";

export const Figures = {
  ConstructionWorker,
  Searching,
  SpaceMan,
  Scaphander,
  SearchingUpwards,
  Electrician,
};
