import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { skeletonify } from "../../skeleton";

import { EmployeeAvatar } from "./EmployeeAvatar";
import { EmployeeAvatarWithName_employee$key } from "./__generated__/EmployeeAvatarWithName_employee.graphql";

const ActualComponent: FC<{
  employee: EmployeeAvatarWithName_employee$key;
  secondaryLabel?: string;
  size?: string | number;
}> = (props) => {
  const { employee: employeeRef, size = "100%", secondaryLabel } = props;
  const employee = useFragment(
    graphql`
      fragment EmployeeAvatarWithName_employee on Employee {
        ...EmployeeAvatar_employee
        firstName
        lastName
      }
    `,
    employeeRef,
  );
  const { firstName, lastName } = employee;

  return (
    <Grid2 container spacing={1} direction="row">
      <Grid2>
        <EmployeeAvatar employee={employee} size={size} />
      </Grid2>
      <Grid2
        style={{
          flex: 1,
          textOverflow: "ellipsis",
        }}
      >
        <Typography noWrap>
          {firstName} {lastName}
        </Typography>
        {hasValue(secondaryLabel) && (
          <Typography variant="body2" color="textSecondary">
            {secondaryLabel}
          </Typography>
        )}
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC<{ size?: string | number }> = (props) => {
  const { size = "100%" } = props;
  return (
    <Skeleton
      variant="circular"
      sx={{
        height: size,
        width: size,
      }}
    />
  );
};

export const EmployeeAvatarWithName = skeletonify("EmployeeAvatarWithName", ActualComponent, SkeletonComponent);
