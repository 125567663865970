import { ApolloProvider } from "@apollo/client";
import { Grid2 } from "@mui/material";
import { ReactElement } from "react";

import { useApolloClient } from "../apollo/apollo-client";
import { ActivityIndicator } from "../components/shared/ActivityIndicator";
import { withRelayEnvironment } from "../relay/RelayEnvironment";

export const GQLProvider = withRelayEnvironment("select_account", ({ children }: { children: ReactElement }) => {
  const client = useApolloClient();

  if (!client) {
    return (
      <Grid2 container justifyContent="center" alignItems="center">
        <Grid2>
          <ActivityIndicator />
        </Grid2>
      </Grid2>
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});
