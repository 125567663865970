import { TextField, TextFieldProps } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import debounce from "lodash/debounce";
import { FC, useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";

import { SublocationFreeText_equipment$key } from "./__generated__/SublocationFreeText_equipment.graphql";

interface Props {
  equipment: SublocationFreeText_equipment$key;
  loading: boolean;
  onTextChanged?: (newLocationId: string) => void;
  debounceValue?: number;
}

export const SublocationFreeText: FC<Props> = ({
  equipment: equipmentRef,
  loading,
  onTextChanged,
  debounceValue = 700,
}) => {
  const equipment = useFragment(
    graphql`
      fragment SublocationFreeText_equipment on Equipment {
        sublocation {
          ... on FreeTextSublocation {
            description
          }
        }
      }
    `,
    equipmentRef,
  );
  const [sublocationText, setSublocationText] = useState(equipment.sublocation?.description ?? "");

  useEffect(() => {
    setSublocationText(equipment.sublocation?.description ?? "");
  }, [equipment.sublocation?.description]);

  const onChangeDebounced = useMemo(() => {
    const emitChange: TextFieldProps["onChange"] = (event) => {
      onTextChanged?.(event.target.value + "");
    };

    const debounceEmit = debounce(emitChange, debounceValue);

    const saveSearch: TextFieldProps["onChange"] = (event) => {
      setSublocationText(event.target.value + "");
      debounceEmit(event);
    };

    return saveSearch;
  }, [onTextChanged, debounceValue]);

  return (
    <TextField
      onChange={onChangeDebounced}
      disabled={loading}
      value={sublocationText}
      style={{ width: "100%" }}
      inputProps={{ maxLength: 8 }}
    />
  );
};
