import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { EquipmentNumber_equipment$key } from "./__generated__/EquipmentNumber_equipment.graphql";

const ActualComponent: FC<{
  equipment: EquipmentNumber_equipment$key | null | undefined;
}> = (props) => {
  const { equipment: equipmentRef } = props;

  const data = useFragment(
    graphql`
      fragment EquipmentNumber_equipment on Equipment {
        equipmentNumber
      }
    `,
    equipmentRef ?? null,
  );

  if (!data) {
    return null;
  }

  return <Typography variant="body1">{data.equipmentNumber.toString()}</Typography>;
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StructureComponent: FC<{ children: any }> = ({ children }) => (
  <Grid2 container direction="row" spacing={1}>
    <Grid2 sx={{ alignItems: "center", display: "flex" }}>
      <Icons.EquipmentId />
    </Grid2>
    <Grid2>{children}</Grid2>
  </Grid2>
);

export const EquipmentNumber = skeletonify("EquipmentNumber", ActualComponent, SkeletonComponent, StructureComponent);
