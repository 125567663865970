import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { FC } from "react";

import { AreaFragment } from "../../__apollo__/graphql";
import { useTranslation } from "../../utility/i18n/translation";

import { AreaAndProcessListItem, AreaOrProcess } from "./AreaAndProcess";

export const AREA_FRAGMENT = gql`
  fragment Area on Area {
    id
    name
    plant {
      id
    }
  }
`;

export const AREA_ALL_OPTION = "areaAllOption";

export const Area: FC<{
  areas: AreaFragment[];
  currentlySelectedAreaId: string;
  onAreaChosen: (areaId: string) => void;
  loading: boolean;
  customTitle?: string;
  showOptionForAll?: boolean;
}> = ({ areas, onAreaChosen, currentlySelectedAreaId, loading, customTitle, showOptionForAll = false }) => {
  const { translate } = useTranslation();
  const handleChange = (event: SelectChangeEvent<string>) => {
    onAreaChosen(event.target.value);
  };

  const areaList: AreaAndProcessListItem[] = areas.map(
    (area) =>
      ({
        id: area.id,
        name: `${area.name} (${area.plant.id})`,
      }) as AreaAndProcessListItem,
  );

  const allOption: AreaAndProcessListItem = {
    id: AREA_ALL_OPTION,
    name: translate("PROFILE.AREA_AND_PROCESS.AREA_ALL_OPTION", "All"),
  };

  const items = showOptionForAll ? [allOption, ...areaList] : areaList;

  const helperText = translate(
    "PROFILE.AREA_AND_PROCESS.AREA_HELPER_TEXT",
    "Choose the area you are working in. Your choice will determine which tickets and equipment you will be able to view and change in the app.",
  );

  const title = hasValue(customTitle) ? customTitle : translate("PROFILE.AREA_AND_PROCESS.AREA_TITLE", "Area");

  return (
    <AreaOrProcess
      loading={loading}
      helperText={helperText}
      items={items}
      title={title}
      onChange={handleChange}
      currentlySelectedId={currentlySelectedAreaId}
      showHelperText={!hasValue(customTitle)}
      showPlaceholder={false}
    />
  );
};
