import { PROCESSES_ALL_OPTION } from "../../components/profile/Process";

import { ConfidentialitySearchState, initialConfidentialitySearchState } from "./confidentiality-search-context";

export type ConfidentialitySearchActions =
  | { type: "setSelectedAreaId"; id: string }
  | { type: "setSelectedProcessId"; id: string }
  | { type: "setUsernameSearchTerm"; term: string }
  | { type: "setCreatedFrom"; date: Date | null }
  | { type: "setCreatedTo"; date: Date | null }
  | { type: "clearSearch" };

export const confidentialitySearchReducer = (
  state: ConfidentialitySearchState,
  action: ConfidentialitySearchActions,
): ConfidentialitySearchState => {
  switch (action.type) {
    case "setSelectedAreaId":
      return {
        ...state,
        selectedAreaId: action.id,
        selectedProcessId: PROCESSES_ALL_OPTION,
      };
    case "setSelectedProcessId":
      return {
        ...state,
        selectedProcessId: action.id,
      };
    case "setUsernameSearchTerm":
      return {
        ...state,
        usernameSearchTerm: action.term,
      };
    case "setCreatedFrom":
      return {
        ...state,
        createdFrom: action.date,
      };
    case "setCreatedTo":
      return {
        ...state,
        createdTo: action.date,
      };
    case "clearSearch":
      return initialConfidentialitySearchState;

    default:
      return state;
  }
};
