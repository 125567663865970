import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  CloseMouldTicketFlow,
  CloseMouldTicketMutation,
  CloseMouldTicketMutationVariables,
  CloseNonMouldTicketMutation,
  CloseNonMouldTicketMutationVariables,
  TicketPriorityEnum,
} from "../../__apollo__/graphql";
import { useGMMutation } from "../../apollo/customApolloHooks";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { useUpdateEquipmentLocationMutations } from "../shared/EquipmentLocationSection";
import { useUpdateCleaningIntervalsMutation } from "../sister-moulds-and-cleaning/SisterMouldList";

export const CLOSE_MOULD_TICKET_MUTATION = gql`
  mutation CloseMouldTicket($input: CloseMouldTicketInput!) {
    closeMouldTicket(input: $input) {
      ... on Ticket {
        id
        status
        isOpen
      }
    }
  }
`;

const CLOSE_NON_MOULD_TICKET = gql`
  mutation CloseNonMouldTicket($input: CloseTicketInput!) {
    closeTicket(input: $input) {
      ... on Ticket {
        id
        status
        isOpen
      }
    }
  }
`;

export const useCloseTicketMutation = (
  ticketId: string,
  priority: TicketPriorityEnum,
  equipmentId?: string,
  underGAT?: boolean,
): { closeTicket: () => void } => {
  const {
    dispatch,
    state: { flowVariant, gatInfo, cleaningState, didCleanMould },
  } = useCloseTicketContext();

  const { translate } = useTranslation();

  const navigate = useNavigate();
  const { showSnack } = useGMSnackbar();

  const onSuccess = () => {
    const message = (): string => {
      if (underGAT) {
        if (gatInfo?.approved) {
          return translate(
            "CLOSE_TICKET.CLOSE_MOULD_GAT_APPROVE",
            "Successfully closed ticket and updated status code to 140",
          );
        } else {
          return translate("CLOSE_TICKET.CLOSE_MOULD_GAT_REJECT", "Rejected GAT test. MTS code changed to 842");
        }
      }
      return translate("CLOSE_TICKET.CLOSE_MOULD_SUCCESSFUL_SNACK", "Successfully closed ticket");
    };

    showSnack({
      message: message(),
      variant: "success",
    });
    navigate(AppRoutes.root, { replace: true });
  };

  const { updateEquipmentLocationData, loading: locationUpdatesLoading } =
    useUpdateEquipmentLocationMutation(equipmentId);

  const [updateCleaningIntervalsMutation, { loading: cleaningIntervalUpdateLoading }] =
    useUpdateCleaningIntervalsMutation();

  const closeMouldTicketVariables = useCloseMouldTicketVariables(ticketId, underGAT);

  const [closeMouldTicketMutation, { loading: closingMouldTicket }] = useGMMutation<
    CloseMouldTicketMutation,
    CloseMouldTicketMutationVariables
  >(CLOSE_MOULD_TICKET_MUTATION, {
    onCompleted: onSuccess,
  });

  const [closeNonMouldTicket, { loading: closingNonMouldTicket }] = useGMMutation<
    CloseNonMouldTicketMutation,
    CloseNonMouldTicketMutationVariables
  >(CLOSE_NON_MOULD_TICKET, {
    variables: {
      input: {
        ticketId,
      },
    },
    onCompleted: onSuccess,
  });

  useEffect(() => {
    dispatch({
      type: "setCloseTicketLoading",
      loading: closingMouldTicket || closingNonMouldTicket || locationUpdatesLoading || cleaningIntervalUpdateLoading,
    });
  }, [cleaningIntervalUpdateLoading, closingMouldTicket, closingNonMouldTicket, dispatch, locationUpdatesLoading]);

  const closeTicket = useCallback(async () => {
    if (flowVariant !== "mould") {
      await updateEquipmentLocationData();
    }

    if (flowVariant !== "mould" || priority === TicketPriorityEnum.Priority5) {
      await closeNonMouldTicket();
    } else {
      if (hasValue(cleaningState) && didCleanMould) {
        await updateCleaningIntervalsMutation({
          variables: {
            input: {
              mouldId: Number.parseInt(cleaningState.mouldId),
              newCleaningInterval: cleaningState.cleaningIntervalUpdate.newCleaningInterval,
              sisterMouldIdsToUpdate: cleaningState.cleaningIntervalUpdate.sisterIdsToUpdate,
              updatedAt: new Date().toISOString(),
            },
          },
        });
      }
      if (closeMouldTicketVariables) {
        await closeMouldTicketMutation({
          variables: closeMouldTicketVariables,
        });
      }
    }
  }, [
    cleaningState,
    closeMouldTicketMutation,
    closeMouldTicketVariables,
    closeNonMouldTicket,
    didCleanMould,
    flowVariant,
    priority,
    updateCleaningIntervalsMutation,
    updateEquipmentLocationData,
  ]);

  return {
    closeTicket,
  };
};

const useCloseMouldTicketVariables = (
  ticketId: string,
  underGAT?: boolean,
): CloseMouldTicketMutationVariables | undefined => {
  const {
    state: { didCleanMould, didSpringChange, springInterval, springIntervalDirty, gatInfo, flowType: contextFlowType },
  } = useCloseTicketContext();

  const flowType = underGAT ? CloseMouldTicketFlow.Gat : contextFlowType;

  if (!flowType) {
    return undefined;
  }

  return {
    input: {
      mouldWasCleaned: didCleanMould ?? false,
      flowType,
      ticketId,
      springChangeInterval: springIntervalDirty ? springInterval : undefined,
      springChanged: didSpringChange ?? false,
      gatInfo:
        underGAT && hasValue(gatInfo) && hasValue(gatInfo.approved)
          ? {
              isApproved: gatInfo.approved,
              rejectionReason: gatInfo.rejectionReason,
            }
          : undefined,
    },
  };
};

const useUpdateEquipmentLocationMutation = (equipmentId?: string) => {
  const { loading, updateEquipmentLocationData } = useUpdateEquipmentLocationMutations(equipmentId);
  const {
    state: { location },
  } = useCloseTicketContext();

  const update = () => {
    updateEquipmentLocationData(location);
  };

  return {
    updateEquipmentLocationData: update,
    loading,
  };
};
