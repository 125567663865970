import { hasValue } from "@lego/mst-error-utilities";
import { Box, Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect, useMemo, useState, useTransition } from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { useRouteRootType } from "../../Router";
import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { useTranslation } from "../../utility/i18n/translation";
import { ErrorPage } from "../PageErrorBoundary";
import { skeletonify } from "../skeleton";
import { FullHeightGrid } from "../ticket-search/TicketSearch";

import { EquipmentHistoryCountsWidget } from "./EquipmentHistoryCountsWidget";
import { EquipmentHistoryFilters } from "./EquipmentHistoryFilters";
import { EquipmentHistoryKnowledgeCenter } from "./EquipmentHistoryKnowledgeCenter";
import { EquipmentHistoryList } from "./EquipmentHistoryList";
import { EquipmentTicketsInput } from "./__generated__/EquipmentHistoryListRefetchQuery.graphql";
import EquipmentHistoryTabForTicketQuery, {
  EquipmentHistoryTabQuery$variables,
  EquipmentHistoryTabQuery as EquipmentHistoryTabQueryType,
} from "./__generated__/EquipmentHistoryTabQuery.graphql";
import { EquipmentHistoryTab_equipment$key } from "./__generated__/EquipmentHistoryTab_equipment.graphql";

export const EquipmentHistoryTabContainer: FC<{
  query: PreloadedQuery<EquipmentHistoryTabQueryType>;
  historyFilters: EquipmentTicketsInput;
  onHistoryFiltersChange: (filters: EquipmentTicketsInput) => void;
  loading: boolean;
  refetch: () => void;
}> = ({ query: queryRef, historyFilters, onHistoryFiltersChange, loading, refetch }) => {
  const { translate } = useTranslation();

  const { ticket, equipment } = usePreloadedQuery<EquipmentHistoryTabQueryType>(
    graphql`
      query EquipmentHistoryTabQuery(
        $ticketInput: QueryTicketInput!
        $equipmentInput: QueryEquipmentInput!
        $skipTicket: Boolean!
        $skipEquipment: Boolean!
        $filters: EquipmentTicketsInput!
      ) {
        ticket(input: $ticketInput) @skip(if: $skipTicket) {
          ... on QueryTicketSuccess {
            data {
              equipment {
                ...EquipmentHistoryTab_equipment @arguments(filters: $filters)
              }
            }
          }
        }
        equipment(input: $equipmentInput) @skip(if: $skipEquipment) {
          ... on QueryEquipmentSuccess {
            data {
              ...EquipmentHistoryTab_equipment @arguments(filters: $filters)
            }
          }
        }
      }
    `,
    queryRef,
  );

  if (hasValue(ticket) && hasValue(ticket.data) && hasValue(ticket.data.equipment)) {
    return (
      <ActualComponent
        equipment={ticket.data.equipment}
        historyFilters={historyFilters}
        onHistoryFiltersChange={onHistoryFiltersChange}
        loading={loading}
        refetch={refetch}
      />
    );
  } else if (hasValue(equipment) && hasValue(equipment.data)) {
    return (
      <ActualComponent
        equipment={equipment.data}
        historyFilters={historyFilters}
        onHistoryFiltersChange={onHistoryFiltersChange}
        loading={loading}
        refetch={refetch}
      />
    );
  } else {
    return (
      <Typography>
        {translate("TICKET_DETAILS.HISTORY.EQUIPMENT_ID_FETCH_ERROR", "Could not fetch history data")}
      </Typography>
    );
  }
};

const ActualComponent: FC<{
  equipment: EquipmentHistoryTab_equipment$key;
  historyFilters: EquipmentTicketsInput;
  onHistoryFiltersChange: (filters: EquipmentTicketsInput) => void;
  loading: boolean;
  refetch: () => void;
}> = ({ equipment: equipmentRef, historyFilters, onHistoryFiltersChange, loading, refetch }) => {
  const [activityCatalogId, setActivityCatalogId] = useState<string | undefined>(undefined);
  const equipment = useFragment(
    graphql`
      fragment EquipmentHistoryTab_equipment on Equipment
      @argumentDefinitions(filters: { type: "EquipmentTicketsInput!" }) {
        ...EquipmentHistoryList_equipment @arguments(input: $filters) #@defer
        __typename
        ticketConnections: tickets(input: $filters) {
          ...EquipmentHistoryCountsWidget_ticketsConnection #@defer
          ...EquipmentHistoryFilters_ticketsConnection #@defer
          ...EquipmentHistoryKnowledgeCenter_ticketsConnection #@defer
        }
      }
    `,
    equipmentRef,
  );

  if (!equipment) {
    return <ErrorPage resetErrorBoundary={refetch} />;
  }

  // This is getting the selected damage catalog id from the equipment fragment owner.
  // We do this instead of getting it from the local historyFilters object because the historyFilters object is not in sync with the data that is shown.
  //
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedDamageCatalogId = equipment.__fragmentOwner.variables?.filters?.damage ?? undefined;

  const showDamageKnowledgeCenter = false;
  const showActivityKnowledgeCenter = equipment.__typename === "Mould";

  const showKnowladgeCenter = selectedDamageCatalogId ? showActivityKnowledgeCenter : showDamageKnowledgeCenter;

  return (
    <Box flexDirection="column" display="flex" height="100%">
      <Box flex="1" minHeight={0}>
        <FullHeightGrid container direction="row" spacing={2}>
          <Grid2 size={{ xs: 2 }}>
            <Box height="100%" overflow="auto" pl={2}>
              <EquipmentHistoryFilters
                hideDamageFilter={equipment.__typename !== "Mould"}
                ticketsConnection={equipment.ticketConnections}
                filters={historyFilters}
                onChange={onHistoryFiltersChange}
              />
              <Box height={200} />
            </Box>
          </Grid2>
          <FullHeightGrid size={{ xs: "grow" }}>
            <Box height="100%" overflow="auto">
              {showKnowladgeCenter && (
                <EquipmentHistoryKnowledgeCenter
                  ticketsConnection={equipment.ticketConnections}
                  showPercentageLegend={false}
                  showZeroState={showDamageKnowledgeCenter}
                  showBackButton={showDamageKnowledgeCenter}
                  showCountLegend
                  showPercentageBasedOnTotalTickets
                  selectedDamageCatalogId={selectedDamageCatalogId}
                  selectedActivityCatalogId={activityCatalogId}
                  onSelectedDamageCatalogIdChange={(damageCatalogId) => {
                    onHistoryFiltersChange({ ...historyFilters, damage: damageCatalogId });
                    setActivityCatalogId(undefined);
                  }}
                  onSelectedActivityCatalogIdChange={(activityCatalogId) => {
                    setActivityCatalogId(activityCatalogId);
                  }}
                />
              )}
              {loading ? <EquipmentHistoryList.Skeleton /> : <EquipmentHistoryList.Suspense equipment={equipment} />}
            </Box>
          </FullHeightGrid>
          <Grid2 size={{ xs: 2 }}>
            <Box height="100%" overflow="auto" className="hide-scrollbar">
              <EquipmentHistoryCountsWidget ticketsConnections={equipment.ticketConnections} />
              <Box height={200} />
            </Box>
          </Grid2>
        </FullHeightGrid>
      </Box>
    </Box>
  );
};

const EquipmentHistoryTabLoader: FC = () => {
  const resourceNumberFromUrl = Number.parseInt((useParams() as { id: string }).id);
  const routeRootType = useRouteRootType();
  const [historyFilters, setHistoryFilters] = useState<EquipmentTicketsInput>({});
  const [queryRef, loadQuery] = useQueryLoader<EquipmentHistoryTabQueryType>(EquipmentHistoryTabForTicketQuery);
  const [isInFlight, startTransition] = useTransition();
  const queryVariables: EquipmentHistoryTabQuery$variables = useMemo(
    () => ({
      ticketInput: {
        ticketNumber: resourceNumberFromUrl,
      },
      equipmentInput: {
        equipmentNumber: resourceNumberFromUrl,
      },
      skipTicket: routeRootType === "equipment",
      skipEquipment: routeRootType === "ticket",
      filters: {
        ...historyFilters,
        orderBy: [
          {
            completedDate: "Descending",
          },
        ],
        status: ["Closed"],
      },
    }),
    [historyFilters, resourceNumberFromUrl, routeRootType],
  );

  const fetch = useCallback(() => {
    startTransition(() => {
      loadQuery(queryVariables, { fetchPolicy: "store-and-network" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return hasValue(queryRef) ? (
    <EquipmentHistoryTabContainer
      query={queryRef}
      historyFilters={historyFilters}
      onHistoryFiltersChange={setHistoryFilters}
      loading={isInFlight}
      refetch={fetch}
    />
  ) : (
    <FillWidthLoading />
  );
};

export const EquipmentHistoryTab = skeletonify("EquipmentHistoryTab", EquipmentHistoryTabLoader, () => (
  <FillWidthLoading />
));
