import { Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { TextWithLabel } from "../../../components/shared/TextWithLabel";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { MouldConstructionNumber_mould$key } from "./__generated__/MouldConstructionNumber_mould.graphql";

const ActualComponent: FC<{
  mould: MouldConstructionNumber_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldConstructionNumber_mould on Mould {
        constructionNumber
      }
    `,
    mouldRef,
  );

  return (
    <TextWithLabel
      text={data.constructionNumber?.toString() || ""}
      label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.CONSTRUCTION_NUMBER", "Construction no.")}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldConstructionNumber = skeletonify("MouldConstructionNumber", ActualComponent, SkeletonComponent);
