import Lottie from "lottie-react";
import { FC } from "react";

import loadingIndicatorAnimation from "../../assets/animations/loading-indicator.json";

export const ActivityIndicator: FC<{ size?: number }> = (props) => {
  const { size = 48 } = props;
  return (
    <Lottie
      style={{
        width: size,
        height: size,
      }}
      loop
      animationData={loadingIndicatorAnimation}
    />
  );
};
