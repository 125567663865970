import { FC } from "react";

import { TicketDetailsMouldDimmingDetailsFragment } from "../../../__apollo__/graphql";
import { useTranslation } from "../../../utility/i18n/translation";
import { TDDimmingsContent } from "../../ticket-details/TDDimmingDetails";

import { ARDCollapsibleSidebarWidget } from "./ARDCollapsibleSidebarWidget";

export const ARDDimmings: FC<TicketDetailsMouldDimmingDetailsFragment> = ({ equipment }) => {
  const { translate } = useTranslation();

  if (!(equipment?.__typename === "EquipmentValue" && equipment.value.__typename === "Mould")) {
    return null;
  }

  return (
    <ARDCollapsibleSidebarWidget
      title={translate("ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.DIMMINGS.TITLE", "Dimmings")}
    >
      <TDDimmingsContent {...equipment.value} />
    </ARDCollapsibleSidebarWidget>
  );
};
