import { hasValue } from "@lego/mst-error-utilities";
import { Button } from "@mui/material";
import { FC, ReactElement } from "react";
import { NavLink } from "react-router";

type TDWidgetAddButtonProps = {
  routeTo: string;
  icon: ReactElement;
  title: string;
};

export const TDWidgetAddButton: FC<TDWidgetAddButtonProps> = ({ icon, routeTo, title }) => {
  return (
    <Button component={NavLink} to={routeTo} variant="text" startIcon={icon} color="secondary" sx={{ p: 0 }}>
      {title}
    </Button>
  );
};

type TDWidgetDialogButtonProps = {
  icon: ReactElement;
  title: string;
  onClick: () => void;
  dataCy?: string;
  loading?: boolean;
};

export const TDWidgetDialogButton: FC<TDWidgetDialogButtonProps> = ({ icon, onClick, title, dataCy, loading }) => {
  return (
    <Button
      variant="text"
      startIcon={icon}
      color="secondary"
      onClick={onClick}
      sx={{ p: 0 }}
      data-cy={hasValue(dataCy) ? dataCy : undefined}
      loading={loading}
    >
      {title}
    </Button>
  );
};
