import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import * as duration from "duration-fns";
import { useMemo } from "react";
import { useFragment } from "react-relay";

import { useAmountFormatter } from "../../components/ticket-details/TDCostsTab";
import { useTranslation } from "../../utility/i18n/translation";

import { useLabels_ticket$key } from "./__generated__/useLabels_ticket.graphql";

export const useLabels = (ticketRef: useLabels_ticket$key) => {
  const { translate } = useTranslation();
  const { formatAmount } = useAmountFormatter();

  const { timeRegistrations, spareParts, cost } = useFragment(
    graphql`
      fragment useLabels_ticket on Ticket {
        timeRegistrations {
          timeSpent
        }
        spareParts(input: { type: CONSUMED }) {
          quantity
        }
        cost {
          total(input: { local: true }) {
            amount
            currency
          }
        }
      }
    `,
    ticketRef,
  );

  const timeLabel = useMemo(() => {
    if (!hasValue(timeRegistrations)) {
      return undefined;
    }

    const { days, hours, minutes } = duration.parse(timeRegistrations.timeSpent);

    const daysFormatted = days < 10 ? `0${days}` : days;
    const hoursFormatted = hours < 10 ? `0${hours}` : hours;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

    if (days > 0) {
      return translate(
        "TICKET_HISTORY_CARD.TIME_UNTIL_TICKET_CLOSED_WITH_DAYS",
        "{{ days }}d:{{ hours }}h:{{ minutes }}m",
        {
          hours: hoursFormatted,
          minutes: minutesFormatted,
          days: daysFormatted,
        },
      );
    } else {
      return translate("TICKET_HISTORY_CARD.TIME_UNTIL_TICKET_CLOSED", "{{ hours }}h:{{ minutes }}m", {
        hours: hoursFormatted,
        minutes: minutesFormatted,
      });
    }
  }, [timeRegistrations, translate]);

  const sparePartLabel = useMemo(() => {
    if (!hasValue(spareParts)) return undefined;

    const sparePartsConsumed = spareParts?.reduce((prev, current) => prev + current.quantity, 0);

    if (sparePartsConsumed === 0) return undefined;

    return translate("TICKET_HISTORY_CARD.SPARE_PARTS_CONSUMED", "{{ count }} spare parts consumed", {
      count: sparePartsConsumed,
    });
  }, [spareParts, translate]);

  const costLabel = useMemo(() => {
    if (!hasValue(cost) || !hasValue(cost.total)) {
      return undefined;
    }

    return translate("TICKET_HISTORY_CARD.COST", "{{ cost }} {{ currency }}", {
      cost: formatAmount(cost.total.amount),
      currency: cost.total.currency,
    });
  }, [cost, translate, formatAmount]);

  return {
    timeLabel,
    sparePartLabel,
    costLabel,
  };
};
