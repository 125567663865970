import { hasValue } from "@lego/mst-error-utilities";
import { Autocomplete, Grid2, TextField, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";

import { useTicketSearchFilterContext } from "./TicketSearchFilters";
import { TicketSearchEquipmentGroupFilter_query$key } from "./__generated__/TicketSearchEquipmentGroupFilter_query.graphql";

export interface TicketSearchEquipmentGroupFilterProps {
  query: TicketSearchEquipmentGroupFilter_query$key | null | undefined;
}

export const TicketSearchEquipmentGroupFilter: FC<TicketSearchEquipmentGroupFilterProps> = (props) => {
  const { query: queryRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchEquipmentGroupFilter_query on Query {
        # TODO: Make required and wrap in error boundary
        equipmentGroups {
          edges {
            node {
              id
              code
              description
            }
          }
        }
      }
    `,
    queryRef,
  );

  const {
    filters: { equipmentGroup: selectedEquipmentGroup },
    onChange,
  } = useTicketSearchFilterContext();

  const { translate } = useTranslation();

  const options = useMemo(
    () => data?.equipmentGroups?.edges.filter(hasValue).map(({ node }) => node) ?? [],
    [data?.equipmentGroups?.edges],
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("TICKET_LIST.FILTERS.EQUIPMENT_GROUP.TITLE", "Equipment group")}
        </Typography>
      </Grid2>
      <Grid2>
        <Autocomplete
          onChange={(_, value) =>
            onChange((prev) => ({
              ...prev,
              equipmentGroup: value ?? undefined,
            }))
          }
          value={selectedEquipmentGroup ?? null}
          getOptionLabel={({ code, description }) => `${code}, ${description}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={options.length === 0}
          options={options}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid2>
    </Grid2>
  );
};
