import { gql } from "@apollo/client";

import {
  IsAllowedToConsumeSparePartsFragment,
  IsAllowedToReturnSparePartsFragment,
  TicketStatus,
} from "../../__apollo__/graphql";

import { PermissionResult } from "./usePermissions";

export enum CanReturnSparePartsRejectionReasons {
  TicketFinanciallyClosed,
  InsufficientRole,
}

export const PERMISSIONS_RETURN_SPARE_PARTS = gql`
  fragment IsAllowedToReturnSpareParts on Ticket {
    id
    isFinanciallyClosed
  }
`;

export const canReturnSpareParts =
  (isAllowedToReturnSparePart: boolean) =>
  (data: IsAllowedToReturnSparePartsFragment): PermissionResult<CanReturnSparePartsRejectionReasons> => {
    const returnSparePartsRejectionReasons: CanReturnSparePartsRejectionReasons[] = [];

    if (data.isFinanciallyClosed) {
      returnSparePartsRejectionReasons.push(CanReturnSparePartsRejectionReasons.TicketFinanciallyClosed);
    }

    if (!isAllowedToReturnSparePart) {
      returnSparePartsRejectionReasons.push(CanReturnSparePartsRejectionReasons.InsufficientRole);
    }

    return {
      isAllowed: returnSparePartsRejectionReasons.length === 0,
      rejectionReasons: returnSparePartsRejectionReasons,
    };
  };

export const PERMISSIONS_CONSUME_SPARE_PARTS = gql`
  fragment IsAllowedToConsumeSpareParts on Ticket {
    id
    isAssigned
    status
  }
`;

export enum CanConsumeSparePartsRejectionReasons {
  TicketClosed,
  TicketNotAssigned,
  InsufficientRole,
}

export const canConsumeSpareParts =
  (isAllowedToConsumeSparePart: boolean) =>
  (data: IsAllowedToConsumeSparePartsFragment): PermissionResult<CanConsumeSparePartsRejectionReasons> => {
    const returnSparePartsRejectionReasons: CanConsumeSparePartsRejectionReasons[] = [];

    if (!data.isAssigned) {
      returnSparePartsRejectionReasons.push(CanConsumeSparePartsRejectionReasons.TicketNotAssigned);
    }

    if (data.status === TicketStatus.Closed) {
      returnSparePartsRejectionReasons.push(CanConsumeSparePartsRejectionReasons.TicketClosed);
    }

    if (!isAllowedToConsumeSparePart) {
      returnSparePartsRejectionReasons.push(CanConsumeSparePartsRejectionReasons.InsufficientRole);
    }

    return {
      isAllowed: returnSparePartsRejectionReasons.length === 0,
      rejectionReasons: returnSparePartsRejectionReasons,
    };
  };
