import { CloseMouldTicketFlow } from "../../__apollo__/graphql";

import { CloseTicketCleaningUpdateStateSlice, CloseTicketState } from "./close-ticket-context";

export type CloseTicketActions =
  | { type: "setShowErrorOnRepairStep"; show: boolean }
  | { type: "setShowErrorOnManHourStep"; show: boolean }
  | { type: "setShowErrorOnMachineHourStep"; show: boolean }
  | { type: "setShowCleaningWarning"; show: boolean }
  | { type: "setShowSpringWarning"; show: boolean }
  | { type: "setDidCleanMould"; didClean: boolean }
  | { type: "setDidSpringChange"; didSpringChange: boolean }
  | { type: "setSpringInterval"; newSpringInterval: number; dirty: boolean }
  | { type: "setSpringChangeStepEnabled"; payload: boolean }
  | { type: "chooseNewLocation"; locationId: string }
  | { type: "chooseNewSublocation"; sublocationId: string }
  | { type: "setNewSublocationText"; sublocationText: string }
  | { type: "setCloseTicketLoading"; loading: boolean }
  | { type: "setGATapproved"; approved: boolean }
  | { type: "setGATrejectionReason"; reason: string }
  | { type: "setShowGATrejectionReasonWarning"; show: boolean }
  | { type: "setManHoursValid"; valid: boolean }
  | { type: "setMachineHoursValid"; valid: boolean }
  | { type: "setCleaningRequired"; required: boolean }
  | { type: "setFlowVariant"; variant: CloseTicketState["flowVariant"] }
  | { type: "setFlowType"; variant: CloseMouldTicketFlow }
  | {
      type: "setCleaningUpdateState";
      newState: CloseTicketCleaningUpdateStateSlice;
    }
  | { type: "setSublocationInputValid"; valid: boolean };

export const closeTicketReducer = (state: CloseTicketState, action: CloseTicketActions): CloseTicketState => {
  switch (action.type) {
    case "setShowErrorOnRepairStep":
      return { ...state, showRepairDocsRequiredWarning: action.show };
    case "setShowErrorOnManHourStep":
      return { ...state, showManHoursRequiredWarning: action.show };
    case "setShowErrorOnMachineHourStep":
      return { ...state, showMachineHoursRequiredWarning: action.show };
    case "setShowCleaningWarning":
      return { ...state, showCleaningWarning: action.show };
    case "setShowSpringWarning":
      return { ...state, showSpringWarning: action.show };
    case "setSpringChangeStepEnabled":
      return { ...state, springStepEnabled: action.payload };
    case "setFlowVariant":
      return { ...state, flowVariant: action.variant };
    case "setFlowType":
      return { ...state, flowType: action.variant };
    case "setManHoursValid":
      return { ...state, manHoursValid: action.valid };
    case "setMachineHoursValid":
      return { ...state, machineHoursValid: action.valid };
    case "setCleaningRequired":
      return { ...state, cleaningRequired: action.required };
    case "setDidCleanMould":
      return {
        ...state,
        didCleanMould: action.didClean,
        showCleaningWarning: false,
      };
    case "setDidSpringChange":
      return {
        ...state,
        didSpringChange: action.didSpringChange,
      };
    case "setSpringInterval":
      return {
        ...state,
        springInterval: action.newSpringInterval,
        springIntervalDirty: action.dirty,
      };
    case "chooseNewLocation":
      return {
        ...state,
        location: {
          ...state.location,
          newLocationId: action.locationId,
        },
      };
    case "chooseNewSublocation":
      return {
        ...state,
        location: {
          ...state.location,
          newSublocationId: action.sublocationId,
          sublocationDirty: true,
          sublocationInputValid: true,
        },
      };
    case "setNewSublocationText":
      return {
        ...state,
        location: {
          ...state.location,
          newSublocationText: action.sublocationText,
          sublocationDirty: true,
        },
      };
    case "setSublocationInputValid":
      return {
        ...state,
        location: {
          ...state.location,
          sublocationInputValid: action.valid,
        },
      };

    case "setCloseTicketLoading":
      return {
        ...state,
        closeTicketLoading: action.loading,
      };

    case "setGATapproved":
      return {
        ...state,
        gatInfo: { ...state.gatInfo, approved: action.approved },
      };

    case "setGATrejectionReason":
      return {
        ...state,
        gatInfo: {
          ...state.gatInfo,
          showGATrejectionReasonMissingWarning: action.reason.length === 0,
          rejectionReason: action.reason,
        },
      };
    case "setShowGATrejectionReasonWarning":
      return {
        ...state,
        gatInfo: {
          ...state.gatInfo,
          showGATrejectionReasonMissingWarning: action.show,
        },
      };
    case "setCleaningUpdateState":
      return {
        ...state,
        cleaningState: action.newState,
      };
  }
};
