import Close from "@mui/icons-material/Close";
import { IconButton, InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import debounce from "lodash/debounce";
import { ChangeEvent, FC, useMemo, useState } from "react";

import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";

import { useSparePartSearchContext } from "./spare-part-search-context";

export const SparePartSearchField: FC = () => {
  const { translate } = useTranslation();
  const { dispatch } = useSparePartSearchContext();
  const [text, setText] = useState("");
  const { palette } = useTheme();

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps["onChange"] = (event) => {
      dispatch({
        type: "setSearchTerm",
        payload: event?.target.value ?? "",
      });
    };
    const debounced = debounce(searchDispatch, 500);

    return (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setText(event.target.value);
      debounced(event);
    };
  }, [dispatch, setText]);

  return (
    <TextField
      placeholder={translate("SPARE_PART_BOM.SEARCH.PLACEHOLDER", "Search spare part")}
      onChange={onChangeDebounced}
      value={text}
      sx={{ backgroundColor: palette.background.paper, width: "100%" }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Search fill={palette.text.disabled} />
            </InputAdornment>
          ),
          endAdornment: text && (
            <IconButton
              onClick={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                onChangeDebounced(e);
                setText("");
              }}
            >
              <Close />
            </IconButton>
          ),
        },
      }}
    />
  );
};
