import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { EquipmentLocation_equipment$key } from "./__generated__/EquipmentLocation_equipment.graphql";

const ActualComponent: FC<{
  equipment: EquipmentLocation_equipment$key | null | undefined;
}> = (props) => {
  const { equipment: equipmentRef } = props;
  const equipment = useFragment(
    graphql`
      fragment EquipmentLocation_equipment on Equipment {
        location {
          description
        }
        sublocation {
          ... on Sublocation {
            description
          }
          ... on FreeTextSublocation {
            description
          }
        }
      }
    `,
    equipmentRef ?? null,
  );

  if (!equipment) {
    return null;
  }

  const { location, sublocation } = equipment;

  return (
    <Grid2 container direction="column" spacing={1} alignItems="flex-end">
      {location && (
        <Grid2 container direction="row" justifyContent="end" alignItems="center">
          <Grid2 sx={{ alignItems: "center", display: "flex" }}>
            <Icons.Location color="disabled" />
          </Grid2>
          <Grid2>
            <Typography variant="body2" color="textSecondary">
              {location.description}
            </Typography>
          </Grid2>
        </Grid2>
      )}
      {sublocation && (
        <Grid2>
          <Typography variant="body2" color="textSecondary">
            {sublocation.description}
          </Typography>
        </Grid2>
      )}
    </Grid2>
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" spacing={1} alignItems="flex-end">
    <Grid2 container direction="row">
      <Grid2 sx={{ alignItems: "center", display: "flex" }}>
        <Icons.Location color="disabled" />
      </Grid2>
      <Grid2>
        <Typography variant="body2" color="textSecondary">
          <Skeleton width={70} />
        </Typography>
      </Grid2>
    </Grid2>
    <Grid2>
      <Typography variant="body2" color="textSecondary">
        <Skeleton width={70} />
      </Typography>
    </Grid2>
  </Grid2>
);

export const EquipmentLocation = skeletonify("EquipmentLocation", ActualComponent, SkeletonComponent);
