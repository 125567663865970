import { Dialog, Divider, Grid2 } from "@mui/material";
import { FC } from "react";

import { ConfidentialitySearchRowFragment } from "../__apollo__/graphql";
import { ConfidentialityDetailsAccessLog } from "../components/confidentiality-details/ConfidentialityDetailsAccessLog";
import { ConfidentialityDetailsCard } from "../components/confidentiality-details/ConfidentialityDetailsCard";

export const ConfidentialityDetailsDialog: FC<{
  open: boolean;
  onClosePressed: () => void;
  data?: ConfidentialitySearchRowFragment;
}> = ({ open, data, onClosePressed }) => {
  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={onClosePressed}>
      <Grid2 size={{ xs: "grow" }}>
        {data && <ConfidentialityDetailsCard data={data} onClosePressed={onClosePressed} />}
      </Grid2>
      <Divider sx={{ my: 2 }} />
      <Grid2 size={{ xs: "grow" }} sx={{ mt: 4 }}>
        {data && <ConfidentialityDetailsAccessLog id={data.id} />}
      </Grid2>
    </Dialog>
  );
};
