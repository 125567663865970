import { Collapse, Grid2, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { FC } from "react";

import { GMDatePicker } from "../../components/shared/GMDatePicker";
import { getTimestampForRelay } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";

import { useTicketSearchFilterContext } from "./TicketSearchFilters";

const minDate = new Date(1990, 1, 1);
export const TicketSearchCompletedDateFilter: FC = () => {
  const { translate } = useTranslation();
  const {
    onChange,
    filters: { completedAfterDate, completedBeforeDate, status },
  } = useTicketSearchFilterContext();

  const handleCompletedFromChanged = (newValue: Date | null) => {
    const newDate = newValue ? getTimestampForRelay(startOfDay(newValue).getTime()) : undefined;
    onChange((prev) => ({
      ...prev,
      completedAfterDate: newDate,
    }));
  };

  const handleCompletedToChanged = (newValue: Date | null) => {
    const newDate = newValue ? getTimestampForRelay(endOfDay(newValue).getTime()) : undefined;
    onChange((prev) => ({
      ...prev,
      completedBeforeDate: newDate,
    }));
  };

  const today = new Date();

  return (
    <Collapse in={status?.length === 1 && status.includes("Closed")} timeout={400}>
      <Grid2 container direction="column" spacing={1}>
        <Grid2>
          <Typography variant="subtitle2">
            {translate("TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_DATE", "Completed date")}
          </Typography>
        </Grid2>
        <Grid2>
          <GMDatePicker
            title={translate("TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_FROM", "From")}
            showOptional={false}
            value={completedAfterDate?.date ? new Date(completedAfterDate?.date) : null}
            onChange={handleCompletedFromChanged}
            minDate={minDate}
            maxDate={completedBeforeDate?.date ? new Date(completedBeforeDate?.date) : today}
          />
        </Grid2>
        <Grid2>
          <GMDatePicker
            title={translate("TICKET_LIST.FILTERS.COMPLETED_DATE.COMPLETED_TO", "To")}
            showOptional={false}
            value={completedBeforeDate?.date ? new Date(completedBeforeDate?.date) : null}
            onChange={handleCompletedToChanged}
            minDate={completedAfterDate?.date ? new Date(completedAfterDate?.date) : minDate}
            maxDate={today}
          />
        </Grid2>
      </Grid2>
    </Collapse>
  );
};
