import { Container } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { FC, SyntheticEvent, useState } from "react";

import { useAreaAndProcessContext } from "../../contexts/area/area-and-process-context";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { GMTabPanel } from "../components/GMTabPanel";
import { SparePartBomTab } from "../dashboard/spare-part-bom/SparePartBom";

import { SparePartsTabContainer } from "./SparePartsTabContainer";

export const SparePartsTabWithBom: FC<{ equipmentNumber?: number }> = ({ equipmentNumber }) => {
  const [value, setValue] = useState(0);
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const { relayProcessId } = useAreaAndProcessContext();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!equipmentNumber || !relayProcessId) {
    showSnack({
      message: translate(
        "TICKET_DETAILS.SPARE_PARTS_TAB.SUB_TABS.ERROR_SNACK",
        "Could not retrieve spare part details for this ticket",
      ),
      variant: "error",
    });

    throw new Error("Missing equipment number or process ID in ticket details spare part tab");
  }

  return (
    <Container maxWidth="xl">
      <Tabs centered value={value} onChange={handleChange}>
        <Tab label={translate("TICKET_DETAILS.SPARE_PARTS_TAB.SUB_TABS.CONSUMPTION", "Consumption information")} />
        <Tab label={translate("TICKET_DETAILS.SPARE_PARTS_TAB.SUB_TABS.BOM", "Bill of Materials")} />
      </Tabs>
      <GMTabPanel value={value} index={0}>
        <SparePartsTabContainer marginTop={2} />
      </GMTabPanel>
      <GMTabPanel value={value} index={1}>
        <SparePartBomTab equipmentNumber={equipmentNumber} processId={relayProcessId} marginTop={2} />
      </GMTabPanel>
    </Container>
  );
};
