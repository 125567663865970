import { Button, Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useLogout } from "../../components/profile/useLogout";
import { useTranslation } from "../../utility/i18n/translation";
import { EmployeeAvatar } from "../components/employee/EmployeeAvatar";
import { skeletonify } from "../skeleton";

import { DashboardHeader_viewer$key } from "./__generated__/DashboardHeader_viewer.graphql";

const ImageSize = 180;
const ActualComponent: FC<{ viewer: DashboardHeader_viewer$key }> = (props) => {
  const { viewer: viewerRef } = props;
  const { me } = useFragment(
    graphql`
      fragment DashboardHeader_viewer on Viewer {
        me @required(action: THROW) {
          firstName
          lastName
          ...EmployeeAvatar_employee
        }
      }
    `,
    viewerRef,
  );
  const { translate } = useTranslation();
  const { logOut } = useLogout();
  const { firstName, lastName } = me;

  return (
    <Grid2 container direction="column" alignItems="center" spacing={2} style={{ marginBottom: 48 }}>
      <Grid2>
        <EmployeeAvatar employee={me} size={ImageSize} />
      </Grid2>

      <Grid2>
        <Typography variant="h1">
          {translate("ASSIGNED_TO_ME.WELCOME", "Welcome back, {{ firstName }} {{ lastName }}", {
            firstName,
            lastName,
          })}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography variant="h3">
          {translate("ASSIGNED_TO_ME.NOT_YOU", "Not {{ firstName }} {{ lastName }}?", {
            firstName,
            lastName,
          })}
        </Typography>
      </Grid2>
      <Grid2>
        <Button onClick={logOut} style={{ textTransform: "none" }}>
          <Typography color="secondary">{translate("ASSIGNED_TO_ME.SIGNOUT_BUTTON", "Sign out")}</Typography>
        </Button>
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" alignItems="center" spacing={2} style={{ marginBottom: 48 }}>
    <Grid2>
      <EmployeeAvatar.Skeleton size={ImageSize} />
    </Grid2>

    <Grid2 size={{ xs: "grow" }}>
      <Typography variant="h1">
        <Skeleton variant="text" width={660} />
      </Typography>
    </Grid2>
    <Grid2 size={{ xs: "grow" }}>
      <Typography variant="h3">
        <Skeleton width={260} />
      </Typography>
    </Grid2>
    <Grid2 size={{ xs: "grow" }}>
      <Button disabled style={{ textTransform: "none" }}>
        <Typography>
          <Skeleton width={74} />
        </Typography>
      </Button>
    </Grid2>
  </Grid2>
);

export const DashboardHeader = skeletonify("DashboardHeader", ActualComponent, SkeletonComponent);
