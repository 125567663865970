import { Grid2, Grid2Props, StepIconProps, styled, Typography } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../utility/icons";

interface StyledGridProps extends Grid2Props {
  active?: boolean;
}

const StyledGridDynamic = styled(Grid2, {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledGridProps>(({ active, theme }) => ({
  color: theme.palette.text.disabled,
  marginRight: 8,
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));

const GridCircle = styled(Grid2)(() => ({
  width: iconSize,
  height: iconSize,
  borderRadius: "50%",
  border: "2px solid currentColor",
}));

const iconSize = 32;

export const ARDStepIcon: FC<StepIconProps> = ({ active, completed, icon: step }) => {
  return (
    <StyledGridDynamic active={active}>
      {completed ? (
        <Grid2
          container
          sx={{
            height: iconSize,
            width: iconSize,
            borderRadius: iconSize / 2,
            backgroundColor: "primary.main",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 style={{ height: 24 }}>
            <Icons.CheckmarkBold style={{ fontSize: 18, color: "white" }} />
          </Grid2>
        </Grid2>
      ) : (
        <GridCircle container justifyContent="center" alignItems="center">
          <Grid2>
            <Typography>{step}</Typography>
          </Grid2>
        </GridCircle>
      )}
    </StyledGridDynamic>
  );
};
