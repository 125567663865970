import { Button, Chip, DialogContentText, Divider, Grid2, Stack, TextField, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import concat from "lodash/concat";
import without from "lodash/without";
import { ChangeEvent, Dispatch, FC, Fragment, useCallback, useEffect, useMemo, useReducer } from "react";
import { useMutation } from "react-relay";

import { SimpleDialog } from "../../../components/shared/SimpleDialog";
import { useTranslation } from "../../../utility/i18n/translation";
import { getLabelForPriority, useColorForPriority, useLabelForTicketStatus } from "../hall-monitor/HallMonitorItem";

import {
  HallMonitorCreateDialogMutation,
  TicketsPriorityInput,
  TicketsStatusInput,
} from "./__generated__/HallMonitorCreateDialogMutation.graphql";
import {
  HallMonitorActions,
  HallMonitorCreateState,
  hallMonitorMapStateToMutationInput,
  hallMonitorReducer,
  initialHallMonitorState,
} from "./hall-monitor-create-state";

export const HallMonitorCreateDialog: FC<{
  open: boolean;
  processId: string;
  closeDialog: () => void;
}> = ({ processId, open, closeDialog }) => {
  const { translate } = useTranslation();

  const [state, dispatch] = useReducer(hallMonitorReducer, initialHallMonitorState);

  useEffect(() => {
    dispatch({ type: "setProcessId", id: processId });
  }, [processId]);

  const [createVariant, createLoading] = useMutation<HallMonitorCreateDialogMutation>(graphql`
    mutation HallMonitorCreateDialogMutation($createHallMonitorVariantInput: MutationCreateHallMonitorScreenInput!) {
      createHallMonitorScreen(input: $createHallMonitorVariantInput) {
        id
        process {
          hallMonitorScreens {
            id
            title
          }
        }
      }
    }
  `);

  const mutationInput = useMemo(() => hallMonitorMapStateToMutationInput(state), [state]);

  const onSave = useCallback(() => {
    if (mutationInput) {
      createVariant({
        variables: mutationInput,
        onCompleted: () => {
          dispatch({ type: "clearState" });
          closeDialog();
        },
      });
    }
  }, [createVariant, closeDialog, mutationInput]);

  const closeDialogAndResetState = useCallback(() => {
    closeDialog();
    dispatch({ type: "clearState" });
  }, [closeDialog]);

  return (
    <SimpleDialog
      onDismiss={closeDialogAndResetState}
      secondaryAction={{
        secondaryActionLabel: translate("HALL_MONITOR.CREATE_DIALOG.CANCEL", "Cancel"),
        secondaryActionPressed: closeDialogAndResetState,
      }}
      open={open}
      title={translate("HALL_MONITOR.CREATE_DIALOG.TITLE", "Add hall monitor")}
      primaryAction={{
        primaryActionLabel: translate("HALL_MONITOR.CREATE_DIALOG.CREATE_BUTTON", "Create"),
        primaryActionPressed: onSave,
        primaryActionLoading: createLoading,
        primaryActionDisabled: !mutationInput,
      }}
      content={{
        type: "node",
        node: <Content state={state} dispatch={dispatch} />,
      }}
      maxWidth={"lg"}
    />
  );
};

type DialogContentProps = {
  state: HallMonitorCreateState;
  dispatch: Dispatch<HallMonitorActions>;
};

const Content: FC<DialogContentProps> = ({ dispatch, state }) => {
  const { translate } = useTranslation();
  const onAddSectionPressed = useCallback(() => {
    dispatch({ type: "addSection" });
  }, [dispatch]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: "setTitle", title: e.target.value });
    },
    [dispatch],
  );

  return (
    <Stack spacing={2}>
      <DialogContentText sx={{ mb: 2 }}>
        {translate("HALL_MONITOR.CREATE_DIALOG.DESCRIPTION", "Write a short title for the variant")}
      </DialogContentText>

      <TextField
        variant="outlined"
        onChange={onChange}
        fullWidth
        rows={1}
        inputProps={{ maxLength: 20 }}
        placeholder={translate("HALL_MONITOR.CREATE_DIALOG.PLACEHOLDER", "Title...")}
      />
      <Divider />
      {state.sections.map((val, index) => (
        <Fragment key={index}>
          <Typography variant="subtitle2">
            {translate("HALL_MONITOR.CREATE_DIALOG.SECTION_HEADER", "Section {{ index }}", { index: index + 1 })}
          </Typography>
          <Section dispatch={dispatch} section={val} sectionIndex={index} />
          {index < state.sections.length - 1 && <Divider sx={{ borderBottomWidth: "thick" }} />}
        </Fragment>
      ))}

      <Button onClick={onAddSectionPressed}>
        {translate("HALL_MONITOR.CREATE_DIALOG.ADD_SECTION", "Add section")}
      </Button>
    </Stack>
  );
};

export type HallMonitorPriorities = Exclude<
  TicketsPriorityInput,
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value"
>;
const priorityOptions: HallMonitorPriorities[] = [
  "Priority1",
  "Priority2",
  "Priority3",
  "Priority4",
  "Priority5",
  "Priority6",
  "Priority7",
  "Priority8",
  "Priority9",
  "PriorityA",
];

export type HallMonitorTicketsStatuses = Exclude<
  TicketsStatusInput,
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value"
>;
const statusOptions: HallMonitorTicketsStatuses[] = ["Closed", "InProgress", "NotStarted", "Released"];

export const HallMonitorTypes = {
  priorityOptions,
  statusOptions,
};

const Section: FC<{
  section: HallMonitorCreateState["sections"][0];
  sectionIndex: number;
  dispatch: Dispatch<HallMonitorActions>;
}> = ({ dispatch, section: { priority: priorities, status: statuses = [] }, sectionIndex }) => {
  const { translate } = useTranslation();
  const { getColorForPriority } = useColorForPriority();
  const { getLabelForTicketStatus } = useLabelForTicketStatus();

  const onPrioritySelected = useCallback(
    (priority: HallMonitorPriorities) => {
      const newPriorities = priorities.includes(priority)
        ? without(priorities, priority)
        : concat(priorities, priority);
      dispatch({
        type: "editSectionPriorities",
        index: sectionIndex,
        priorities: newPriorities,
      });
    },
    [dispatch, priorities, sectionIndex],
  );

  const onTicketStatusSelected = useCallback(
    (status: HallMonitorTicketsStatuses) => {
      const newStatuses = statuses.includes(status) ? without(statuses, status) : concat(statuses, status);
      dispatch({
        type: "editSectionTicketStatuses",
        index: sectionIndex,
        statuses: newStatuses,
      });
    },
    [dispatch, sectionIndex, statuses],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "editSectionTitle",
        index: sectionIndex,
        title: e.target.value,
      });
    },
    [dispatch, sectionIndex],
  );

  const onCodingChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // Remove white spaces, split on comma
      const values = e.target.value.replace(/\s/g, "").split(",");
      dispatch({
        type: "editSectionCoding",
        index: sectionIndex,
        coding: values,
      });
    },
    [dispatch, sectionIndex],
  );

  return (
    <Stack spacing={2}>
      <DialogContentText>
        {translate("HALL_MONITOR.CREATE_DIALOG.VARIANT_DESCRIPTION", "Write a short title for the section")}
      </DialogContentText>

      <TextField
        variant="outlined"
        onChange={onChange}
        fullWidth
        rows={1}
        inputProps={{ maxLength: 20 }}
        placeholder={translate("HALL_MONITOR.CREATE_DIALOG.SECTION_TITLE_PLACEHOLDER", "Section title...")}
      />
      <DialogContentText>
        {translate("HALL_MONITOR.CREATE_DIALOG.PRIORITIES", "Choose which priorities to include")}
      </DialogContentText>
      <Grid2 container spacing={1}>
        {priorityOptions.map((option) => {
          const onClick = () => {
            onPrioritySelected(option);
          };
          const selected = priorities.includes(option);

          return (
            <Grid2 key={option}>
              <Chip
                label={getLabelForPriority(option)}
                sx={{
                  backgroundColor: selected ? getColorForPriority(option) : undefined,
                  borderColor: selected ? undefined : getColorForPriority(option),
                }}
                variant={selected ? "filled" : "outlined"}
                onClick={onClick}
              />
            </Grid2>
          );
        })}
      </Grid2>
      <DialogContentText>
        {translate("HALL_MONITOR.CREATE_DIALOG.STATUSES", "Choose which ticket statuses to include")}
      </DialogContentText>
      <Grid2 container spacing={1}>
        {statusOptions.map((option) => {
          const onClick = () => {
            onTicketStatusSelected(option);
          };

          const selected = statuses.includes(option);

          return (
            <Grid2 key={option}>
              <Chip
                label={getLabelForTicketStatus(option)}
                variant={selected ? "filled" : "outlined"}
                onClick={onClick}
              />
            </Grid2>
          );
        })}
      </Grid2>
      <DialogContentText>
        {translate("HALL_MONITOR.CREATE_DIALOG.CODING", 'Coding (comma separated, i.e. "AA11, AA12")')}
      </DialogContentText>

      <TextField
        variant="outlined"
        onChange={onCodingChanged}
        fullWidth
        rows={1}
        inputProps={{ maxLength: 200 }}
        placeholder={translate("HALL_MONITOR.CREATE_DIALOG.PLACEHOLDER_CODING", "Coding...")}
      />
    </Stack>
  );
};
