import { ApolloError, gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { useEffect } from "react";

import {
  GetRepairFlowVariantForTicketQuery,
  GetRepairFlowVariantForTicketQueryVariables,
  RepairComponentOptionsQuery,
  RepairComponentOptionsQueryVariables,
  RepairDescriptionActivitiesQuery,
  RepairDescriptionActivitiesQueryVariables,
  RepairDescriptionCausesQuery,
  RepairDescriptionCausesQueryVariables,
  RepairDescriptionDamagesQuery,
  RepairDescriptionDamagesQueryVariables,
  RepairDocumentationFlowVariant,
  SubEquipmentOptionsQuery,
  SubEquipmentOptionsQueryVariables,
} from "../../__apollo__/graphql";
import { useGMLazyQuery, useGMQuery } from "../../apollo/customApolloHooks";

import { useAddRepairDescriptionContext } from "./add-repair-description-context";

export const REPAIR_FLOW_VARIANT = gql`
  query GetRepairFlowVariantForTicket($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        repairDocumentation {
          id
          repairFlowVariant
        }
      }
    }
  }
`;

export const REPAIR_DESCRIPTION_SUB_EQUIPMENTS = gql`
  query SubEquipmentOptions($input: RepairDocumentationSubEquipmentOptionsForTicket!) {
    repairDescriptionSubEquipmentOptions(input: $input) {
      id
      ...SubEquipmentOption
    }
  }
  fragment SubEquipmentOption on RepairDocumentationSubEquipmentOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_COMPONENTS = gql`
  query RepairComponentOptions($input: RepairDocumentationComponentOptionsForTicket!) {
    repairDescriptionComponentOptions(input: $input) {
      id
      ...ComponentOption
    }
  }
  fragment ComponentOption on RepairDocumentationComponentOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_DAMAGES = gql`
  query RepairDescriptionDamages($input: RepairDescriptionDamageOptionsForTicket!) {
    repairDescriptionDamageOptions(input: $input) {
      id
      ...DamageOption
    }
  }
  fragment DamageOption on RepairDocumentationDamageOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_CAUSES = gql`
  query RepairDescriptionCauses($input: RepairDescriptionCauseOptionsForTicket!) {
    repairDescriptionCauseOptions(input: $input) {
      id
      ...CauseOption
    }
  }

  fragment CauseOption on RepairDocumentationCauseOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_ACTIVITIES = gql`
  query RepairDescriptionActivities($input: RepairDescriptionActivityOptionsForTicket!) {
    repairDescriptionActivityOptions(input: $input) {
      id
      ...ActivityOption
    }
  }

  fragment ActivityOption on RepairDocumentationActivityOption {
    id
    description
  }
`;

// Pre-fetches repair doc data by loading it into the Apollo cache
export const usePrefetchRepairDescriptionData = (
  ticketId: string,
): {
  loading: boolean;
  errors: ApolloError | undefined;
} => {
  const {
    dispatch,
    state: { chosenDamage, chosenSubEquipment, chosenComponent },
  } = useAddRepairDescriptionContext();

  const {
    data: variantData,
    loading: variantLoading,
    error: variantError,
  } = useGMQuery<GetRepairFlowVariantForTicketQuery, GetRepairFlowVariantForTicketQueryVariables>(REPAIR_FLOW_VARIANT, {
    variables: { input: { id: ticketId } },
    onCompleted: (data) => {
      if (
        data.ticket.__typename === "Ticket" &&
        data.ticket.repairDocumentation.repairFlowVariant === RepairDocumentationFlowVariant.Packing
      ) {
        dispatch({
          type: "set_repair_flow_variant",
          variant: RepairDocumentationFlowVariant.Packing,
        });
      }
    },
  });

  const { loading: subEquipmentLoading } = useGMQuery<SubEquipmentOptionsQuery, SubEquipmentOptionsQueryVariables>(
    REPAIR_DESCRIPTION_SUB_EQUIPMENTS,
    {
      skip:
        variantLoading ||
        (variantData?.ticket.__typename === "Ticket" &&
          variantData.ticket.repairDocumentation.repairFlowVariant === RepairDocumentationFlowVariant.Baseline),
      variables: { input: { ticketId } },
      fetchPolicy: "network-only",
    },
  );

  const { loading: componentsLoading, error: subEquipmentError } = useGMQuery<
    RepairComponentOptionsQuery,
    RepairComponentOptionsQueryVariables
  >(REPAIR_DESCRIPTION_COMPONENTS, {
    skip: !hasValue(chosenSubEquipment),
    variables: {
      input: { ticketId, subEquipmentId: chosenSubEquipment?.id ?? "" },
    },
    fetchPolicy: "network-only",
  });

  const { loading: damagesLoading, error: damagesError } = useGMQuery<
    RepairDescriptionDamagesQuery,
    RepairDescriptionDamagesQueryVariables
  >(REPAIR_DESCRIPTION_DAMAGES, {
    variables: { input: { ticketId, componentId: chosenComponent?.id } },
    fetchPolicy: "network-only",
  });

  const [getCauses, { loading: causesLoading, error: causesError }] = useGMLazyQuery<
    RepairDescriptionCausesQuery,
    RepairDescriptionCausesQueryVariables
  >(REPAIR_DESCRIPTION_CAUSES);

  const { loading: activitiesLoading, error: activitiesError } = useGMQuery<
    RepairDescriptionActivitiesQuery,
    RepairDescriptionActivitiesQueryVariables
  >(REPAIR_DESCRIPTION_ACTIVITIES, {
    variables: { input: { ticketId } },
    onCompleted: (data) => {
      dispatch({
        type: "set_should_show_activities",
        show: hasValue(data) && data.repairDescriptionActivityOptions.length > 0,
      });
    },
  });

  useEffect(() => {
    if (hasValue(chosenDamage) && !causesLoading) {
      getCauses({
        variables: { input: { chosenDamage: chosenDamage.id, ticketId } },
      });
    }
  }, [causesLoading, chosenDamage, getCauses, ticketId]);

  return {
    loading: damagesLoading || activitiesLoading || subEquipmentLoading || componentsLoading,
    errors: damagesError || activitiesError || causesError || variantError || subEquipmentError,
  };
};
