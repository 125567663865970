import { Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { TextWithLabel } from "../../../components/shared/TextWithLabel";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { MouldCavitiesWithDimmings_mould$key } from "./__generated__/MouldCavitiesWithDimmings_mould.graphql";

const ActualComponent: FC<{
  mould: MouldCavitiesWithDimmings_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldCavitiesWithDimmings_mould on Mould {
        elementsPerShot
        dimmings {
          count
        }
      }
    `,
    mouldRef,
  );

  return (
    <TextWithLabel
      text={`${data.elementsPerShot ?? "?"} / ${data.dimmings?.count ?? "?"}`}
      label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.CAVITIES_AND_DIMMINGS", "Elements / dimmings")}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldCavitiesWithDimmings = skeletonify("MouldCavitiesWithDimmings", ActualComponent, SkeletonComponent);
