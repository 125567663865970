import { Typography } from "@mui/material";
import { ReactElement } from "react";

import { FillWidthLoading } from "../components/shared/FillWidthLoading";
import { useTranslation } from "../utility/i18n/translation";

type TicketByIdType<T> = {
  ticket: { __typename?: "NotFound" } | TicketWithTypename<T>;
};

type TicketWithTypename<T> = { __typename?: "Ticket" } & T;

type TheComponent = {
  component: ReactElement;
  type: "errorOrLoadingComponent";
};
export type TypeChecked<T> = { data: TicketWithTypename<T>; type: "data" };
type HookReturn<T> = TheComponent | TypeChecked<T>;

type HookInput<T> = {
  loading: boolean;
  data?: TicketByIdType<T>;
  customLoadingComponent?: ReactElement;
};

/**
 * A util hook for rendering loading/errors when fetching a ticket by ID, that in turn type checks your input to make sure it is of the type you want.
 *
 * Example usage:
 *
 * ```typescript
 *
 * const checked = useTicketQueryTypeCheck<GetTicketDetails_ticket_Ticket>({
    data,
    loading,
  });

  if (checked.type === 'Component') {
    return checked.component;
  }

  return [The components you want to return if the ticket is fetched successfully]


  ```
 *
 * @param param0 Your data from Apollo client, as well as the `loading` status
 * @returns Either the relevant loading/error components, or the data of the type you want
 */
export const useTicketQueryTypeCheck = <T,>({ data, loading, customLoadingComponent }: HookInput<T>): HookReturn<T> => {
  const { translate } = useTranslation();

  if (loading) {
    return {
      type: "errorOrLoadingComponent",
      component: customLoadingComponent ?? <FillWidthLoading />,
    };
  }

  if (!data) {
    return {
      type: "errorOrLoadingComponent",
      component: <Typography color="error">{translate("TICKET_DETAILS.NO_DATA", "Could not fetch data")}</Typography>,
    };
  }

  if (data.ticket.__typename !== "Ticket") {
    return {
      type: "errorOrLoadingComponent",
      component: (
        <Typography color="error">
          {translate("TICKET_DETAILS.COULD_NOT_FIND_TICKET", "Could not find ticket")}
        </Typography>
      ),
    };
  }

  return { data: data.ticket, type: "data" };
};
