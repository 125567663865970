import { gql } from "@apollo/client";
import { FC } from "react";

import { CodingFragment } from "../../../__apollo__/graphql";

import { ChipWithDefaultBackground } from "./TicketCardMouldChips";

export const TICKET_CARD_CODING_FRAGMENT = gql`
  fragment Coding on Ticket {
    id
    coding {
      ... on Coding {
        number
        text
      }
    }
  }
`;

export const TicketCardCoding: FC<CodingFragment> = ({ coding }) => {
  if (coding?.__typename !== "Coding") {
    return null;
  }

  const { text, number: theCode } = coding;

  return <ChipWithDefaultBackground label={`${theCode} : ${text}`} />;
};
