import { Grid2, Typography } from "@mui/material";
import { compareAsc, formatDistanceToNow, parseISO } from "date-fns";
import { FC } from "react";

import { localeToDateFnsLocale } from "../../../utility/i18n/i18n";
import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";

export const CountdownTimer: FC<{ endDate: string }> = ({ endDate }) => {
  const iconSize = 20;
  const pastDueDate = compareAsc(new Date(), parseISO(endDate)) === 1;
  const { locale } = useTranslation();

  return (
    <Grid2 container direction="row" justifyContent="center" alignItems="center" alignContent="center">
      <Icons.Countdown
        style={{
          width: iconSize,
          height: iconSize,
        }}
        color={pastDueDate ? "error" : "primary"}
      />
      <Typography color={pastDueDate ? "error" : "primary"} variant="body2">
        {formatDistanceToNow(parseISO(endDate), {
          addSuffix: true,
          locale: localeToDateFnsLocale(locale),
        })}
      </Typography>
    </Grid2>
  );
};
