import { hasValue } from "@lego/mst-error-utilities";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useEffect } from "react";
import { useFragment } from "react-relay";

import { SublocationFreeText } from "../components/equipment/SublocationFreeText";
import { SublocationSelect } from "../components/equipment/SublocationSelect";

import { useCreateTicketContext } from "./create-ticket-context";
import { SublocationInput } from "./CreateTicketLocationStep";
import { CreateTicketSublocationComponent_equipment$key } from "./__generated__/CreateTicketSublocationComponent_equipment.graphql";

interface Props {
  equipment: CreateTicketSublocationComponent_equipment$key;
  loading: boolean;
  onChange: (sublocation: SublocationInput) => void;
}

export const SublocationComponent: FC<Props> = ({ equipment: equipmentRef, loading, onChange }) => {
  const { state, dispatch } = useCreateTicketContext();

  const equipment = useFragment(
    graphql`
      fragment CreateTicketSublocationComponent_equipment on Equipment {
        __typename
        sublocation {
          __typename
          ... on FreeTextSublocation {
            description
          }
          ... on Sublocation {
            description
          }
        }
        ...SublocationFreeText_equipment
        ...SublocationSelect_equipment
      }
    `,
    equipmentRef,
  );

  const { sublocation } = equipment;

  const isMandatory = equipment.__typename === "Mould" || equipment.__typename === "PackProcessingEquipment";

  useEffect(() => {
    dispatch({
      type: "set_sublocation",
      payload: {
        isMandatory,
        text:
          equipment.sublocation?.__typename === "FreeTextSublocation" ||
          equipment.sublocation?.__typename === "Sublocation"
            ? equipment.sublocation.description
            : undefined,
      },
    });
  }, [dispatch, equipment.sublocation, isMandatory]);

  const handleFreeText = (newValue: string) => {
    dispatch({
      type: "set_sublocation",
      payload: { isMandatory, text: newValue },
    });
    onChange({ sublocationText: newValue });
  };

  const handleSelect = (newValue: string) => {
    dispatch({
      type: "set_sublocation",
      payload: { isMandatory, text: newValue },
    });
    onChange({ sublocationId: newValue });
  };

  if (
    sublocation?.__typename === "Sublocation" ||
    (!hasValue(sublocation) && equipment.__typename === "PackProcessingEquipment")
  ) {
    if (state.currentStep !== "location") {
      return <Typography>{sublocation?.description ?? ""}</Typography>;
    }
    return <SublocationSelect equipment={equipment} loading={loading} onSublocationSelected={handleSelect} />;
  }

  if (state.currentStep !== "location") {
    return <Typography>{sublocation?.__typename === "FreeTextSublocation" ? sublocation?.description : ""}</Typography>;
  }

  return (
    <SublocationFreeText loading={loading} equipment={equipment} onTextChanged={handleFreeText} debounceValue={3000} />
  );
};
