import { TextField } from "@mui/material";
import { ChangeEvent, FC } from "react";

import { useTranslation } from "../../utility/i18n/translation";

export const CmsTicketDescription: FC<{
  onChange: (value: string | null) => void;
}> = ({ onChange }) => {
  const { translate } = useTranslation();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.trim() !== "") {
      onChange(value);
    } else {
      onChange(null);
    }
  };

  return (
    <TextField
      placeholder={translate("CREATE_CMS_TICKET.DESCRIPTION", "Description")}
      multiline
      minRows={2}
      onChange={handleOnChange}
      fullWidth
      inputProps={{ maxLength: 5000 }} // Sanity limit
    />
  );
};
