import { gql } from "@apollo/client";
import { Grid2, IconButton, Tooltip } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";

import { TicketDetailsLocationFragment } from "../../__apollo__/graphql";
import { useAreaAndProcessContext } from "../../contexts/area";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION } from "../../utility/permissions/ticket";
import { usePermissions } from "../../utility/permissions/usePermissions";
import { CardWithTitle } from "../shared/CardWithTitle";
import { EQUIPMENT_LOCATION_FRAGMENT } from "../shared/EquipmentLocationSection";
import { TextWithLabel } from "../shared/TextWithLabel";

import { TDUpdateLocationDialog } from "./TDUpdateLocationDialog";
import { TDWidgetDialogButton } from "./TDWidgetAddButton";

export const TD_CURRENT_LOCATION = gql`
  fragment TicketDetailsLocation on IEquipment {
    id
    plantId
    ...EquipmentLocation
    ...CanEditEquipmentLocation
    location {
      ... on Location {
        id
        description
      }
    }
    subLocation {
      ... on SubLocationText {
        id
        value
      }
      ... on SubLocation {
        id
        description
      }
    }
    ... on Mould {
      id
      rack
      mouldMachine
    }
  }
  ${PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION}
  ${EQUIPMENT_LOCATION_FRAGMENT}
`;

export const TDCurrentLocationOld: FC<TicketDetailsLocationFragment> = (equipment) => {
  const { translate } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const closeDialog = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const {
    ticket: { canEditEquipmentLocation },
  } = usePermissions();

  const subLocation = useMemo(() => {
    const { subLocation } = equipment;

    switch (subLocation?.__typename) {
      case "SubLocation":
        return subLocation.description;
      case "SubLocationText":
        return subLocation.value;
      case "NotFound":
      default:
        return "";
    }
  }, [equipment]);

  if (!equipment || equipment.location?.__typename !== "Location") {
    return null;
  }

  const rightItem = canEditEquipmentLocation(equipment) ? (
    <TDWidgetDialogButton
      icon={<Icons.Edit />}
      title={translate("TICKET_DETAILS.CARDS.LOCATION_ADD_BUTTON", "Location")}
      onClick={openDialog}
    />
  ) : undefined;

  return (
    <CardWithTitle title={translate("TICKET_DETAILS.CARDS.LOCATION_TITLE", "Current location")} rightItem={rightItem}>
      <Grid2 container direction="row" justifyContent={"space-between"}>
        <Grid2>
          <Grid2 container spacing={1} direction="column">
            <Grid2>
              <TextWithLabel
                label={translate("TICKET_DETAILS.CARDS.LOCATION_LABEL", "Hall/Section")}
                text={equipment.location.description}
              />
            </Grid2>
            <Grid2>
              <TextWithLabel
                label={translate("TICKET_DETAILS.CARDS.SUBLOCATION_LABEL", "Room (Row/Place)")}
                text={subLocation}
              />
            </Grid2>
            {equipment.__typename === "Mould" && equipment.rack && (
              <Grid2>
                <TextWithLabel label={translate("TICKET_DETAILS.CARDS.RACK_LABEL", "Rack")} text={equipment.rack} />
              </Grid2>
            )}
            {equipment.__typename === "Mould" && equipment.mouldMachine && (
              <Grid2>
                <TextWithLabel
                  label={translate("TICKET_DETAILS.CARDS.MACHINE_LABEL", "Machine")}
                  text={equipment.mouldMachine.toString()}
                />
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2>
          <EquipmentOnOtherPlantWarning equipmentPlantId={equipment.plantId} />
        </Grid2>
      </Grid2>

      <TDUpdateLocationDialog data={equipment} onDismiss={closeDialog} open={editDialogOpen} />
    </CardWithTitle>
  );
};

const EquipmentOnOtherPlantWarning: FC<{ equipmentPlantId: number }> = ({ equipmentPlantId }) => {
  const {
    selectedArea: { plantId: userPlantId },
  } = useAreaAndProcessContext();
  const { translate } = useTranslation();

  if (userPlantId === equipmentPlantId.toString()) {
    return null;
  }

  return (
    <Tooltip
      title={translate("EQUIPMENT_CARD.ON_OTHER_PLANT_WARNING_TOOLTIP", "Equipment located on plant {{ plantId }}", {
        plantId: equipmentPlantId.toString(),
      })}
    >
      <IconButton>
        <Icons.WarningTriangle color="error" />
      </IconButton>
    </Tooltip>
  );
};
