import { Chip, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";

import { TicketTypePill_ticket$key } from "./__generated__/TicketTypePill_ticket.graphql";

export const TicketTypePill = (props: { ticket: TicketTypePill_ticket$key }) => {
  const { translate } = useTranslation();
  const theme = useTheme();
  const ticket = useFragment(
    graphql`
      fragment TicketTypePill_ticket on Ticket {
        __typename
      }
    `,
    props.ticket,
  );
  if (ticket.__typename === "MPEETicket") {
    return (
      <Chip
        label={translate("TICKET_TYPE.MPEE", "MPE&E")}
        sx={{
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.common.white,
        }}
      />
    );
  }

  if (ticket.__typename === "CMSTicket") {
    return <Chip label={translate("TICKET_TYPE.CMS", "CMS")} />;
  }

  return null;
};
