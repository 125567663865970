import { hasValue, redactVariablesForSentry } from "@lego/mst-error-utilities";
import { GraphQLError } from "graphql";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import {
  Environment,
  FetchFunction,
  Network,
  Observable,
  PayloadError,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";

import { getAreaFromStorage } from "../contexts/area/area-and-process-context";
import { useAuthContext } from "../contexts/AuthContext";
import { AzurePrompt, getAccessToken } from "../msal";
import { getCrashReporter } from "../utility/crash-reporter";
import { CustomSnackWithErrorDetails } from "../utility/errorSnackbar/CustomSnackWithErrorDetails";
import { useTranslation } from "../utility/i18n/translation";

/**
 * Relay requires developers to configure a "fetch" function that tells Relay how to load
 * the results of GraphQL queries from your server (or other data source). See more at
 * https://relay.dev/docs/en/quick-start-guide#relay-environment.
 */
const fetchQuery: (prompt: AzurePrompt, onErrors: (errors: unknown[]) => void) => FetchFunction =
  (prompt, onErrors) => (params: RequestParameters, variables: Variables) => {
    return Observable.create((sink) => {
      (async () => {
        const token = await getAccessToken(prompt);
        const response = await fetch(
          process.env.REACT_APP_GRAPHQL_TARGET === "local" || process.env.REACT_APP_GRAPHQL_TARGET === "local-relay"
            ? `http://localhost:4000/graphql`
            : `${process.env.REACT_APP_BASE_URL}/graphql`,
          {
            body: JSON.stringify({
              operationName: params.name,
              query: params.text,
              variables,
            }),
            headers: {
              "content-type": "application/json",
              "x-user-language": i18next.language,
              "x-plant-id": getAreaFromStorage().plantId,
              ...(hasValue(token)
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {}),
            },
            credentials: "include",
            method: "POST",
          },
        );

        const errors: PayloadError[] = [];

        const json = await response.json();

        if (Array.isArray(json.errors) && json.errors.length > 0) {
          errors.push(...json.errors);
        }

        sink.next(json);

        if (errors.length > 0) {
          onErrors(errors);
          for (const error of errors) {
            getCrashReporter().captureException({
              exception: error as Error,
              context: {
                extra: {
                  operationName: params.name,
                  query: params.text,
                  variables: redactVariablesForSentry(variables),
                },
              },
            });
          }
        }

        sink.complete();
      })().catch((error) => {
        getCrashReporter().captureException({
          exception: error as Error,
          context: {
            extra: {
              operationName: params.name,
              query: params.text,
              variables: redactVariablesForSentry(variables),
            },
          },
        });
        sink.error(error);
      });
    });
  };

export const useConfiguredRelayEnvironment = (prompt: AzurePrompt): Environment => {
  const { currentUserId } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new Store(new RecordSource()), [currentUserId]);

  const onRelayErrors = useCallback(
    (errors: unknown[]) => {
      enqueueSnackbar(translate("ERRORS.UNKNOWN", "Unexpected error occurred"), {
        content: function RelayErrorSnack(key, message) {
          return (
            <CustomSnackWithErrorDetails
              id={key}
              message={typeof message === "string" ? message : ""}
              errorLines={errors
                .map((err) => {
                  const { extensions, message } = err as GraphQLError;
                  const { eventId } = extensions ?? {};
                  if (!eventId) {
                    return message;
                  }
                  return `${message}: ${eventId}`;
                })
                .filter(hasValue)}
            />
          );
        },
        persist: true,
      });
    },
    [enqueueSnackbar, translate],
  );

  return useMemo(
    () =>
      new Environment({
        network: Network.create(fetchQuery(prompt, onRelayErrors)),
        store,
      }),
    [prompt, onRelayErrors, store],
  );
};

export const withRelayEnvironment = <P extends Record<string, unknown>>(prompt: AzurePrompt, Component: FC<P>): FC<P> =>
  function RelayWrapper(props) {
    const env = useConfiguredRelayEnvironment(prompt);

    return (
      <RelayEnvironmentProvider environment={env}>
        <Component {...props} />
      </RelayEnvironmentProvider>
    );
  };
