import { FC, ReactElement, createContext, useCallback, useContext, useMemo } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthError } from "@azure/msal-browser";

import { AppRoutes } from "../Router";
import { msalRequest } from "../msal/config";
import { useGMSnackbar } from "../utility/snackbar";
import { useTranslation } from "../utility/i18n/translation";

type AuthContext = {
  logIn: () => void;
  logOut: () => void;
  email?: string;
  authenticated: boolean;
  isRepairman: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  isPlannedJobsListUser: boolean;
  currentUserId?: string;
  globalUserId?: string;
};

const doNothing = () => {
  // do nothing
};

const AuthContext = createContext<AuthContext>({
  authenticated: false,
  isRepairman: false,
  isAdmin: false,
  isSuperUser: false,
  isPlannedJobsListUser: false,
  logIn: doNothing,
  logOut: doNothing,
});

export const useAuthContext = (): AuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("Missing AuthContextProvider");
  }

  return context;
};

export const userHasRole = (roles: string | string[], rolesToCheckFor: string[]) => {
  const userRoles = typeof roles === "string" ? [roles] : roles;
  if (userRoles && userRoles.length > 0) {
    const hasRole = userRoles.some((role) => rolesToCheckFor.some((roleToCheckFor) => role.includes(roleToCheckFor)));
    return hasRole;
  } else {
    return false;
  }
};

export const AuthContextProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const authenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();
  const { handleAuthError } = useHandleAuthError();

  const { currentUserId, email, isRepairman, isAdmin, isPlannedJobsListUser, isSuperUser } = useMemo<{
    currentUserId?: string;
    email?: string;
    isRepairman: boolean;
    isAdmin: boolean;
    isSuperUser: boolean;
    isPlannedJobsListUser: boolean;
  }>(() => {
    if (accounts.length > 0 && accounts[0].idTokenClaims) {
      const typed = accounts[0].idTokenClaims as {
        employeeid: string;
        roles: string | string[];
        preferred_username: string;
      };
      const userRoles = typed.roles ?? [];
      return {
        currentUserId: typed.employeeid,
        email: typed.preferred_username,
        isRepairman: userHasRole(userRoles, roles.repairman.concat(roles.superuser)),
        isSuperUser: userHasRole(userRoles, roles.superuser),
        isAdmin: userHasRole(userRoles, roles.admin),
        isPlannedJobsListUser: userHasRole(userRoles, roles.plannedJobs),
      };
    } else {
      return {
        isRepairman: false,
        isAdmin: false,
        isPlannedJobsListUser: false,
        isSuperUser: false,
      };
    }
  }, [accounts]);

  const logOut = useCallback(async () => {
    try {
      if (accounts.length > 0) {
        instance.logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: AppRoutes.login,
        });
      } else {
        instance.logoutRedirect();
      }
    } catch (error) {
      handleAuthError(error as AuthError);
    }
  }, [accounts, handleAuthError, instance]);

  const logIn = useCallback(async () => {
    try {
      await instance.loginRedirect({
        ...msalRequest,
        prompt: "select_account",
      });
    } catch (error) {
      handleAuthError(error as AuthError);
    }
  }, [handleAuthError, instance]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        logOut,
        logIn,
        isRepairman,
        isAdmin,
        isSuperUser,
        isPlannedJobsListUser,
        email,
        currentUserId: currentUserId,
        globalUserId: window.btoa(`Employee:${currentUserId}`),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useHandleAuthError = (): {
  handleAuthError: (loginError: AuthError) => void;
} => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();
  const { instance } = useMsal();

  const handleAuthError = (loginError: AuthError) => {
    switch (loginError.errorCode) {
      case "user_cancelled":
        showSnack({
          message: translate(
            "LOGIN.ERRORS.USER_CANCELLED_FLOW",
            "User cancelled the login flow. Please sign in before using the app.",
          ),
          variant: "warning",
        });
        return;
      case "login_required":
        instance.loginRedirect({
          ...msalRequest,
          prompt: "select_account",
        });
        return;
      case "interaction_in_progress":
      case "no_account_error":
      case "no_tokens_found":
        return;
      default:
        console.error(loginError.errorCode);
        showSnack({
          message: translate(
            "LOGIN.ERRORS.UNHANDLED",
            "An error occurred during login, please try again. If it persists, please contact support",
          ),
          variant: "error",
        });
        break;
    }
  };
  return { handleAuthError };
};

export const useGetAccessToken = (): {
  getAccessToken: () => Promise<string | undefined>;
} => {
  const { instance, accounts } = useMsal();
  const { handleAuthError } = useHandleAuthError();

  const getAccessToken = useCallback(async () => {
    try {
      const request = {
        ...msalRequest,
        account: accounts.length > 0 ? accounts[0] : undefined,
      };
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      handleAuthError(error as AuthError);
      return undefined;
    }
  }, [accounts, handleAuthError, instance]);

  return {
    getAccessToken,
  };
};

const roles = {
  repairman: [
    "repairman",
    "d244d971-4483-4434-96a4-39ef6f9a9c27", // on prem uat
    "72f6d188-e6b3-425e-a853-36642cd5f171", // on prem prod
    "d315f73c-6f02-4cc1-9938-dccf9b672857", // cloud uat
    "d858c603-645b-45db-b556-e92fd814f79b", // cloud prod
  ],
  superuser: [
    "super_user",
    "98b908c1-96aa-4154-aaaf-7d894af6cb0b", // on prem uat
    "40f99878-d965-4d96-b2ba-39b67b25bb5a", // on prem prod
    "fffa4c32-5c80-44c3-8ce2-0badca12318e", // cloud uat
    "a487ad49-c265-4098-b0cb-0a6b1fae7975", // cloud prod
  ],
  admin: [
    "admin",
    "72f7504e-5d76-4b31-868d-1c8805756b70", // on prem
  ],
  plannedJobs: [
    "planned_jobs",
    "98d194fb-864c-4897-95c9-c50eb1778636", // on prem uat
    "20cd3a55-1d0b-46fc-a66f-1491fa3992f2", // on prem prod
    "aac8c9f3-3f79-4a19-8647-d450a0150ca9", // cloud uat
    "b570daef-297a-4ba7-bcd7-af5cc34edbec", // cloud prod
  ],
};
