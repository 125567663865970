import { Button, Grid2, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router";

import { TimeRegistrationOverviewFragment } from "../../__apollo__/graphql";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { AddMachineHoursOverview } from "../add-time/overview/machine-hours-overview/MachineHoursOverview";

import { CTNoManhoursOrRepairDocs } from "./CTNoManhoursOrRepairDocs";

export const CTMachineHours: FC<TimeRegistrationOverviewFragment> = (data) => {
  const { translate } = useTranslation();
  const { id } = data;

  const totalMachineHoursInMinutes = data.totalMachineHoursInMinutes ?? 0;

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("CLOSE_TICKET.MACHINE_HOURS_STEP.CARD_HEADER", "Time spent")}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {translate(
            "CLOSE_TICKET.MACHINE_HOURS_STEP.CARD_DESCRIPTION",
            "Before closing the ticket, please check that the machine time registration is correct",
          )}
        </Typography>
      </Grid2>
      {totalMachineHoursInMinutes > 0 ? (
        <Grid2 marginX={8}>
          <AddMachineHoursOverview {...data} interactive={false} />
        </Grid2>
      ) : (
        <Grid2>
          <NoMachineHours />
        </Grid2>
      )}
      <Grid2>
        <Button
          data-cy="CTMachineHours-addTimeButton"
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
          component={NavLink}
          to={AppRoutes.tickets.machineHours(id)}
          startIcon={<Icons.Edit color="primary" />}
        >
          {translate("CLOSE_TICKET.MACHINE_HOURS_STEP.ADD_TIME_BUTTON", "Time")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

const NoMachineHours = () => {
  const { translate } = useTranslation();
  const {
    state: { showMachineHoursRequiredWarning },
  } = useCloseTicketContext();

  const title = translate("CLOSE_TICKET.MACHINE_HOURS_STEP.NO_TIME_HEADER", "No time registered");

  const message = translate(
    "CLOSE_TICKET.MACHINE_HOURS_STEP.NO_TIME_ERROR",
    "Time registration is mandatory for this ticket type",
  );

  return <CTNoManhoursOrRepairDocs title={title} message={message} showWarning={showMachineHoursRequiredWarning} />;
};
