import CloseIcon from "@mui/icons-material/Close";
import { Grid2, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import graphql from "babel-plugin-relay/macro";
import debounce from "lodash/debounce";
import { FC, Suspense, useCallback, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useAreaAndProcessContext } from "../../../contexts/area";

import { SearchEquipmentDialogList } from "./SearchEquipmentDialogList";
import { SearchEquipmentDialogLocationFilterPropsLocationType } from "./SearchEquipmentDialogLocationFilter";
import { SearchEquipmentDialogRowProps } from "./SearchEquipmentDialogRow";
import { SearchEquipmentDialogQuery as SearchEquipmentDialogQueryType } from "./__generated__/SearchEquipmentDialogQuery.graphql";

type SearchEquipmentDialogProps = {
  open: boolean;
  title: string;
  onDismiss: () => void;
} & Pick<SearchEquipmentDialogRowProps, "onEquipmentPressed">;

export const SearchEquipmentDialog: FC<SearchEquipmentDialogProps> = ({
  open,
  title,
  onDismiss,
  onEquipmentPressed,
}) => {
  const dismiss = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  return (
    <Dialog open={open} onClose={dismiss} fullWidth maxWidth={"md"}>
      <DialogTitle>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2>
            <Typography variant="h3">{title}</Typography>
          </Grid2>
          <Grid2>
            <IconButton onClick={dismiss} size="large">
              <CloseIcon />
            </IconButton>
          </Grid2>
        </Grid2>
      </DialogTitle>
      <Suspense fallback={<SearchEquipmentDialogList.Skeleton />}>
        <Content onEquipmentPressed={onEquipmentPressed} />
      </Suspense>
    </Dialog>
  );
};

const Content: FC<Pick<SearchEquipmentDialogRowProps, "onEquipmentPressed">> = ({ onEquipmentPressed }) => {
  const { selectedArea } = useAreaAndProcessContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocationFilter, setSelectedLocationFilter] =
    useState<SearchEquipmentDialogLocationFilterPropsLocationType>(null);

  const query = useLazyLoadQuery<SearchEquipmentDialogQueryType>(
    graphql`
      query SearchEquipmentDialogQuery(
        $plantInput: QueryPlantInput!
        $equipmentSearchInput: PlantEquipmentSearchInput!
      ) {
        plant(input: $plantInput) {
          ... on QueryPlantSuccess {
            data {
              ...SearchEquipmentDialogList_plant @arguments(equipmentSearchInput: $equipmentSearchInput)
            }
          }
        }
      }
    `,
    {
      plantInput: {
        plantNumber: Number.parseInt(selectedArea.plantId),
      },
      equipmentSearchInput: {
        searchTerm: "",
      },
    },
  );

  const onChangeDebounced = useMemo(() => {
    const searchDispatch = (newValue: string) => {
      setSearchTerm(newValue);
    };

    return debounce(searchDispatch, 300, {
      maxWait: 1000,
    });
  }, []);

  const onLocationFilterChanged = useCallback(
    (newLocationFilter: SearchEquipmentDialogLocationFilterPropsLocationType) => {
      setSelectedLocationFilter(newLocationFilter);
    },
    [],
  );

  return (
    <SearchEquipmentDialogList
      onEquipmentPressed={onEquipmentPressed}
      plant={query.plant?.data}
      debouncedOnChange={onChangeDebounced}
      searchTerm={searchTerm}
      onLocationChanged={onLocationFilterChanged}
      selectedLocation={selectedLocationFilter}
    />
  );
};
