import { Button, Grid2 } from "@mui/material";
import { FC } from "react";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { useAuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "../../utility/i18n/translation";

import { useCreateTicketContext } from "./create-ticket-context";

interface Props {
  submit: (assign: boolean) => void;
  loading: boolean;
}

export const CreateTicketSubmitButtons: FC<Props> = ({ submit, loading }) => {
  const { translate } = useTranslation();
  const { isRepairman } = useAuthContext();
  const { dispatch } = useCreateTicketContext();

  const handleBack = () => {
    dispatch({ type: "step_backward" });
  };

  const handleSubmit = () => {
    submit(false);
  };

  const handleSubmitWithAssign = () => {
    submit(true);
  };

  return (
    <Grid2 container alignItems="center">
      <Grid2 mr={2}>
        <Button size="small" onClick={handleBack} disabled={loading}>
          {translate("CREATE_TICKET.BUTTON_BACK", "Back")}
        </Button>
      </Grid2>
      {isRepairman && (
        <Grid2 mr={2}>
          <Button
            data-cy={"CreateTicketWithAssignSubmitButton"}
            disabled={loading}
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleSubmitWithAssign}
          >
            {translate("CREATE_TICKET.CREATE_ASSIGN", "Create and assign to me")}
          </Button>
        </Grid2>
      )}
      <Grid2>
        <Button
          data-cy={"CreateTicketSubmitButton"}
          disabled={loading}
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {translate("CREATE_TICKET.CREATE", "Create")}
        </Button>
      </Grid2>
      {loading && (
        <Grid2 ml={2}>
          <ActivityIndicator />
        </Grid2>
      )}
    </Grid2>
  );
};
