import { TicketPriorityEnum } from "../../../__apollo__/graphql";

export enum TicketTypeEnum {
  CMS,
  MPEE,
  Mould,
  Equipment,
}

export interface ChangePriorityDialogField {
  name: string;
  value: TicketPriorityEnum;
  translation: string;
}

export const ALLOWED_TICKET_PRIORITIES: Record<TicketTypeEnum, TicketPriorityEnum[]> = {
  [TicketTypeEnum.CMS]: [TicketPriorityEnum.Priority1, TicketPriorityEnum.Priority2, TicketPriorityEnum.Priority8],
  [TicketTypeEnum.MPEE]: [TicketPriorityEnum.Priority1, TicketPriorityEnum.Priority2, TicketPriorityEnum.Priority3],
  [TicketTypeEnum.Mould]: [TicketPriorityEnum.Priority1, TicketPriorityEnum.Priority2, TicketPriorityEnum.Priority4],
  [TicketTypeEnum.Equipment]: [
    TicketPriorityEnum.Priority1,
    TicketPriorityEnum.Priority2,
    TicketPriorityEnum.Priority3,
    TicketPriorityEnum.Priority5,
  ],
};
