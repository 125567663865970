import { redactValues } from "@lego/mst-error-utilities";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";

import { getNodeEnvironment } from "./environment";

export const API_TIMEOUT_THRESHOLD_IN_MS = 30000;

class SentryCrashReporter {
  private static sharedInstance: SentryCrashReporter | undefined = undefined;

  static get sharedReporter(): SentryCrashReporter {
    if (this.sharedInstance === undefined) {
      this.sharedInstance = new SentryCrashReporter();
    }

    return this.sharedInstance;
  }

  start(overrides: Sentry.BrowserOptions = {}) {
    const environment = getNodeEnvironment();

    if (environment === "local" || environment === "e2e") {
      return;
    }

    Sentry.init({
      ...overrides,
      dsn: "https://7cd0dd59fdb842798b1d0ca5a7f4e441@buggy.apps.lego.com/82",
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment,
    });
  }

  captureException: (typeof Sentry)["captureException"] = (exception, context) => {
    return Sentry.captureException(redactValues(exception), context);
  };

  captureEvent: (typeof Sentry)["captureEvent"] = (event) => {
    return Sentry.captureEvent(redactValues(event));
  };

  addBreadcrumb: (typeof Sentry)["addBreadcrumb"] = (breadcrumb) => {
    Sentry.addBreadcrumb(redactValues(breadcrumb));
  };
}

export const getCrashReporter = () => SentryCrashReporter.sharedReporter;
