import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { useAuthContext } from "../contexts/AuthContext";
import { useDefaultSnackbarErrorHandler } from "../utility/errorSnackbar/errorCodeTranslations";

export const useGMQuery: typeof useQuery = (query, options) => {
  const { authenticated } = useAuthContext();
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();

  return useQuery(query, {
    onError: showErrorSnackForStatusCode,
    skip: !authenticated,
    ...options,
  });
};

export const useGMLazyQuery: typeof useLazyQuery = (query, options) => {
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();

  return useLazyQuery(query, {
    onError: showErrorSnackForStatusCode,
    ...options,
  });
};

export const useGMMutation: typeof useMutation = (mutation, options) => {
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();
  return useMutation(mutation, {
    onError: (error) => showErrorSnackForStatusCode(error),
    ...options,
  });
};
