import { Container, Grid2, Typography } from "@mui/material";
import { FC } from "react";

import pkg from "../../package.json";
import { AreaAndProcess } from "../components/profile/AreaAndProcess";
import { ChangeLanguage } from "../components/profile/ChangeLanguage";
import { ProfilePictureAndSignOutCard } from "../components/profile/ProfilePictureAndSignoutCard";
import { PictureAndSignOut } from "../components/shared/PictureAndSignOut";

export const ProfileContainer: FC = () => {
  return (
    <Container maxWidth="lg">
      <Grid2 container spacing={3} style={{ marginTop: 24 }}>
        <Grid2 size={{ xs: 12, lg: 4 }}>
          <PictureAndSignOut Component={ProfilePictureAndSignOutCard} />
        </Grid2>
        <Grid2 container direction="column" size={{ xs: 12, lg: 8 }}>
          <Grid2>
            <ChangeLanguage />
          </Grid2>
          <Grid2 style={{ marginTop: 24 }}>
            <AreaAndProcess />
          </Grid2>
          <Grid2 style={{ alignSelf: "flex-end" }}>
            <Typography variant="caption" style={{ opacity: 0.5 }}>
              {pkg.version}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};
