import { Container, Grid2 } from "@mui/material";
import { FC, useCallback } from "react";

import { ScrollToTopFab } from "../../../containers";
import { PageErrorBoundary } from "../../PageErrorBoundary";
import { InStockToggle } from "../../dashboard/spare-part-bom/SparePartBomHeader";
import { SparePartSearch } from "../../dashboard/spare-part-bom/SparePartSearch";
import {
  SparePartSearchProvider,
  useSparePartSearchContext,
} from "../../dashboard/spare-part-bom/spare-part-search-context";
import { CountAndSearch } from "../CountAndSearch";

export const SparepartSearchContainer: FC = () => {
  return (
    <Container maxWidth="xl">
      <PageErrorBoundary>
        <SparePartSearchProvider>
          <SparepartSearchActualComponent />
        </SparePartSearchProvider>
      </PageErrorBoundary>
    </Container>
  );
};
const SparepartSearchActualComponent: FC = () => {
  const { dispatch } = useSparePartSearchContext();
  const onSearchChanged = useCallback(
    (value: string) => {
      dispatch({ type: "setSearchTerm", payload: value });
    },
    [dispatch],
  );

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Grid2 container display="flex" direction="row">
        <Grid2 size={{ xs: 11 }} sx={{ mb: 3 }}>
          <CountAndSearch onChange={onSearchChanged} autoFocus />
        </Grid2>
        <Grid2 size={{ xs: 1 }}>
          <InStockToggle />
        </Grid2>
      </Grid2>
      <PageErrorBoundary>
        <SparePartSearch />
      </PageErrorBoundary>
      <ScrollToTopFab />
    </Container>
  );
};
