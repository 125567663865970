import { hasValue } from "@lego/mst-error-utilities";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, Grid2, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { PropsWithChildren, ReactElement } from "react";

import { ActivityIndicator } from "./ActivityIndicator";

export type GMDropdownProps = {
  placeholderText?: string;
  value: string | undefined;
  disabled?: boolean;
  loading?: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  dataCy?: string;
};

export const GMDropdown = ({
  onChange,
  placeholderText,
  disabled = false,
  loading = false,
  value,
  children,
  dataCy,
}: PropsWithChildren<GMDropdownProps>): ReactElement | null => {
  const showPlaceholder = hasValue(placeholderText);
  return (
    <Grid2 style={{ flex: 1, position: "relative" }} data-cy={hasValue(dataCy) ? dataCy : undefined}>
      <FormControl
        variant="outlined"
        disabled={disabled}
        style={{
          minWidth: 120,
          width: "100%",
        }}
      >
        <Select displayEmpty={showPlaceholder} IconComponent={DownArrowIcon} value={value ?? ""} onChange={onChange}>
          {children}
        </Select>
      </FormControl>
      {loading && (
        <Grid2 style={{ position: "absolute", top: "7px", right: "36px" }}>
          <ActivityIndicator />
        </Grid2>
      )}
    </Grid2>
  );
};
