import { hasValue } from "@lego/mst-error-utilities";
import { Box, Container, Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect, useMemo, useState, useTransition } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useSearchParams } from "react-router";

import { addDays, getTimestampForRelay } from "../../utility/date";
import { MouldMiniOrderDialog } from "../components/mould/MouldMiniOrderDialog";
import { PageErrorBoundary } from "../PageErrorBoundary";
import { skeletonify } from "../skeleton";
import { isValidEquipmentId } from "../utils";

import CreateTicketQuery, {
  CreateTicketQuery as CreateTicketQueryType,
} from "./__generated__/CreateTicketQuery.graphql";
import { CreateTicketProvider, useCreateTicketContext } from "./create-ticket-context";
import { CreateTicketCard } from "./CreateTicketCard";
import { OpenAndRecentlyClosedTickets } from "./OpenAndRecentlyClosedTickets";
import { useEquipmentValidator } from "./useEquipmentValidator";
import { useQueryParam } from "./useQueryParam";

const ActualComponent: FC<{
  query: PreloadedQuery<CreateTicketQueryType>;
  loading: boolean;
  refetch: () => void;
}> = ({ query: queryRef, loading, refetch }) => {
  const [, setSearchParams] = useSearchParams();
  const validator = useEquipmentValidator();
  const { dispatch } = useCreateTicketContext();
  const [miniOrderDialogOpen, setMiniOrderDialogOpen] = useState(false);

  const { equipment } = usePreloadedQuery(
    graphql`
      query CreateTicketQuery(
        $input: QueryEquipmentInput!
        $completedDate: LocalTimestampFilterInput!
        $skip: Boolean!
      ) {
        equipment(input: $input) @skip(if: $skip) {
          ...CreateTicketCard_equipment #@defer
          ... on QueryEquipmentSuccess {
            data {
              id
              isActive
              plant {
                plantNumber
              }
              ... on Mould {
                statusCode
              }
              equipmentNumber
              ...OpenAndRecentlyClosedTickets_equipment @arguments(completedDate: $completedDate) #@defer
              ...MouldMiniOrderDialog_mould
            }
          }
        }
      }
    `,
    queryRef,
  );

  const setEquipment = useCallback(() => {
    if (equipment?.data?.id && equipment.data.equipmentNumber) {
      dispatch({ type: "set_equipment", payload: equipment?.data.id });
      setSearchParams(
        {
          equipmentId: equipment.data.equipmentNumber.toString(),
        },
        { replace: true },
      );
    }
  }, [dispatch, equipment?.data?.equipmentNumber, equipment?.data?.id, setSearchParams]);

  const unsetEquipment = useCallback(() => {
    dispatch({ type: "set_equipment", payload: null });
    setSearchParams({}, { replace: true });
  }, [dispatch, setSearchParams]);

  useEffect(() => {
    const equipmentStatus = validator(equipment?.data?.isActive ?? null, equipment?.data?.plant?.plantNumber ?? null);

    if (equipmentStatus && equipmentStatus === "valid" && equipment?.data?.statusCode !== 999) {
      setEquipment();
    } else {
      unsetEquipment();
    }
  }, [
    equipment?.data?.equipmentNumber,
    equipment?.data?.id,
    equipment?.data?.isActive,
    equipment?.data?.plant?.plantNumber,
    equipment?.data?.statusCode,
    setEquipment,
    unsetEquipment,
    validator,
  ]);

  useEffect(() => {
    if (equipment?.data?.statusCode === 999) {
      setMiniOrderDialogOpen(true);
    }
  }, [equipment?.data?.statusCode]);

  const onMiniOrderDialogConfirm = useCallback(() => {
    refetch();
    setMiniOrderDialogOpen(false);
  }, [refetch]);

  return (
    <Grid2 container spacing={2} style={{ marginTop: 14 }}>
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <CreateTicketCard equipment={equipment ?? null} loading={loading} />
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <OpenAndRecentlyClosedTickets equipment={equipment?.data} loading={loading} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Box height={80}></Box>
      </Grid2>
      {hasValue(equipment) && hasValue(equipment.data) && (
        <MouldMiniOrderDialog
          open={miniOrderDialogOpen}
          equipment={equipment.data}
          onConfirm={onMiniOrderDialogConfirm}
          onDismiss={() => setMiniOrderDialogOpen(false)}
        />
      )}
    </Grid2>
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container spacing={2} style={{ marginTop: 14 }}>
    <Grid2 size={{ xs: 12, lg: 6 }}>
      <CreateTicketCard equipment={null} />
    </Grid2>
    <Grid2 size={{ xs: 12, lg: 6 }}>
      <OpenAndRecentlyClosedTickets />
    </Grid2>
  </Grid2>
);

const CreateTicketPage = skeletonify("CreateTicketPage", ActualComponent, SkeletonComponent);

const CreateTicket: FC = () => {
  const params = useQueryParam();
  const equipmentId = Number.parseInt(params.get("equipmentId") ?? "");
  const { state } = useCreateTicketContext();
  const { equipmentSearch } = state;
  const [queryRef, loadQuery, dispose] = useQueryLoader<CreateTicketQueryType>(CreateTicketQuery);
  const [isInFlight, startTransition] = useTransition();

  const completedDate = useMemo(() => getTimestampForRelay(addDays(new Date(), -2).getTime()), []);

  const refetchQuery = useCallback(() => {
    startTransition(() => {
      loadQuery(
        {
          input: { equipmentNumber: equipmentSearch ?? 0 },
          completedDate: { ge: completedDate },
          skip: !isValidEquipmentId(equipmentSearch?.toString() ?? ""),
        },
        { fetchPolicy: "store-and-network" },
      );
    });
  }, [completedDate, equipmentSearch, loadQuery]);

  useEffect(() => {
    refetchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSearch]);

  useEffect(() => {
    if (!hasValue(queryRef)) {
      loadQuery({
        completedDate: { ge: completedDate },
        input: { equipmentNumber: isNaN(equipmentId) ? 0 : equipmentId },
        skip: isNaN(equipmentId),
      });
    }
  }, [completedDate, equipmentId, loadQuery, queryRef]);

  return (
    <Container maxWidth="xl" style={{ marginTop: 24 }}>
      <PageErrorBoundary onReset={() => dispose()}>
        {queryRef ? (
          <CreateTicketPage.Suspense query={queryRef} loading={isInFlight} refetch={refetchQuery} />
        ) : (
          <CreateTicketPage.Skeleton />
        )}
      </PageErrorBoundary>
    </Container>
  );
};

export const CreateTicketContainer: FC = () => {
  return (
    <CreateTicketProvider>
      <CreateTicket />
    </CreateTicketProvider>
  );
};
