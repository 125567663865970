import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  RepairComponentOptionsQuery,
  RepairComponentOptionsQueryVariables,
  RepairDocumentationComponentOption,
  RepairDocumentationFlowVariant,
} from "../../../__apollo__/graphql";
import { useGMQuery } from "../../../apollo/customApolloHooks";
import {
  MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION,
  useAddRepairDescriptionContext,
} from "../../../contexts/add-repair-description/add-repair-description-context";
import { REPAIR_DESCRIPTION_COMPONENTS } from "../../../contexts/add-repair-description/add-repair-description-gql-queries";
import { useTranslation } from "../../../utility/i18n/translation";
import { FillWidthLoading } from "../../shared/FillWidthLoading";
import { ARDAutocomplete } from "../ARDAutocomplete";
import { ARDNavigationButtons } from "../ARDNavigationButtons";

export const ARDChooseComponent: FC = () => {
  const { translate } = useTranslation();
  const { id: ticketId } = useParams() as { id: string };
  const {
    state: { chosenSubEquipment, customComponentString, variant, chosenComponent, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const { data, loading, error } = useGMQuery<RepairComponentOptionsQuery, RepairComponentOptionsQueryVariables>(
    REPAIR_DESCRIPTION_COMPONENTS,
    {
      skip: !hasValue(chosenSubEquipment) || variant === RepairDocumentationFlowVariant.Baseline,
      variables: {
        input: {
          ticketId,
          subEquipmentId: chosenSubEquipment?.id ?? "",
        },
      },
    },
  );

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data || error) {
    return (
      <Typography>
        {translate("ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.NO_COMPONENTS", "No components to show, please refresh")}
      </Typography>
    );
  }

  const onValueSelected = (value: RepairDocumentationComponentOption | null) => {
    if (value) {
      dispatch({ type: "set_component", chosenComponent: value });
      if (!value.requiresAdditionalInput) {
        dispatch({ type: "step_forward" });
      }
    }
  };

  if (!(currentStep === "component")) {
    return (
      <Typography>
        {chosenComponent?.description}
        {chosenComponent?.requiresAdditionalInput && customComponentString ? ` - ${customComponentString}` : ""}
      </Typography>
    );
  }

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <Typography>
          {translate("ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.CHOOSE", "Choose the appropriate component")}
        </Typography>
      </Grid2>
      <Grid2>
        <ARDAutocomplete<RepairDocumentationComponentOption>
          onChange={onValueSelected}
          options={data.repairDescriptionComponentOptions ?? []}
          chosenValue={chosenComponent ?? null}
          cyIdentifier="component"
        />
        {chosenComponent?.requiresAdditionalInput && <CustomComponentInputComponent />}
      </Grid2>
    </Grid2>
  );
};

const CustomComponentInputComponent: FC = () => {
  const { translate } = useTranslation();
  const [text, setText] = useState("");

  const {
    state: { customComponentString },
    dispatch,
  } = useAddRepairDescriptionContext();

  useEffect(() => {
    if (customComponentString && customComponentString.length > 0 && text.length === 0) {
      setText(customComponentString);
    }
  }, [customComponentString, text.length]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    dispatch({ type: "set_custom_component", customInput: newValue });
  };

  return (
    <Grid2 container direction="column" spacing={3} style={{ marginTop: 20 }}>
      <Grid2>
        <TextField
          defaultValue={customComponentString}
          variant="outlined"
          onChange={onChange}
          fullWidth
          inputProps={{ maxLength: MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION }}
          label={translate(
            "ADD_REPAIR_DOCUMENTATION.CHOOSE_COMPONENT.CUSTOM_INPUT_PLACEHOLDER",
            "Component description",
          )}
        />
      </Grid2>
      <Grid2>
        <ARDNavigationButtons />
      </Grid2>
    </Grid2>
  );
};
