import { Grid2, Skeleton, Typography } from "@mui/material";
import { CSSProperties, FC } from "react";

export const TextWithLabel: FC<{
  text: string;
  label: string;
  alignTextRight?: boolean;
}> = ({ text, label, alignTextRight = false }) => {
  const textAlignStyle: CSSProperties = {
    textAlign: alignTextRight ? "right" : "left",
  };
  return (
    <Grid2 container direction="column">
      <Grid2>
        <Typography style={textAlignStyle}>{text}</Typography>
      </Grid2>
      <Grid2>
        <Typography variant="body2" color="textSecondary" style={textAlignStyle}>
          {label}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export const TextWithLabelSkeleton: FC = () => {
  return (
    <Grid2 container direction={"column"}>
      <Grid2>
        <Skeleton variant="text" width={160} />
      </Grid2>
      <Grid2>
        <Skeleton variant="text" width={80} />
      </Grid2>
    </Grid2>
  );
};

export const TextWithUnitValue: FC<{
  value: number;
  label: string;
  unit?: string;
}> = ({ value, label, unit }) => {
  return (
    <Grid2 size={{ xs: "grow" }} sx={{ padding: "10px 18px" }}>
      <Typography color="textSecondary" fontSize="13px">
        {label}
      </Typography>
      <Typography fontSize="16px">{[value, unit].join(" ")}</Typography>
    </Grid2>
  );
};
