import { hasValue } from "@lego/mst-error-utilities";
import { TextField, Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import graphql from "babel-plugin-relay/macro";
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { useFragment, useMutation } from "react-relay";

import { SimpleDialog } from "../../components/shared/SimpleDialog";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { TicketErrorDescription } from "../components/ticket/TicketErrorDescription";

import { AddErrorDescriptionDialogMutation } from "./__generated__/AddErrorDescriptionDialogMutation.graphql";
import { AddErrorDescriptionDialog_Content_ticket$key } from "./__generated__/AddErrorDescriptionDialog_Content_ticket.graphql";
import { AddErrorDescriptionDialog_ticket$key } from "./__generated__/AddErrorDescriptionDialog_ticket.graphql";

const MAX_LENGTH_FOR_ERROR_DESCRIPTION = 10000;

export const AddErrorDescriptionDialog: FC<{
  open: boolean;
  closeDialog: () => void;
  ticket: AddErrorDescriptionDialog_ticket$key;
}> = ({ open, closeDialog, ticket: ticketRef }) => {
  const { translate } = useTranslation();
  const [newDescription, setNewDescription] = useState<string>();
  const { loading, onSavePressed } = useAddMutation(closeDialog);
  const validInput = hasValue(newDescription) && newDescription.length > 0;

  const ticket = useFragment(
    graphql`
      fragment AddErrorDescriptionDialog_ticket on Ticket {
        id
        ...AddErrorDescriptionDialog_Content_ticket
      }
    `,
    ticketRef ?? null,
  );

  const onSave = useCallback(() => {
    if (validInput) {
      onSavePressed(newDescription, ticket.id);
      setNewDescription(undefined);
    }
  }, [validInput, onSavePressed, newDescription, ticket.id]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewDescription(newValue);
  };

  return (
    <SimpleDialog
      onDismiss={closeDialog}
      secondaryAction={{
        secondaryActionLabel: translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.CANCEL_BUTTON", "Cancel"),
        secondaryActionPressed: closeDialog,
      }}
      open={open}
      title={translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.TITLE", "Add error description")}
      primaryAction={{
        primaryActionLabel: translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.SAVE_BUTTON", "SAVE"),
        primaryActionPressed: onSave,
        primaryActionLoading: loading,
      }}
      content={{
        type: "node",
        node: <Content ticket={ticket} onChange={onChange} textFieldValue={newDescription} />,
      }}
      maxWidth={"lg"}
    />
  );
};

const Content: FC<{
  ticket: AddErrorDescriptionDialog_Content_ticket$key;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  textFieldValue?: string;
}> = ({ ticket: ticketRef, onChange, textFieldValue }) => {
  const { translate } = useTranslation();

  const ticket = useFragment(
    graphql`
      fragment AddErrorDescriptionDialog_Content_ticket on Ticket {
        ...TicketErrorDescription_ticket
      }
    `,
    ticketRef ?? null,
  );

  return (
    <>
      <DialogContentText sx={{ mb: 2 }}>
        {translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.SUBTITLE", "Current error description")}
      </DialogContentText>
      <TicketErrorDescription ticket={ticket} />
      <DialogContentText sx={{ my: 2 }}>
        {translate(
          "TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.ADDITIONAL_SUBTITLE",
          "Additional error description",
        )}
      </DialogContentText>
      <Typography>
        {translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.INPUT_HEADER", "Error description")}
      </Typography>
      <TextField
        variant="outlined"
        onChange={onChange}
        fullWidth
        minRows={5}
        multiline
        inputProps={{ maxLength: MAX_LENGTH_FOR_ERROR_DESCRIPTION }}
        value={textFieldValue}
        placeholder={translate(
          "TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.INPUT_PLACEHOLDEr",
          "Enter new error description",
        )}
      />
    </>
  );
};

const useAddMutation = (closeDialog: () => void) => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();
  const failureString = translate(
    "TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_SAVE_FAILED",
    "Could not save, please try again",
  );

  const [commit, isInFlight] = useMutation<AddErrorDescriptionDialogMutation>(graphql`
    mutation AddErrorDescriptionDialogMutation($input: MutationAddTicketErrorDescriptionInput!) {
      addTicketErrorDescription(input: $input) {
        ... on MutationAddTicketErrorDescriptionSuccess {
          data {
            ...TicketErrorDescription_ticket
          }
        }
      }
    }
  `);

  const onSavePressed = useCallback(
    (description: string, ticketId: string) => {
      commit({
        variables: { input: { description, ticketId } },
        onCompleted: () => {
          showSnack({
            message: translate("SCREENS.TICKET_ADD_ERROR_DESCRIPTION.SUCCESS", "Successfully added error description"),
            variant: "success",
          });
          closeDialog();
        },
        onError: () => {
          showSnack({ message: failureString, variant: "error" });
        },
      });
    },
    [closeDialog, commit, failureString, showSnack, translate],
  );

  return { onSavePressed, loading: isInFlight };
};
