import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { GMImageWithFallback } from "../../components/shared/GMImageComponents";
import { SparePartIdWithIcon } from "../../components/ticket-card/components/SparePartIdWithIcon";
import { skeletonify } from "../skeleton";

import { SparePartsTabConsumedRow_ISparePartMovement$key } from "./__generated__/SparePartsTabConsumedRow_ISparePartMovement.graphql";

const ActualComponent: FC<{
  sparePartMovement: SparePartsTabConsumedRow_ISparePartMovement$key | null;
}> = (props) => {
  const { sparePartMovement: SparePartMovementRef } = props;
  const data = useFragment(
    graphql`
      fragment SparePartsTabConsumedRow_ISparePartMovement on SparePartMovement {
        quantity
        sparePart {
          sparePartNumber
          image {
            uri
          }
          description
        }
        ... on ConsumedSparePartMovement {
          consumedFrom {
            description
            storageBin
          }
        }
      }
    `,
    SparePartMovementRef,
  );
  if (!data) {
    return null;
  }
  const { quantity, sparePart, consumedFrom } = data;

  return (
    <Grid2 container flexDirection={"row"} size={{ xs: "grow" }} spacing={2}>
      <Grid2 container size={{ xs: 2 }} justifyContent="center" alignItems="center">
        <GMImageWithFallback url={sparePart.image.uri} />
      </Grid2>
      <Grid2 container size={{ xs: 9 }} flexDirection="column" spacing={2}>
        <Grid2>
          <SparePartIdWithIcon sparePartId={sparePart.sparePartNumber.toString()} opaque />
        </Grid2>
        <Grid2>
          <Typography>{sparePart.description}</Typography>
        </Grid2>
        {consumedFrom && (
          <Grid2>
            <Typography color={"GrayText"} variant="caption">
              {consumedFrom.description}, {consumedFrom.storageBin}
            </Typography>
          </Grid2>
        )}
      </Grid2>
      <Grid2 container size={{ xs: 1 }} alignItems="center" justifyContent={"center"}>
        <Grid2>
          <Typography variant="h3">{quantity}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => {
  return (
    <Grid2 container flexDirection={"row"} size={{ xs: "grow" }} spacing={2}>
      <Grid2 container size={{ xs: 2 }} justifyContent="center" alignItems="center">
        <GMImageWithFallback url={""} />
      </Grid2>
      <Grid2 container size={{ xs: 9 }} flexDirection="column" spacing={2}>
        <Grid2>
          <Skeleton variant="text" width={120} />
          <Grid2>
            <Skeleton variant="text" width={280} />
          </Grid2>

          <Grid2>
            <Typography color={"GrayText"} variant="caption">
              <Skeleton variant="text" width={180} />
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 container size={{ xs: 1 }} alignItems="center" justifyContent={"center"}>
        <Grid2>
          <Skeleton variant="text" width={40} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export const SparePartsTabConsumedRow = skeletonify("SparePartsTabConsumedRow", ActualComponent, SkeletonComponent);
