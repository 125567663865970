import { FC } from "react";

import { useTranslation } from "../../../utility/i18n/translation";
import { TDRepairDescriptionContent } from "../../ticket-details/TDRepairDescription";
import { TicketDetailsRepairDescriptionsFragment } from "../../../__apollo__/graphql";

import { ARDCollapsibleSidebarWidget } from "./ARDCollapsibleSidebarWidget";

export const ARDRepairDocumentation: FC<TicketDetailsRepairDescriptionsFragment> = ({
  repairDocumentation: { damageList },
}) => {
  const { translate } = useTranslation();
  return (
    <div data-cy="ARDRepairDocumentation">
      <ARDCollapsibleSidebarWidget
        title={translate("ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.REPAIR_DOCS.TITLE", "Repair documentation")}
      >
        <TDRepairDescriptionContent damageList={damageList} />
      </ARDCollapsibleSidebarWidget>
    </div>
  );
};
