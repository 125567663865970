import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../../utility/icons";

export const SparePartIdWithIcon: FC<{
  sparePartId: string;
  opaque?: boolean;
}> = ({ sparePartId, opaque }) => {
  const opacity = opaque ? 0.6 : 1;
  return (
    <Grid2 container direction="row" alignItems="center">
      <Grid2>
        <Icons.SparePartCogs sx={{ mt: 0.5 }} fillOpacity={opacity} />
      </Grid2>
      <Grid2>
        <Typography variant="body2" sx={{ opacity }}>
          {sparePartId}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
