import { Box, Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { isPast } from "date-fns";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useDateFromMiddlewareWithLocale } from "../../../utility/date";
import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";
import { DateTimeWithCalendarIcon } from "../DateTimeWithCalendarIcon";
import { useTicketClosedText } from "../useTicketClosedText";
import { useTicketFlowColor } from "../useTicketFlowColor";

import { RequiredDates_Countdown_timestamp$key } from "./__generated__/RequiredDates_Countdown_timestamp.graphql";
import { RequiredDates_ticket$key } from "./__generated__/RequiredDates_ticket.graphql";

const ActualComponent: FC<{
  timestamp: RequiredDates_Countdown_timestamp$key;
}> = (props) => {
  const { timestamp: timestampRef } = props;
  const { time, date } = useFragment(
    graphql`
      fragment RequiredDates_Countdown_timestamp on LocalTimestamp {
        time
        date
      }
    `,
    timestampRef,
  );

  const { formatDistanceToNow } = useDateFromMiddlewareWithLocale();

  const requiredEndDateISO = `${date}T${time}`;
  const endDateColor = isPast(new Date(requiredEndDateISO)) ? "error" : "primary";

  return (
    <Grid2 container direction="row" justifyContent="flex-end" alignItems="center">
      <Grid2 sx={{ alignItems: "center", display: "flex" }}>
        <Icons.Countdown color={endDateColor} />
      </Grid2>
      <Grid2>
        <Typography variant="body2" color={endDateColor}>
          {formatDistanceToNow(requiredEndDateISO, {
            addSuffix: true,
          })}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={150} />;

export const Countdown = skeletonify("Countdown", ActualComponent, SkeletonComponent);

const ActualRequiredDatesComponent: FC<{ ticket: RequiredDates_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;
  const ticket = useFragment(
    graphql`
      fragment RequiredDates_ticket on Ticket {
        status
        ...useTicketFlowColor_ticket
        ...useTicketClosedText_ticket
        requiredEndTimestamp {
          date
          time
          ...RequiredDates_Countdown_timestamp
          ...DateTimeWithCalendarIcon_localTimestamp
        }
        completedTimestamp {
          ...DateTimeWithCalendarIcon_localTimestamp
        }
      }
    `,
    ticketRef,
  );
  const flowColor = useTicketFlowColor(ticket);
  const closedText = useTicketClosedText(ticket);
  const { requiredEndTimestamp, status, completedTimestamp } = ticket;

  if (status === "Closed") {
    return (
      <Grid2 container direction="column" alignItems="center" spacing={1}>
        {completedTimestamp && (
          <Grid2>
            <DateTimeWithCalendarIcon date={completedTimestamp} borderColor={flowColor} />
          </Grid2>
        )}
        <Grid2>
          <Typography variant="body2">{closedText}</Typography>
        </Grid2>
      </Grid2>
    );
  }

  return (
    <Grid2 container direction="column" alignItems="center" spacing={1}>
      {requiredEndTimestamp && (
        <Grid2>
          <DateTimeWithCalendarIcon date={requiredEndTimestamp} />
        </Grid2>
      )}
      {requiredEndTimestamp && (
        <Grid2>
          <Countdown timestamp={requiredEndTimestamp} />
        </Grid2>
      )}
    </Grid2>
  );
};

const ActualRequiredDatesSkeleton: FC = () => (
  <Box sx={{ width: 150 }}>
    <Grid2 container direction="column">
      <Grid2>
        <Skeleton />
      </Grid2>
      <Grid2>
        <Countdown.Skeleton />
      </Grid2>
    </Grid2>
  </Box>
);

export const RequiredDates = skeletonify("RequiredDates", ActualRequiredDatesComponent, ActualRequiredDatesSkeleton);
