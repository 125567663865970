import { Autocomplete, Grid2, TextField, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";

import { EquipmentHistoryDamageFilter_ticketsConnectionFacets$key } from "./__generated__/EquipmentHistoryDamageFilter_ticketsConnectionFacets.graphql";

export const EquipmentHistoryDamageFilter: FC<{
  ticketsConnectionFacets: EquipmentHistoryDamageFilter_ticketsConnectionFacets$key | null;
  selectedDamageCatalogId?: string;
  onChange: (nextSelectedDamageCatalogId?: string) => void;
}> = ({ ticketsConnectionFacets: facetsRef, selectedDamageCatalogId, onChange }) => {
  const data = useFragment(
    graphql`
      fragment EquipmentHistoryDamageFilter_ticketsConnectionFacets on TicketsConnectionFacets {
        damage {
          catalogId
          shortText
        }
      }
    `,
    facetsRef,
  );

  const { translate } = useTranslation();

  const options = useMemo(() => {
    const facets =
      data?.damage?.map(({ catalogId, shortText }) => ({
        catalogId,
        shortText,
      })) ?? [];
    return facets;
  }, [data?.damage]);

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="subtitle2">
          <Typography variant="subtitle2">{translate("TICKET_LIST.FILTERS.DAMAGE.TITLE", "Damage")}</Typography>
        </Typography>
      </Grid2>
      <Grid2>
        <Autocomplete
          data-testid="damage-filter"
          onChange={(_, value) => onChange(value?.catalogId)}
          value={options.find(({ catalogId }) => catalogId === selectedDamageCatalogId) ?? null}
          getOptionLabel={({ shortText }) => shortText}
          isOptionEqualToValue={(option, value) => option.catalogId === value.catalogId}
          disabled={options.length === 0}
          options={options}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid2>
    </Grid2>
  );
};
