// https://www.figma.com/file/aMa6b2gmq5NaaJDLf5HAIe/Flags-of-325-countries-(Community)
import cn from "../assets/images/flags/CN.svg";
import cz from "../assets/images/flags/CZ.svg";
import da from "../assets/images/flags/DK.svg";
import gb from "../assets/images/flags/GB.svg";
import us from "../assets/images/flags/US.svg";
import vn from "../assets/images/flags/VN.svg";
import hu from "../assets/images/flags/HU.svg";
import mx from "../assets/images/flags/MX.svg";
import logo from "../assets/images/logo.png";

export const images = {
  logo,
  flags: {
    mx,
    gb,
    us,
    vn,
    hu,
    cz,
    cn,
    da,
  },
};
