import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useFormatRelayLocalTimestampWithLocale } from "../../../utility/date";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { CreatedDateTime_ticket$key } from "./__generated__/CreatedDateTime_ticket.graphql";

const ActualComponent: FC<{ ticket: CreatedDateTime_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;

  const data = useFragment(
    graphql`
      fragment CreatedDateTime_ticket on Ticket {
        createdTimestamp {
          date
          time
        }
      }
    `,
    ticketRef,
  );

  const { createdTimestamp } = data;
  const { parseTimestamp } = useFormatRelayLocalTimestampWithLocale();
  const { translate } = useTranslation();
  const formattedDateTime = parseTimestamp({
    date: createdTimestamp.date,
    time: createdTimestamp.time,
    dateFormat: "Pp",
  });
  if (!data) {
    return null;
  }

  return (
    <Grid2>
      {hasValue(formattedDateTime) && (
        <Typography variant="body2" color="textSecondary">
          {translate("CREATEDDATETIME.CREATED", "Created")} {formattedDateTime}
        </Typography>
      )}
    </Grid2>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const CreatedDateTime = skeletonify("CreatedDateTime", ActualComponent, SkeletonComponent);
