import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, Grid2, IconButton, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useState } from "react";

import { ConfidentialitySearchRowFragment } from "../../__apollo__/graphql";
import { useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { GMImageWithFallback } from "../shared/GMImageComponents";

export const ConfidentialityDetailsCard: FC<{
  data: ConfidentialitySearchRowFragment;
  onClosePressed: () => void;
}> = ({ data, onClosePressed }) => {
  return (
    <Grid2 container flexDirection="column" sx={{ px: 6 }}>
      <HeaderRowWithNav onClosePressed={onClosePressed} />
      <Grid2 container flexDirection="row" spacing={9}>
        <Grid2 size={{ xs: "grow", md: 3 }}>
          <Image url={data.imageInformation.image.large} />
        </Grid2>
        <Grid2 size={{ md: 9 }} sx={{ mt: 6 }}>
          <Information {...data} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const Image: FC<{ url: string }> = ({ url }) => {
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const { translate } = useTranslation();
  const {
    shape: { borderRadius },
  } = useTheme();

  const onPreviewButtonPressed = useCallback(() => {
    setPreviewDialogOpen(true);
  }, []);

  const onCloseClicked = useCallback(() => {
    setPreviewDialogOpen(false);
  }, []);

  return (
    <Grid2 container justifyContent="end">
      <PreviewDialog open={previewDialogOpen} onCloseClicked={onCloseClicked} url={url} />
      <Grid2
        justifyContent="center"
        alignItems="center"
        sx={{
          borderColor: "secondary.main",
          border: 2,
          borderRadius,
          overflow: "hidden",
        }}
      >
        <GMImageWithFallback url={url} style={{ objectFit: "cover" }} />
      </Grid2>
      <Grid2>
        <Button onClick={onPreviewButtonPressed} variant="text" startIcon={<Icons.PopupPreview color="primary" />}>
          {translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.PREVIEW_BUTTON", "Preview")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

const PreviewDialog: FC<{
  url: string;
  open: boolean;
  onCloseClicked: () => void;
}> = ({ url, open, onCloseClicked }) => {
  return (
    <Dialog open={open} fullScreen onClose={onCloseClicked}>
      <Grid2 container flexDirection="column">
        <Grid2 alignSelf="end">
          <IconButton onClick={onCloseClicked}>
            <CloseIcon style={{ fontSize: 40 }} />
          </IconButton>
        </Grid2>
        <Grid2>
          <GMImageWithFallback url={url} style={{ objectFit: "contain" }} />
        </Grid2>
      </Grid2>
    </Dialog>
  );
};

const Information: FC<ConfidentialitySearchRowFragment> = ({
  id,
  imageInformation: { createdAt, createdBy },
  area,
  process,
}) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  return (
    <Grid2 container flexDirection="column" spacing={2} justifyContent="center" alignSelf="center">
      <Grid2>
        <RowWithLabel
          label={translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.CREATED_BY", "Created by:")}
          value={createdBy.__typename === "Employee" ? createdBy.id : ""}
        />
      </Grid2>
      <Grid2>
        <RowWithLabel
          label={translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.CREATED_AT", "Created time:")}
          value={format(createdAt, "Pp")}
        />
      </Grid2>
      <Grid2>
        <RowWithLabel
          label={translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.PLANT", "Plant:")}
          value={`${area?.name} (${area?.plant.id})`}
        />
      </Grid2>
      <Grid2>
        <RowWithLabel
          label={translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.PROCESS", "Process area:")}
          value={process?.name ?? ""}
        />
      </Grid2>
      <Grid2>
        <RowWithLabel
          label={translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.LABELS.IMAGE_ID", "Image ID:")}
          value={id}
        />
      </Grid2>
    </Grid2>
  );
};

const HeaderRowWithNav: FC<{ onClosePressed: () => void }> = ({ onClosePressed }) => {
  const { translate } = useTranslation();

  return (
    <Grid2 container justifyContent="space-between" alignItems="center">
      <Grid2>
        <Typography variant="h3">{translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.HEADER", "Image Details")}</Typography>
      </Grid2>
      <Grid2>
        <Button variant="text" onClick={onClosePressed}>
          {translate("CONFIDENTIALITY_DETAILS.HEADER_CARD.BACK_BUTTON", "Back to search result")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

const RowWithLabel: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Grid2 container flexDirection="row">
      <Grid2 minWidth="20%">
        <Typography variant="subtitle2">{label}</Typography>
      </Grid2>
      <Grid2>
        <Typography>{value}</Typography>
      </Grid2>
    </Grid2>
  );
};
