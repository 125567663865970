import { Divider, Grid2, Typography } from "@mui/material";
import { FC, useMemo } from "react";

import { TimeRegistrationOverviewFragment } from "../../../__apollo__/graphql";
import { useAddTimeContext } from "../../../contexts/add-time/add-time-context";
import { useTranslation } from "../../../utility/i18n/translation";

import { AddTimeOverviewRow } from "./AddTimeOverviewRow";
import { AddTimeTotalTimeSpentRow } from "./AddTimeTotalTimeSpentRow";

export const AddTimeOverview: FC<TimeRegistrationOverviewFragment & { interactive?: boolean }> = ({
  totalManHoursInMinutes,
  manHours,
  interactive = true,
}) => {
  const { translate } = useTranslation();

  const {
    state: { additions, deletions },
  } = useAddTimeContext();

  const totalTimeAfterChanges = useMemo(() => {
    const addedMinutes = additions.map((val) => val.minutesSpent).reduce((prev, curr) => prev + curr, 0);

    const removedMinutes = deletions.map((val) => val.minutes).reduce((prev, curr) => prev + curr, 0);

    return totalManHoursInMinutes + addedMinutes - removedMinutes;
  }, [additions, deletions, totalManHoursInMinutes]);

  return (
    <Grid2 container direction="column" spacing={2}>
      {interactive && (
        <Grid2>
          <Typography variant="subtitle2">{translate("ADD_TIME.OVERVIEW.HEADER", "Overview")}</Typography>
        </Grid2>
      )}
      <Grid2 container direction="column" spacing={2}>
        {manHours.map((manHour) => {
          return (
            <Grid2 key={manHour.id} style={{ width: "100%" }}>
              <AddTimeOverviewRow {...manHour} interactive={interactive} />
              <Divider />
            </Grid2>
          );
        })}
        <Grid2
          style={{
            marginRight: 48,
          }}
        >
          <AddTimeTotalTimeSpentRow
            totalTimeSpentInMinutes={totalManHoursInMinutes}
            totalTimeAfterChanges={totalTimeAfterChanges}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
