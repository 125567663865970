import { Button, Card, Divider, Grid2 } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";

import { GetDataForAddMachineTimeFlowQuery } from "../../__apollo__/graphql";
import { useMachineHoursContext } from "../../contexts/add-machine-hours/add-machine-hours-context";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { ActivityIndicator } from "../shared/ActivityIndicator";

import { AddMachineHoursHeader } from "./AddMachineHoursHeader";
import { useAddMachineHoursMutation } from "./addMachineHoursMutation";
import { AddMachineHoursRow } from "./AddMachineHoursRow";
import { AddMachineHoursOverview } from "./overview/machine-hours-overview/MachineHoursOverview";

export const AddMachineHoursRoot: FC<
  Extract<GetDataForAddMachineTimeFlowQuery["ticket"], { __typename?: "Ticket" }>
> = (props) => {
  const { translate } = useTranslation();
  const { saveChanges, loading } = useAddMachineHoursMutation(props.id);
  const navigate = useNavigate();
  const { showSnack } = useGMSnackbar();
  const { state, dispatch } = useMachineHoursContext();

  const onAddMachineClicked = () => {
    dispatch({ type: "set_show_empty_row", showEmptyRow: true });
  };

  const onSavePressed = async () => {
    const result = await saveChanges();
    if (result === "success") {
      navigate(-1);
    }
    if (result === "no-request-made") {
      showSnack({
        message: translate(
          "ADD_MACHINE_HOURS.NO_REQUEST_MADE",
          "No additions or deletions detected, so no changes made",
        ),
        variant: "info",
      });
    }
  };

  return (
    <Card
      sx={{
        py: 5,
        px: 7.5,
      }}
    >
      <Grid2 container direction="column">
        <Grid2>
          <AddMachineHoursHeader />
        </Grid2>
        <Grid2 container direction="column" spacing={1} style={{ width: "100%" }}>
          {state.additions.map((addition) => {
            return (
              <Grid2 key={addition.machine.id}>
                <AddMachineHoursRow ticketId={props.id} machine={addition.machine} />
                <Divider />
              </Grid2>
            );
          })}
          {state.showEmptyRow && (
            <Grid2>
              <AddMachineHoursRow ticketId={props.id} />
            </Grid2>
          )}
        </Grid2>
        <Grid2 style={{ marginTop: 20 }}>
          <Button variant="outlined" color="primary" onClick={onAddMachineClicked} disabled={state.showEmptyRow}>
            {translate("ADD_MACHINE_HOURS.ADD_MACHINE", "Add machine")}
          </Button>
        </Grid2>
        <Grid2 style={{ marginTop: 40 }}>
          <AddMachineHoursOverview {...props} />
        </Grid2>
        <Grid2 style={{ alignSelf: "flex-end", marginTop: 20 }}>
          <Button variant="contained" color="primary" onClick={onSavePressed} disabled={loading}>
            {loading ? <ActivityIndicator size={32} /> : translate("ADD_MACHINE_HOURS.SAVE", "Save")}
          </Button>
        </Grid2>
      </Grid2>
    </Card>
  );
};
