import { Box, Grid2, TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";

type Props = TextFieldProps & { loading: boolean };

export const LoadingTextField: FC<Props> = (props) => {
  const { loading, ...rest } = props;
  return (
    <Grid2 style={{ position: "relative" }}>
      <TextField
        {...rest}
        InputProps={{
          endAdornment: loading ? (
            <Box mr={2}>
              <ActivityIndicator />
            </Box>
          ) : null,
        }}
      />
    </Grid2>
  );
};
