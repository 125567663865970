import { hasValue } from "@lego/mst-error-utilities";
import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from "react";

import {
  ActivityOptionFragment,
  CauseOptionFragment,
  ComponentOptionFragment,
  DamageOptionFragment,
  RepairDocumentationFlowVariant,
  SubEquipmentOptionFragment,
} from "../../__apollo__/graphql";

import { AddRepairDescriptionActions, addRepairReducer } from "./add-repair-description-reducer";

export type ARDStep = "subEquipment" | "component" | "damage" | "cause" | "activities" | "comment" | "summary";
export interface AddRepairDocumentationState {
  chosenDamage?: DamageOptionFragment;
  chosenSubEquipment?: SubEquipmentOptionFragment;
  chosenComponent?: ComponentOptionFragment;
  showActivities: boolean;
  customDamageString?: string;
  customSubEquipmentString?: string;
  customComponentString?: string;
  chosenCause?: CauseOptionFragment;
  customCauseString?: string;
  chosenActivities: ActivityOptionFragment[];
  repairComment?: string;
  currentStep: ARDStep;
  variant: RepairDocumentationFlowVariant;
}

export const initialRepairDescriptionState: AddRepairDocumentationState = {
  showActivities: false,
  chosenActivities: [],
  currentStep: "damage",
  variant: RepairDocumentationFlowVariant.Baseline,
};
export interface AddRepairDescriptionContext {
  state: AddRepairDocumentationState;
  dispatch: Dispatch<AddRepairDescriptionActions>;
}

const Context = createContext<AddRepairDescriptionContext | undefined>(undefined);

export const useAddRepairDescriptionContext = (): AddRepairDescriptionContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Missing AddRepairDescriptionProvider");
  }

  return context;
};

export const AddRepairDescriptionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(addRepairReducer, initialRepairDescriptionState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION = 40;
export const isDamageInputForRepairDescriptionValid = (state: AddRepairDocumentationState): boolean => {
  if (state.chosenDamage?.requiresAdditionalInput) {
    return isCustomStringValid(state.customDamageString);
  } else {
    return hasValue(state.chosenDamage);
  }
};

export const isCauseInputForRepairDescriptionValid = (state: AddRepairDocumentationState): boolean => {
  if (state.chosenCause?.requiresAdditionalInput) {
    return isCustomStringValid(state.customCauseString);
  } else {
    return hasValue(state.chosenCause);
  }
};

const isCustomStringValid = (value?: string | null): boolean => {
  return hasValue(value) && value.length > 0 && value.length <= MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION;
};
