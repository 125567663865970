import { gql } from "@apollo/client";
import { Grid2 } from "@mui/material";
import { FC } from "react";

import { AddRepairDocumentationSidebarFragment } from "../../../__apollo__/graphql";
import { TD_MOULD_DIMMING_DETAILS } from "../../ticket-details/TDDimmingDetails";
import { TD_ERROR_DESCRIPTION_FRAGMENT } from "../../ticket-details/TDErrorDescription";
import { TD_REPAIR_DOCUMENTATION } from "../../ticket-details/TDRepairFragments";

import { ARDDimmings } from "./ARDDimmings";
import { ARDErrorDescription } from "./ARDErrorDescription";
import { ARDRepairDocumentation } from "./ARDRepairDocumentation";
import { ARDSpareParts } from "./ARDSpareParts";

export const ARD_SIDEBAR_FRAGMENT = gql`
  fragment AddRepairDocumentationSidebar on Ticket {
    id
    ...TicketDetailsErrorDescription
    ...TicketDetailsRepairDescriptions
    ...TicketDetailsMouldDimmingDetails
  }
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
  ${TD_REPAIR_DOCUMENTATION}
  ${TD_MOULD_DIMMING_DETAILS}
`;

export const ARDSidebar: FC<AddRepairDocumentationSidebarFragment> = (props) => {
  return (
    <Grid2 container direction="column" spacing={2} marginBottom={50}>
      <Grid2>
        <ARDErrorDescription {...props} />
      </Grid2>
      <Grid2>
        <ARDRepairDocumentation {...props} />
      </Grid2>
      <Grid2>
        <ARDSpareParts />
      </Grid2>
      <Grid2>
        <ARDDimmings {...props} />
      </Grid2>
    </Grid2>
  );
};
