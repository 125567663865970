import { hasValue } from "@lego/mst-error-utilities";

import { CreateTicketState, CreateTicketStep } from "./create-ticket-context";
import { AllowedTicketPriority } from "./__generated__/CreateTicketDescriptionStepMutation.graphql";

export type CreateTicketActions =
  | { type: "set_equipment"; payload: string | null }
  | { type: "set_equipment_search"; payload: number | null }
  | { type: "set_location"; payload: string }
  | {
      type: "set_room";
      payload: string;
    }
  | { type: "set_priority"; payload: AllowedTicketPriority }
  | { type: "set_required_end_date"; date: Date | null }
  | { type: "set_error_changeover"; payload: boolean }
  | { type: "set_error_after_repair"; payload: boolean }
  | {
      type: "set_description";
      payload: string;
    }
  | {
      type: "set_sublocation";
      payload: { isMandatory: boolean; text?: string };
    }
  | { type: "step_forward" }
  | { type: "step_backward" }
  | { type: "set_equipment_type"; value: CreateTicketState["equipmentType"] }
  | { type: "set_current_step"; currentStep: CreateTicketStep };

export const createTicketReducer = (state: CreateTicketState, action: CreateTicketActions): CreateTicketState => {
  switch (action.type) {
    case "set_equipment":
      if (action.payload === state.equipmentId) {
        return state;
      }
      return {
        ...state,
        equipmentId: action.payload ?? undefined,
        locationId: undefined,
        description: undefined,
        priority: undefined,
        errorAfterRepair: false,
        errorDuringChangeOver: false,
      };
    case "set_equipment_search":
      if (action.payload === state.equipmentSearch) {
        return state;
      }
      return {
        ...state,
        equipmentSearch: action.payload ?? undefined,
      };
    case "set_location":
      return {
        ...state,
        locationId: action.payload,
      };
    case "set_equipment_type":
      return {
        ...state,
        equipmentType: action.value,
      };
    case "set_sublocation":
      return {
        ...state,
        sublocation: action.payload,
      };
    case "set_room":
      return {
        ...state,
        room: action.payload,
      };
    case "set_priority":
      return {
        ...state,
        priority: action.payload,
      };
    case "set_required_end_date":
      return {
        ...state,
        requiredEndDate: action.date,
      };
    case "set_error_changeover":
      return {
        ...state,
        errorDuringChangeOver: action.payload,
      };
    case "set_error_after_repair":
      return {
        ...state,
        errorAfterRepair: action.payload,
      };
    case "set_description":
      return {
        ...state,
        description: action.payload,
      };
    case "step_forward":
    case "step_backward":
      return navigateStepIfPossible(state, action);

    default:
      return state;
  }
};

const navigateStepIfPossible = (
  state: CreateTicketState,
  action:
    | Extract<CreateTicketActions, { type: "step_forward" }>
    | Extract<CreateTicketActions, { type: "step_backward" }>,
): CreateTicketState => {
  switch (state.currentStep) {
    case "equipment":
      if (action.type === "step_backward") {
        return state;
      }

      if (!hasValue(state.equipmentId)) {
        return state;
      }

      return {
        ...state,
        currentStep: state.equipmentType === "Mould" ? "priority" : "location",
      };
    case "location":
      if (action.type === "step_backward") {
        return { ...state, currentStep: "equipment" };
      }

      return {
        ...state,
        currentStep: "priority",
      };

    case "priority":
      if (action.type === "step_backward") {
        return {
          ...state,
          currentStep: state.equipmentType === "Mould" ? "equipment" : "location",
        };
      }

      if (!hasValue(state.priority)) {
        return state;
      }

      return {
        ...state,
        currentStep: "description",
      };

    case "description":
      if (action.type === "step_backward") {
        return { ...state, currentStep: "priority" };
      }

      return state;
    default:
      return state;
  }
};
