import { FormGroup, Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { TicketStatus } from "../../__apollo__/graphql";
import { useTranslation } from "../../utility/i18n/translation";

import { TicketSearchBooleanFilterWithFacet } from "./TicketSearchBooleanFilterComponents";
import { useTicketSearchFilterContext } from "./TicketSearchFilters";
import { TicketsStatusInput } from "./__generated__/TicketSearchQuery.graphql";
import {
  TicketSearchStatusFilter_ticketsConnectionFacets$data,
  TicketSearchStatusFilter_ticketsConnectionFacets$key,
} from "./__generated__/TicketSearchStatusFilter_ticketsConnectionFacets.graphql";

type Facets = NonNullable<TicketSearchStatusFilter_ticketsConnectionFacets$data["status"]>;
export interface TicketSearchStatusFilterProps {
  ticketsConnectionFacets: TicketSearchStatusFilter_ticketsConnectionFacets$key | null;
}

export const TicketSearchStatusFilter: FC<TicketSearchStatusFilterProps> = (props) => {
  const { ticketsConnectionFacets: facetsRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchStatusFilter_ticketsConnectionFacets on TicketsConnectionFacets {
        status {
          count
          value
        }
      }
    `,
    facetsRef,
  );
  const {
    filters: { status: selectedStatuses = [], completedAfterDate, completedBeforeDate },
    onChange,
  } = useTicketSearchFilterContext();
  const { translate } = useTranslation();

  const facets = data?.status?.map(({ count, value }) => ({ count, value })) ?? [];

  const handleChange = (status: TicketsStatusInput) => (enabled: boolean) => {
    const filteredStatuses = selectedStatuses?.filter((s) => s !== status) ?? [];

    const newStatus = enabled ? filteredStatuses.concat(status) : filteredStatuses;

    const clearCompletedDate = !newStatus.includes(TicketStatus.Closed);
    onChange((prev) => ({
      ...prev,
      status: newStatus,
      completedAfterDate: clearCompletedDate ? undefined : completedAfterDate,
      completedBeforeDate: clearCompletedDate ? undefined : completedBeforeDate,
    }));
  };

  const statuses = useMemo<{ label: string; value: TicketsStatusInput }[]>(
    () => [
      {
        label: translate("TICKET_LIST.FILTERS.TICKET_STATUS.ASSIGNED", "Assigned"),
        value: "InProgress",
      },
      {
        label: translate("TICKET_LIST.FILTERS.TICKET_STATUS.UNASSIGNED", "Unassigned"),
        value: "NotStarted",
      },
      {
        label: translate("TICKET_LIST.FILTERS.TICKET_STATUS.RELEASED", "Released"),
        value: "Released",
      },
      {
        label: translate("TICKET_LIST.FILTERS.TICKET_STATUS.CLOSED", "Closed"),
        value: "Closed",
      },
    ],
    [translate],
  );

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("TICKET_LIST.FILTERS.TICKET_STATUS.TITLE", "Ticket status")}
        </Typography>
      </Grid2>
      <Grid2 ml={2}>
        <FormGroup>
          {statuses.map(({ label, value }) => (
            <TicketSearchBooleanFilterWithFacet<Facets[0]>
              key={value}
              label={label}
              enabled={selectedStatuses.includes(value)}
              onChange={handleChange(value)}
              facetValue={{ facets: facets, value: value }}
            />
          ))}
        </FormGroup>
      </Grid2>
    </Grid2>
  );
};
