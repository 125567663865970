import { Avatar, AvatarProps, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useAuthenticatedPictureSource } from "../../hooks/images";
import { useClientRect } from "../../hooks/measurements";
import { skeletonify } from "../../skeleton";

import { EmployeeAvatar_employee$key } from "./__generated__/EmployeeAvatar_employee.graphql";

const stringToColor = (str: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const useStringAvatar = (name: string, size: string | number): AvatarProps => {
  const [rect, ref] = useClientRect<HTMLDivElement>();
  return {
    ref,
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size,
      fontSize: rect ? rect.height / 3 : undefined,
    },
    children: name
      .split(" ")
      .map((s) => s[0])
      .join(""),
  };
};

const ActualComponent: FC<{
  employee: EmployeeAvatar_employee$key;
  size?: string | number;
}> = (props) => {
  const { employee: employeeRef, size = "100%" } = props;
  const employee = useFragment(
    graphql`
      fragment EmployeeAvatar_employee on Employee {
        firstName
        lastName
        image {
          uri
        }
      }
    `,
    employeeRef,
  );
  const { firstName, lastName, image } = employee;
  const src = useAuthenticatedPictureSource(image.uri);
  const stringAvatar = useStringAvatar(`${firstName} ${lastName}`, size);

  return <Avatar src={src} {...stringAvatar}></Avatar>;
};

const SkeletonComponent: FC<{ size?: string | number }> = (props) => {
  const { size = "100%" } = props;
  return (
    <Skeleton
      variant="circular"
      sx={{
        height: size,
        width: size,
      }}
    />
  );
};

export const EmployeeAvatar = skeletonify("EmployeeAvatar", ActualComponent, SkeletonComponent);
