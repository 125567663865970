import { hasValue } from "@lego/mst-error-utilities";
import { Card, Grid2, Skeleton, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { AreaWithFlag } from "../../components/shared/AreaWithFlag";
import { ChipWithDefaultBackground } from "../../components/ticket-card/components/TicketCardMouldChips";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { useMouldStatusCode } from "../components/mould/statusCodeHook";
import { StatusPill } from "../components/mould/StatusPill";
import { skeletonify } from "../skeleton";

import { MtsLogCard_mould$key } from "./__generated__/MtsLogCard_mould.graphql";
import { MtsLogCard_mtsLog$key } from "./__generated__/MtsLogCard_mtsLog.graphql";
import { MtsLogCardDataRow } from "./MtsLogCardDataRow";

const ActualComponent: FC<
  { isHighlighted?: boolean } & ({ mtsLog: MtsLogCard_mtsLog$key } | { mould: MtsLogCard_mould$key })
> = (props) => {
  const { isHighlighted = false } = props;
  const { palette } = useTheme();
  const borderColor = palette.secondary.main;
  const { translate } = useTranslation();
  const isMtsLog = "mtsLog" in props;
  const mtsLogData = useFragment(
    graphql`
      fragment MtsLogCard_mtsLog on MTSLog {
        employee {
          firstName
          lastName
        }
        location {
          description
        }
        plant {
          ...AreaWithFlag_plant
        }
        createdDate {
          date
          time
        }
        statusCode
        rack
        sublocation {
          ... on FreeTextSublocation {
            description
          }
          ... on Sublocation {
            description
          }
        }
        mouldMachine {
          equipmentNumber
        }
      }
    `,
    isMtsLog ? props.mtsLog : null,
  );

  const mouldData = useFragment(
    graphql`
      fragment MtsLogCard_mould on Mould {
        location {
          description
        }
        plant {
          ...AreaWithFlag_plant
        }
        statusCode
        rack
        sublocation {
          ... on FreeTextSublocation {
            description
          }
          ... on Sublocation {
            description
          }
        }
        mouldMachine {
          equipmentNumber
        }
      }
    `,
    isMtsLog ? null : props.mould,
  );

  const data = mtsLogData ?? mouldData;
  const statusDescription = useMouldStatusCode(data?.statusCode ?? null);

  if (!hasValue(data)) {
    return null;
  }

  return (
    <Card
      sx={{
        p: 2,
        ...(isHighlighted && {
          border: "solid 2px",
          borderColor: borderColor,
        }),
      }}
    >
      <Grid2 container direction="row">
        <Grid2 container direction="column" size={{ xs: 1 }}>
          <StatusPill
            statusCode={data.statusCode ?? 0}
            backgroundColor={!isMtsLog ? palette.success.main : undefined}
          />
        </Grid2>
        <Grid2 size={{ xs: "grow" }}>
          <Grid2 container direction="column" spacing={1} pt={1}>
            <Grid2>
              <Typography variant="h5">{statusDescription}</Typography>
            </Grid2>
            <Grid2 container>
              <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.PLANT", "Plant")}>
                <AreaWithFlag plant={data.plant ?? null} />
              </MtsLogCardDataRow>
            </Grid2>
            {data.location?.description && (
              <Grid2 container>
                <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.LOCATION", "Location")}>
                  <Typography>{data.location.description}</Typography>
                </MtsLogCardDataRow>
              </Grid2>
            )}
            {data.rack && (
              <Grid2 container>
                <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.RACK", "Rack")}>
                  <Typography>{data.rack}</Typography>
                </MtsLogCardDataRow>
              </Grid2>
            )}
            {data.sublocation?.description && (
              <Grid2 container>
                <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.ROOM", "Room (Row/Place)")}>
                  <Typography>{data.sublocation.description}</Typography>
                </MtsLogCardDataRow>
              </Grid2>
            )}
            {data.mouldMachine?.equipmentNumber && (
              <Grid2 container>
                <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.MACHINE", "Machine")}>
                  <Typography>{data.mouldMachine.equipmentNumber}</Typography>
                </MtsLogCardDataRow>
              </Grid2>
            )}
            {"employee" in data && (
              <Grid2 container>
                <MtsLogCardDataRow title={translate("TICKET_DETAILS.MTS_LOG_TAB.EMPLOYEE", "Employee")}>
                  <Typography>
                    {data.employee?.firstName} {data.employee?.lastName}
                  </Typography>
                </MtsLogCardDataRow>
              </Grid2>
            )}
            {"createdDate" in data && (
              <Grid2 container direction="row" spacing={1}>
                <Grid2>
                  <ChipWithDefaultBackground icon={<Icons.Calendar />} label={data.createdDate.date} />
                </Grid2>
                <Grid2>
                  <ChipWithDefaultBackground icon={<Icons.Clock />} label={data.createdDate.time} />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </Card>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={100} />;
};

export const MtsLogCard = skeletonify("MtsLogCard", ActualComponent, SkeletonComponent);
