import { Chip, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { skeletonify } from "../../skeleton";

import { EquipmentGroupChip_equipment$key } from "./__generated__/EquipmentGroupChip_equipment.graphql";

const ActualComponent: FC<{
  equipment: EquipmentGroupChip_equipment$key | null | undefined;
}> = (props) => {
  const { equipment: equipmentRef } = props;
  const equipment = useFragment(
    graphql`
      fragment EquipmentGroupChip_equipment on Equipment {
        group {
          id
          description
        }
      }
    `,
    equipmentRef ?? null,
  );

  if (!equipment?.group) {
    return null;
  }

  const { id, description } = equipment.group;

  return <Chip data-cy="EquipmentGroupChip" variant="filled" label={`${id} ${description}`} />;
};

const SkeletonComponent: FC = () => <Skeleton width={120} height={40} sx={{ borderRadius: 2 }} />;

export const EquipmentGroupChip = skeletonify("EquipmentGroupChip", ActualComponent, SkeletonComponent);
