import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Checkbox, FormControlLabel, FormGroup, Grid2, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { endOfDay, startOfDay } from "date-fns";
import uniqBy from "lodash/uniqBy";
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";

import { ConfidentialityAccessLogQuery, ConfidentialityAccessLogQueryVariables } from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { FillWidthLoading } from "../shared/FillWidthLoading";
import { GMDatePicker } from "../shared/GMDatePicker";

export const ConfidentialityDetailsAccessLog: FC<{ id: string }> = ({ id }) => {
  const { loading, data } = useAccessLogQuery(id);
  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  const [viewedByFilter, setViewedByFilter] = useState<string[]>([]);

  const markEmployee = useCallback(
    (employeeId: string) => {
      if (viewedByFilter.includes(employeeId)) {
        setViewedByFilter(viewedByFilter.filter((val) => val !== employeeId));
      } else {
        setViewedByFilter((curr) => {
          return curr.concat([employeeId]);
        });
      }
    },
    [viewedByFilter],
  );

  const filtered = useMemo(() => {
    const filteredByViewedBy = data?.confidentialityAccessLog.filter((item) => {
      const containedInViewedBy = viewedByFilter.length > 0 ? viewedByFilter.includes(item.viewedBy.employeeId) : true;

      return containedInViewedBy;
    });

    const filteredByDate = filteredByViewedBy?.filter((val) => {
      const viewedAt = new Date(val.viewedAt);
      const isAfterFromLimit = hasValue(timeFrom) ? viewedAt >= startOfDay(timeFrom) : true;
      const isBeforeToLimit = hasValue(timeTo) ? viewedAt <= endOfDay(timeTo) : true;
      return isAfterFromLimit && isBeforeToLimit;
    });

    return filteredByDate;
  }, [data?.confidentialityAccessLog, timeFrom, timeTo, viewedByFilter]);

  return (
    <Grid2 container direction="row" sx={{ px: 6, mt: 4 }}>
      <Grid2 size={{ xs: "grow", md: 3 }}>
        <LogFilter
          unfiltered={data?.confidentialityAccessLog ?? []}
          from={{ fromDate: timeFrom, setFrom: setTimeFrom }}
          to={{ setTo: setTimeTo, toDate: timeTo }}
          viewedBy={{
            filter: viewedByFilter,
            markEmployee,
          }}
        />
      </Grid2>
      <Grid2 size={{ xs: "grow", md: 9 }} minHeight={1000}>
        {loading && <FillWidthLoading />}
        <AccessLogGrid data={filtered ?? []} />
      </Grid2>
    </Grid2>
  );
};

type FilterOptions = {
  unfiltered: ConfidentialityAccessLogQuery["confidentialityAccessLog"];
  from: {
    fromDate: Date | null;
    setFrom: Dispatch<SetStateAction<Date | null>>;
  };
  to: {
    toDate: Date | null;
    setTo: Dispatch<SetStateAction<Date | null>>;
  };
  viewedBy: {
    filter: string[];
    markEmployee: (employeeId: string) => void;
  };
};

const LogFilter: FC<FilterOptions> = ({
  unfiltered,
  from: { fromDate, setFrom },
  to: { setTo, toDate },
  viewedBy: { filter, markEmployee },
}) => {
  const { translate } = useTranslation();

  const viewedByOptions = uniqBy(unfiltered, (log) => log.viewedBy.employeeId);

  return (
    <Grid2 container spacing={4} direction="column" sx={{ pr: 2 }}>
      <Grid2>
        <Typography variant="h3">{translate("CONFIDENTIALITY_ACCESS_LOG.FILTER.TITLE", "Filter")}</Typography>
      </Grid2>
      <Grid2>
        <GMDatePicker
          title={translate("CONFIDENTIALITY_ACCESS_LOG.FILTER.FROM_PICKER_TITLE", "View time from")}
          value={fromDate}
          onChange={setFrom}
          showOptional={false}
        />
      </Grid2>
      <Grid2>
        <GMDatePicker
          title={translate("CONFIDENTIALITY_ACCESS_LOG.FILTER.TO_PICKER_TITLE", "View time to")}
          value={toDate}
          onChange={setTo}
          showOptional={false}
        />
      </Grid2>
      <Grid2 container direction="column">
        <Grid2>
          <Typography>{translate("CONFIDENTIALITY_ACCESS_LOG.FILTER.VIEWED_BY_TITLE", "Viewed by")}</Typography>
        </Grid2>
        <Grid2>
          <FormGroup>
            {viewedByOptions.map((option) => {
              const handleChange = () => {
                markEmployee(option.viewedBy.employeeId);
              };
              const checked = filter.includes(option.viewedBy.employeeId);
              return (
                <FormControlLabel
                  key={option.viewedBy.userName}
                  control={<Checkbox onChange={handleChange} checked={checked} />}
                  label={`${option.viewedBy.userName} (${option.viewedBy.employeeId})`}
                />
              );
            })}
          </FormGroup>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const columnNames = {
  id: "id",
  username: "username",
  employeeId: "employeeId",
  viewedAt: "viewedAt",
};
const AccessLogGrid: FC<{
  data: ConfidentialityAccessLogQuery["confidentialityAccessLog"];
}> = ({ data }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  const columnMinWidth = 200;
  const [pageSize, setPageSize] = useState(10);

  const onPageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const columns: GridColDef[] = [
    {
      field: columnNames.username,
      headerName: translate("CONFIDENTIALITY_ACCESS_LOG.COLUMN_NAMES.VIEWED_BY_USERNAME", "Viewed by (username)"),
      minWidth: columnMinWidth,
      flex: 1,
    },
    {
      field: columnNames.employeeId,
      headerName: translate("CONFIDENTIALITY_ACCESS_LOG.COLUMN_NAMES.VIEWED_BY_ID", "Viewed by (employee ID)"),
      minWidth: columnMinWidth,
      flex: 1,
    },
    {
      field: columnNames.viewedAt,
      headerName: translate("CONFIDENTIALITY_ACCESS_LOG.COLUMN_NAMES.VIEWED_TIME", "Viewed time"),
      minWidth: columnMinWidth,
      flex: 1,
    },
  ];

  const rows = data.map<{
    username: string;
    employeeId: string;
    viewedAt: string;
  }>((log) => {
    return {
      id: log.id,
      employeeId: log.viewedBy.employeeId,
      username: log.viewedBy.userName,
      viewedAt: format(log.viewedAt, "Pp"),
    };
  });

  return (
    <Grid2 container direction="column" spacing={4}>
      <Grid2>
        <Typography variant="h3">{translate("CONFIDENTIALITY_ACCESS_LOG.TITLE", "Access log")}</Typography>
      </Grid2>
      <Grid2>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 20, 50]}
          disableColumnMenu
          disableRowSelectionOnClick
          paginationModel={{ page: 0, pageSize: pageSize }}
          onPaginationModelChange={({ pageSize }) => onPageSizeChange(pageSize)}
        />
      </Grid2>
    </Grid2>
  );
};

const ACCESS_LOG = gql`
  query ConfidentialityAccessLog($input: ConfidentialityAccessLogQueryInput!) {
    confidentialityAccessLog(input: $input) {
      id
      viewedAt
      viewedBy {
        userName
        employeeId
      }
    }
  }
`;

const useAccessLogQuery = (imageId: string) => {
  return useGMQuery<ConfidentialityAccessLogQuery, ConfidentialityAccessLogQueryVariables>(ACCESS_LOG, {
    variables: { input: { imageId } },
  });
};
