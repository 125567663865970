import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from "react";

import { MachineTypeFragment } from "../../__apollo__/graphql";

import { AddMachineHoursActions, addMachineHoursReducer } from "./add-machine-hours-reducer";

export type AddMachineHourAddition = {
  machine: MachineTypeFragment;
  minutesSpent: number;
  comment?: string;
};

export type MachineHoursDeletion = {
  machineHourId: string;
  timeRegistrationNumber: number;
  minutes: number;
};

export interface AddMachineHoursState {
  additions: AddMachineHourAddition[];
  deletions: MachineHoursDeletion[];
  showEmptyRow: boolean;
}

const initialMachineHoursState: AddMachineHoursState = {
  additions: [],
  deletions: [],
  showEmptyRow: true,
};

export interface MachineHoursContext {
  state: AddMachineHoursState;
  dispatch: Dispatch<AddMachineHoursActions>;
}

const Context = createContext<MachineHoursContext | undefined>(undefined);

export const useMachineHoursContext = (): MachineHoursContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Missing AddMachineHoursProvider");
  }

  return context;
};

export const AddMachineHoursProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(addMachineHoursReducer, initialMachineHoursState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
