import { EquipmentSearchState } from "./equipment-search-context";

export type EquipmentSearchActions =
  | {
      type: "setCount";
      count: number;
    }
  | {
      type: "setSearchTerm";
      searchTerm: string | null;
    };

export const equipmentSearchReducer = (
  state: EquipmentSearchState,
  action: EquipmentSearchActions,
): EquipmentSearchState => {
  switch (action.type) {
    case "setCount":
      return {
        ...state,
        count: action.count,
      };
    case "setSearchTerm":
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
  }
};
