import { CSSProperties } from "react";

import { useTranslation } from "../../utility/i18n/translation";
import { images } from "../../utility/images";

export type FlagIconVariants = keyof typeof images.flags;

const getIconForCountry = (country: FlagIconVariants, style?: CSSProperties) => {
  return <img src={images.flags[country]} style={style} />;
};

export const getFlagIconForPlantNumber = (plantNumber: number | undefined, style?: CSSProperties) => {
  switch (plantNumber) {
    case 300:
    case 501:
    case 392:
    case 403:
      return getIconForCountry("da", style);
    case 305:
    case 395:
      return getIconForCountry("cz", style);
    case 689:
    case 658:
      return getIconForCountry("cn", style);
    case 371:
    case 379:
      return getIconForCountry("hu", style);
    case 372:
    case 394:
    case 431:
    case 504:
      return getIconForCountry("mx", style);
    case 482:
      return getIconForCountry("us", style);
    case 476:
      return getIconForCountry("vn", style);
  }
};

export const usePlantIdentifierForNumber = (plantNumber: number | undefined) => {
  const { translate } = useTranslation();
  switch (plantNumber) {
    case 300:
    case 501:
    case 392:
    case 403:
      return translate("PLANT_IDENTIFIERS.BILLUND", "BLL");
    case 305:
    case 395:
      return translate("PLANT_IDENTIFIERS.KLADNO", "KLD");
    case 689:
    case 658:
      return translate("PLANT_IDENTIFIERS.JIAXING", "JIA");
    case 371:
    case 379:
      return translate("PLANT_IDENTIFIERS.NYIREGYHAZA", "NYI");
    case 372:
    case 394:
    case 504:
    case 431:
      return translate("PLANT_IDENTIFIERS.MONTERREY", "LOM");
    case 500:
      return translate("PLANT_IDENTIFIERS.PLM", "PLM");
    case 482:
      return translate("PLANT_IDENTIFIERS.RICHMOND", "LMR");
    case 476:
      return translate("PLANT_IDENTIFIERS.VIETNAM", "VIE");
  }
};
