import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, GridSize, SxProps, TextField, TextFieldProps, Theme, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import debounce from "lodash/debounce";
import { ChangeEvent, FC, Fragment, Suspense, useCallback, useEffect, useMemo, useState, useTransition } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";

import { useTranslation } from "../../../utility/i18n/translation";
import { isValidSparePartId } from "../../utils";
import { SearchFieldAdornments, SearchFieldAdornmentVariant } from "../equipment/SearchFieldAdornments";
import { LoadingTextField } from "../LoadingTextField";

import SparePartSearchTextFieldQuery, {
  SparePartSearchTextFieldQuery as SparePartSearchTextFieldQueryType,
} from "./__generated__/SparePartSearchTextFieldQuery.graphql";
import { SparePartSearchLabel } from "./SparePartSearchLabel";

type SparePartSearchTextFieldProps = {
  onSparePartSelected: (sparePartNumber: string) => void;
  initialSparePartNumber?: string;
  showPrefixLabel?: boolean;
  showInput?: boolean;
  showPlaceholder?: boolean;
  variant?: SearchFieldAdornmentVariant;
  prefixLabelStyle?: SxProps<Theme>;
  inputFieldSize?: GridSize;
};

type InnerComponentLoaderProps = Omit<SparePartSearchTextFieldProps, "initialSparePartNumber"> & {
  onSearchIconClicked?: () => void;
  onClearIconClicked?: () => void;
  sparePartSearchString: string;
  setSparePartSearchString: (newValue: string) => void;
};

export const SparePartSearchTextField: FC<SparePartSearchTextFieldProps> = (props) => {
  const [sparePartSearchString, setSparePartSearchString] = useState(props.initialSparePartNumber ?? "");

  const { onSparePartSelected } = props;
  const variant = props.variant ?? "search";

  const onClearIconClicked = useCallback(() => {
    setSparePartSearchString("");
    onSparePartSelected("");
  }, [onSparePartSelected]);

  return (
    <>
      <InnerComponentLoader
        {...props}
        onClearIconClicked={["search-and-clear", "clear"].includes(variant) ? onClearIconClicked : undefined}
        sparePartSearchString={sparePartSearchString}
        setSparePartSearchString={setSparePartSearchString}
      />
    </>
  );
};

const SkeletonLoadingView: FC<{
  searchString: string;
  inputFieldSize?: GridSize;
}> = ({ searchString, inputFieldSize = 5 }) => {
  const { translate } = useTranslation();
  return (
    <Grid2 container spacing={1} direction="column">
      <Grid2>
        <Typography>{translate("CREATE_CMS_TICKET.SPAREPART_ID.TEXT", "Please enter spare part ID")}</Typography>
      </Grid2>
      <Grid2 container direction="row" alignItems="center">
        <Grid2 size={{ lg: inputFieldSize }}>
          <LoadingTextField loading value={searchString} />
        </Grid2>
        <Grid2 size={{ lg: "grow" }}>
          <SparePartSearchLabel.Skeleton />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const InnerComponent: FC<
  InnerComponentLoaderProps & {
    query: PreloadedQuery<SparePartSearchTextFieldQueryType>;
  }
> = ({
  onSparePartSelected,
  query: queryRef,
  setSparePartSearchString,
  onSearchIconClicked,
  onClearIconClicked,
  showPrefixLabel = false,
  showInput = true,
  showPlaceholder = true,
  sparePartSearchString,
  prefixLabelStyle,
  inputFieldSize = 5,
  variant,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const { sparePart } = usePreloadedQuery<SparePartSearchTextFieldQueryType>(
    graphql`
      query SparePartSearchTextFieldQuery($input: QuerySparePartInput!, $skip: Boolean!) {
        sparePart(input: $input) @skip(if: $skip) {
          ...SparePartSearchLabel_querySparePartResult
          ... on QuerySparePartSuccess {
            data {
              sparePartNumber
            }
          }
        }
      }
    `,
    queryRef,
  );

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps["onChange"] = (event) => {
      const value = event?.target.value;
      if (!isValidSparePartId(value)) {
        return;
      }

      setSparePartSearchString(value);
      onSparePartSelected(value);
    };
    const debounced = debounce(searchDispatch, 500);

    return (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value ?? "";
      if (value === "" && onClearIconClicked !== undefined) {
        onClearIconClicked();
      }
      if (isNaN(Number(value))) {
        return;
      }
      setSparePartSearchString(value);
      debounced(event);
    };
  }, [setSparePartSearchString, onSparePartSelected, onClearIconClicked]);

  return (
    <Grid2 container spacing={1} direction="column">
      {showPrefixLabel && (
        <Grid2>
          <Typography sx={prefixLabelStyle}>
            {translate("CREATE_CMS_TICKET.SPAREPART_ID.TEXT", "Please enter spare part ID")}
          </Typography>
        </Grid2>
      )}
      <Grid2>
        <Grid2 container direction="row" alignItems="center">
          {showInput ? (
            <Grid2 size={{ xs: inputFieldSize }}>
              <TextField
                placeholder={
                  showPlaceholder
                    ? translate("CREATE_CMS_TICKET.SPAREPART_ID.PLACEHOLDER", "Enter spare part ID")
                    : undefined
                }
                style={{ backgroundColor: palette.background.paper }}
                onChange={onChangeDebounced}
                value={sparePartSearchString}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <SearchFieldAdornments
                      variant={variant}
                      onSearchIconClicked={onSearchIconClicked}
                      onClearIconClicked={onClearIconClicked}
                    />
                  ),
                }}
              />
            </Grid2>
          ) : (
            <Grid2>
              <Typography>{sparePart?.data?.sparePartNumber}</Typography>
            </Grid2>
          )}
          {sparePart && (
            <Grid2 size={{ xs: "grow" }}>
              <SparePartSearchLabel.Suspense sparePart={sparePart} />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const InnerComponentLoader: FC<InnerComponentLoaderProps> = ({
  setSparePartSearchString,
  onSearchIconClicked,
  sparePartSearchString,
  ...rest
}) => {
  const [queryRef, loadQuery] = useQueryLoader<SparePartSearchTextFieldQueryType>(SparePartSearchTextFieldQuery);
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      loadQuery(
        {
          input: {
            sparePartNumber: sparePartSearchString,
          },
          skip: !hasValue(sparePartSearchString) || !isValidSparePartId(sparePartSearchString),
        },
        { fetchPolicy: "store-and-network" },
      );
    });
  }, [sparePartSearchString, loadQuery]);

  return (
    <Suspense fallback={<SkeletonLoadingView searchString={sparePartSearchString} />}>
      {queryRef ? (
        <InnerComponent
          {...rest}
          query={queryRef}
          onSearchIconClicked={onSearchIconClicked}
          sparePartSearchString={sparePartSearchString}
          setSparePartSearchString={setSparePartSearchString}
        />
      ) : (
        <SkeletonLoadingView searchString={sparePartSearchString} />
      )}
    </Suspense>
  );
};
