import { hasValue } from "@lego/mst-error-utilities";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogContentText, Grid2, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, ReactElement, useCallback, useMemo } from "react";

type SimpleDialogProps = {
  open: boolean;
  title: string;
  content:
    | {
        type: "text";
        subtitle?: string;
        body: string;
      }
    | {
        type: "node";
        node: ReactElement;
      };
  onDismiss: () => void;
  primaryAction: {
    primaryActionLabel: string;
    primaryActionPressed: () => void;
    primaryActionLoading?: boolean;
    primaryActionDisabled?: boolean;
  };
  secondaryAction?: {
    secondaryActionLabel: string;
    secondaryActionPressed: () => void;
    secondaryActionDisabled?: boolean;
  };
  maxWidth?: Pick<DialogProps, "maxWidth">["maxWidth"];
};

export const SimpleDialog: FC<SimpleDialogProps> = ({
  open,
  title,
  content,
  onDismiss,
  primaryAction: {
    primaryActionLabel,
    primaryActionPressed,
    primaryActionLoading = false,
    primaryActionDisabled = false,
  },
  secondaryAction,
  maxWidth,
}) => {
  const contentElement = useMemo(() => {
    if (content.type === "text") {
      const { body, subtitle } = content;
      return (
        <DialogContent>
          {hasValue(subtitle) && <DialogContentText>{subtitle}</DialogContentText>}
          <Typography>{body}</Typography>
        </DialogContent>
      );
    } else {
      return <DialogContent>{content.node}</DialogContent>;
    }
  }, [content]);

  const dismiss = useCallback(() => {
    if (primaryActionLoading) {
      return;
    }
    onDismiss();
  }, [onDismiss, primaryActionLoading]);

  return (
    <Dialog
      open={open}
      onClose={dismiss}
      data-cy="SimpleDialog"
      fullWidth={hasValue(maxWidth) ? true : false}
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2>
            <Typography variant="h3">{title}</Typography>
          </Grid2>
          <Grid2>
            <IconButton onClick={dismiss} size="large" disabled={primaryActionLoading}>
              <CloseIcon />
            </IconButton>
          </Grid2>
        </Grid2>
      </DialogTitle>
      {contentElement}
      <DialogActions sx={{ mt: 3, mx: 3 }}>
        {secondaryAction && (
          <Button
            onClick={secondaryAction.secondaryActionPressed}
            color="primary"
            disabled={secondaryAction.secondaryActionDisabled}
          >
            {secondaryAction.secondaryActionLabel}
          </Button>
        )}
        <Button
          onClick={primaryActionPressed}
          color="primary"
          variant="contained"
          loading={primaryActionLoading}
          disabled={primaryActionDisabled}
          data-cy="SimpleDialog-primaryAction"
        >
          {primaryActionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
