import { MsalProvider } from "@azure/msal-react";
import { CssBaseline, styled } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router";

import { MainRouter } from "./Router";
import { MuiNavbar } from "./components";
import { Banner } from "./components/shared/banner/Banner";
import { BannerProvider } from "./components/shared/banner/banner-context";
import { AuthContextProvider } from "./contexts/AuthContext";
import { GQLProvider } from "./contexts/GQLProvider";
import { UserPreferencesProvider } from "./contexts/UserPreferencesContext";
import { UsersnapProvider } from "./contexts/UsersnapProvider";
import { AreaProvider, RequireArea } from "./contexts/area";
import { HallMonitorSetupContainer } from "./migration/hall-monitor/hall-monitor-setup/HallMonitorSetupContainer";
import { HallMonitorContainer } from "./migration/hall-monitor/hall-monitor/HallMonitorContainer";
import { useAnalytics } from "./migration/hooks/analytics";
import { MicrosoftADInstance } from "./msal/config";
import { getCrashReporter } from "./utility/crash-reporter";
import { initI18n } from "./utility/i18n/i18n";
import { DatePickerLocalizationProvider } from "./utility/i18n/translation";
import { UserPermissionsProvider } from "./utility/permissions/permission-context";

initI18n();

const MainContent = styled("main")(() => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
}));

export const ImageCache = new Map<string, string>();

getCrashReporter().start();

export const App = (): ReactElement => {
  useAnalytics();

  return (
    <MsalProvider instance={MicrosoftADInstance}>
      <CssBaseline />
      <AuthContextProvider>
        <UsersnapProvider>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100dvh",
            }}
          >
            <Router>
              <Routes>
                <Route path={"/hall-monitors"} element={<HallMonitorSetupContainer />} />
                <Route path={"/hall-monitors/:id"} element={<HallMonitorContainer />} />
                <Route path={"/auth-callback"} element={null} />
                <Route
                  path="*"
                  element={
                    <AreaProvider>
                      <GQLProvider>
                        <RequireArea>
                          <UserPreferencesProvider>
                            <UserPermissionsProvider>
                              <DatePickerLocalizationProvider>
                                <BannerProvider>
                                  <MuiNavbar />
                                  <Banner />
                                  <MainContent data-cy="AppMainContent">
                                    <MainRouter />
                                  </MainContent>
                                </BannerProvider>
                              </DatePickerLocalizationProvider>
                            </UserPermissionsProvider>
                          </UserPreferencesProvider>
                        </RequireArea>
                      </GQLProvider>
                    </AreaProvider>
                  }
                />
              </Routes>
            </Router>
          </Box>
        </UsersnapProvider>
      </AuthContextProvider>
    </MsalProvider>
  );
};
