import { gql } from "@apollo/client";
import { FC } from "react";

import { TdRepairDescriptionQuery, TdRepairDescriptionQueryVariables } from "../../../__apollo__/graphql";
import { useGMQuery } from "../../../apollo/customApolloHooks";
import { TDRepairDescriptionApollo } from "../../../components/ticket-details/TDRepairDescription";
import { TD_REPAIR_DOCUMENTATION } from "../../../components/ticket-details/TDRepairFragments";

import { TDWidgetWithCollapsibleRowsSkeleton } from "./TDWidgetWithCollapsibleRowsSkeleton";

const QUERY = gql`
  query TDRepairDescription($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...TicketDetailsRepairDescriptions
      }
    }
  }
  ${TD_REPAIR_DOCUMENTATION}
`;

export const TDRepairDescription: FC<{ ticketNumber: string }> = ({ ticketNumber }) => {
  const { data, loading } = useGMQuery<TdRepairDescriptionQuery, TdRepairDescriptionQueryVariables>(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDWidgetWithCollapsibleRowsSkeleton showTopRightButton />;
  }

  if (!data || data.ticket.__typename !== "Ticket" || data.ticket.isCmsTicket) {
    return null;
  }

  return <TDRepairDescriptionApollo {...data.ticket} />;
};
