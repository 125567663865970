import { Chip, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useFormatRelayLocalTimestampWithLocale } from "../../utility/date";
import { Icons } from "../../utility/icons";

import { DateTimeWithCalendarIcon_localTimestamp$key } from "./__generated__/DateTimeWithCalendarIcon_localTimestamp.graphql";

export const DateTimeWithCalendarIcon: FC<{
  date?: DateTimeWithCalendarIcon_localTimestamp$key;
  borderColor?: string;
}> = ({ date: dateRef, borderColor }) => {
  const {
    palette: { background },
  } = useTheme();

  const date = useFragment(
    graphql`
      fragment DateTimeWithCalendarIcon_localTimestamp on LocalTimestamp {
        date
        time
      }
    `,
    dateRef ?? null,
  );
  const { parseTimestamp } = useFormatRelayLocalTimestampWithLocale();
  if (!date) {
    return null;
  }

  const res = parseTimestamp({
    date: date.date,
    time: date.time,
    dateFormat: "Pp",
  });

  const borderStyling = borderColor ? { borderColor, borderWidth: "1px", borderStyle: "solid" } : null;

  return (
    <Chip
      label={res}
      style={{ backgroundColor: background.default, ...borderStyling }}
      icon={<Icons.Calendar color="primary" />}
    />
  );
};
