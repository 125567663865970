import { Box, Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { CollapsibleWidget } from "../../components/shared/CollapsibleWidget";
import { Icons } from "../../utility/icons";

import { TicketRepairDocumentationStandardRow } from "./TicketRepairDocumentationStandardRow";
import { TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$key } from "./__generated__/TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation.graphql";

export const TicketRepairDocumentationPackingRow: FC<{
  subEquipmentRepairDocumentation: TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$key;
  padding?: number;
}> = ({ subEquipmentRepairDocumentation: subEquipmentRepairDocumentationRef, padding }) => {
  const { description, components } = useFragment(
    graphql`
      fragment TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation on SubEquipmentRepairDocumentation {
        description
        components {
          description
          damages {
            ...TicketRepairDocumentationStandardRow_damage
          }
        }
      }
    `,
    subEquipmentRepairDocumentationRef,
  );

  return (
    <CollapsibleWidget componentWhenClosed={<RowHeader title={description} />} padding={padding ?? 8}>
      <Grid2 container style={{ paddingLeft: 20, paddingRight: 40, marginBottom: 20 }} spacing={2}>
        <Grid2>
          <Grid2 container direction="column" spacing={1} borderLeft={1}>
            {components.map((component, index) => {
              return (
                <Grid2 key={`${index}:${component}`} container direction="row" sx={{ mt: 2 }}>
                  {/* Horizontal divider */}
                  <Grid2>
                    <Box
                      width={25}
                      borderTop={1}
                      sx={{
                        mt: 1.5,
                      }}
                    />
                  </Grid2>
                  <Grid2 sx={{ ml: 2, flex: 1 }}>
                    <Grid2>
                      <Typography>{component.description}</Typography>
                    </Grid2>

                    <Grid2 sx={{ mt: 1 }}>
                      {component.damages.map((damage, index) => {
                        return (
                          <TicketRepairDocumentationStandardRow
                            isCollapsible={false}
                            key={`${index}:${damage}`}
                            damage={damage}
                          />
                        );
                      })}
                    </Grid2>
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
        </Grid2>
      </Grid2>
    </CollapsibleWidget>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid2 container spacing={2} style={{ flex: 1 }}>
      <Grid2>
        <Icons.SubEquipment />
      </Grid2>
      <Grid2>
        <Typography>{title}</Typography>
      </Grid2>
    </Grid2>
  );
};
