import { gql } from "@apollo/client";
import { Button, Grid2, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import { NavLink } from "react-router";

import { ProfileLinkPictureQuery } from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { GMAvatar } from "../shared/GMImageComponents";

const imgHeight = 42;

const PROFILE_LINK_QUERY = gql`
  query ProfileLinkPicture {
    me {
      id
      firstName
      lastName
      profilePicture {
        id
        small
      }
    }
  }
`;

export const ProfileLink: FC = () => {
  const { data, loading } = useGMQuery<ProfileLinkPictureQuery>(PROFILE_LINK_QUERY);
  const { translate } = useTranslation();

  return (
    <>
      <Button
        component={NavLink}
        to={AppRoutes.profile}
        sx={{
          color: "white",
          textTransform: "none",
          borderRadius: 0,
          padding: 0,
          "&.active": {
            borderBottom: "2px solid white",
            mt: "4px",
          },
        }}
      >
        <>
          {!data || (loading && <Typography>{translate("NAVBAR.PROFILE", "Profile")}</Typography>)}
          {data && (
            <Grid2
              sx={{
                height: imgHeight,
                width: imgHeight,
                borderRadius: imgHeight / 2,
                backgroundColor: "white",
              }}
            >
              <GMAvatar {...data.me} pictureUri={data.me.profilePicture.small} />
            </Grid2>
          )}
        </>
      </Button>
    </>
  );
};
