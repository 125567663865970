import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../utility/icons";

export const ErrorMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <Grid2 container alignItems="center" data-cy="ErrorMessage">
      <Grid2>
        <Icons.WarningTriangle color="error" />
      </Grid2>
      <Grid2 ml={1}>
        <Typography variant="body2" color="error">
          {message}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
