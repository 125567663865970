import { hasValue } from "@lego/mst-error-utilities";
import { Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback } from "react";
import { usePaginationFragment } from "react-relay";

import { ExtractNodeFromConnection } from "../../utility-types";
import { useTranslation } from "../../utility/i18n/translation";
import { InfiniteList } from "../components/InfiniteList";

import { SisterMouldCard } from "./SisterMouldCard";
import {
  SisterMouldCardsList_mould$data,
  SisterMouldCardsList_mould$key,
} from "./__generated__/SisterMouldCardsList_mould.graphql";

type Item = ExtractNodeFromConnection<SisterMouldCardsList_mould$data["sisterMoulds"]>;

export const SisterMouldCardsList: FC<{
  mould: SisterMouldCardsList_mould$key;
}> = (props) => {
  const { mould: mouldRef } = props;
  const { data, ...paginationArgs } = usePaginationFragment(
    graphql`
      fragment SisterMouldCardsList_mould on Mould
      @refetchable(queryName: "SisterMouldCardsListRefetchQuery")
      @argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "ID" }) {
        ...SisterMouldCard_mould
        sisterMoulds(first: $first, after: $after) @connection(key: "SisterMouldCardsList_sisterMoulds") {
          edges {
            node {
              id
              ...SisterMouldCard_mould
            }
          }
        }
      }
    `,
    mouldRef,
  );
  const { translate } = useTranslation();
  const emptyLabel = translate("TICKET_DETAILS.SISTER_MOULDS_TAB.EMPTY", "No sister moulds found");
  const errorLabel = translate("TICKET_DETAILS.SISTER_MOULDS_TAB.ERROR", "Error getting sister moulds");

  const itemRender = useCallback((item: Item) => <SisterMouldCard.Suspense mould={item} />, []);
  const itemKeyExtractor = useCallback((item: Item) => item.id, []);
  const items = data.sisterMoulds.edges.filter(hasValue).map(({ node }) => node);

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2 width="100%">
        <SisterMouldCard mould={data} isHighlighted />
      </Grid2>
      <Grid2 width="100%">
        <InfiniteList<Item>
          items={items}
          itemRender={itemRender}
          itemKeyExtractor={itemKeyExtractor}
          emptyLabel={emptyLabel}
          errorLabel={errorLabel}
          {...paginationArgs}
        />
      </Grid2>
    </Grid2>
  );
};
