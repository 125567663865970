import { DoNotDisturbAlt } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Chip,
  Grid2,
  Accordion as MuiAccordion,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo, useState } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";

import { QAOperationCharacteristicsData } from "./QAOperationCharacteristicsData";
import { QAOperationsData_characteristics$key } from "./__generated__/QAOperationsData_characteristics.graphql";
import { QAOperationsData_equipment$key } from "./__generated__/QAOperationsData_equipment.graphql";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(() => ({
  padding: 0,
  borderBottom: "3px solid rgba(0, 0, 0, .125)",
  "&.MuiAccordion-root:before": {
    backgroundColor: "transparent",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
  "&.MuiPaper-root": {
    width: "100%",
  },
}));
const VALIDATION_RESULT_APPROVED = "A";
const VALIDATION_RESULT_REJECTED = "R" as const;

export const QAOperationsData: FC<{ queryRef: QAOperationsData_equipment$key }> = ({ queryRef }) => {
  const { translate } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const data = useFragment(
    graphql`
      fragment QAOperationsData_equipment on Equipment {
        ... on Mould {
          qualityManagementInspection {
            operations {
              name
              activityNumber
              validationResult
              ...QAOperationsData_characteristics #@defer
            }
          }
        }
      }
    `,
    queryRef,
  );

  const approvalStatus = (validationResult: string) => {
    switch (validationResult) {
      case VALIDATION_RESULT_APPROVED:
        return <Chip icon={<Icons.CheckmarkBold />} label="Approved" color="success" style={{ fontSize: 18 }} />;
      case VALIDATION_RESULT_REJECTED:
        return <Chip icon={<DoNotDisturbAlt />} label="Rejected" color="error" style={{ fontSize: 18 }} />;
      default:
        return (
          <Typography variant="body2" color="textSecondary">
            {translate("QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.NO_TESTS", "No tests")}
          </Typography>
        );
    }
  };

  const sortedOperations = useMemo(() => {
    const operationsCopy = [...(data?.qualityManagementInspection?.operations || [])];
    return operationsCopy.sort((a, b) => a.activityNumber.localeCompare(b.activityNumber));
  }, [data]);

  return (
    <Grid2 container size={{ xs: "grow" }}>
      {sortedOperations?.map((operation, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={operation.validationResult !== "" ? handleChange(`panel${index}`) : undefined}
          >
            <AccordionSummary expandIcon={operation.validationResult !== "" ? <ExpandMoreIcon /> : null}>
              <Grid2 container alignItems="center" spacing={2} size={{ xs: "grow" }}>
                <Grid2>
                  <Icons.Hashtag />
                </Grid2>
                <Grid2 size={{ xs: 3 }}>
                  <Typography>
                    {operation.activityNumber} - {operation.name}
                  </Typography>
                </Grid2>
                <Grid2>{approvalStatus(operation.validationResult)}</Grid2>
              </Grid2>
            </AccordionSummary>
            <OperationCharacteristics queryRef={operation} />
          </Accordion>
        );
      })}
    </Grid2>
  );
};

const OperationCharacteristics: FC<{ queryRef: QAOperationsData_characteristics$key }> = ({ queryRef }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = useFragment(
    graphql`
      fragment QAOperationsData_characteristics on QualityManagementOperation {
        characteristics {
          description
          validationResult
          ...QAOperationCharacteristicsData_characteristicsData
        }
      }
    `,
    queryRef,
  );

  return (
    <AccordionDetails>
      <Grid2 container style={{ backgroundColor: theme.palette.background.default }}>
        <Grid2
          container
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: theme.palette.background.paper, margin: "16px" }}
        >
          {data.characteristics.map((characteristic, index) => (
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontSize="16px" sx={{ marginRight: "8px" }}>
                  {characteristic.description}
                </Typography>
                {characteristic.validationResult === "R" && <Icons.WarningTriangle color="error" />}
              </AccordionSummary>

              <QAOperationCharacteristicsData queryRef={characteristic} />
            </Accordion>
          ))}
        </Grid2>
      </Grid2>
    </AccordionDetails>
  );
};
