import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";

import {
  BulkTimeAdditionInput,
  UpdateTimeForTicketMutation,
  UpdateTimeForTicketMutationVariables,
} from "../../__apollo__/graphql";
import { useGMMutation } from "../../apollo/customApolloHooks";
import { useAddTimeContext } from "../../contexts/add-time/add-time-context";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";

import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from "./overview/time-registration-gql-fragments";

const UPDATE_TIME_FOR_TICKET = gql`
  mutation UpdateTimeForTicket($input: ManageTimeRegistrationsInBulkInput!) {
    manageTimeRegistrationsInBulk(input: $input) {
      ... on Ticket {
        id
        ...TimeRegistrationOverview
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
`;

type SaveTimeResult = "success" | "error";

export const useAddTimeMutation = (
  ticketId: string,
): { saveChanges: () => Promise<SaveTimeResult>; loading: boolean } => {
  const {
    state: { additions, deletions },
  } = useAddTimeContext();
  const { translate } = useTranslation();

  const { showSnack } = useGMSnackbar();

  const [updateTimeForTicket, { loading }] = useGMMutation<
    UpdateTimeForTicketMutation,
    UpdateTimeForTicketMutationVariables
  >(UPDATE_TIME_FOR_TICKET, {
    onCompleted: () => {
      showSnack({
        message: translate("ADD_TIME.SUCCESS_SNACK", "Time for ticket successfully updated"),
        variant: "success",
      });
    },
  });

  const saveChanges = async (): Promise<SaveTimeResult> => {
    const res = await updateTimeForTicket({
      variables: {
        input: {
          ticketId,
          additions: additions
            .filter((val) => val.minutesSpent > 0)
            .map((addition) => {
              return {
                employeeId: addition.employeeId,
                minutes: addition.minutesSpent,
                comment: addition.comment,
              } as BulkTimeAdditionInput;
            }),
          timeRegistrationNumbersToDelete: deletions.map((deletion) => deletion.timeRegistrationNumber.toString()),
        },
      },
    });

    return !hasValue(res) || hasValue(res.errors) ? "error" : "success";
  };

  return {
    saveChanges,
    loading,
  };
};
