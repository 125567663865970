import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";

import { TicketFlowType, useTicketClosedText_ticket$key } from "./__generated__/useTicketClosedText_ticket.graphql";

const CLOSED_YELLOW_TYPES: TicketFlowType[] = [
  "YellowFunctionalTest",
  "YellowRepair",
  "YellowToProduction",
  "YellowToProductionRejected",
  "YellowToQA",
  "YellowToQARejected",
  "YellowToQAApproved",
];

export const useTicketClosedText = (ticketRef: useTicketClosedText_ticket$key): string => {
  const { flowType, rejectionState, status } = useFragment(
    graphql`
      fragment useTicketClosedText_ticket on Ticket {
        status
        ... on MouldTicket {
          flowType
          rejectionState
        }
      }
    `,
    ticketRef,
  );

  const { translate } = useTranslation();

  if (status !== "Closed") {
    return "";
  }

  if (rejectionState) {
    switch (rejectionState) {
      case "RejectedByProduction":
        return translate("TICKET.REJECTED_BY_PRODUCTION", "Rejected by production");
      case "RejectedByQuality":
        return translate("TICKET.REJECTED_BY_QUALITY", "Rejected by quality");
      case "RejectedByMPEE":
        return translate("TICKET.REJECTED_BY_MPEE", "Rejected by MPE&E");
    }
  }

  if (flowType) {
    if (flowType === "Green") {
      return translate("TICKET.CLOSED_GREEN", "Closed green");
    }

    if (CLOSED_YELLOW_TYPES.includes(flowType)) {
      return translate("TICKET.CLOSED_YELLOW", "Closed yellow");
    }
  }

  return translate("TICKET_CARD.CLOSED", "Closed");
};
