import { Grid2 } from "@mui/material";
import { FC } from "react";

import { ActivityIndicator } from "./ActivityIndicator";

export const FillWidthLoading: FC = () => {
  return (
    <Grid2 container justifyContent="center" alignItems="center" style={{ marginTop: 24 }}>
      <Grid2>
        <ActivityIndicator />
      </Grid2>
    </Grid2>
  );
};
