import { Card, Grid2, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

import { CollapsibleWidget } from "../../shared/CollapsibleWidget";

export const ARDCollapsibleSidebarWidget: FC<{
  children: ReactNode;
  title: string;
}> = ({ title, children }) => {
  return (
    <Card data-cy="ARDCollapsibleSidebarWidget">
      <CollapsibleWidget
        padding={2.5}
        componentWhenClosed={
          <Grid2>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid2>
        }
      >
        {children}
      </CollapsibleWidget>
    </Card>
  );
};
