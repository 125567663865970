import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";

import { EquipmentHistoryKnowledgeCenter_ticketsConnection$key } from "./__generated__/EquipmentHistoryKnowledgeCenter_ticketsConnection.graphql";

const RADIUS = 70;

const StyledLegendWrapper = styled("li")(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    "&[data-selectable=true]": { cursor: "pointer" },
    "&[data-selectable=true]:hover": { boxShadow: "0px 0px 14px 4px rgba(0, 0, 0, 0.25)" },
    "&[data-selectable=true][data-active=true]": {
      boxShadow: "0px 0px 14px 4px rgba(0, 0, 0, 0.25)",
      zIndex: 500,
    },
    borderRadius: 3,
    paddingRight: 1.25,
    paddingLeft: 1.25,
    gap: 1,
  }),
);

const StyledLegendColor = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    fontSize: "0.8125rem",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    minWidth: "28px",
    "&:empty": { minWidth: "12px" },
    borderRadius: 1.5,
    marginY: 1,
  }),
);

const StyledDonutChartWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    alignItems: "center",
    flexDirection: {
      xs: "column",
      md: "row",
    },
  }),
);

const StyledSvg = styled("svg")(({ theme }) =>
  theme.unstable_sx({
    margin: "-.5rem 50px 0 50px",
    minWidth: 200,
  }),
);

const StyledDonutChartCenterLabelContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: "absolute",
    height: 200,
    width: 200,
    top: 0,
    left: 0,
    margin: "-0.5rem 50px 0 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
);

const StyledTypographyCentered = styled(Typography)({
  maxWidth: "100px",
  display: "flex",
  fontWeight: 600,
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const StyledUl = styled("ul")(({ theme }) =>
  theme.unstable_sx({
    listStyle: "none",
    gridGap: "4px 41px",
    display: "grid",
    gridTemplateRows: {
      xs: "repeat(5, min-content)",
      sm: "repeat(3, min-content)",
      md: "repeat(5, min-content)",
      lg: "repeat(3, min-content)",
    },
    gridAutoFlow: "column",
    padding: 0,
  }),
);

type LegendProps = {
  selected?: boolean;
  selectable?: boolean;
  color: readonly [string, string];
  children: React.ReactNode;
  onClick?: () => void;
  count?: string;
};

const Legend = ({ color, selectable = true, selected, onClick, children, count }: LegendProps) => {
  return (
    <StyledLegendWrapper data-selectable={selectable} data-active={selected} onClick={selectable ? onClick : undefined}>
      <StyledLegendColor style={{ backgroundColor: color[0], color: color[1] }}>{count}</StyledLegendColor>
      <Typography variant="body1" whiteSpace="nowrap">
        {children}
      </Typography>
    </StyledLegendWrapper>
  );
};

type DonutChartSegmentProps = { color: string; from: number; to: number; selected?: boolean };

const DonutChartSegment = ({ color, from, to, selected }: DonutChartSegmentProps) => {
  const strokeDash = Math.PI * 2 * RADIUS;
  return (
    <circle
      cx={100}
      cy={100}
      r={RADIUS}
      filter={selected ? "url(#shadow)" : undefined}
      fill="none"
      stroke={color}
      strokeWidth={selected ? 38 : 32}
      strokeDasharray={[0, strokeDash * from, strokeDash * (to - from), strokeDash * (1 - to)].join(" ")}
      // strokeDashoffset makes is so that the stroke starts from the top
      strokeDashoffset={strokeDash * 0.25}
      style={{ transition: "stroke-dasharray 0.5s linear" }}
    />
  );
};

const sum = (data: { count: number }[]) => data.reduce((accumulator, { count }) => count + accumulator, 0);

const activityColors = [
  ["#006DB7", "#FFFE"],
  ["#FFD502", "#000000AB"],
  ["#00AA47", "#FFFE"],
  ["#6EB9E7", "#FFFE"],
  ["#DD9417", "#FFFE"],
] as const;

export const EquipmentHistoryKnowledgeCenter = ({
  showBackButton = false,
  showZeroState = false,
  showCountLegend = false,
  showPercentageLegend = false,
  showPercentageBasedOnTotalTickets = false,
  ticketsConnection,
  selectedDamageCatalogId,
  selectedActivityCatalogId,
  onSelectedDamageCatalogIdChange,
  onSelectedActivityCatalogIdChange,
}: {
  showBackButton?: boolean;
  showZeroState?: boolean;
  showPercentageLegend?: boolean;
  showCountLegend?: boolean;
  showPercentageBasedOnTotalTickets?: boolean;
  ticketsConnection: EquipmentHistoryKnowledgeCenter_ticketsConnection$key | null | undefined;
  selectedDamageCatalogId?: string;
  selectedActivityCatalogId?: string;
  onSelectedDamageCatalogIdChange: (catalogId: string | undefined) => void;
  onSelectedActivityCatalogIdChange: (catalogId: string | undefined) => void;
}) => {
  const { translate } = useTranslation();

  const data = useFragment(
    graphql`
      fragment EquipmentHistoryKnowledgeCenter_ticketsConnection on TicketsConnection {
        count
        facets {
          damage {
            count
            shortText
            catalogId
          }
          activity {
            count
            shortText
            catalogId
          }
        }
      }
    `,
    ticketsConnection ?? null,
  );

  if (!data?.facets.damage && !data?.facets.activity) {
    return null;
  }

  const selected = selectedActivityCatalogId || selectedDamageCatalogId;
  const values =
    (selectedDamageCatalogId ? data?.facets.activity?.slice(0, 5) : data?.facets.damage?.slice(0, 5)) ?? [];
  const colors = activityColors;
  const selectedDamage = data.facets?.damage.find(({ catalogId }) => catalogId === selectedDamageCatalogId);

  const totalCount = sum(values);
  const count = data.count;
  const percentageCount = showPercentageBasedOnTotalTickets ? count : totalCount;

  if (!showZeroState && !count) return null;

  const onSelect = (catalogId: string) => {
    if (selectedDamageCatalogId) {
      onSelectedActivityCatalogIdChange(catalogId === selectedActivityCatalogId ? undefined : catalogId);
    } else {
      onSelectedDamageCatalogIdChange(catalogId === selectedDamageCatalogId ? undefined : catalogId);
    }
  };

  const helperElement = (
    <Tooltip
      arrow
      placement="right"
      title={translate(
        "TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.HELP_TEXT",
        "Use the chart to get an overview of the repair activities related to your selected damage",
      )}
    >
      <HelpOutlineIcon />
    </Tooltip>
  );

  return (
    <Box mb={2}>
      <Card sx={{ px: 2 }}>
        {selectedDamage ? (
          <>
            <Typography variant="subtitle2" display="flex" justifyContent="space-between">
              <Box display="flex" gap={0.5} alignItems="center">
                {translate("TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER_TITLE_ACTIVITIES", "Damage: {{ damage }}", {
                  damage: selectedDamage.shortText,
                })}
                {helperElement}
              </Box>
              {showBackButton && (
                <Box sx={{ height: 0 }}>
                  <Tooltip
                    title={translate(
                      "TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.REMOVE_DAMAGE_FILTER",
                      "Remove damage filter",
                    )}
                  >
                    <IconButton
                      sx={{ mr: -1 }}
                      onClick={() => onSelectedDamageCatalogIdChange(undefined)}
                      aria-label={translate(
                        "TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.REMOVE_DAMAGE_FILTER",
                        "Remove damage filter",
                      )}
                    >
                      <Icons.RightArrow style={{ transform: "rotate(180deg)" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: "1rem", marginTop: "-6px" }}>
              {translate(
                "TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.KNOWLEDGE_CENTER_SUBTITLE_ACTIVITIES",
                "Top 5 repair activities",
              )}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle2" display="flex" gap={1}>
            {translate("TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER_TITLE_DAMAGES", "Top 5 damages")}
            {helperElement}
          </Typography>
        )}
        <Typography variant="body2" style={{ fontSize: "0.8125rem" }}>
          {translate("TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.TOTAL_TICKETS_WITH_COUNT", "Total tickets: {{ count }}", {
            count: count,
          })}
        </Typography>
        <StyledDonutChartWrapper>
          <Box position="relative">
            <StyledSvg width="200" height="200" viewBox="0 0 200 200">
              <defs>
                <filter id="shadow" colorInterpolationFilters="sRGB" x="-50%" y="-50%" height="200%" width="200%">
                  <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity={0.2} />
                </filter>
              </defs>
              <DonutChartSegment color="#eee" from={0} to={1} />
              {values.map(({ count, catalogId }, idx, arr) => {
                const from = sum(arr.slice(0, idx)) / (percentageCount < totalCount ? totalCount : percentageCount);
                const to = from + count / (percentageCount < totalCount ? totalCount : percentageCount);
                return (
                  <DonutChartSegment
                    key={idx}
                    color={colors[idx % colors.length][0]}
                    selected={!!selected && catalogId === selected}
                    from={from}
                    to={to}
                  />
                );
              })}
            </StyledSvg>
            <StyledDonutChartCenterLabelContainer>
              {!!count &&
                (selectedActivityCatalogId ? (
                  <>
                    <StyledTypographyCentered variant="subtitle2" mb={-1}>
                      {Math.round(
                        ((values.find(({ catalogId }) => catalogId === selected)?.count || 0) / percentageCount) * 100,
                      )}
                      %
                    </StyledTypographyCentered>
                    <StyledTypographyCentered fontSize="0.8125rem">
                      {values.find(({ catalogId }) => catalogId === selected)?.count}/{count}
                    </StyledTypographyCentered>
                  </>
                ) : (
                  <>
                    <StyledTypographyCentered variant="subtitle2" mb={-1}>
                      {count}
                    </StyledTypographyCentered>
                    <StyledTypographyCentered fontSize="0.8125rem">
                      {translate("TICKET_DETAILS.HISTORY.KNOWLEDGE_CENTER.TOTAL_TICKETS", "Tickets")}
                    </StyledTypographyCentered>
                  </>
                ))}
            </StyledDonutChartCenterLabelContainer>
          </Box>
          <StyledUl>
            {!count
              ? undefined
              : values.map(({ count: activityCount, catalogId, shortText }, idx) => (
                  <Legend
                    key={catalogId}
                    color={colors[idx % colors.length]}
                    selected={catalogId === selected}
                    selectable={false}
                    onClick={() => onSelect(catalogId)}
                    count={showCountLegend ? `${activityCount}/${count}` : undefined}
                  >
                    {showPercentageLegend && (
                      <>
                        {Math.round((activityCount / percentageCount) * 100)}
                        {"% "}
                      </>
                    )}
                    {shortText}
                  </Legend>
                ))}
          </StyledUl>
        </StyledDonutChartWrapper>
      </Card>
    </Box>
  );
};
