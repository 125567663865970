import { Grid2, GridSize, SxProps, TextField, TextFieldProps, Theme, Typography, useTheme } from "@mui/material";
import debounce from "lodash/debounce";
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";

import { useTranslation } from "../../../utility/i18n/translation";
import { CreateTicketEquipmentLabel } from "../../create-ticket/CreateTicketEquipmentLabel";
import { SearchEquipmentDialog } from "../../create-ticket/search-equipment-dialog/SearchEquipmentDialog";
import { isValidEquipmentId } from "../../utils";

import { SearchFieldAdornments, SearchFieldAdornmentVariant } from "./SearchFieldAdornments";

type EquipmentSearchTextFieldProps = {
  onEquipmentSelected: (equipmentNumber: number | null) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  initialEquipmentNumber?: string;
  showPrefixLabel?: boolean;
  showInput?: boolean;
  showPlaceholder?: boolean;
  variant?: SearchFieldAdornmentVariant;
  prefixLabelStyle?: SxProps<Theme>;
  inputFieldSize?: GridSize;
};

type InnerComponentLoaderProps = EquipmentSearchTextFieldProps & {
  onSearchIconClicked: () => void;
  onClearIconClicked?: () => void;
  equipmentSearchString?: string;
  setEquipmentSearchString: (newValue: string) => void;
};

export const EquipmentSearchTextField: FC<EquipmentSearchTextFieldProps> = (props) => {
  const { translate } = useTranslation();
  const [equipmentSearchString, setEquipmentSearchString] = useState(props.initialEquipmentNumber);

  const { onEquipmentSelected } = props;
  const [showEquipmentSearchDialog, setShowEquipmentSearchDialog] = useState(false);

  const onSearchIconClicked = useCallback(() => {
    setShowEquipmentSearchDialog(true);
  }, []);

  const onClearIconClicked = useCallback(() => {
    setEquipmentSearchString("");
    onEquipmentSelected(null);
  }, [onEquipmentSelected]);

  const onDismissSearchEquipmentDialog = useCallback(() => {
    setShowEquipmentSearchDialog(false);
  }, []);

  const onDialogOptionPressed = useCallback(
    (equipmentNumber: number) => {
      onDismissSearchEquipmentDialog();
      setEquipmentSearchString(equipmentNumber.toString());
      onEquipmentSelected(equipmentNumber);
    },
    [onDismissSearchEquipmentDialog, onEquipmentSelected],
  );

  return (
    <>
      <InnerComponent
        {...props}
        onSearchIconClicked={onSearchIconClicked}
        onClearIconClicked={onClearIconClicked}
        equipmentSearchString={equipmentSearchString}
        setEquipmentSearchString={setEquipmentSearchString}
      />
      <SearchEquipmentDialog
        onEquipmentPressed={onDialogOptionPressed}
        onDismiss={onDismissSearchEquipmentDialog}
        open={showEquipmentSearchDialog}
        title={translate("CREATE_TICKET.ERROR.SEARCH_DIALOG.TITLE", "Equipment search")}
      />
    </>
  );
};

const InnerComponent: FC<InnerComponentLoaderProps> = ({
  onEquipmentSelected,
  setEquipmentSearchString,
  onSearchIconClicked,
  onClearIconClicked,
  showPrefixLabel = false,
  showInput = true,
  showPlaceholder = true,
  equipmentSearchString,
  prefixLabelStyle,
  inputFieldSize = 5,
  variant,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps["onChange"] = (event) => {
      const value = event?.target.value;
      if (!isValidEquipmentId(value)) {
        return;
      }

      onEquipmentSelected(Number.parseInt(value));
    };
    const debounced = debounce(searchDispatch, 500);

    return (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value ?? "";
      if (value === "" && onClearIconClicked !== undefined) {
        onClearIconClicked();
      }
      if (isNaN(Number(value))) {
        return;
      }
      setEquipmentSearchString(value);
      debounced(event);
    };
  }, [setEquipmentSearchString, onEquipmentSelected, onClearIconClicked]);

  return (
    <Grid2 container spacing={1} direction="column">
      {showPrefixLabel && (
        <Grid2>
          <Typography sx={prefixLabelStyle}>
            {translate("CREATE_TICKET.EQUIPMENT_STEP.TEXT", "Please enter equipment ID")}
          </Typography>
        </Grid2>
      )}
      <Grid2>
        <Grid2 container size="grow" direction="row" alignItems="center">
          {showInput ? (
            <Grid2 size={{ xs: inputFieldSize }}>
              <TextField
                placeholder={
                  showPlaceholder
                    ? translate("CREATE_TICKET.EQUIPMENT_STEP.PLACEHOLDER", "Enter equipment id")
                    : undefined
                }
                sx={{ backgroundColor: palette.background.paper }}
                onChange={onChangeDebounced}
                value={equipmentSearchString || ""}
                fullWidth
                slotProps={{
                  input: {
                    endAdornment: (
                      <SearchFieldAdornments
                        variant={variant}
                        onSearchIconClicked={onSearchIconClicked}
                        onClearIconClicked={onClearIconClicked}
                      />
                    ),
                  },
                }}
              />
            </Grid2>
          ) : (
            <Grid2>
              <Typography>{equipmentSearchString}</Typography>
            </Grid2>
          )}
          {equipmentSearchString && (
            <Grid2 size={{ xs: "grow" }}>
              <CreateTicketEquipmentLabel.Suspense equipmentSearchString={equipmentSearchString} />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
