import { Box, Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { CommentList_comment$key } from "./__generated__/CommentList_comment.graphql";

const ActualComponent: FC<{ comments: CommentList_comment$key | null | undefined }> = ({ comments: commentsRef }) => {
  const { format } = useDateFromMiddlewareWithLocale();
  const { translate } = useTranslation();

  const comments = useFragment(
    graphql`
      fragment CommentList_comment on Comment
      @relay(plural: true)
      @argumentDefinitions(includeTimestamp: { type: "Boolean", defaultValue: true }) {
        description
        timestamp @include(if: $includeTimestamp) {
          date
          time
        }
      }
    `,
    commentsRef ?? null,
  );

  if (!comments) {
    return null;
  }

  return (
    <Grid2 container direction="column">
      {comments.map(({ description, timestamp }, index) => (
        <Grid2 key={index}>
          <Typography>{description}</Typography>
          {timestamp && (
            <Typography variant="caption" color="textSecondary">
              {`${translate("MISC.CREATED_PREFIX", "Created {{ timestamp }}", {
                timestamp: format(`${timestamp.date}T${timestamp.time}`, CARD_DATE_FORMAT),
              })}`}
            </Typography>
          )}
        </Grid2>
      ))}
    </Grid2>
  );
};

const SkeletonComponent: FC = () => (
  <Box sx={{ flex: 1 }}>
    <Skeleton width={75} />
    <Skeleton width={75} height={20} />
  </Box>
);

export const CommentList = skeletonify("CommentList", ActualComponent, SkeletonComponent);
