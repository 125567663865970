import { Grid2 } from "@mui/material";
import { ComponentProps, FC } from "react";

import { PrinterAutocomplete } from "./PrinterAutocomplete";

export const PrintDialogContent: FC<ComponentProps<typeof PrinterAutocomplete>> = ({ onPrinterSelected }) => {
  return (
    <Grid2 container>
      <Grid2 sx={{ width: "100%", mt: 1 }}>
        <PrinterAutocomplete.Suspense onPrinterSelected={onPrinterSelected} />
      </Grid2>
    </Grid2>
  );
};
