import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../../utility/icons";

export const SparePartEANWithIcon: FC<{
  EAN: string;
  opaque?: boolean;
}> = ({ EAN, opaque }) => {
  const opacity = opaque ? 0.6 : 1;
  return (
    <Grid2 container direction="row" alignItems="center">
      <Grid2>
        <Icons.Barcode fillOpacity={opacity} strokeOpacity={opacity} sx={{ mt: 0.6, mr: 1 }} />
      </Grid2>
      <Grid2>
        <Typography variant="body2" sx={{ opacity }}>
          {EAN}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
