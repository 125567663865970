import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Dialog, Grid2, IconButton } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

import { ImageRowFragment } from "../../__apollo__/graphql";

import { GMImageWithFallback } from "./GMImageComponents";

export const IMAGE_ROW_FRAGMENT = gql`
  fragment ImageRow on ImageInformation {
    id
    imageNumber
    image {
      id
      small
      large
    }
  }
`;

export const ImageRow: FC<{ images: ImageRowFragment[] }> = ({ images }) => {
  const [dialogInfo, setDialogInfo] = useState<{ open: boolean; indexToOpenOn?: number } | undefined>();
  const rawImages = images.map((imageFragment) => imageFragment.image);

  const openDialogOnIndex = (index: number) => {
    setDialogInfo({ open: true, indexToOpenOn: index });
  };

  return (
    <Grid2 container direction="row" spacing={2}>
      {dialogInfo?.open && (
        <ImageCarouselDialog
          images={rawImages}
          onClosePressed={() => setDialogInfo({ open: false })}
          openOnIndex={dialogInfo?.indexToOpenOn}
        />
      )}
      {images.map((image, index) => {
        const onClick = () => {
          openDialogOnIndex(index);
        };

        return (
          <Grid2 key={image.id} onClick={onClick}>
            <GMImageWithFallback
              url={image.image.small}
              style={{
                height: 100,
                width: 100,
                borderRadius: 12,
              }}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
};

const ImageCarouselDialog: FC<{
  onClosePressed: () => void;
  images: ImageRowFragment["image"][];
  openOnIndex?: number;
}> = ({ images, onClosePressed, openOnIndex }) => {
  const [activeImage, setActiveImage] = useState(hasValue(openOnIndex) ? openOnIndex : 0);

  const canGoForwards = activeImage + 1 < images.length;
  const canGoBackwards = activeImage > 0;

  const stepForwards = useCallback(() => {
    if (canGoForwards) {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, canGoForwards]);

  const stepBackwards = useCallback(() => {
    if (canGoBackwards) {
      setActiveImage(activeImage - 1);
    }
  }, [activeImage, canGoBackwards]);

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      switch (event.key) {
        case "ArrowLeft":
          stepBackwards();
          break;
        case "ArrowRight":
          stepForwards();
          break;
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [stepBackwards, stepForwards]);

  return (
    <Dialog open maxWidth="xl" fullWidth onClose={onClosePressed}>
      <Grid2 container justifyContent="space-between" alignItems="center" flexDirection="row" margin="auto">
        <Grid2 size={{ xs: 1 }}>
          <IconButton disabled={!canGoBackwards} onClick={stepBackwards}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </Grid2>
        <Grid2 size={{ xs: 10 }}>
          {images.length > 0 && (
            <GMImageWithFallback
              url={images[activeImage].large}
              style={{
                height: "75vh",
                borderRadius: 12,
                objectFit: "contain",
              }}
            />
          )}
        </Grid2>
        <Grid2 container size={{ xs: 1 }} justifyContent="flex-end">
          <IconButton disabled={!canGoForwards} onClick={stepForwards}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </Dialog>
  );
};
