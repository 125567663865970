import { gql } from "@apollo/client";
import { Button, Grid2, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";

import { AddRepairDescriptionMutation, AddRepairDescriptionMutationVariables } from "../../../__apollo__/graphql";
import { useGMMutation } from "../../../apollo/customApolloHooks";
import {
  AddRepairDocumentationState,
  useAddRepairDescriptionContext,
} from "../../../contexts/add-repair-description/add-repair-description-context";
import { useTranslation } from "../../../utility/i18n/translation";
import { useGMSnackbar } from "../../../utility/snackbar";
import { ActivityIndicator } from "../../shared/ActivityIndicator";
import { TD_REPAIR_DOCUMENTATION_WIDGET } from "../../ticket-details/TDRepairFragments";

const ADD_REPAIR_DESCRIPTION = gql`
  mutation AddRepairDescription($input: AddRepairDocumentationForTicketInput!) {
    addRepairDocumentation(input: $input) {
      ... on RepairDocumentation {
        id
        ...RepairDocumentationWidget
      }
      ... on TicketAlreadyCompleted {
        id
      }
    }
  }
  ${TD_REPAIR_DOCUMENTATION_WIDGET}
`;

export const ARDSummary: FC = () => {
  const { id: ticketId } = useParams() as { id: string };
  const { translate } = useTranslation();
  const { dispatch, state } = useAddRepairDescriptionContext();
  const { showSnack } = useGMSnackbar();
  const navigate = useNavigate();

  const [uploadRepairDescription, { loading: saving }] = useGMMutation<
    AddRepairDescriptionMutation,
    AddRepairDescriptionMutationVariables
  >(ADD_REPAIR_DESCRIPTION, {
    onCompleted: (response) => {
      switch (response.addRepairDocumentation.__typename) {
        case "TicketAlreadyCompleted":
          showSnack({
            message: translate(
              "ADD_REPAIR_DOCUMENTATION.SUMMARY.REPAIR_DESCRIPTION_TICKET_ALREADY_COMPLETED",
              "The ticket is already completed",
            ),
            variant: "warning",
          });
          break;
        case "RepairDocumentation":
          showSnack({
            message: translate(
              "ADD_REPAIR_DOCUMENTATION.SUMMARY.REPAIR_DESCRIPTION_UPLOAD_SUCCESS",
              "Repair description added",
            ),
            variant: "success",
          });
          break;
      }
    },
  });

  const saveAndAddAnother = async () => {
    const input = getInputFromState(ticketId, state);
    if (input) {
      await uploadRepairDescription({ variables: input });
      dispatch({ type: "reset_flow" });
    }
  };

  const save = async () => {
    const input = getInputFromState(ticketId, state);
    if (input) {
      await uploadRepairDescription({ variables: input });
      navigate(-1);
    }
  };

  return (
    <Grid2 container spacing={2} direction="column">
      <Grid2>
        <Typography>
          {translate(
            "ADD_REPAIR_DOCUMENTATION.SUMMARY.HELPER_TEXT",
            "All steps completed. You can now save your repair documentation",
          )}
        </Typography>
      </Grid2>
      {saving ? (
        <Grid2 style={{ alignSelf: "center" }}>
          <ActivityIndicator />
        </Grid2>
      ) : (
        <Grid2 container spacing={2} sx={{ mb: 4 }}>
          <Grid2>
            <Button variant="outlined" color="primary" disabled={saving} onClick={saveAndAddAnother}>
              {translate("ADD_REPAIR_DOCUMENTATION.SUMMARY.SAVE_AND_ADD_ANOTHER", "Save and add another repair doc")}
            </Button>
          </Grid2>
          <Grid2>
            <Button variant="contained" color="primary" onClick={save} disabled={saving} data-cy="ARDSummarySaveButton">
              {translate("ADD_REPAIR_DOCUMENTATION.SUMMARY.SAVE", "Save")}
            </Button>
          </Grid2>
        </Grid2>
      )}
    </Grid2>
  );
};

const getInputFromState = (
  ticketId: string,
  state: AddRepairDocumentationState,
): AddRepairDescriptionMutationVariables | undefined => {
  if (!state.chosenDamage || !state.chosenCause) {
    return undefined;
  }

  const input: AddRepairDescriptionMutationVariables = {
    input: {
      ticketId: ticketId,
      subEquipment: state.chosenSubEquipment?.id,
      component: state.chosenComponent?.id,
      damage: state.chosenDamage?.id,
      cause: state.chosenCause?.id,
      activities: state.chosenActivities.map((activity) => activity.id),
      createdAt: new Date().toISOString(),
      customDamageDescription: state.customDamageString,
      customCauseDescription: state.customCauseString,
      customComponentDescription: state.customComponentString,
      customSubEquipmentDescription: state.customSubEquipmentString,
      repairComment: state.repairComment,
    },
  };

  return input;
};
