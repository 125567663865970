import { Configuration, LogLevel, PublicClientApplication, SilentRequest } from "@azure/msal-browser";

import { getCrashReporter } from "../utility/crash-reporter";
import { getNodeEnvironment } from "../utility/environment";

const UatClientId = "43e9bef3-6094-4f38-bc0e-3d4125997fff";
const ProdClientId = "ccf6cafc-f333-49ae-bc38-a04c714d676e";

const clientId: string = getNodeEnvironment() === "production" ? ProdClientId : UatClientId;

export const msalRequest = {
  scopes: ["openid", `${clientId}/MaintainIT`],
} as SilentRequest;

const msalConfig = {
  auth: {
    clientId,
    authority: "https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa",
    // AppRoutes.login hardcoded here to avoid circular dependency
    postLogoutRedirectUri: `${window.location.origin}/login`,
    redirectUri: `${window.location.origin}/auth-callback`,
  },
  cache: {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            getCrashReporter().captureException({
              exception: new Error(message),
            });
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
} as Configuration;

export const MicrosoftADInstance = new PublicClientApplication(msalConfig);
