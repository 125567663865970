export const stringIsPositiveInteger = (theString: string): boolean => {
  const asNumber = Number.parseFloat(theString);

  return (
    !Number.isNaN(asNumber) &&
    Number.isInteger(asNumber) &&
    asNumber >= 0 &&
    !theString.includes(".") &&
    !theString.includes(",")
  );
};
