import { Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement, useMemo } from "react";
import { useFragment } from "react-relay";

import { CardWithTitle, CardWithTitleSkeleton } from "../../components/shared/CardWithTitle";
import { TextWithLabel } from "../../components/shared/TextWithLabel";
import { useFormatDateWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { skeletonify } from "../skeleton";

import { QATestStatus_equipment$key } from "./__generated__/QATestStatus_equipment.graphql";

const ICON_SIZE = 32;
const skeletonTextWidth = 80;
const skeletonIconSize = 50;

const ActualComponent = ({ equipment: equipmentRef }: { equipment: QATestStatus_equipment$key }) => {
  const { translate } = useTranslation();

  const data = useFragment(
    graphql`
      fragment QATestStatus_equipment on Equipment {
        ... on Mould {
          qualityManagementInspection {
            lastUpdated
            validationResult
            measurementType
          }
        }
      }
    `,
    equipmentRef,
  );

  const testStatusText = useMemo(() => {
    switch (data.qualityManagementInspection?.validationResult) {
      case "A":
        return "Approved by QA";
      case "R":
        return "Rejected by QA";
      default:
        return "---";
    }
  }, [data.qualityManagementInspection?.validationResult]);
  const testStatusLastUpdated = useFormatDateWithLocale(data.qualityManagementInspection?.lastUpdated, "dd/MM/yyyy");

  return (
    <CardWithTitle title={translate("TICKET_DETAILS.CARDS.TEST_STATUS.TITLE", "Test Status")}>
      <Grid2 container direction="row">
        <Grid2 size={{ xs: 4 }}>
          <TestStatusItem
            icon={<Icons.TestStatus style={{ fontSize: ICON_SIZE }} />}
            text={testStatusText}
            label={translate("TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.LAST_TEST_STATUS", "Last test status")}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <TestStatusItem
            icon={<Icons.TestStatus style={{ fontSize: ICON_SIZE }} />}
            text={testStatusLastUpdated}
            label={translate(
              "TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.LAST_TEST_STATUS_UPDATE",
              "Last test status update",
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <TestStatusItem
            icon={<Icons.TypeOfMeasurement style={{ fontSize: ICON_SIZE }} />}
            text={data.qualityManagementInspection?.measurementType}
            label={translate("TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.TYPE_OF_MEASUREMENT", "Type of measurement")}
          />
        </Grid2>
      </Grid2>
    </CardWithTitle>
  );
};

const TestStatusItem: FC<{
  icon: ReactElement;
  text: string | null | undefined;
  label: string;
}> = ({ icon, text, label }) => {
  return (
    <Grid2 container direction="row" alignItems="center" spacing={1} maxHeight={60}>
      {/* Max height is a hack to prevent blank spaces from flex layout black magic */}
      <Grid2
        sx={{
          mr: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {icon}
      </Grid2>
      <Grid2>
        <TextWithLabel label={label} text={text ?? "---"} />
      </Grid2>
    </Grid2>
  );
};
const SkeletonComponent = () => {
  return <TestStatusSkeleton />;
};
const TestStatusSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid2 container direction="row" rowSpacing={3}>
        {[...Array(3)].map((index) => (
          <Grid2 size={{ xs: 4 }} key={index}>
            <TestStatusItemSkeleton />
          </Grid2>
        ))}
      </Grid2>
    </CardWithTitleSkeleton>
  );
};

const TestStatusItemSkeleton = () => {
  return (
    <Grid2 container direction="row">
      <Grid2 sx={{ mr: 1 }}>
        <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" height={skeletonIconSize} width={skeletonIconSize} />
      </Grid2>
      <Grid2>
        <Grid2>
          <Skeleton variant="text" width={skeletonTextWidth} />
          <Skeleton variant="text" width={skeletonTextWidth} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
export const QATestStatus = skeletonify("QATestStatus", ActualComponent, SkeletonComponent);
