import { ApolloError } from "@apollo/client";
import { hasValue, MIDDLEWARE_ERROR_MESSAGE_SEPARATOR } from "@lego/mst-error-utilities";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useTranslation } from "../i18n/translation";

import { CustomSnackWithErrorDetails } from "./CustomSnackWithErrorDetails";

export const extractCodeFromError = (error: Error | ApolloError): number | undefined => {
  // Check if error contains custom error code from our custom errors
  if ("graphQLErrors" in error && error.graphQLErrors.length === 1) {
    const extensions = error.graphQLErrors[0].extensions;

    if (extensions && "exception" in extensions && extensions.exception) {
      const { errorCode } = extensions.exception as { errorCode?: number };
      if (errorCode) {
        return errorCode;
      }
    }
  }

  // Check if error message contains code from default message format
  const errorMessagePrefix = "Request failed with status code ";
  if (error.message.includes(errorMessagePrefix)) {
    const code = error.message.substr(errorMessagePrefix.length);
    const asNumber = Number.parseInt(code, 10);
    if (!isNaN(asNumber) && Number.isSafeInteger(asNumber)) {
      return asNumber;
    }
  }

  return undefined;
};

export const unhandledTypeError = (translate: TFunction): string => {
  return translate(
    "FAILURES.TYPE_NOT_HANDLED",
    "Received unknown response, please ensure you have the latest version of the app",
  );
};

const getMiddlewareErrorTranslation = (
  translate: TFunction,
  error?: ApolloError | Error,
  defaultErrorStringOverride?: string,
): string => {
  const unknownError = defaultErrorStringOverride ?? translate("ERRORS.UNKNOWN", "Unexpected error occurred");
  const timeoutErrorString = translate("ERRORS.TIMEOUT", "Request timed out, please try again");

  if (!hasValue(error)) {
    return unknownError;
  }

  // Cannot do instanceof check for Errors.TypeError here, as Apollo Client
  // has changed the error type to ApolloError in their onError callback function
  if (error.message.includes("Timeout error")) {
    return timeoutErrorString;
  }

  if (error.message.includes("Network request failed")) {
    return translate("ERRORS.NETWORK_ERROR", "Network error, please check your connection and try again");
  }

  const errorCode = extractCodeFromError(error);

  if (!hasValue(errorCode)) {
    return unknownError;
  }

  switch (errorCode) {
    case 404:
      // Will hopefully almost always be handled in GQL unions, but in cae it isn't, this is more helpful to the user than 'unknown error'
      return translate("ERRORS.ENTITY_NOT_FOUND", "Could not find the entity you requested");
    case 408:
      return timeoutErrorString;
    case 422:
      return translate("ERRORS.UNPROCESSABLE_ENTITY", "Bad master data");
    case 423:
      return translate("ERRORS.ENTITY_LOCKED", "The data you tried to edit is already being edited by another user");
    case 500:
      return translate("ERRORS.SERVER_ERROR", "There was an error on the server, please try again or contact support");
    case 503:
      return translate("ERRORS.SAP_UNREACHABLE", "SAP unreachable");

    default:
      return unknownError;
  }
};

export type DefaultSnackbarErrorHandling = {
  showErrorSnackForStatusCode: (error: ApolloError, defaultErrorStringOverride?: string) => void;
};

export const useDefaultSnackbarErrorHandler = (): DefaultSnackbarErrorHandling => {
  const { translate } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const showErrorSnackForStatusCode = useCallback(
    (error: ApolloError, defaultErrorStringOverride?: string) => {
      const translatedMessage = getMiddlewareErrorTranslation(translate, error, defaultErrorStringOverride);

      const backendErrorMessage = error.message.split(MIDDLEWARE_ERROR_MESSAGE_SEPARATOR);

      enqueueSnackbar(translatedMessage, {
        content: (key, message) => (
          <CustomSnackWithErrorDetails
            id={key}
            message={typeof message === "string" ? message : ""}
            errorLines={backendErrorMessage}
          />
        ),
        persist: true,
      });
    },

    [enqueueSnackbar, translate],
  );

  return {
    showErrorSnackForStatusCode,
  };
};
