import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { useAreaAndProcessContext } from "../../contexts/area";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { PlannedJobsTable } from "./PlannedJobsTable";
import { PlannedSpringChangeJobRow } from "./PlannedSpringChangeJobRow";
import { PlannedSpringChangeJobsTabQuery } from "./__generated__/PlannedSpringChangeJobsTabQuery.graphql";

const ActualComponent: FC = () => {
  const { translate } = useTranslation();
  const { selectedArea: area } = useAreaAndProcessContext();

  const { plant } = useLazyLoadQuery<PlannedSpringChangeJobsTabQuery>(
    graphql`
      query PlannedSpringChangeJobsTabQuery($input: QueryPlantInput!) {
        plant(input: $input) {
          ... on QueryPlantSuccess {
            data {
              mouldsForSpringChange {
                id
                shotsSinceLastSpringChange
                springChangeInterval
                ...PlannedSpringChangeJobRow_mould
              }
            }
          }
        }
      }
    `,
    { input: { plantNumber: parseInt(area.plantId) } },
  );

  const columnHeaders = [
    ["equipmentNumber", translate("PLANNED_JOBS.HEADERS.EQUIPMENT_NUMBER", "Equipment")],
    ["description", translate("PLANNED_JOBS.HEADERS.DESCRIPTION", "Description")],
    ["statusCode", translate("PLANNED_JOBS.HEADERS.STATUS_CODE", "Status")],
    ["location", translate("PLANNED_JOBS.HEADERS.LOCATION", "Location")],
    ["weight", translate("PLANNED_JOBS.HEADERS.WEIGHT", "Weight")],
    ["type", translate("PLANNED_JOBS.HEADERS.TYPE", "Mould type")],
    ["boxConcept", translate("PLANNED_JOBS.HEADERS.BOX_CONCEPT", "BoxConcept")],
    ["machine", translate("PLANNED_JOBS.HEADERS.MACHINE", "Machine")],
    ["lastSpringChangeShots", translate("PLANNED_JOBS.HEADERS.LAST_SPRING_CHANGE_SHOTS", "Last spring change")],
    ["currentShots", translate("PLANNED_JOBS.HEADERS.CURRENT_SHOTS", "Current no. shot")],
    ["shotsUpdated", translate("PLANNED_JOBS.HEADERS.SHOTS_UPDATED", "Shot updated")],
    ["springChangeInterval", translate("PLANNED_JOBS.HEADERS.SPRING_CHANGE_INTERVAL", "Spring change interval")],
    ["springChangeDifference", translate("PLANNED_JOBS.HEADERS.SPRING_CHANGE_DIFFERENCE", "Spring change diff")],
    ["overshot", translate("PLANNED_JOBS.HEADERS.OVERSHOT", "Overshot")],
    ["numberOfInserts", translate("PLANNED_JOBS.HEADERS.NUMBER_OF_INSERTS", "No. of inserts")],
    ["ticketNumber", translate("PLANNED_JOBS.HEADERS.TICKET_NUMBER", "Ticket")],
  ];

  const sortByOvershot = (
    left: {
      shotsSinceLastSpringChange: number | null | undefined;
      springChangeInterval: number | null | undefined;
    },
    right: {
      shotsSinceLastSpringChange: number | null | undefined;
      springChangeInterval: number | null | undefined;
    },
  ) => {
    if (
      hasValue(left.shotsSinceLastSpringChange) &&
      hasValue(left.springChangeInterval) &&
      hasValue(right.shotsSinceLastSpringChange) &&
      hasValue(right.springChangeInterval)
    ) {
      const leftOvershot = left.shotsSinceLastSpringChange - left.springChangeInterval;
      const rightOvershot = right.shotsSinceLastSpringChange - right.springChangeInterval;
      return rightOvershot - leftOvershot;
    }
    return 0;
  };

  const jobRows = useMemo(
    () =>
      plant?.data?.mouldsForSpringChange
        ?.slice()
        .sort(sortByOvershot)
        .map((mould) => <PlannedSpringChangeJobRow key={mould?.id} mould={mould} />),
    [plant?.data?.mouldsForSpringChange],
  );

  return <PlannedJobsTable columnHeaders={columnHeaders} jobRows={jobRows || []} />;
};

const SkeletonComponent: FC = () => <FillWidthLoading />;

export const PlannedSpringChangeJobsTab = skeletonify("PlannedSpringChangeJobsTab", ActualComponent, SkeletonComponent);
