import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { ErrorMessage } from "../../migration/components/ErrorMessage";

export const CTNoManhoursOrRepairDocs: FC<{
  title: string;
  message: string;
  showWarning: boolean;
}> = ({ title, message, showWarning }) => {
  return (
    <Grid2 container direction="column" spacing={1} style={{ marginLeft: 8 }}>
      <Grid2>
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </Grid2>
      {showWarning && <CTWarningMessage message={message} />}
    </Grid2>
  );
};

export const CTWarningMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <Grid2 container alignItems="center" spacing={1} data-cy="CTWarningMessage">
      <Grid2>
        <ErrorMessage message={message} />
      </Grid2>
    </Grid2>
  );
};
