import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from "react";

import { CreateTicketActions, createTicketReducer } from "./create-ticket-reducer";
import { AllowedTicketPriority } from "./__generated__/CreateTicketDescriptionStepMutation.graphql";

export type CreateTicketStep = "equipment" | "location" | "priority" | "description";

export interface CreateTicketState {
  equipmentId?: string;
  equipmentType?: "Equipment" | "Mould";
  equipmentSearch?: number;
  locationId?: string;
  sublocation: {
    isMandatory: boolean;
    text?: string;
  };
  room?: string;
  priority?: AllowedTicketPriority;
  requiredEndDate?: Date | null;
  description?: string;
  errorAfterRepair: boolean;
  errorDuringChangeOver: boolean;
  currentStep: CreateTicketStep;
}

export const initialCreateTicketState: CreateTicketState = {
  currentStep: "equipment",
  sublocation: { isMandatory: false },
  errorAfterRepair: false,
  errorDuringChangeOver: false,
};

export interface CreateTicketContext {
  state: CreateTicketState;
  dispatch: Dispatch<CreateTicketActions>;
}

const Context = createContext<CreateTicketContext | undefined>(undefined);

export const useCreateTicketContext = (): CreateTicketContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Missing AddCreateTicketProvider");
  }

  return context;
};

export const CreateTicketProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(createTicketReducer, initialCreateTicketState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
