import { Grid2, Typography, useTheme } from "@mui/material";
import { FC } from "react";

import { TicketPriorityEnum } from "../../../__apollo__/graphql";
import { useTranslation } from "../../../utility/i18n/translation";

const useColorForPriority = (priority: TicketPriorityEnum): string => {
  const { palette } = useTheme();
  switch (priority) {
    case TicketPriorityEnum.Priority1:
      return palette.error.main;

    case TicketPriorityEnum.Priority2:
      return palette.warning.dark;

    case TicketPriorityEnum.MouldCall:
    case TicketPriorityEnum.Priority3:
      return palette.warning.main;

    case TicketPriorityEnum.PriorityA:
    case TicketPriorityEnum.Priority4:
    case TicketPriorityEnum.Priority6:
    case TicketPriorityEnum.Priority7:
    case TicketPriorityEnum.Priority8:
    case TicketPriorityEnum.Priority9:
      return palette.text.secondary;

    case TicketPriorityEnum.Priority5:
      return palette.text.disabled;

    default:
      return "white";
  }
};

const getFontSizeForPriority = (priority: TicketPriorityEnum): string => {
  switch (priority) {
    case TicketPriorityEnum.MouldCall:
    case TicketPriorityEnum.Priority5:
      return "0.875rem";
    case TicketPriorityEnum.Priority1:
    case TicketPriorityEnum.Priority2:
    case TicketPriorityEnum.Priority3:
    case TicketPriorityEnum.PriorityA:
    case TicketPriorityEnum.Priority4:
    case TicketPriorityEnum.Priority6:
    case TicketPriorityEnum.Priority7:
    case TicketPriorityEnum.Priority8:
    case TicketPriorityEnum.Priority9:
    default:
      return "1.25";
  }
};

const SIZE = 48;
export const PriorityPill: FC<{
  priority: TicketPriorityEnum;
  size?: number;
}> = ({ priority, size = SIZE }) => {
  const color = useColorForPriority(priority);
  const { translate } = useTranslation();

  return (
    <Grid2 container direction="column" alignItems="center">
      <Grid2
        container
        style={{
          backgroundColor: color,
          height: size / 16 + "rem",
          width: size / 16 + "rem",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography align="center" style={{ color: "white", fontSize: getFontSizeForPriority(priority) }} variant="h2">
          {translate(`PRIORITIES.${priority}`, priority)}
        </Typography>
      </Grid2>
      <Typography variant="caption" color="textSecondary">
        {translate("PRIORITIES.PRIORITY", "Priority")}
      </Typography>
    </Grid2>
  );
};
