import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Paper, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent } from "react";
import { useParams } from "react-router";

import { GetTicketDetailsHeaderQuery, GetTicketDetailsHeaderQueryVariables } from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { useTicketQueryTypeCheck } from "../../containers/TicketByIdTypecheckHook";
import { RouteTypes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { CountdownTimer } from "../ticket-card/components/CountdownTimer";
import { EquipmentIdWithIcon } from "../ticket-card/components/EquipmentIdWithIcon";
import { PriorityPill } from "../ticket-card/components/PriorityPill";
import { TicketIdWithIcon } from "../ticket-card/components/TicketIdWithIcon";
import { TicketDateWithCalendarIcon } from "../ticket-card/components/TicketStartDate";

import { TDMenu, TICKET_DETAILS_FAB_FRAGMENT } from "./TDMenu";

const TICKET_DETAILS_HEADER_FRAGMENT = gql`
  fragment TicketDetailsHeader on Ticket {
    id
    requiredEndDate
    priority
    completedDate
    isOpen
    equipment {
      ... on EquipmentValue {
        value {
          id
          description
        }
      }
    }
    ...TicketDetailsFab
  }
  ${TICKET_DETAILS_FAB_FRAGMENT}
`;

const TICKET_DETAILS_HEADER = gql`
  query GetTicketDetailsHeader($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...TicketDetailsHeader
      }
    }
  }
  ${TICKET_DETAILS_HEADER_FRAGMENT}
`;

const GUTTERS = "10%";

export const TDHeaderSkeleton: FC<{ narrow?: boolean }> = ({ narrow = false }) => {
  const height = narrow ? 100 : 140;
  return (
    <Paper
      style={{
        borderRadius: 0,
        position: "fixed",
        width: "100%",
        zIndex: 2,
        height,
      }}
      data-cy="TDHeader-loading"
    >
      <Grid2 container direction="row" alignItems="center" sx={{ py: 1, mx: GUTTERS }} justifyContent="space-between">
        <Grid2 container direction="row" size={{ xs: 6 }} height={height}>
          {/* Prio pill */}
          <Grid2>
            <Skeleton width={48} height={48} variant="circular" />
          </Grid2>
          {/* Main */}
          <Grid2 sx={{ ml: 3 }}>
            <Grid2 container direction="column">
              <Grid2>
                <Grid2 container direction="row" spacing={2}>
                  <Grid2>
                    <Skeleton width={90} variant="text" />
                  </Grid2>
                  <Grid2>
                    <Skeleton width={90} variant="text" />
                  </Grid2>
                </Grid2>
                <Grid2>
                  <Skeleton width={200} variant="text" />
                </Grid2>
              </Grid2>
              {/* Tabs */}
              {!narrow && (
                <Grid2 sx={{ pt: 4 }}>
                  <Skeleton width={200} variant="rectangular" />
                </Grid2>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
        {/* Date/time */}
        <Grid2 container direction="column" size={{ xs: 3 }} height={height} justifyContent="start">
          <Grid2>
            <Skeleton width={180} variant="text" />
          </Grid2>
          <Grid2>
            <Skeleton width={180} variant="text" />
          </Grid2>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export const TDHeader: FC<{
  showFab?: boolean;
  tabs?: {
    currentTab: RouteTypes["ticket"]["tab"];
    onTabPressed: (event: SyntheticEvent, newValue: RouteTypes["ticket"]["tab"]) => void;
  };
}> = ({ showFab, tabs }) => {
  const { id } = useParams() as RouteTypes["ticket"];
  const { translate } = useTranslation();

  const { data, loading } = useGMQuery<GetTicketDetailsHeaderQuery, GetTicketDetailsHeaderQueryVariables>(
    TICKET_DETAILS_HEADER,
    {
      variables: { input: { id } },
    },
  );

  const checked = useTicketQueryTypeCheck<Extract<GetTicketDetailsHeaderQuery["ticket"], { __typename?: "Ticket" }>>({
    data,
    loading,
    customLoadingComponent: <TDHeaderSkeleton narrow />,
  });

  if (checked.type === "errorOrLoadingComponent") {
    return checked.component;
  }

  const { equipment, priority } = checked.data;

  const hasEquipment = hasValue(equipment) && equipment.__typename === "EquipmentValue";

  return (
    <Paper
      sx={{
        position: { md: "sticky" },
        borderRadius: 0,
        top: 0,
        width: "100%",
        zIndex: 2,
      }}
      data-cy="TDHeader"
    >
      <Grid2 container direction="row" alignItems="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
        <Grid2 container justifyContent="flex-end" style={{ width: GUTTERS }}>
          <Grid2>
            <PriorityPill priority={priority} />
          </Grid2>
        </Grid2>
        <Grid2 style={{ flex: 1, marginLeft: 36 }}>
          <Grid2 container flexDirection="column" spacing={2}>
            <Grid2 container spacing={2}>
              {hasEquipment && (
                <Grid2>
                  <EquipmentIdWithIcon equipmentId={equipment.value.id} />
                </Grid2>
              )}

              <Grid2>
                <TicketIdWithIcon ticketId={checked.data.id} />
              </Grid2>
            </Grid2>

            {hasEquipment && (
              <Grid2 style={{ paddingLeft: 4 }}>
                <Typography variant="subtitle2">{equipment.value.description}</Typography>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2>
          <Dates {...checked.data} />
        </Grid2>
        <Grid2
          style={{
            width: GUTTERS,
            height: 0,
          }}
        >
          {showFab && <TDMenu apolloData={checked.data} />}
        </Grid2>
      </Grid2>
      {tabs && (
        <Grid2 sx={{ ml: GUTTERS, pl: 1 }}>
          <Tabs value={tabs.currentTab} onChange={tabs.onTabPressed}>
            <Tab value={"details"} label={translate("TICKET_DETAILS.HEADER.TABS.DETAILS", "Details")} id={"details"} />
            <Tab label={translate("TICKET_DETAILS.HEADER.TABS.HISTORY", "History")} id={"history"} value={"history"} />
            <Tab label={translate("TICKET_DETAILS.HEADER.TABS.COSTS", "Costs")} id={"costs"} value={"costs"} />
            <Tab
              label={translate("TICKET_DETAILS.HEADER.TABS.DOCUMENTS", "Documents")}
              id={"documents"}
              value={"documents"}
            />
          </Tabs>
        </Grid2>
      )}
    </Paper>
  );
};

const Dates: FC<Extract<GetTicketDetailsHeaderQuery["ticket"], { __typename?: "Ticket" }>> = ({
  requiredEndDate,
  isOpen,
  completedDate,
}) => {
  if (!isOpen && completedDate) {
    return (
      <Grid2>
        <TicketDateWithCalendarIcon date={completedDate} />
      </Grid2>
    );
  }
  return (
    <Grid2>
      <Grid2 sx={{ mb: 1 }}>{requiredEndDate && <TicketDateWithCalendarIcon date={requiredEndDate} />}</Grid2>
      {requiredEndDate && (
        <Grid2>
          <CountdownTimer endDate={requiredEndDate} />
        </Grid2>
      )}
    </Grid2>
  );
};
