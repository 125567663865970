import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { TicketNumber_ticket$key } from "./__generated__/TicketNumber_ticket.graphql";

const ActualComponent: FC<{ ticket: TicketNumber_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;

  const ticket = useFragment(
    graphql`
      fragment TicketNumber_ticket on Ticket {
        ticketNumber
      }
    `,
    ticketRef,
  );

  return <Typography variant="body1">{ticket.ticketNumber}</Typography>;
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StructureComponent: FC<{ children: any }> = ({ children }) => (
  <Grid2 container direction="row" spacing={1}>
    <Grid2 sx={{ alignItems: "center", display: "flex" }}>
      <Icons.TicketId />
    </Grid2>
    <Grid2>{children}</Grid2>
  </Grid2>
);

export const TicketNumber = skeletonify("TicketNumber", ActualComponent, SkeletonComponent, StructureComponent);
