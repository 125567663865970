import { AppBar, Box, styled, Toolbar, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import { useLocation } from "react-router";

import { useAuthContext } from "../../contexts/AuthContext";
import { BackToTopAnchor } from "../../migration/components/BackToTop";
import { useTranslation } from "../../utility/i18n/translation";
import { images } from "../../utility/images";

import { NavLinks } from "./NavLinks";
import { NewCmsTicketLink } from "./NewCmsTicketLink";
import { NewTicketLink } from "./NewTicketLink";
import { ProfileLink } from "./ProfileLink";

const HEIGHT = "min-content";

const AppBarNavContainer = styled(AppBar)(() => ({
  justifyContent: "center",
  height: HEIGHT,
}));

const StyledToolbar = styled(Toolbar)(() => ({
  minHeight: HEIGHT,
}));

const ImgImage = styled("img")(() => ({
  height: 48,
}));

const getHeaderColor = (): string | undefined => {
  const hostname = window.location.hostname;
  if (hostname.includes("uat")) {
    return "chocolate";
  }

  if (hostname.includes("localhost")) {
    return "green";
  }

  return undefined;
};

export const MuiNavbar: FC<{ showLinks?: boolean }> = ({ showLinks = true }) => {
  const { translate } = useTranslation();
  const { isRepairman } = useAuthContext();
  const location = useLocation();

  if (location.pathname.includes("/hall-monitor")) {
    return null;
  }

  return (
    <AppBarNavContainer
      position="static"
      style={{
        padding: 0,
        borderRadius: 0,
        backgroundColor: getHeaderColor(),
      }}
    >
      <StyledToolbar id={BackToTopAnchor}>
        <ImgImage src={images.logo} />
        <Typography
          style={{
            marginTop: 4,
            marginLeft: 16,
            marginRight: 16,
            fontSize: "1.2rem",
            fontWeight: 700,
          }}
          variant="h3"
        >
          {translate("NAVBAR.SITE_NAME", "MaintainIt")}
        </Typography>
        {showLinks ? (
          <>
            <Box style={{ flex: 1 }}>
              <NavLinks />
            </Box>
            <Box style={{ display: "flex", gap: 8 }}>
              {isRepairman && <NewCmsTicketLink />}
              <NewTicketLink />
              <ProfileLink />
            </Box>
          </>
        ) : null}
      </StyledToolbar>
    </AppBarNavContainer>
  );
};
