import { gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, Grid2, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router";

import { RepairDocumentationAccordionFragment } from "../../__apollo__/graphql";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { repairDamageListLength } from "../ticket-details/TDRepairDescription";
import { TD_REPAIR_DOCUMENTATION_WIDGET } from "../ticket-details/TDRepairFragments";

import { CTNoManhoursOrRepairDocs } from "./CTNoManhoursOrRepairDocs";
import { CTRepairDocumentationRow } from "./CTRepairDocumentationRow";
import { CTRepairDocumentationRowPacking } from "./CTRepairDocumentationRowPacking";

export const REPAIR_DOCUMENTATION_ACCORDION = gql`
  fragment RepairDocumentationAccordion on Ticket {
    id
    repairDocumentation {
      id
      ...RepairDocumentationWidget
    }
  }
  ${TD_REPAIR_DOCUMENTATION_WIDGET}
`;

export const CTRepairDocumentation: FC<RepairDocumentationAccordionFragment> = ({
  id,
  repairDocumentation: { damageList },
}) => {
  const { translate } = useTranslation();

  const length = repairDamageListLength(damageList);

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("CLOSE_TICKET.REPAIR_STEP.CARD_HEADER", "Repair documentation (mandatory)")}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {translate(
            "CLOSE_TICKET.REPAIR_STEP.CARD_DESCRIPTION",
            "Before closing the ticket, please check that it has been documented correctly",
          )}
        </Typography>
      </Grid2>
      {length === 0 && (
        <Grid2>
          <NoDocumentation />
        </Grid2>
      )}
      <Grid2>
        <RepairDocumentationAccordionComponent damageList={damageList} />
      </Grid2>
      <Grid2>
        <Button
          data-cy="CTRepairDocumentation-addRepairButton"
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
          component={NavLink}
          to={AppRoutes.tickets.repair(id)}
          startIcon={<AddIcon />}
        >
          {translate("CLOSE_TICKET.REPAIR_STEP.DOCUMENTATION_BUTTON", "Documentation")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

const NoDocumentation: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { showRepairDocsRequiredWarning },
  } = useCloseTicketContext();

  const title = translate("CLOSE_TICKET.REPAIR_STEP.NO_DOCUMENTATION_HEADER", "No documentation registered");

  const message = translate(
    "CLOSE_TICKET.REPAIR_STEP.NO_DOCUMENTATION_ERROR",
    "Repair documentation is mandatory for this ticket type",
  );

  return <CTNoManhoursOrRepairDocs title={title} message={message} showWarning={showRepairDocsRequiredWarning} />;
};

export const RepairDocumentationAccordionComponent: FC<{
  damageList: RepairDocumentationAccordionFragment["repairDocumentation"]["damageList"];
  padding?: number;
}> = ({ damageList, padding }) => {
  if (damageList.__typename === "RepairDocumentationDamageList") {
    return (
      <Grid2 container>
        {damageList.damages.map((damage, index) => {
          return (
            <Grid2 key={index} size={{ xs: 12 }}>
              <CTRepairDocumentationRow {...damage} padding={padding} />
              {index < damageList.damages.length - 1 && <Divider />}
            </Grid2>
          );
        })}
      </Grid2>
    );
  } else if (damageList.__typename === "RepairDocumentationSubEquipmentList") {
    return (
      <Grid2 container>
        {damageList.subEquipments.map((subEquipment, index) => {
          return (
            <Grid2 key={index} size={{ xs: 12 }}>
              <CTRepairDocumentationRowPacking {...subEquipment} padding={padding} />
              {index < damageList.subEquipments.length - 1 && <Divider />}
            </Grid2>
          );
        })}
      </Grid2>
    );
  } else {
    return null;
  }
};
