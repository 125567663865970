import { TicketPriorityEnum } from "../../../__apollo__/graphql";
import { useTranslation } from "../../../utility/i18n/translation";

import { ChangePriorityDialogField } from "./ChangePriorityUtils";

export const useChangePriorityField = (): ((key: TicketPriorityEnum) => ChangePriorityDialogField | null) => {
  const { translate } = useTranslation();

  return (key: TicketPriorityEnum): ChangePriorityDialogField | null => {
    switch (key) {
      case TicketPriorityEnum.Priority1:
        return {
          name: "Priority1",
          value: TicketPriorityEnum.Priority1,
          translation: translate(`PRIORITIES.Priority1`, "1"),
        };
      case TicketPriorityEnum.Priority2:
        return {
          name: "Priority2",
          value: TicketPriorityEnum.Priority2,
          translation: translate(`PRIORITIES.Priority2`, "2"),
        };
      case TicketPriorityEnum.Priority3:
        return {
          name: "Priority3",
          value: TicketPriorityEnum.Priority3,
          translation: translate(`PRIORITIES.Priority3`, "3"),
        };
      case TicketPriorityEnum.Priority4:
        return {
          name: "Priority4",
          value: TicketPriorityEnum.Priority4,
          translation: translate(`PRIORITIES.Priority4`, "4"),
        };
      case TicketPriorityEnum.Priority5:
        return {
          name: "Priority5",
          value: TicketPriorityEnum.Priority5,
          translation: translate(`PRIORITIES.Priority5`, "5"),
        };
      case TicketPriorityEnum.Priority8:
        return {
          name: "Priority8",
          value: TicketPriorityEnum.Priority8,
          translation: translate(`PRIORITIES.Priority8`, "8"),
        };
      default:
        return null;
    }
  };
};
