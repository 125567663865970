import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { TicketRepairDocumentationAccordion } from "../repair-documentation/TicketRepairDocumentation";
import { skeletonify } from "../skeleton";

import { EquipmentHistoryCardRepairDocumentation_repairDocumentation$key } from "./__generated__/EquipmentHistoryCardRepairDocumentation_repairDocumentation.graphql";

const ActualComponent = (props: {
  repairDocumentation: EquipmentHistoryCardRepairDocumentation_repairDocumentation$key;
}) => {
  const { repairDocumentation: repairDocumentationRef } = props;
  const { translate } = useTranslation();

  const repairDocumentation = useFragment(
    graphql`
      fragment EquipmentHistoryCardRepairDocumentation_repairDocumentation on RepairDocumentation {
        ...TicketRepairDocumentationAccordion_repairDocumentation
      }
    `,
    repairDocumentationRef,
  );

  return (
    <Grid2 container direction="column" spacing={1} pl={1}>
      <Grid2>
        <Typography variant="subtitle2" textAlign="start">
          {translate("TICKET_HISTORY_CARD.REPAIR_DOCUMENTATION", "Repair documentation")}
        </Typography>
      </Grid2>
      <Grid2>
        <TicketRepairDocumentationAccordion repairDocumentation={repairDocumentation} padding={0} />
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent = () => {
  return (
    <Grid2 container direction="column" spacing={1} pl={1}>
      <Grid2>
        <Skeleton width={150} height={32} />
      </Grid2>
      <Grid2>
        <TicketRepairDocumentationAccordion.Skeleton />
      </Grid2>
    </Grid2>
  );
};

export const EquipmentHistoryCardRepairDocumentation = skeletonify(
  "EquipmentHistoryCardRepairDocumentation",
  ActualComponent,
  SkeletonComponent,
);
