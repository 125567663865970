import { hasValue } from "@lego/mst-error-utilities";
import { Autocomplete, Grid2, TextField, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";

import { useTicketSearchFilterContext } from "./TicketSearchFilters";
import { TicketSearchSublocationFilter_process$key } from "./__generated__/TicketSearchSublocationFilter_process.graphql";

export interface TicketSearchSublocationFilterProps {
  process: TicketSearchSublocationFilter_process$key | null | undefined;
}

export const TicketSearchSublocationFilter: FC<TicketSearchSublocationFilterProps> = (props) => {
  const { process: processRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchSublocationFilter_process on Process {
        # TODO: Make required and wrap in error boundary
        sublocations {
          id
          code
          description
        }
      }
    `,
    processRef,
  );

  const {
    filters: { sublocation: selectedSublocation },
    onChange,
  } = useTicketSearchFilterContext();

  const { translate } = useTranslation();

  const options = useMemo(() => data?.sublocations?.filter(hasValue) ?? [], [data?.sublocations]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="subtitle2">{translate("TICKET_LIST.FILTERS.SUBLOCATION.TITLE", "Room / row")}</Typography>
      </Grid2>
      <Grid2>
        <Autocomplete
          onChange={(_, value) => onChange((prev) => ({ ...prev, sublocation: value ?? undefined }))}
          value={selectedSublocation ?? null}
          getOptionLabel={({ code, description }) => `${code}, ${description}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={options.length === 0}
          options={options}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid2>
    </Grid2>
  );
};
