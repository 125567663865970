import { hasValue } from "@lego/mst-error-utilities";
import { Grid2 } from "@mui/material";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";

import { SparePartSearchProvider } from "../dashboard/spare-part-bom/spare-part-search-context";

import { CmsTicketFormProps } from "./CmsCreateTicket";
import { CmsEquipmentSearch } from "./CmsEquipmentSearch";
import { useCmsCreateSparepartManufacturingTicketMutation } from "./CmsMutations";
import { CmsSparePartSearch } from "./CmsSparePartSearch";
import { CmsTicketDescription } from "./CmsTicketDescription";
import { CmsTicketEndDate } from "./CmsTicketEndDate";
import { CmsTicketQuantity } from "./CmsTicketQuantity";
import { CmsTicketSubmitButtons } from "./CmsTicketSubmitButtons";

export const CmsSparePartManufacturingForm: FC<CmsTicketFormProps> = ({ onComplete }) => {
  const [mutation, loading] = useCmsCreateSparepartManufacturingTicketMutation();

  const [equipmentId, setEquipmentId] = useState<string | null>();
  const [sparePartId, setSparePartId] = useState<string | null>();
  const [quantity, setQuantity] = useState<number | null>();
  const [requiredEndDate, setRequiredEndDate] = useState<Date | null>();
  const [description, setDescription] = useState<string | null>();

  const formCompleted =
    hasValue(sparePartId) && hasValue(quantity) && hasValue(description) && hasValue(requiredEndDate);

  const onClick = useCallback(
    (assign: boolean) => {
      if (!formCompleted) {
        return;
      }
      mutation({
        variables: {
          input: {
            assignToSelf: assign,
            equipmentId: equipmentId ?? undefined,
            sparePartId: sparePartId,
            quantity: quantity,
            requiredEndDate: format(requiredEndDate, "yyyy-MM-dd"),
            description,
          },
        },
        onCompleted: ({ result }) => {
          const ticketNumber = result?.data?.ticketNumber;
          if (hasValue(ticketNumber) && hasValue(onComplete)) {
            onComplete(ticketNumber, assign);
          }
        },
      });
    },
    [formCompleted, mutation, equipmentId, sparePartId, quantity, requiredEndDate, description, onComplete],
  );

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <CmsEquipmentSearch onChange={setEquipmentId} />
      </Grid2>
      <Grid2>
        <SparePartSearchProvider>
          <CmsSparePartSearch onChange={setSparePartId} />
        </SparePartSearchProvider>
      </Grid2>
      <Grid2>
        <CmsTicketQuantity value={quantity ?? null} onChange={setQuantity} />
      </Grid2>
      <Grid2>
        <CmsTicketEndDate value={requiredEndDate ?? null} onChange={setRequiredEndDate} />
      </Grid2>
      <Grid2>
        <CmsTicketDescription onChange={setDescription} />
      </Grid2>
      <Grid2>
        <CmsTicketSubmitButtons loading={loading} submit={(assign) => onClick(assign)} disabled={!formCompleted} />
      </Grid2>
    </Grid2>
  );
};
