import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { TicketLocation_ticket$key } from "./__generated__/TicketLocation_ticket.graphql";

const ActualComponent: FC<{
  ticket: TicketLocation_ticket$key;
}> = ({ ticket: ticketRef }) => {
  const { translate } = useTranslation();
  const ticket = useFragment(
    graphql`
      fragment TicketLocation_ticket on Ticket {
        location {
          description
        }
        sublocation {
          ... on Sublocation {
            description
          }
          ... on FreeTextSublocation {
            description
          }
        }
      }
    `,
    ticketRef,
  );

  if (!ticket) {
    return null;
  }

  const { location, sublocation } = ticket;

  return (
    <Grid2 container direction="row" spacing={1} alignItems="flex-start">
      <Grid2 sx={{ alignItems: "center" }}>
        <Icons.Location color="disabled" />
      </Grid2>
      <Grid2>
        {location && (
          <Grid2 container direction="row" justifyContent="start" alignItems="center">
            <Grid2>
              <Typography variant="body2" color="textSecondary">
                {location.description}
              </Typography>
            </Grid2>
          </Grid2>
        )}
        {sublocation && (
          <Grid2>
            <Typography variant="body2" color="textSecondary">
              {sublocation.description}
            </Typography>
          </Grid2>
        )}
        {!hasValue(location) && !hasValue(sublocation) && (
          <Grid2 container direction="row" justifyContent="start" alignItems="center">
            <Grid2>
              <Typography variant="body2" color="textSecondary">
                {translate("TICKET_DETAILS.CARDS.LOCATION_UNKNOWN", "Unknown")}
              </Typography>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" spacing={1} alignItems="flex-end">
    <Grid2 container direction="row">
      <Grid2 sx={{ alignItems: "center", display: "flex" }}>
        <Icons.Location color="disabled" />
      </Grid2>
      <Grid2>
        <Typography variant="body2" color="textSecondary">
          <Skeleton width={70} />
        </Typography>
      </Grid2>
    </Grid2>
    <Grid2>
      <Typography variant="body2" color="textSecondary">
        <Skeleton width={70} />
      </Typography>
    </Grid2>
  </Grid2>
);

export const TicketLocation = skeletonify("TicketLocation", ActualComponent, SkeletonComponent);
