import { hasValue } from "@lego/mst-error-utilities";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Dialog, Grid2, IconButton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import orderBy from "lodash/orderBy";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";

import { GMImageWithFallback } from "../../components/shared/GMImageComponents";

import {
  ImageRowImages_imageInformation$data,
  ImageRowImages_imageInformation$key,
} from "./__generated__/ImageRowImages_imageInformation.graphql";

// TODO: Skeletonify

export const ImageRow: FC<{ images: ImageRowImages_imageInformation$key }> = ({ images: imagesRef }) => {
  const [dialogInfo, setDialogInfo] = useState<{ open: boolean; indexToOpenOn?: number } | undefined>();
  const openDialogOnIndex = (index: number) => {
    setDialogInfo({ open: true, indexToOpenOn: index });
  };

  const images = useFragment(
    graphql`
      fragment ImageRowImages_imageInformation on ImageInformation @relay(plural: true) {
        id
        # Used for ordering the images
        # eslint-disable-next-line relay/unused-fields
        imageNumber
        image {
          uri
        }
      }
    `,
    imagesRef,
  );

  const sortedImages = useMemo(() => orderBy(images, "imageNumber", "asc"), [images]);

  return (
    <Grid2 container direction="row" spacing={2}>
      {dialogInfo?.open && (
        <ImageCarouselDialog
          images={sortedImages}
          onClosePressed={() => setDialogInfo({ open: false })}
          openOnIndex={dialogInfo?.indexToOpenOn}
        />
      )}
      {sortedImages.map((image, index) => {
        const onClick = () => {
          openDialogOnIndex(index);
        };

        return (
          <Grid2 key={image.id} onClick={onClick}>
            <GMImageWithFallback
              url={image.image.uri}
              style={{
                height: 100,
                width: 100,
                borderRadius: 12,
              }}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
};

const ImageCarouselDialog: FC<{
  onClosePressed: () => void;
  images: ImageRowImages_imageInformation$data;
  openOnIndex?: number;
}> = ({ images, onClosePressed, openOnIndex }) => {
  const [activeImage, setActiveImage] = useState(hasValue(openOnIndex) ? openOnIndex : 0);

  const canGoForwards = activeImage < images.length - 1;
  const canGoBackwards = activeImage > 0;

  const stepForwards = useCallback(() => {
    if (canGoForwards) {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, canGoForwards]);

  const stepBackwards = useCallback(() => {
    if (canGoBackwards) {
      setActiveImage(activeImage - 1);
    }
  }, [activeImage, canGoBackwards]);

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      switch (event.key) {
        case "ArrowLeft":
          stepBackwards();
          break;
        case "ArrowRight":
          stepForwards();
          break;
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [stepBackwards, stepForwards]);

  return (
    <Dialog open maxWidth="xl" fullWidth onClose={onClosePressed}>
      <Grid2 container justifyContent="space-between" alignItems="center" flexDirection="row" margin="auto">
        <Grid2 size={{ xs: 1 }}>
          <IconButton disabled={!canGoBackwards} onClick={stepBackwards}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </Grid2>
        <Grid2 size={{ xs: 10 }}>
          {images.length > 0 && (
            <GMImageWithFallback
              url={images[activeImage].image.uri}
              style={{
                height: "75vh",
                borderRadius: 12,
                objectFit: "contain",
              }}
            />
          )}
        </Grid2>
        <Grid2 container size={{ xs: 1 }} justifyContent="flex-end">
          <IconButton disabled={!canGoForwards} onClick={stepForwards}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </Dialog>
  );
};
