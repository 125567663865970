import { Grid2 } from "@mui/material";
import { FC } from "react";

import { TextWithLabel } from "../../components/shared/TextWithLabel";
import { useTranslation } from "../../utility/i18n/translation";

export const SisterMouldPreventiveMaintenance: FC<{
  title: string;
  interval: number;
  percentage: number;
}> = ({ title, interval, percentage }) => {
  const { translate, locale } = useTranslation();

  return (
    <Grid2 container direction="row" size="grow" spacing={2}>
      <Grid2 size={{ xs: 4 }}>
        <TextWithLabel
          text={title}
          label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.NAME", "Preventive name")}
        />
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <TextWithLabel
          text={"Shots"}
          label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.INTERVAL_TYPE", "Interval type")}
        />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <TextWithLabel
          text={interval.toLocaleString(locale)}
          label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.INTERVAL", "Interval")}
        />
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <TextWithLabel
          text={`${percentage}%`}
          label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.CURRENT_STATUS", "Current status")}
        />
      </Grid2>
      {/* TODO: Add after sys. cleaning dialog has been added */}
      {/* <Grid2 container justifyContent="flex-end" sm>
      <Grid2
        container
        
        direction="column"
        justifyContent="center"
        size={{ xs: 2 }}
      >
        <Icons.Edit color="secondary" />
      </Grid2>
    </Grid2> */}
    </Grid2>
  );
};
