import { MachineTypeFragment } from "../../__apollo__/graphql";

import { AddMachineHourAddition, AddMachineHoursState, MachineHoursDeletion } from "./add-machine-hours-context";

export type AddMachineHoursActions =
  | {
      type: "add_machine_to_additions_array";
      machine: MachineTypeFragment;
    }
  | {
      type: "update_time_reg_for_machine";
      machineId: string;
      minutesSpent: number;
    }
  | {
      type: "update_comment_for_addition";
      machineId: string;
      comment?: string;
    }
  | { type: "remove_machine_from_additions"; machineId: string }
  | { type: "set_show_empty_row"; showEmptyRow: boolean }
  | {
      type: "mark_registration_for_deletion";
      machineHourId: string;
      timeRegistrationNumber: number;
      minutes: number;
    };

export const addMachineHoursReducer = (
  state: AddMachineHoursState,
  action: AddMachineHoursActions,
): AddMachineHoursState => {
  switch (action.type) {
    case "add_machine_to_additions_array":
      return {
        ...state,
        additions: addMachineToAdditions({
          machine: action.machine,
          currentArray: state.additions,
        }),
        showEmptyRow: false,
      };
    case "update_time_reg_for_machine":
      return {
        ...state,
        additions: updateTimeForMachine({
          machineId: action.machineId,
          minutes: action.minutesSpent,
          currentArray: state.additions,
        }),
      };
    case "remove_machine_from_additions":
      return {
        ...state,
        additions: removeMachineFromAdditions(action.machineId, state.additions),
      };
    case "mark_registration_for_deletion": {
      return {
        ...state,
        deletions: updateDeletionStateForTimeReg(
          action.machineHourId,
          action.timeRegistrationNumber,
          action.minutes,
          state.deletions,
        ),
      };
    }
    case "update_comment_for_addition": {
      return {
        ...state,
        additions: updateCommentForAddition(state.additions, action.machineId, action.comment),
      };
    }
    case "set_show_empty_row": {
      return {
        ...state,
        showEmptyRow: action.showEmptyRow,
      };
    }

    default:
      return state;
  }
};

const updateCommentForAddition = (
  currentArray: AddMachineHourAddition[],
  machineId: string,
  comment?: string,
): AddMachineHourAddition[] => {
  const index = findIndexForMachine(machineId, currentArray);
  if (index === -1) {
    return currentArray;
  }
  const res = [...currentArray];
  res[index] = { ...res[index], comment };

  return res;
};

const updateDeletionStateForTimeReg = (
  machineHourId: string,
  timeRegistrationNumber: number,
  minutes: number,
  currentArray: MachineHoursDeletion[],
): MachineHoursDeletion[] => {
  const res = [...currentArray];
  const index = currentArray.findIndex(
    (val) => val.timeRegistrationNumber === timeRegistrationNumber && val.machineHourId === machineHourId,
  );
  if (index === -1) {
    res.push({ machineHourId, timeRegistrationNumber, minutes });
  } else {
    res.splice(index, 1);
  }
  return res;
};

const removeMachineFromAdditions = (
  machineId: string,
  currentArray: AddMachineHourAddition[],
): AddMachineHourAddition[] => {
  const res = [...currentArray];
  const index = findIndexForMachine(machineId, currentArray);
  if (index !== -1) {
    res.splice(index, 1);
  }
  return res;
};

const addMachineToAdditions = ({
  machine,
  currentArray,
}: {
  machine: MachineTypeFragment;
  currentArray: AddMachineHourAddition[];
}): AddMachineHourAddition[] => {
  const currentIndex = findIndexForMachine(machine.id, currentArray);

  const res = [...currentArray];

  if (currentIndex === -1) {
    res.push({ machine, minutesSpent: 0 });
    return res;
  }

  res[currentIndex] = {
    ...currentArray[currentIndex],
    ...{
      machine,
    },
  };

  return res;
};

const updateTimeForMachine = ({
  machineId,
  currentArray,
  minutes,
}: {
  machineId: string;
  minutes: number;
  currentArray: AddMachineHourAddition[];
}) => {
  const currentIndex = findIndexForMachine(machineId, currentArray);

  if (currentIndex === -1) {
    return currentArray;
  }

  const res = [...currentArray];

  res[currentIndex] = {
    ...currentArray[currentIndex],
    minutesSpent: minutes,
  };

  return res;
};

const findIndexForMachine = <T extends { machine: { id: string } }>(machineId: string, array: T[]): number => {
  return array.findIndex((val) => val.machine.id === machineId);
};
