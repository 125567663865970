import { hasValue } from "@lego/mst-error-utilities";
import { Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { TextWithLabel } from "../../../components/shared/TextWithLabel";
import { useFormatDateWithLocale } from "../../../utility/date";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { MouldCurrentProductionEnd_mould$key } from "./__generated__/MouldCurrentProductionEnd_mould.graphql";

const ActualComponent: FC<{
  mould: MouldCurrentProductionEnd_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { currentProductionOrder } = useFragment(
    graphql`
      fragment MouldCurrentProductionEnd_mould on Mould {
        currentProductionOrder {
          priority
          plannedFinishAt {
            date
            time
          }
        }
      }
    `,
    mouldRef,
  );

  const date = currentProductionOrder?.plannedFinishAt?.date;
  const time = currentProductionOrder?.plannedFinishAt?.time;
  const priority = currentProductionOrder?.priority;
  const formatedDateTime = useFormatDateWithLocale(
    hasValue(currentProductionOrder?.plannedFinishAt) ? `${date}T${time}` : null,
    "dd/MM/yyyy, H.mm",
  );

  return (
    <TextWithLabel
      text={formatedDateTime ? `${priority}: ${formatedDateTime} ` : "---"}
      label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.CURRENT_PRODUCTION_END", "Current production end")}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldCurrentProductionEnd = skeletonify("MouldCurrentProductionEnd", ActualComponent, SkeletonComponent);
