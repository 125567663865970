import { Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { TextWithLabel } from "../../../components/shared/TextWithLabel";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { MouldTonSize_mould$key } from "./__generated__/MouldTonSize_mould.graphql";

const ActualComponent: FC<{
  mould: MouldTonSize_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldTonSize_mould on Mould {
        tonSize
      }
    `,
    mouldRef,
  );

  return (
    <TextWithLabel
      text={data.tonSize || ""}
      label={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.TON_SIZE", "Ton size")}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldTonSize = skeletonify("MouldTonSize", ActualComponent, SkeletonComponent);
