import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";

import { AssignedToFragment, AvatarFragment, TdAssignmentDialogFragment } from "../../__apollo__/graphql";
import { CARD_DATE_FORMAT, useFormatDateWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { usePermissions } from "../../utility/permissions/usePermissions";
import { CardWithTitle } from "../shared/CardWithTitle";
import { GMAvatar } from "../shared/GMImageComponents";

import { TD_ASSIGNMENT_DIALOG_FRAGMENT } from "./TDAssignmentDialogFragment";
import { TDAssignmentDialog, useAssignmentMutations } from "./TDAssignmentDialogs";
import { TDWidgetDialogButton } from "./TDWidgetAddButton";

export const TD_ASSIGNED_TO_FRAGMENT = gql`
  fragment AssignedTo on Ticket {
    id
    startedDate
    ...TDAssignmentDialog
    assignedTo {
      ... on Employee {
        id
        firstName
        lastName
        profilePicture {
          id
          small
        }
      }
    }
  }
  ${TD_ASSIGNMENT_DIALOG_FRAGMENT}
`;

export const TD_ASSIGN_MUTATION = gql`
  mutation AssignToMe($input: AssignUserToTicketInput!) {
    assignUserToTicket(input: $input) {
      id
      ...AssignedTo
    }
  }
  ${TD_ASSIGNED_TO_FRAGMENT}
`;

export const TD_UNASSIGN_MUTATION = gql`
  mutation UnassignTicket($input: UnassignTicketInput!) {
    unassignTicket(input: $input) {
      id
      ...AssignedTo
    }
  }
  ${TD_ASSIGNED_TO_FRAGMENT}
`;

const AssignRightButton: FC<
  Omit<AssignedToFragment, "assignedTo" | "startedDate"> & {
    openDialog: () => void;
    variant: "assign" | "unassign";
    dataCy?: string;
  }
> = ({ openDialog, variant, dataCy, ...data }) => {
  const { translate } = useTranslation();
  const {
    ticket: { canAssignTicket, canEditEquipmentLocation, canUnassignTicket },
  } = usePermissions();

  const {
    assign: { assignLoading, assignMutation },
    unassign: { unassignLoading, unassignMutation },
  } = useAssignmentMutations();

  const equipment = data.equipment?.__typename === "EquipmentValue" ? data.equipment.value : undefined;

  const icon = variant === "assign" ? <Icons.Assign /> : <Icons.Unassign />;
  const title =
    variant === "assign"
      ? translate("TICKET_DETAILS.CARDS.ASSIGNED_TO_ASSIGN_BUTTON", "Assign")
      : translate("TICKET_DETAILS.CARDS.ASSIGNED_TO_UNASSIGN_BUTTON", "Unassign");

  const loading = variant === "assign" ? assignLoading : unassignLoading;

  const onClick = useCallback(() => {
    if (variant === "assign") {
      assignMutation({ variables: { input: { ticketId: data.id } } });
    } else {
      unassignMutation({ variables: { input: { ticketId: data.id } } });
    }
  }, [assignMutation, data.id, unassignMutation, variant]);

  const variantAllowed =
    data.isOpen &&
    ((variant === "assign" && canAssignTicket(data)) || (variant === "unassign" && canUnassignTicket(data)));

  if (variantAllowed && equipment && canEditEquipmentLocation(equipment)) {
    return <TDWidgetDialogButton icon={icon} title={title} onClick={openDialog} dataCy={dataCy} />;
  }

  if (variantAllowed) {
    return <TDWidgetDialogButton icon={icon} title={title} onClick={onClick} loading={loading} dataCy={dataCy} />;
  }

  return null;
};

export const TDAssignedToOld: FC<AssignedToFragment> = ({ assignedTo, startedDate, ...rest }) => {
  const { translate } = useTranslation();
  const title = translate("TICKET_DETAILS.CARDS.ASSIGNED_TO_TITLE", "Assigned To");

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const closeAssignDialog = useCallback(() => {
    setAssignDialogOpen(false);
  }, []);

  const openAssignDialog = useCallback(() => {
    setAssignDialogOpen(true);
  }, []);

  const dateLabel = useFormatDateWithLocale(startedDate, CARD_DATE_FORMAT);

  if (!assignedTo) {
    return (
      <CardWithTitle
        title={title}
        rightItem={
          <AssignRightButton
            {...rest}
            openDialog={openAssignDialog}
            variant={"assign"}
            dataCy={"TDAssignedTo-assign-button"}
          />
        }
      >
        <Grid2
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ width: "70%", height: 70 }}
        >
          <Grid2>
            <Icons.NoTickets />
          </Grid2>
          <Grid2>
            <Typography variant="caption">
              {translate("TICKET_DETAILS.CARDS.ASSIGNED_TO_UNASSIGNED", "Unassigned")}
            </Typography>
          </Grid2>
        </Grid2>
        {rest.equipment?.__typename === "EquipmentValue" && (
          <TDAssignmentDialog open={assignDialogOpen} data={rest} onDismiss={closeAssignDialog} variant="assign" />
        )}
      </CardWithTitle>
    );
  }

  if (assignedTo?.__typename !== "Employee") {
    return null;
  }

  return <TDCreatedByOrAssignedTo user={assignedTo} title={title} date={dateLabel} data={rest} />;
};

export const TDCreatedByOrAssignedTo: FC<{
  title: string;
  user: AvatarFragment;
  data: TdAssignmentDialogFragment;
  date?: string;
}> = ({ date, title, user, data }) => {
  const avatarSize = 46;
  const [unassignDialogOpen, setUnassignDialogOpen] = useState(false);

  const closeUnassignDialog = useCallback(() => {
    setUnassignDialogOpen(false);
  }, []);

  const openUnassignDialog = useCallback(() => {
    setUnassignDialogOpen(true);
  }, []);

  return (
    <CardWithTitle
      title={title}
      halfSize
      rightItem={
        <AssignRightButton
          {...data}
          openDialog={openUnassignDialog}
          variant={"unassign"}
          dataCy="TDAssignedTo-unassign-button"
        />
      }
    >
      <Grid2 container spacing={1} direction="row">
        <Grid2>
          <GMAvatar
            {...user}
            pictureUri={user.profilePicture.small}
            style={{
              width: avatarSize,
              height: avatarSize,
            }}
          />
        </Grid2>
        <Grid2
          style={{
            textOverflow: "ellipsis",
          }}
        >
          <Typography noWrap>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {date}
          </Typography>
        </Grid2>
      </Grid2>
      {hasValue(data) && (
        <TDAssignmentDialog open={unassignDialogOpen} data={data} onDismiss={closeUnassignDialog} variant="unassign" />
      )}
    </CardWithTitle>
  );
};
