import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { skeletonify } from "../../migration/skeleton";
import { useTranslation } from "../../utility/i18n/translation";

import { AreaWithFlag_plant$key } from "./__generated__/AreaWithFlag_plant.graphql";
import { getFlagIconForPlantNumber, usePlantIdentifierForNumber } from "./flagIcons";

const ActualComponent: FC<{
  plant: AreaWithFlag_plant$key | null | undefined;
}> = ({ plant: plantRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment AreaWithFlag_plant on Plant {
        plantNumber
      }
    `,
    plantRef ?? null,
  );

  const countryIdentifier = usePlantIdentifierForNumber(data?.plantNumber);

  const notFoundLabel = translate("TICKET_DETAILS.SISTER_MOULDS_TAB.NO_PLANT", "No plant information found");

  if (!hasValue(data) || !hasValue(countryIdentifier)) {
    return <Typography>{notFoundLabel}</Typography>;
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2>
        <Typography>
          {countryIdentifier} ({data.plantNumber})
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {getFlagIconForPlantNumber(data.plantNumber, {
            height: 15,
          })}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const AreaWithFlag = skeletonify("AreaWithFlag", ActualComponent, SkeletonComponent);
