import { Chip, Grid2, useTheme } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";

import {
  RepairDescriptionActivitiesQuery,
  RepairDescriptionActivitiesQueryVariables,
} from "../../../__apollo__/graphql";
import { useGMQuery } from "../../../apollo/customApolloHooks";
import { useAddRepairDescriptionContext } from "../../../contexts/add-repair-description/add-repair-description-context";
import { REPAIR_DESCRIPTION_ACTIVITIES } from "../../../contexts/add-repair-description/add-repair-description-gql-queries";
import { ARDNavigationButtons } from "../ARDNavigationButtons";

export const ARDChooseActivities: FC = () => {
  const {
    palette: {
      primary: { main: primaryColor },
    },
  } = useTheme();
  const { id: ticketId } = useParams() as { id: string };
  const {
    state: { chosenActivities, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const { data } = useGMQuery<RepairDescriptionActivitiesQuery, RepairDescriptionActivitiesQueryVariables>(
    REPAIR_DESCRIPTION_ACTIVITIES,
    {
      variables: { input: { ticketId } },
    },
  );

  if (!(currentStep === "activities") && chosenActivities.length > 0) {
    return (
      <Grid2 container spacing={2}>
        {chosenActivities.map((activity) => {
          return (
            <Grid2 key={activity.id}>
              <Chip
                label={activity.description}
                style={{
                  color: "white",
                  backgroundColor: primaryColor,
                }}
              />
            </Grid2>
          );
        })}
      </Grid2>
    );
  }

  return (
    <Grid2 container spacing={2} data-cy="ARDChooseActivities-active">
      <Grid2 container spacing={3}>
        {data?.repairDescriptionActivityOptions.map((activity) => {
          const activitySelected = chosenActivities?.some((item) => activity.id === item.id) ?? false;

          const onClick = () => {
            dispatch({ type: "activity_selected", activity });
          };

          return (
            <Grid2 key={activity.id}>
              <Chip
                label={activity.description}
                onClick={onClick}
                variant={activitySelected ? "filled" : "outlined"}
                style={{
                  color: activitySelected ? "white" : primaryColor,
                  backgroundColor: activitySelected ? primaryColor : undefined,
                }}
              />
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2>
        <ARDNavigationButtons />
      </Grid2>
    </Grid2>
  );
};
