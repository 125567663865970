import { hasValue } from "@lego/mst-error-utilities";
import { Container, Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { ComponentProps, FC, ReactElement, useCallback, useEffect, useState } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { EquipmentDocumentList } from "../../migration/equipment-documents/EquipmentDocumentList";
import {
  EquipmentDocumentsGroupSearch,
  equipmentDocumentsGroupSearchNoGroupId,
} from "../../migration/equipment-documents/EquipmentDocumentsGroupSearch";
import { PageErrorBoundary } from "../../migration/PageErrorBoundary";
import { skeletonify } from "../../migration/skeleton";
import { useRouteRootType } from "../../Router";

import TDDocumentsTabQuery, {
  TDDocumentsTabQuery as TDDocumentsTabQueryType,
} from "./__generated__/TDDocumentsTabQuery.graphql";

type Props = {
  query: PreloadedQuery<TDDocumentsTabQueryType>;
  onChange: ComponentProps<typeof EquipmentDocumentsGroupSearch.Suspense>["onGroupSelected"];
};

const ActualComponent = (props: Props) => {
  const { query: queryRef } = props;
  const { ticket, equipment: equipmentData } = usePreloadedQuery(
    graphql`
      query TDDocumentsTabQuery(
        $ticketInput: QueryTicketInput!
        $equipmentInput: QueryEquipmentInput!
        $documentsInput: EquipmentDocumentsInput!
        $skipTicketQuery: Boolean!
        $skipEquipmentQuery: Boolean!
      ) {
        ticket(input: $ticketInput) @skip(if: $skipTicketQuery) {
          ... on QueryTicketSuccess {
            data {
              equipment {
                ...EquipmentDocumentsGroupSearch_equipment
                ...EquipmentDocumentList_equipment @arguments(documents: $documentsInput)
              }
            }
          }
        }
        equipment(input: $equipmentInput) @skip(if: $skipEquipmentQuery) {
          ... on QueryEquipmentSuccess {
            data {
              ...EquipmentDocumentsGroupSearch_equipment
              ...EquipmentDocumentList_equipment @arguments(documents: $documentsInput)
            }
          }
        }
      }
    `,
    queryRef,
  );

  const equipment = hasValue(ticket?.data?.equipment) ? ticket?.data?.equipment : equipmentData?.data;

  return {
    search: <EquipmentDocumentsGroupSearch.Suspense onGroupSelected={props.onChange} equipment={equipment} />,
    list: <EquipmentDocumentList.Suspense data={equipment} />,
  };
};

const SkeletonComponent = {
  search: <EquipmentDocumentsGroupSearch.Skeleton />,
  list: <EquipmentDocumentList.Skeleton />,
};

const StructureComponent = ({ search, list }: { search: ReactElement; list: ReactElement }) => (
  <Grid2 container direction="column" size={{ xs: "grow" }}>
    <Grid2>{search}</Grid2>
    <Grid2 sx={{ mx: 0.5 }}>{list}</Grid2>
  </Grid2>
);

const DocumentsTab = skeletonify("DocumentsTab", ActualComponent, () => SkeletonComponent, StructureComponent);

export const TDDocumentsTab: FC = () => {
  const { id } = useParams() as { id: string };
  const [documentGroup, setDocumentGroup] = useState<string | null>(null);
  const routeRootType = useRouteRootType();

  const onGroupChanged = useCallback((newValue: string | null) => {
    setDocumentGroup(newValue);
  }, []);

  const [queryRef, loadQuery] = useQueryLoader<TDDocumentsTabQueryType>(TDDocumentsTabQuery);

  useEffect(
    () =>
      loadQuery(
        {
          ticketInput: {
            ticketNumber: Number.parseInt(id),
          },
          documentsInput: {
            documentGroup: documentGroup !== equipmentDocumentsGroupSearchNoGroupId ? documentGroup : null,
          },
          equipmentInput: {
            equipmentNumber: Number.parseInt(id),
          },
          skipEquipmentQuery: routeRootType === "ticket",
          skipTicketQuery: routeRootType === "equipment",
        },
        { fetchPolicy: "store-and-network" },
      ),
    [documentGroup, id, loadQuery, routeRootType],
  );

  return (
    <Container maxWidth="xl">
      <PageErrorBoundary>
        <Grid2 container sx={{ mt: 2 }}>
          {queryRef ? <DocumentsTab.Suspense query={queryRef} onChange={onGroupChanged} /> : <DocumentsTab.Skeleton />}
        </Grid2>
      </PageErrorBoundary>
    </Container>
  );
};
