import { BannerState, BannerType } from "./banner-context";

export type BannerActions =
  | {
      type: "show";
      payload: { text: string; type?: BannerType; innerBanner?: boolean };
    }
  | {
      type: "hide";
    };

export const createBannerReducer = (state: BannerState, action: BannerActions): BannerState => {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        ...action.payload,
      };
    case "hide":
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
};
