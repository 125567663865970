import { Chip, Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { CardWithTitle, CardWithTitleSkeleton } from "../../components/shared/CardWithTitle";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { QAOperationsData } from "./QAOperationsData";
import { QAOperations_equipment$key } from "./__generated__/QAOperations_equipment.graphql";

const skeletonTextWidth = 200;

const ActualComponent = ({ equipment: equipmentRef }: { equipment: QAOperations_equipment$key }) => {
  const { translate } = useTranslation();
  const query = useFragment(
    graphql`
      fragment QAOperations_equipment on Equipment {
        ... on Mould {
          ...QAOperationsData_equipment
        }
      }
    `,
    equipmentRef,
  );

  return (
    <CardWithTitle title={translate("QA_DETAILS.CARDS.MOULD_DETAILS.LABELS.QA", "QA")}>
      <Grid2 container direction="row">
        <QAOperationsData queryRef={query} />
      </Grid2>
    </CardWithTitle>
  );
};
const SkeletonComponent = () => {
  return <QAOperationsSkeleton />;
};

const QAOperationsSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid2 container direction="column">
        {[...Array(5)].map((item) => (
          <Grid2 display="flex" key={item}>
            <Skeleton variant="text" width={skeletonTextWidth} />
            <Chip sx={{ width: 100, height: 20, alignSelf: "center", marginLeft: 7 }} />
          </Grid2>
        ))}
      </Grid2>
    </CardWithTitleSkeleton>
  );
};
export const QAOperations = skeletonify("QAOperations", ActualComponent, SkeletonComponent);
