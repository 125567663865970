import { Box, Chip, Grid2, styled, Typography } from "@mui/material";
import { CSSProperties, FC } from "react";
import { useParams } from "react-router";

import {
  RepairDocumentationAccordionFragment,
  RepairDocumentationDamageFragment,
  RepairDocumentationSubEquipmentFragment,
  TicketDetailsRepairDescriptionsFragment,
} from "../../__apollo__/graphql";
import { AppRoutes } from "../../Router";
import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { usePermissions } from "../../utility/permissions/usePermissions";
import { RepairDocumentationAccordionComponent } from "../close-ticket/CTRepairDocumentation";
import { CardWithTitle } from "../shared/CardWithTitle";
import { ImageRow } from "../shared/ImageRow";

import { TDWidgetAddButton } from "./TDWidgetAddButton";

export const TDRepairDescriptionApollo: FC<TicketDetailsRepairDescriptionsFragment> = ({
  repairDocumentation: { damageList },
  ...data
}) => {
  const { translate } = useTranslation();
  const title = translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.TITLE", "Repair documentation");

  const {
    ticket: { canAddRepairDescription },
  } = usePermissions();

  const canAddRepair = canAddRepairDescription(data);

  const rightItem = canAddRepair ? <AddButton /> : undefined;

  const length = repairDamageListLength(damageList);

  if (length === 0) {
    return (
      <CardWithTitle title={title} rightItem={rightItem}>
        <Typography>
          {translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.NO_REPAIR_DOCS", "No repair documentation yet")}
        </Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} rightItem={rightItem}>
      <RepairDocumentationAccordionComponent damageList={damageList} padding={0} />
    </CardWithTitle>
  );
};

const AddButton: FC = () => {
  const { translate } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <TDWidgetAddButton
      icon={<Icons.Plus />}
      routeTo={AppRoutes.tickets.repair(id)}
      title={translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.BUTTON", "Documentation")}
    />
  );
};

export const TDRepairDescriptionContent: FC<{
  damageList: TicketDetailsRepairDescriptionsFragment["repairDocumentation"]["damageList"];
}> = ({ damageList }) => {
  const { translate } = useTranslation();

  const length = repairDamageListLength(damageList);

  return (
    <Grid2 container direction="column" data-cy="TDRepairDescriptionContent">
      {length === 0 && (
        <Grid2>
          <Typography>
            {translate(
              "TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.NO_REPAIR_DOCUMENTATION_YET",
              "No repair documentation on this ticket yet",
            )}
          </Typography>
        </Grid2>
      )}
      {damageList.__typename === "RepairDocumentationDamageList"
        ? damageList.damages.map((damage, index) => (
            <Grid2 key={index}>
              <TDRepairRow {...damage} />
            </Grid2>
          ))
        : damageList.__typename === "RepairDocumentationSubEquipmentList"
          ? damageList.subEquipments.map((damage, index) => (
              <Grid2 key={index}>
                <TDPackingRepairRow {...damage} />
              </Grid2>
            ))
          : null}
    </Grid2>
  );
};

const iconSize = 48;

const TDPackingRepairRow: FC<RepairDocumentationSubEquipmentFragment> = ({ description, components }) => {
  return (
    <Grid2 container direction="column">
      <Grid2>
        <HeaderAndIcon text={description} icon="subEquipment" />
      </Grid2>
      <Grid2>
        <TDPackingRepairRowContent components={components} />
      </Grid2>
    </Grid2>
  );
};

export const TDPackingRepairRowContent: FC<{
  components: RepairDocumentationSubEquipmentFragment["components"];
}> = ({ components }) => {
  const { translate } = useTranslation();

  return (
    <Grid2 container direction="row">
      <Grid2>
        <Box borderLeft={1} style={{ marginLeft: iconSize / 2 }}>
          <Grid2 container direction="column">
            {components.map((component) => {
              return (
                <Grid2 key={component.code} container direction="row" sx={{ mt: 2 }}>
                  {/* Horizontal divider */}
                  <Grid2>
                    <Box
                      width={35}
                      borderTop={1}
                      sx={{
                        mt: 1.5,
                      }}
                    />
                  </Grid2>

                  {/* Main content */}
                  <Grid2 sx={{ ml: 2, flex: 1 }}>
                    <Grid2>
                      <Typography>
                        {translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.COMPONENT", "Component")}
                      </Typography>
                    </Grid2>
                    <Grid2 sx={{ my: 1 }}>
                      <Chip variant="outlined" label={component.description} />
                    </Grid2>
                    <Grid2 sx={{ mt: 1 }}>
                      {component.damages.map((damage) => {
                        return <TDRepairRow key={damage.damageId} {...damage} />;
                      })}
                    </Grid2>
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
      </Grid2>
    </Grid2>
  );
};

const marginBetweenIconAndContent = 28;
const marginBetweenHeaderAndContent = 12;

const TDRepairRow: FC<RepairDocumentationDamageFragment> = ({
  activities,
  causes,
  comments,
  damageCodeText,
  images,
}) => {
  return (
    <>
      <HeaderAndIcon text={damageCodeText} icon="repair" />
      <Box borderLeft={1} style={{ marginLeft: iconSize / 2 }}>
        <Grid2 container direction="column" style={{ marginLeft: marginBetweenIconAndContent + iconSize / 2 }}>
          {/* Cause and activity chips */}
          <Grid2 container direction="row">
            <Grid2 size={{ xs: 12 }}>
              <Causes causes={causes} />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Activities activities={activities} />
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Comments comments={comments} />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <ImageRow images={images} />
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

const Header = styled(Typography)({ marginBottom: 12, fontWeight: 700 });

const HeaderAndIcon: FC<{
  text: string;
  icon: "subEquipment" | "repair";
}> = ({ text, icon }) => {
  const style: CSSProperties = {
    fontSize: iconSize,
    marginRight: marginBetweenIconAndContent,
  };
  return (
    <Grid2 container direction="row" alignItems="center">
      <Grid2>{icon === "repair" ? <Icons.Hardhat style={style} /> : <Icons.SubEquipment style={style} />}</Grid2>
      <Grid2>
        <Typography variant="h3">{text}</Typography>
      </Grid2>
    </Grid2>
  );
};

const Causes: FC<{
  causes: Extract<
    TicketDetailsRepairDescriptionsFragment["repairDocumentation"]["damageList"],
    { __typename?: "RepairDocumentationDamageList" }
  >["damages"][number]["causes"];
}> = ({ causes }) => {
  const { translate } = useTranslation();
  return (
    <Grid2
      style={{
        marginRight: 32,
        marginBottom: marginBetweenHeaderAndContent,
      }}
    >
      <Header>{translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.CAUSE", "Cause")}</Header>
      <Grid2 container spacing={2}>
        {causes.map((cause) => {
          return (
            <Grid2 key={cause.description}>
              <Chip variant="outlined" label={cause.description} />
            </Grid2>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

const Activities: FC<{
  activities: Extract<
    TicketDetailsRepairDescriptionsFragment["repairDocumentation"]["damageList"],
    { __typename?: "RepairDocumentationDamageList" }
  >["damages"][number]["activities"];
}> = ({ activities }) => {
  const { translate } = useTranslation();
  if (activities.length === 0) {
    return null;
  }

  return (
    <Grid2
      style={{
        marginBottom: marginBetweenHeaderAndContent,
        marginRight: 32,
      }}
    >
      <Header>{translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.ACTIVITY", "Activity")}</Header>
      <Grid2 container spacing={2}>
        {activities.map((activity) => {
          return (
            <Grid2 key={activity.description}>
              <Chip variant="outlined" label={activity.description} />
            </Grid2>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

const Comments: FC<{
  comments: Extract<
    TicketDetailsRepairDescriptionsFragment["repairDocumentation"]["damageList"],
    { __typename?: "RepairDocumentationDamageList" }
  >["damages"][number]["comments"];
}> = ({ comments }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  if (comments.length === 0) {
    return null;
  }

  return (
    <Grid2
      style={{
        paddingRight: 140,
        marginBottom: 16,
      }}
    >
      <Header>{translate("TICKET_DETAILS.CARDS.REPAIR_DOCUMENTATION.DESCRIPTION", "Description")}</Header>

      {comments.map((comment) => {
        const dateWithPrefix = `${translate("MISC.CREATED_PREFIX", "Created")} ${format(
          comment.createdDate,
          CARD_DATE_FORMAT,
        )}`;

        return (
          <Grid2 key={`${comment.createdDate}:${comment.description}`}>
            <Typography
              style={{
                marginBottom: 16,
                wordWrap: "break-word",
              }}
            >
              {comment.description}
            </Typography>
            <Typography variant="caption">{dateWithPrefix}</Typography>
          </Grid2>
        );
      })}
    </Grid2>
  );
};

export const repairDamageListLength = (
  damageList: RepairDocumentationAccordionFragment["repairDocumentation"]["damageList"],
): number => {
  return damageList.__typename === "RepairDocumentationDamageList"
    ? damageList.damages.length
    : damageList.__typename === "RepairDocumentationSubEquipmentList"
      ? damageList.subEquipments.length
      : 0;
};
