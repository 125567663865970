import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Grid2, IconButton, IconButtonProps, styled, useTheme } from "@mui/material";
import { FC, Fragment, ReactElement, ReactNode, useState } from "react";

interface StyledIconButtonDynamicProps extends IconButtonProps {
  expanded?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<StyledIconButtonDynamicProps>(({ expanded, theme }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  ...(expanded && {
    transform: "rotate(180deg)",
  }),
}));

export const CollapsibleWidget: FC<{
  componentWhenClosed: ReactElement;
  children: ReactNode;
  padding?: number;
}> = ({ componentWhenClosed, children, padding = 0 }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid2
        data-cy="CollapsibleWidget"
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={handleExpandClick}
        sx={{
          px: padding,
          "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        {componentWhenClosed}
        <Grid2>
          <StyledIconButton size="large" expanded={expanded}>
            <ExpandMoreIcon />
          </StyledIconButton>
        </Grid2>
      </Grid2>
      <Collapse in={expanded} timeout="auto" style={{ paddingLeft: padding, paddingRight: padding, width: "100%" }}>
        {children}
      </Collapse>
    </>
  );
};
