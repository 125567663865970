import { Card, Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { ActivityIndicator } from "../../../components/shared/ActivityIndicator";
import { useAreaAndProcessContext } from "../../../contexts/area";
import { Figures } from "../../../utility/figures";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import SparePartSearchQuery, {
  SparePartSearchQuery as SparePartSearchQueryType,
} from "./__generated__/SparePartSearchQuery.graphql";
import { useSparePartSearchContext } from "./spare-part-search-context";
import { SparePartSearchList } from "./SparePartSearchList";

const ActualComponent = (props: { query: PreloadedQuery<SparePartSearchQueryType> }) => {
  const { query: queryRef } = props;
  const { node } = usePreloadedQuery(
    graphql`
      query SparePartSearchQuery($input: ProcessSparePartSearchInput!, $processId: ID!) {
        node(id: $processId) {
          ... on Process {
            ...SparePartSearchList_process @arguments(input: $input, processId: $processId) #@defer
          }
        }
      }
    `,
    queryRef,
  );

  const list = node ? <SparePartSearchList.Suspense query={node} /> : <SparePartSearchList.Skeleton />;

  return {
    list,
  };
};

const SkeletonComponent = () => ({
  list: (
    <Grid2 container justifyContent={"center"} alignItems={"center"}>
      <Grid2>
        <ActivityIndicator />
      </Grid2>
    </Grid2>
  ),
});

const StructureComponent = ({ list }: { list: ReactElement }) => {
  const { translate } = useTranslation();
  return (
    <Grid2 container direction="column" sx={{ mt: 2 }}>
      <Grid2 sx={{ mx: 0.5 }}>
        <Card>
          <Grid2 container sx={{ padding: 2.5 }} flexDirection="row">
            <Grid2 size={{ xs: 12 }}>
              <Typography variant="subtitle1">{translate("SPARE_PART_SEARCH.HEADER", "Spare parts")}</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>{list}</Grid2>
          </Grid2>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export const SparePartSearchLoader = skeletonify(
  "SparePartSearchLoader",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);

// search term must be at least 3 characters long or contain chinese characters
const shouldSearch = (searchTerm: string) => searchTerm.length >= 3 || /[\u4E00-\u9FFF]/.test(searchTerm);

export const SparePartSearch: FC = () => {
  const [queryRef, loadQuery] = useQueryLoader<SparePartSearchQueryType>(SparePartSearchQuery);
  const { id } = useParams() as { id: string };
  const { relayProcessId: processId } = useAreaAndProcessContext();
  const {
    state: { searchTerm, filterOnStock },
  } = useSparePartSearchContext();

  useEffect(() => {
    if (!processId) {
      return;
    }

    if (!shouldSearch(searchTerm || "")) {
      return;
    }

    return loadQuery(
      { input: { searchTerm, inStock: filterOnStock }, processId },
      { fetchPolicy: "store-and-network" },
    );
  }, [loadQuery, processId, searchTerm, filterOnStock]);

  if (!id && !shouldSearch(searchTerm || "")) {
    return <NoQuery />;
  }
  return queryRef && searchTerm && searchTerm.length > 0 ? <SparePartSearchLoader.Suspense query={queryRef} /> : null;
};

const NoQuery: FC = () => {
  const { translate } = useTranslation();
  return (
    <Grid2 container direction="column" spacing={2} alignItems="center">
      <Grid2>
        <Typography fontWeight={700} align="center">
          {translate("SPAREPART_LIST.SEARCH_SUBTITLE", "Please search to see list of spare parts")}
        </Typography>
      </Grid2>
      <Grid2>
        <Figures.SearchingUpwards />
      </Grid2>
    </Grid2>
  );
};
