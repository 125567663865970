import { Button, Grid2, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { NavLink } from "react-router";

import { TimeRegistrationOverviewFragment } from "../../__apollo__/graphql";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { AddTimeOverview } from "../add-time/overview/AddTimeOverview";

import { CTNoManhoursOrRepairDocs } from "./CTNoManhoursOrRepairDocs";

export const CTManhours: FC<TimeRegistrationOverviewFragment> = (data) => {
  const { translate } = useTranslation();
  const { id } = data;
  const { dispatch } = useCloseTicketContext();

  useEffect(() => {
    dispatch({
      type: "setManHoursValid",
      valid: data.totalManHoursInMinutes > 0,
    });
  }, [data.totalManHoursInMinutes, dispatch]);

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("CLOSE_TICKET.MANHOURS_STEP.CARD_HEADER", "Time spent (mandatory)")}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {translate(
            "CLOSE_TICKET.MANHOURS_STEP.CARD_DESCRIPTION",
            "Before closing the ticket, please check that the time registration is correct",
          )}
        </Typography>
      </Grid2>
      {data.totalManHoursInMinutes > 0 ? (
        <Grid2 marginX={8}>
          <AddTimeOverview {...data} interactive={false} />
        </Grid2>
      ) : (
        <Grid2>
          <NoManhours />
        </Grid2>
      )}
      <Grid2>
        <Button
          data-cy="CTManhours-addTimeButton"
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
          component={NavLink}
          to={AppRoutes.tickets.manHours(id)}
          startIcon={<Icons.Edit color="primary" />}
        >
          {translate("CLOSE_TICKET.MANHOURS_STEP.ADD_TIME_BUTTON", "Time")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

const NoManhours = () => {
  const { translate } = useTranslation();
  const {
    state: { showManHoursRequiredWarning },
  } = useCloseTicketContext();

  const title = translate("CLOSE_TICKET.MANHOURS_STEP.NO_TIME_HEADER", "No time registered");

  const message = translate(
    "CLOSE_TICKET.MANHOURS_STEP.NO_TIME_ERROR",
    "Time registration is mandatory for this ticket type",
  );

  return <CTNoManhoursOrRepairDocs title={title} message={message} showWarning={showManHoursRequiredWarning} />;
};
