import { useEffect, useState } from "react";

import { getAreaFromStorage } from "../../contexts/area/area-and-process-context";
import { getAccessToken } from "../../msal";
import { getNodeEnvironment } from "../../utility/environment";

export const useAuthenticatedPictureSource = (uri: string | undefined): string | undefined => {
  const [src, setSrc] = useState<string | undefined>();

  useEffect(() => {
    if (!uri) {
      return;
    }

    let objectURL: string | undefined;

    const fetchImage = async (url: string) => {
      const token = await getAccessToken(undefined);
      const fullUrl = buildImageUrl(url);

      try {
        const response = await fetch(fullUrl, {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }), // Only set Authorization header if token is available
            "x-plant-id": getAreaFromStorage().plantId,
          },
        });

        objectURL = URL.createObjectURL(await response.blob());
        setSrc(objectURL);
      } catch {
        setSrc(undefined);
      }
    };

    fetchImage(uri);
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [uri]);

  return src;
};

const buildImageUrl = (url: string): string => {
  // Avoid CORS on localhost
  if (getNodeEnvironment() === "local") {
    return url.replace(/^https?:\/\/[^/]*\//g, "");
  }

  // Profile pictures coming from relay are already full URLs for some reason
  if (url.startsWith("http")) {
    return url;
  }

  return `${process.env.REACT_APP_BASE_URL}${url}`;
};
