import { Card, Grid2, Skeleton, Typography } from "@mui/material";
import { FC, ReactElement, ReactNode, useMemo } from "react";

const MAX_LENGTH_OF_WORD_IN_TITLE = 16;
export const CardWithTitle: FC<{
  title: string;
  halfSize?: boolean;
  rightItem?: ReactElement;
  children: ReactNode;
}> = ({ title, halfSize = false, children, rightItem }) => {
  const lengthOfLongestWordInTitle = useMemo(() => {
    const wordsInTitle = title.split(" ");

    return wordsInTitle.reduce((prev, curr) => {
      return curr.length > prev ? curr.length : prev;
    }, 0);
  }, [title]);

  return (
    <Card data-cy={`CardWithTitle-${title.replace(" ", "-")}`} style={{ flexGrow: 1 }}>
      <Grid2 container direction="row" style={{ padding: 20 }}>
        <Grid2
          size={{ xs: 12, lg: halfSize ? 3 : 2 }}
          style={{
            textOverflow: "ellipsis",
            marginBottom: 8,
            marginTop: 4,
          }}
        >
          <Typography variant="subtitle1" noWrap={lengthOfLongestWordInTitle > MAX_LENGTH_OF_WORD_IN_TITLE}>
            {title}
          </Typography>
        </Grid2>
        <Grid2 container direction="column" size={{ xs: 12, lg: "grow" }}>
          {children}
        </Grid2>
        {rightItem && (
          <Grid2 container size={{ xs: 12, lg: halfSize ? 2.5 : 2 }} justifyContent="flex-end">
            <Grid2>{rightItem}</Grid2>
          </Grid2>
        )}
      </Grid2>
    </Card>
  );
};

export const CardWithTitleSkeleton: FC<{
  halfSize?: boolean;
  showTopRightButton?: boolean;
  children: ReactNode;
}> = ({ halfSize = false, children, showTopRightButton = false }) => {
  return (
    <Card style={{ flexGrow: 1 }}>
      <Grid2 container direction="row" style={{ padding: 20 }}>
        <Grid2
          size={{ xs: 12, lg: halfSize ? 4 : 2 }}
          style={{
            textOverflow: "ellipsis",
            marginBottom: 8,
            marginTop: 4,
          }}
        >
          <Skeleton variant="text" width={150} />
        </Grid2>
        <Grid2 size={{ xs: 12, lg: "grow" }}>{children}</Grid2>
        {showTopRightButton && (
          <Grid2 size={{ xs: 12, lg: 2 }} container justifyContent="flex-end">
            <Skeleton variant="text" width={150} height={40} />
          </Grid2>
        )}
      </Grid2>
    </Card>
  );
};
