import { Grid2, Typography } from "@mui/material";
import { CSSProperties, FC, useCallback } from "react";

import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";

import { CmsTicketType } from "./CmsTicketType";

export const CmsTicketTypeSelector: FC<{
  selectedType: string;
  onChange: (value: string) => void;
}> = ({ selectedType, onChange }) => {
  const { translate } = useTranslation();

  const onClick = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const iconStyle: CSSProperties = { fontSize: "65px" };

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2 container direction="row" justifyContent="center">
        <Grid2>
          <Typography variant="body1">
            {translate("CREATE_CMS_TICKET.TYPE", "What type of order is needed?")}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container direction="row" justifyContent="center" spacing={8}>
        <Grid2>
          <CmsTicketType
            icon={<Icons.EquipmentId style={iconStyle} />}
            label={translate("CREATE_CMS_TICKET.EQUIPMENT_REPAIR", "Equipment repair")}
            selected={selectedType === "equipment_repair"}
            onClick={() => onClick("equipment_repair")}
          />
        </Grid2>
        <Grid2>
          <CmsTicketType
            icon={<Icons.SparePartCogs style={iconStyle} />}
            label={translate("CREATE_CMS_TICKET.SPAREPART_REPAIR", "Spare part repair")}
            selected={selectedType === "sparepart_repair"}
            onClick={() => onClick("sparepart_repair")}
          />
        </Grid2>
        <Grid2>
          <CmsTicketType
            icon={<Icons.SparepartAssign style={iconStyle} />}
            label={translate("CREATE_CMS_TICKET.SPAREPART_MANUFACTURING", "Spare part manufacturing")}
            selected={selectedType === "sparepart_manufacturing"}
            onClick={() => onClick("sparepart_manufacturing")}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
