import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";

export const SparePartInStock: FC<{
  opaque?: boolean;
}> = ({ opaque }) => {
  const { translate } = useTranslation();

  const opacity = opaque ? 0.6 : 1;

  const text = translate("SPARE_PART.IS_IN_STOCK", "in stock");
  return (
    <Grid2 container direction="row" alignItems="center">
      <Grid2>
        <Icons.CheckmarkBold height={"18px"} color={"success"} sx={{ mt: 0.7, mr: 1 }} />
      </Grid2>
      <Grid2>
        <Typography variant="body2" sx={{ opacity }}>
          {text}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
