import { FC } from "react";

import { TicketDetailsErrorDescriptionFragment } from "../../../__apollo__/graphql";
import { useTranslation } from "../../../utility/i18n/translation";
import { TDErrorDescriptionContent } from "../../ticket-details/TDErrorDescription";

import { ARDCollapsibleSidebarWidget } from "./ARDCollapsibleSidebarWidget";

export const ARDErrorDescription: FC<TicketDetailsErrorDescriptionFragment> = (props) => {
  const { translate } = useTranslation();
  return (
    <div data-cy="ARDErrorDescription">
      <ARDCollapsibleSidebarWidget
        title={translate("ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.ERROR_DESC.TITLE", "Error description")}
      >
        <TDErrorDescriptionContent {...props} />
      </ARDCollapsibleSidebarWidget>
    </div>
  );
};
