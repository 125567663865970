import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { useAreaAndProcessContext } from "../../contexts/area";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { PlannedCleaningJobRow } from "./PlannedCleaningJobRow";
import { PlannedJobsTable } from "./PlannedJobsTable";
import { PlannedCleaningJobsTabQuery } from "./__generated__/PlannedCleaningJobsTabQuery.graphql";

const ActualComponent: FC = () => {
  const { translate } = useTranslation();
  const { selectedArea: area } = useAreaAndProcessContext();

  const { plant } = useLazyLoadQuery<PlannedCleaningJobsTabQuery>(
    graphql`
      query PlannedCleaningJobsTabQuery($input: QueryPlantInput!) {
        plant(input: $input) {
          ... on QueryPlantSuccess {
            data {
              mouldsForCleaning {
                id
                shotsSinceLastCleaning
                cleaningInterval
                ...PlannedCleaningJobRow_mould
              }
            }
          }
        }
      }
    `,
    { input: { plantNumber: parseInt(area.plantId) } },
  );

  const columnHeaders = [
    ["equipmentNumber", translate("PLANNED_JOBS.HEADERS.EQUIPMENT_NUMBER", "Equipment")],
    ["description", translate("PLANNED_JOBS.HEADERS.DESCRIPTION", "Description")],
    ["statusCode", translate("PLANNED_JOBS.HEADERS.STATUS_CODE", "Status")],
    ["location", translate("PLANNED_JOBS.HEADERS.LOCATION", "Location")],
    ["weight", translate("PLANNED_JOBS.HEADERS.WEIGHT", "Weight")],
    ["type", translate("PLANNED_JOBS.HEADERS.TYPE", "Mould type")],
    ["boxConcept", translate("PLANNED_JOBS.HEADERS.BOX_CONCEPT", "BoxConcept")],
    ["machine", translate("PLANNED_JOBS.HEADERS.MACHINE", "Machine")],
    ["lastCleaningShots", translate("PLANNED_JOBS.HEADERS.LAST_CLEANING_SHOTS", "Last cleaning")],
    ["currentShots", translate("PLANNED_JOBS.HEADERS.CURRENT_SHOTS", "Current no. shot")],
    ["shotsUpdated", translate("PLANNED_JOBS.HEADERS.SHOTS_UPDATED", "Shot updated")],
    ["cleaningInterval", translate("PLANNED_JOBS.HEADERS.CLEANING_INTERVAL", "SYS cleaning interval")],
    ["cleaningDifference", translate("PLANNED_JOBS.HEADERS.CLEANING_DIFFERENCE", "SYS cleaning diff")],
    ["overshot", translate("PLANNED_JOBS.HEADERS.OVERSHOT", "Overshot")],
    ["numberOfInserts", translate("PLANNED_JOBS.HEADERS.NUMBER_OF_INSERTS", "No. of inserts")],
    ["expectedCleaningDate", translate("PLANNED_JOBS.HEADERS.EXPECTED_CLEANING_DATE", "Expected cleaning date")],
    ["ticketNumber", translate("PLANNED_JOBS.HEADERS.TICKET_NUMBER", "Ticket")],
  ];

  const sortByOvershot = (
    left: {
      shotsSinceLastCleaning: number | null | undefined;
      cleaningInterval: number | null | undefined;
    },
    right: {
      shotsSinceLastCleaning: number | null | undefined;
      cleaningInterval: number | null | undefined;
    },
  ) => {
    if (
      hasValue(left.shotsSinceLastCleaning) &&
      hasValue(left.cleaningInterval) &&
      hasValue(right.shotsSinceLastCleaning) &&
      hasValue(right.cleaningInterval)
    ) {
      const leftOvershot = left.shotsSinceLastCleaning - left.cleaningInterval;
      const rightOvershot = right.shotsSinceLastCleaning - right.cleaningInterval;
      return rightOvershot - leftOvershot;
    }
    return 0;
  };

  const jobRows = useMemo(
    () =>
      plant?.data?.mouldsForCleaning
        ?.slice()
        .sort(sortByOvershot)
        .map((mould) => <PlannedCleaningJobRow key={mould?.id} mould={mould} />),
    [plant?.data?.mouldsForCleaning],
  );

  return <PlannedJobsTable columnHeaders={columnHeaders} jobRows={jobRows || []} />;
};

const SkeletonComponent: FC = () => <FillWidthLoading />;

export const PlannedCleaningJobsTab = skeletonify("PlannedCleaningJobsTab", ActualComponent, SkeletonComponent);
