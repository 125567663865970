import { SparePartSearchState } from "./spare-part-search-context";

export type SparePartSearchActions =
  | {
      type: "filterOnStock";
      payload: boolean;
    }
  | {
      type: "setSearchTerm";
      payload: string | null;
    };

export const sparePartSearchReducer = (
  state: SparePartSearchState,
  action: SparePartSearchActions,
): SparePartSearchState => {
  switch (action.type) {
    case "filterOnStock":
      return {
        ...state,
        filterOnStock: action.payload,
      };
    case "setSearchTerm":
      return {
        ...state,
        searchTerm: action.payload,
      };
  }
};
