import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC, useEffect, useState, useTransition } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";

import { EquipmentSearchTextField } from "../components/equipment/EquipmentSearchTextField";
import { LoadingTextField } from "../components/LoadingTextField";
import { useEquipmentValidator } from "../create-ticket/useEquipmentValidator";
import { useQueryParam } from "../create-ticket/useQueryParam";
import { isValidEquipmentId } from "../utils";

import CmsEquipmentSearchQuery, {
  CmsEquipmentSearchQuery as CmsEquipmentSearchQueryType,
} from "./__generated__/CmsEquipmentSearchQuery.graphql";

const ActualComponent: FC<{
  initialEquipmentNumber?: string;
  query: PreloadedQuery<CmsEquipmentSearchQueryType>;
  onChange: (value: string | null) => void;
  onEquipmentNumberChange: (value: number | null) => void;
}> = ({ initialEquipmentNumber: value, query: queryRef, onChange, onEquipmentNumberChange }) => {
  const validator = useEquipmentValidator();

  const { equipment } = usePreloadedQuery<CmsEquipmentSearchQueryType>(
    graphql`
      query CmsEquipmentSearchQuery($input: QueryEquipmentInput!, $skip: Boolean!) {
        equipment(input: $input) @skip(if: $skip) {
          ... on QueryEquipmentSuccess {
            data {
              id
              isActive
              plant {
                plantNumber
              }
            }
          }
        }
      }
    `,
    queryRef,
  );

  useEffect(() => {
    const equipmentId = equipment?.data?.id;
    const equipmentStatus = validator(equipment?.data?.isActive ?? null, equipment?.data?.plant?.plantNumber ?? null);
    if (hasValue(equipmentId) && equipmentStatus && equipmentStatus === "valid") {
      onChange(equipmentId);
    } else {
      onChange(null);
    }
  }, [equipment, onChange, validator]);

  return (
    <EquipmentSearchTextField
      initialEquipmentNumber={value}
      onEquipmentSelected={onEquipmentNumberChange}
      showPrefixLabel
      showPlaceholder={false}
      prefixLabelStyle={{ fontSize: "18px" }}
      variant="search-and-clear"
      inputFieldSize={3}
    />
  );
};

const SkeletonComponent = () => {
  return <LoadingTextField loading />;
};

export const CmsEquipmentSearch: FC<{
  onChange: (value: string | null) => void;
}> = ({ onChange }) => {
  const params = useQueryParam();
  const equipmentId = params.get("equipmentId") ?? undefined;
  const [equipmentNumber, setEquipmentNumber] = useState<number | null>(
    equipmentId ? Number.parseInt(equipmentId) : null,
  );
  const [queryRef, loadQuery] = useQueryLoader<CmsEquipmentSearchQueryType>(CmsEquipmentSearchQuery);
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      loadQuery(
        {
          input: {
            equipmentNumber: equipmentNumber ?? 0,
          },
          skip: !hasValue(equipmentNumber) || !isValidEquipmentId(equipmentNumber.toString()),
        },
        { fetchPolicy: "store-and-network" },
      );
    });
  }, [equipmentNumber, loadQuery]);

  return queryRef ? (
    <ActualComponent
      initialEquipmentNumber={equipmentId}
      query={queryRef}
      onChange={onChange}
      onEquipmentNumberChange={setEquipmentNumber}
    />
  ) : (
    <SkeletonComponent />
  );
};
