import { Button } from "@mui/material";
import { ComponentProps, FC, forwardRef, Fragment } from "react";
import { NavLink } from "react-router";

import { useAuthContext } from "../../contexts/AuthContext";
import { AppRoutes } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { usePermissions } from "../../utility/permissions/usePermissions";

import { QuickLinksMenu } from "./QuickLinksMenu";

const buttonStyle: ComponentProps<typeof Button>["sx"] = {
  color: "white",
  height: "100%",
  textTransform: "none",
  borderRadius: 0,
  "&.active": {
    borderBottom: "2px solid white",
    mt: "4px",
  },
};

export const NavLinks: FC = () => {
  const { translate } = useTranslation();
  const { canViewConfidentialityReport } = usePermissions();
  const { isRepairman, isAdmin, isPlannedJobsListUser } = useAuthContext();

  return (
    <>
      <Button sx={buttonStyle} component={ExactNavLink} to={AppRoutes.root}>
        {translate("NAVBAR.HOME", "Home")}
      </Button>
      {isRepairman && (
        <Button sx={buttonStyle} component={NavLink} to={AppRoutes.tickets.list}>
          {translate("NAVBAR.TICKETS", "Tickets")}
        </Button>
      )}
      <Button sx={buttonStyle} component={NavLink} to={AppRoutes.equipment.search}>
        {translate("NAVBAR.EQUIPMENT", "Equipment")}
      </Button>
      <Button sx={buttonStyle} component={NavLink} to={AppRoutes.sparepart.search}>
        {translate("NAVBAR.SPAREPART", "Spare part")}
      </Button>
      <QuickLinksMenu buttonStyle={buttonStyle} />
      {isAdmin && (
        <Button sx={buttonStyle} component={NavLink} to={AppRoutes.admin}>
          {translate("NAVBAR.ADMIN", "Admin")}
        </Button>
      )}
      {canViewConfidentialityReport && (
        <Button sx={buttonStyle} component={NavLink} to={AppRoutes.imageSearch}>
          {translate("NAVBAR.IMAGE_SEARCH", "Image search")}
        </Button>
      )}
      {isPlannedJobsListUser && (
        <Button sx={buttonStyle} component={NavLink} to={AppRoutes.plannedJobs}>
          {translate("NAVBAR.PLANNED_JOBS", "Planned jobs")}
        </Button>
      )}
    </>
  );
};

const ExactNavLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof NavLink>>(function ExactNavLink(
  { children, ...props },
  ref,
) {
  return (
    <NavLink {...props} ref={ref}>
      {children}
    </NavLink>
  );
});
