// TODO: Generate file with enums from schema.graphql
import { TicketPriority } from "../../migration/components/ticket/__generated__/PriorityPill_ticket.graphql";
import { TicketFlowType } from "../permissions/__generated__/useTicketPermissions_canAddErrorDescription_ticket.graphql";

export const CORRECTIVE_PRIORITIES: TicketPriority[] = ["Priority1", "Priority2", "Priority3"];
export const OBSERVATIONS_PRIORITIES: TicketPriority[] = ["Priority5"];
export const PREVENTIVE_PRIORITIES: TicketPriority[] = [
  "Priority4",
  "Priority6",
  "Priority7",
  "Priority8",
  "Priority9",
  "PriorityA",
];

export const YELLOW_FLOW_TYPES: TicketFlowType[] = [
  "YellowFunctionalTest",
  "YellowRepair",
  "YellowToMPEE",
  "YellowToProduction",
  "YellowToProductionRejected",
  "YellowToQA",
  "YellowToQAApproved",
];
