import { gql } from "@apollo/client";
import { Grid2, Typography } from "@mui/material";
import { FC, useEffect } from "react";

import { UpdateLocationFragment } from "../../__apollo__/graphql";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { useTranslation } from "../../utility/i18n/translation";
import { EQUIPMENT_LOCATION_FRAGMENT, EquipmentLocationSection } from "../shared/EquipmentLocationSection";
import { isEquipmentSublocationUpdateValid } from "../ticket-details/TDUpdateLocationDialog";

export const UPDATE_LOCATION_FRAGMENT = gql`
  fragment UpdateLocation on Ticket {
    id
    equipment {
      ... on EquipmentValue {
        value {
          id
          ...EquipmentLocation
        }
      }
    }
  }
  ${EQUIPMENT_LOCATION_FRAGMENT}
`;

export const CTUpdateLocation: FC<UpdateLocationFragment> = (props) => {
  const { translate } = useTranslation();

  if (!props.equipment || props.equipment.__typename !== "EquipmentValue") {
    return (
      <Typography color="error">{translate("CLOSE_TICKET.LOCATION_STEP.ERROR", "Could not find equipment")}</Typography>
    );
  }

  return <Checked {...props.equipment.value} />;
};

const Checked: FC<Extract<UpdateLocationFragment["equipment"], { __typename?: "EquipmentValue" }>["value"]> = (
  data,
) => {
  const { translate } = useTranslation();

  const {
    state: {
      location: { newSublocationText, newLocationId, newSublocationId, sublocationDirty },
    },
    dispatch,
  } = useCloseTicketContext();

  useEffect(() => {
    const valid = isEquipmentSublocationUpdateValid({
      isSublocationMandatory: data.sublocationMetaInfo.isSublocationMandatory,
      sublocationData: data.subLocation,
      sublocationDirty: sublocationDirty ?? false,
      newSublocationText,
    });
    dispatch({ type: "setSublocationInputValid", valid });
  }, [
    data.subLocation,
    data.sublocationMetaInfo.isSublocationMandatory,
    dispatch,
    newSublocationText,
    sublocationDirty,
  ]);

  const onLocationSelected = (newLocationId: string) => {
    dispatch({ type: "chooseNewLocation", locationId: newLocationId });
  };

  const onSublocationSelected = (newSublocation: string) => {
    dispatch({ type: "chooseNewSublocation", sublocationId: newSublocation });
  };

  const onSublocationFreeTextUpdated = (newText: string) => {
    dispatch({ type: "setNewSublocationText", sublocationText: newText });
  };

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography variant="subtitle2">{translate("CLOSE_TICKET.LOCATION_STEP.CARD_HEADER", "Location")}</Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {translate("CLOSE_TICKET.LOCATION_STEP.QUESTION", "Is the location of the equipment correct?")}
        </Typography>
      </Grid2>
      <Grid2>
        <EquipmentLocationSection
          {...data}
          onLocationSelected={onLocationSelected}
          onSublocationSelected={onSublocationSelected}
          onSublocationTextUpdated={onSublocationFreeTextUpdated}
          initialSublocationText={newSublocationText}
          initialLocationId={newLocationId}
          initialSublocationId={newSublocationId}
        />
      </Grid2>
    </Grid2>
  );
};
