import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState, MouseEvent, ReactElement, FC, Fragment, useCallback } from "react";

import { useTranslation } from "../../utility/i18n/translation";

export const QuickLinksMenu: FC<{ buttonStyle: SxProps<Theme> }> = ({ buttonStyle }) => {
  const { translate } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button sx={buttonStyle} onClick={handleClick}>
        {translate("NAVBAR.QUICK_LINKS.BUTTON", "Quick Links")}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StyledLink linkTo="https://newmat.engineering.corp.lego.com/">
          <MenuItem onClick={handleClose}>
            {translate("NAVBAR.QUICK_LINKS.MATERIAL_CREATION", "Material Creation")}
          </MenuItem>
        </StyledLink>
        <StyledLink linkTo="https://go.legogroup.io/ManageEquipments">
          <MenuItem onClick={handleClose}>
            {translate("NAVBAR.QUICK_LINKS.EQUIPMENT_CREATION", "Equipment creation/scrap/move")}
          </MenuItem>
        </StyledLink>
        <StyledLink linkTo="https://dkatcpp-t1.corp.lego.com/documents/">
          <MenuItem onClick={handleClose}>{translate("NAVBAR.QUICK_LINKS.DocMan", "DocMan")}</MenuItem>
        </StyledLink>
      </Menu>
    </>
  );
};

const StyledLink: FC<{ linkTo: string; children: ReactElement }> = ({ children, linkTo }) => {
  return (
    <a target="_blank" href={linkTo} rel="noreferrer" style={{ color: "inherit", textDecoration: "inherit" }}>
      {children}
    </a>
  );
};
