import { hasValue } from "@lego/mst-error-utilities";
import { FormControlLabel, Grid2, Radio, RadioGroup, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { ChangeEvent, FC, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";

import { CloseMouldTicketFlow } from "../../__apollo__/graphql";
import { useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { skeletonify } from "../../migration/skeleton";
import { useTranslation } from "../../utility/i18n/translation";
import { useBannerContext } from "../shared/banner/banner-context";

import CTChooseFlowQuery, {
  CTChooseFlowQuery as CTChooseFlowQueryType,
  TicketAllowedCloseType,
} from "./__generated__/CTChooseFlowQuery.graphql";

export const CTChooseFlow: FC<{ ticketNumber: number | null }> = ({ ticketNumber }) => {
  const { translate } = useTranslation();

  const [queryRef, loadQuery] = useQueryLoader<CTChooseFlowQueryType>(CTChooseFlowQuery);

  useEffect(() => {
    if (hasValue(ticketNumber)) {
      loadQuery(
        {
          input: { ticketNumber },
        },
        { fetchPolicy: "store-and-network" },
      );
    }
  }, [loadQuery, ticketNumber]);

  if (ticketNumber === null) {
    return <Typography>{translate("CLOSE_TICKET.FLOW_STEP.MISSING_ORDER_ID", "No Order id found")}</Typography>;
  }

  return (
    <Grid2 container direction="column" spacing={4}>
      <Grid2>
        <Typography>{translate("CLOSE_TICKET.FLOW_STEP.SUMMARY", "You are now ready to close the ticket")}</Typography>
      </Grid2>
      <Grid2>
        <Typography>{translate("CLOSE_TICKET.FLOW_STEP.CHOOSE_FLOW", "Please choose the appropriate flow")}</Typography>
      </Grid2>
      <Grid2 style={{ marginLeft: 40 }}>
        {queryRef ? <TicketCloseFlowOptions.Suspense query={queryRef} /> : <TicketCloseFlowOptions.Skeleton />}
      </Grid2>
    </Grid2>
  );
};

const ActualComponent: FC<{ query: PreloadedQuery<CTChooseFlowQueryType> }> = (props) => {
  const { query: queryRef } = props;

  const { translate } = useTranslation();
  const {
    dispatch,
    state: { flowType },
  } = useCloseTicketContext();

  const { dispatch: bannerDispatch } = useBannerContext();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "setFlowType",
      variant: event.target.value as CloseMouldTicketFlow,
    });
  };

  const getFlowValue = (key: TicketAllowedCloseType): CloseMouldTicketFlow | undefined => {
    switch (key) {
      case "Green":
        return CloseMouldTicketFlow.Green;
      case "YellowToProduction":
        return CloseMouldTicketFlow.YellowToProduction;
      case "YellowToMPEE":
        return CloseMouldTicketFlow.YellowToMpee;
    }
  };

  const getFlowLabel = (key: TicketAllowedCloseType): string => {
    switch (key) {
      case "Green":
        return translate("CLOSE_TICKET.FLOW_STEP.FLOW_OPTIONS_GREEN", "Close green");
      case "YellowToProduction":
        return translate("CLOSE_TICKET.FLOW_STEP.FLOW_OPTIONS_YELLOW", "Close yellow to production");
      case "YellowToMPEE":
        return translate("CLOSE_TICKET.FLOW_STEP.FLOW_OPTIONS_MPEE", "Close yellow to MPEE");
      default:
        return key;
    }
  };

  const { ticket } = usePreloadedQuery(
    graphql`
      query CTChooseFlowQuery($input: QueryTicketInput!) {
        ticket(input: $input) {
          ... on QueryTicketSuccess {
            data {
              ... on MouldTicket {
                allowedCloseType
                hasOtherTicketsInYellowFlow
              }
            }
          }
        }
      }
    `,
    queryRef ?? null,
  );

  const bannerWarning = translate(
    "CLOSE_TICKET.FLOW_STEP.ALREADY_HAS_APPROVAL_IN_QA_WARNING",
    'This mould already has a sample waiting for approval in QA. Until that approval has happened, only "Close green" is allowed',
  );
  useEffect(() => {
    if (ticket.data?.hasOtherTicketsInYellowFlow) {
      bannerDispatch({
        type: "show",
        payload: { text: bannerWarning, type: "warning", innerBanner: true },
      });
    }
  }, [bannerDispatch, bannerWarning, ticket.data?.hasOtherTicketsInYellowFlow]);

  return (
    <RadioGroup onChange={onChange} value={flowType}>
      {ticket?.data?.allowedCloseType?.map((type) => (
        <FormControlLabel key={type} value={getFlowValue(type)} control={<Radio />} label={getFlowLabel(type)} />
      ))}
    </RadioGroup>
  );
};

const SkeletonComponent = () => <Skeleton width={160} height={44} variant="text" />;

const TicketCloseFlowOptions = skeletonify("TicketCloseFlowOptions", ActualComponent, SkeletonComponent);
