import { Autocomplete, Skeleton, TextField } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";
import { fuzzySearch } from "../utils";

import {
  PrinterAutocompleteQuery,
  PrinterAutocompleteQuery$data,
} from "./__generated__/PrinterAutocompleteQuery.graphql";

type Printer = NonNullable<PrinterAutocompleteQuery$data["printers"]>[0];

export const ActualComponent: FC<{
  onPrinterSelected: (printerId: string) => void;
  disabled?: boolean;
}> = ({ onPrinterSelected, disabled }) => {
  const { translate } = useTranslation();
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | undefined>(undefined);
  const { printers } = useLazyLoadQuery<PrinterAutocompleteQuery>(
    graphql`
      query PrinterAutocompleteQuery {
        printers {
          id
          description
        }
      }
    `,
    {},
  );

  return (
    <Autocomplete<Printer>
      onChange={(_, value) => {
        if (value) {
          setSelectedPrinter(value);
          onPrinterSelected(value.id);
        }
      }}
      filterOptions={(options, state) => {
        return fuzzySearch(state.inputValue, options, ["description", "id"]);
      }}
      loading={disabled}
      value={selectedPrinter ?? null}
      getOptionLabel={({ description, id }) => `${description} (${id})`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={printers?.length === 0}
      options={printers ?? []}
      renderInput={(params) => (
        <TextField {...params} label={translate("PRINTER_AUTOCOMPLETE_PLACEHOLDER", "Choose a printer...")} />
      )}
    />
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={"90%"} />;
};

export const PrinterAutocomplete = skeletonify("PrinterAutocomplete", ActualComponent, SkeletonComponent);
