import { Button, Grid2 } from "@mui/material";
import { FC } from "react";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { useAuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "../../utility/i18n/translation";

interface Props {
  submit: (assign: boolean) => void;
  loading: boolean;
  disabled?: boolean;
}

export const CmsTicketSubmitButtons: FC<Props> = ({ submit, loading, disabled }) => {
  const { translate } = useTranslation();
  const { isRepairman } = useAuthContext();

  const handleSubmit = () => {
    submit(false);
  };

  const handleSubmitWithAssign = () => {
    submit(true);
  };

  return (
    <Grid2 container alignItems="center" justifyContent="end">
      {loading && (
        <Grid2 mr={2}>
          <ActivityIndicator />
        </Grid2>
      )}
      {isRepairman && (
        <Grid2 mr={2}>
          <Button
            disabled={loading || disabled}
            size="large"
            color="primary"
            variant="outlined"
            onClick={handleSubmitWithAssign}
            sx={{
              borderWidth: "2px",
              "&:hover": { borderWidth: "2px" },
              "&:disabled": { borderWidth: "2px" },
            }}
          >
            {translate("CREATE_TICKET.CREATE_ASSIGN", "Create and assign to me")}
          </Button>
        </Grid2>
      )}
      <Grid2>
        <Button disabled={loading || disabled} size="large" color="primary" variant="contained" onClick={handleSubmit}>
          {translate("CREATE_TICKET.CREATE", "Create")}
        </Button>
      </Grid2>
    </Grid2>
  );
};
