import { Card, CardActionArea, Divider, Grid2, Skeleton, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";
import { NavLink } from "react-router";

import { AreaWithFlag } from "../../components/shared/AreaWithFlag";
import { AppRoutes } from "../../Router";
import { EquipmentLocation } from "../components/equipment/EquipmentLocation";
import { MouldCavitiesWithDimmings } from "../components/mould/MouldCavitiesWithDimmings";
import { MouldConstructionNumber } from "../components/mould/MouldConstructionNumber";
import { MouldCurrentProductionEnd } from "../components/mould/MouldCurrentProductionEnd";
import { MouldNextProductionStart } from "../components/mould/MouldNextProductionStart";
import { MouldStandardPartsPerHour } from "../components/mould/MouldStandardPartsPerHour";
import { MouldStatusChip } from "../components/mould/MouldStatusChip";
import { MouldTonSize } from "../components/mould/MouldTonSize";
import { MouldType } from "../components/mould/MouldType";
import { MouldWeight } from "../components/mould/MouldWeight";
import { skeletonify } from "../skeleton";

import { SisterMouldCard_mould$key } from "./__generated__/SisterMouldCard_mould.graphql";
import { SisterMouldSpringChangeRow } from "./SisterMouldSpringChangeRow";
import { SisterMouldSysCleaningRow } from "./SisterMouldSysCleaningRow";

const ActualComponent: FC<{
  mould: SisterMouldCard_mould$key;
  isHighlighted?: boolean;
}> = ({ mould: mouldRef, isHighlighted = false }) => {
  const borderColor = useTheme().palette.secondary.main;
  const data = useFragment(
    graphql`
      fragment SisterMouldCard_mould on Mould {
        ...EquipmentLocation_equipment
        ...MouldCavitiesWithDimmings_mould
        ...MouldConstructionNumber_mould
        ...MouldCurrentProductionEnd_mould
        ...MouldNextProductionStart_mould
        ...MouldStandardPartsPerHour_mould
        ...MouldStatusChip_mould
        ...MouldTonSize_mould
        ...MouldType_mould
        ...MouldWeight_mould
        ...SisterMouldSysCleaningRow_cleaning
        ...SisterMouldSpringChangeRow_springChange
        plant {
          ...AreaWithFlag_plant
        }
        equipmentNumber
      }
    `,
    mouldRef,
  );

  const routeTo = AppRoutes.equipment.byId(data.equipmentNumber.toString(), "details");

  return (
    <Card
      sx={{
        p: 0,
        ...(isHighlighted && {
          border: "solid 2px",
          borderColor: borderColor,
        }),
      }}
    >
      <CardActionArea component={NavLink} to={routeTo} sx={{ p: 2 }}>
        <Grid2 container direction="column" spacing={1}>
          <Grid2 container direction="row" spacing={3}>
            <Grid2>
              <Typography sx={{ fontWeight: "bold" }}>{data.equipmentNumber}</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <MouldStatusChip mould={data} />
            </Grid2>
            <Grid2 container justifyContent="flex-end" size={{ sm: "grow" }}>
              <Grid2>
                <AreaWithFlag plant={data.plant} />
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 container direction="row" spacing={2}>
            <Grid2 container direction="column" size={{ xs: 2 }} spacing={2}>
              <Grid2>
                <MouldConstructionNumber mould={data} />
              </Grid2>
              <Grid2>
                <MouldWeight mould={data} />
              </Grid2>
            </Grid2>

            <Grid2 container direction="column" size={{ xs: 2.5 }} spacing={2}>
              <Grid2>
                <MouldType mould={data} />
              </Grid2>
              <Grid2>
                <MouldTonSize mould={data} />
              </Grid2>
            </Grid2>

            <Grid2 container direction="column" size={{ xs: 2 }} spacing={2}>
              <Grid2>
                <MouldStandardPartsPerHour mould={data} />
              </Grid2>
              <Grid2>
                <MouldCavitiesWithDimmings mould={data} />
              </Grid2>
            </Grid2>

            <Grid2 container direction="column" size={{ xs: 3 }} spacing={2}>
              <Grid2>
                <MouldCurrentProductionEnd mould={data} />
              </Grid2>
              <Grid2>
                <MouldNextProductionStart mould={data} />
              </Grid2>
            </Grid2>

            <Grid2 container direction="column" size={{ sm: "grow" }} justifyContent="center">
              <Grid2>
                <EquipmentLocation equipment={data} />
              </Grid2>
            </Grid2>
          </Grid2>

          <Divider sx={{ mt: 2 }} />

          <Grid2 container direction="column" spacing={2}>
            <Grid2>
              <SisterMouldSysCleaningRow mould={data} />
            </Grid2>
            <Grid2>
              <SisterMouldSpringChangeRow mould={data} />
            </Grid2>
          </Grid2>
        </Grid2>
      </CardActionArea>
    </Card>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={100} />;
};

export const SisterMouldCard = skeletonify("SisterMouldCard", ActualComponent, SkeletonComponent);
