import { Grid2 } from "@mui/material";
import { FC, Suspense, useState } from "react";

import { FillWidthLoading } from "../components/shared/FillWidthLoading";
import { useAreaAndProcessContext } from "../contexts/area";
import { CountAndSearch } from "../migration/components/CountAndSearch";
import { ProcessSelect } from "../migration/sublocation-list/ProcessSelect";
import { SublocationList } from "../migration/sublocation-list/SublocationList";

export const AdminContainer: FC = () => {
  const [searchString, setSearchString] = useState("");
  const [relayProcessId, setRelayProcessId] = useState(useAreaAndProcessContext().relayProcessId || "");

  return (
    <Grid2 container direction="column" height="100%">
      <Grid2 container direction="row" justifyContent="center" sx={{ mt: 2 }}>
        <Grid2 container size={{ xs: 7, md: 6 }} direction="row" justifyContent="space-around">
          <Grid2 size={{ xs: 4 }}>
            <ProcessSelect.Suspense value={relayProcessId} onProcessSelected={setRelayProcessId} />
          </Grid2>
          <Grid2 size={{ xs: 8 }}>
            <CountAndSearch onChange={setSearchString} />
          </Grid2>
        </Grid2>
      </Grid2>
      <Suspense fallback={<FillWidthLoading />}>
        <Grid2
          container
          direction="row"
          justifyContent="center"
          className="hide-scrollbar"
          sx={{ my: 2, overflow: "auto", flex: 1 }}
        >
          <Grid2 size={{ xs: 7, md: 6 }}>
            <SublocationList searchString={searchString} relayProcessId={relayProcessId} />
          </Grid2>
        </Grid2>
      </Suspense>
    </Grid2>
  );
};
