import { hasValue } from "@lego/mst-error-utilities";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Grid2, styled, TextField, TextFieldProps, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { FC, ReactElement, useCallback } from "react";

import { AreaQuery, ProcessQuery, ProcessQueryVariables } from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { useConfidentialitySearchContext } from "../../contexts/confidentiality-search/confidentiality-search-context";
import { useTranslation } from "../../utility/i18n/translation";
import { Area, AREA_ALL_OPTION } from "../profile/Area";
import { AREA_SELECTOR_QUERY, PROCESS_SELECTOR_QUERY } from "../profile/AreaAndProcess";
import { Process } from "../profile/Process";
import { GMDatePicker, InputTitle } from "../shared/GMDatePicker";

const minDate = new Date(2021, 1, 1);

const FilterContainerGrid: FC<{ children: ReactElement }> = ({ children }) => {
  return <Grid2 size={{ xs: 12, md: 6, lg: 3 }}>{children}</Grid2>;
};

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
  },
}));

type ImageSearchComponentProps = {
  onSearchPressed: () => void;
};

export const ImageSearchComponent: FC<ImageSearchComponentProps> = ({ onSearchPressed }) => {
  const { translate } = useTranslation();
  const {
    state: { createdFrom, createdTo, selectedAreaId, today },
    dispatch,
  } = useConfidentialitySearchContext();

  const handleCreatedFromChanged = (newValue: Date | null) => {
    dispatch({ type: "setCreatedFrom", date: newValue ? startOfDay(newValue) : null });
  };

  const handleCreatedToChanged = (newValue: Date | null) => {
    dispatch({ type: "setCreatedTo", date: newValue ? endOfDay(newValue) : null });
  };

  const { processesError } = useProcessQuery(selectedAreaId);

  const { areaError, areas } = useAreaQuery();

  if (!areas || hasValue(areaError) || hasValue(processesError)) {
    return <Errors />;
  }

  return (
    <Card style={{ marginTop: 40, padding: 50 }}>
      <Grid2 container spacing={4}>
        <Grid2>
          <Typography variant="subtitle1">
            {translate("IMAGE_SEARCH.SEARCH_FILTERS.TITLE", "What are you looking for?")}
          </Typography>
        </Grid2>
        <Grid2 container direction="row" spacing={4}>
          <FilterContainerGrid>
            <AreaSelector />
          </FilterContainerGrid>
          <FilterContainerGrid>
            <ProcessSelector />
          </FilterContainerGrid>
          <FilterContainerGrid>
            <GMDatePicker
              title={translate("IMAGE_SEARCH.SEARCH_FILTERS.CREATED_FROM", "Created date from")}
              value={createdFrom}
              onChange={handleCreatedFromChanged}
              minDate={minDate}
              maxDate={hasValue(createdTo) ? createdTo : today}
            />
          </FilterContainerGrid>
          <FilterContainerGrid>
            <GMDatePicker
              title={translate("IMAGE_SEARCH.SEARCH_FILTERS.CREATED_TO", "Created date to")}
              value={createdTo}
              onChange={handleCreatedToChanged}
              minDate={hasValue(createdFrom) ? createdFrom : minDate}
              maxDate={today}
            />
          </FilterContainerGrid>
          <FilterContainerGrid>
            <UsernameInput />
          </FilterContainerGrid>
          <SearchButtons onSearchPressed={onSearchPressed} />
        </Grid2>
      </Grid2>
    </Card>
  );
};

const SearchButtons: FC<ImageSearchComponentProps> = ({ onSearchPressed }) => {
  const { translate } = useTranslation();
  const { dispatch } = useConfidentialitySearchContext();

  const onClearButtonPressed = useCallback(() => {
    dispatch({ type: "clearSearch" });
  }, [dispatch]);

  const onSearchButtonPressed = useCallback(() => {
    onSearchPressed();
  }, [onSearchPressed]);

  return (
    <Grid2 style={{ alignSelf: "flex-end" }} size={{ xs: 12, md: 6, lg: 9 }}>
      <Grid2 container justifyContent="flex-end">
        <Grid2>
          <StyledButton variant="text" sx={{ p: 2 }} onClick={onClearButtonPressed}>
            {translate("IMAGE_SEARCH.SEARCH_FILTERS.CLEAR_BUTTON", "Clear")}
          </StyledButton>
        </Grid2>
        <Grid2>
          <StyledButton variant="contained" startIcon={<SearchIcon />} sx={{ p: 2 }} onClick={onSearchButtonPressed}>
            {translate("IMAGE_SEARCH.SEARCH_FILTERS.SEARCH_BUTTON", "Search")}
          </StyledButton>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const UsernameInput: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { usernameSearchTerm },
    dispatch,
  } = useConfidentialitySearchContext();

  const onChange: TextFieldProps["onChange"] = (event) => {
    dispatch({ type: "setUsernameSearchTerm", term: event.target.value });
  };

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <InputTitle title={translate("IMAGE_SEARCH.SEARCH_FILTERS.USERNAME_SEARCH_LABEL", "User name (watermark)")} />
      </Grid2>
      <Grid2>
        <TextField
          style={{ width: "100%" }}
          onChange={onChange}
          value={usernameSearchTerm}
          placeholder={translate(
            "IMAGE_SEARCH.SEARCH_FILTERS.USERNAME_SEARCH_PLACEHOLDER",
            "Enter (part of) user name",
          )}
        />
      </Grid2>
    </Grid2>
  );
};

const ProcessSelector: FC = () => {
  const {
    state: { selectedAreaId, selectedProcessId },
    dispatch,
  } = useConfidentialitySearchContext();
  const { processesLoading, processes } = useProcessQuery(selectedAreaId);
  const { translate } = useTranslation();

  const onProcessChosen = useCallback(
    (processId: string) => {
      dispatch({ type: "setSelectedProcessId", id: processId });
    },
    [dispatch],
  );

  return (
    <Process
      customTitle={translate("IMAGE_SEARCH.SEARCH_FILTERS.PROCESS", "Process area")}
      loading={processesLoading}
      processes={processes?.allProcesses ?? []}
      onProcessChosen={onProcessChosen}
      currentlySelectedProcessId={selectedProcessId}
      disabled={!hasValue(selectedAreaId) || !hasValue(processes) || processes.allProcesses.length === 0}
      showOptionForAll
    />
  );
};

const AreaSelector: FC = () => {
  const {
    state: { selectedAreaId },
    dispatch,
  } = useConfidentialitySearchContext();

  const { areas, areasLoading } = useAreaQuery();
  const { translate } = useTranslation();

  const onAreaChosen = useCallback(
    (areaId: string) => {
      dispatch({ type: "setSelectedAreaId", id: areaId });
    },
    [dispatch],
  );

  return (
    <Area
      customTitle={translate("IMAGE_SEARCH.SEARCH_FILTERS.PLANT", "Plant")}
      loading={areasLoading}
      areas={areas?.allAreas ?? []}
      onAreaChosen={onAreaChosen}
      currentlySelectedAreaId={selectedAreaId}
      showOptionForAll
    />
  );
};

const Errors: FC = () => {
  const { translate } = useTranslation();
  return (
    <Card style={{ marginTop: 40, padding: 50 }}>
      <Grid2 container>
        <Grid2>
          <Typography variant="subtitle1">
            {translate("IMAGE_SEARCH.SEARCH_FILTERS.AREA_ERROR", "Could not fetch areas, please refresh and try again")}
          </Typography>
        </Grid2>
      </Grid2>
    </Card>
  );
};

const useAreaQuery = () => {
  const { data: areas, loading: areasLoading, error: areaError } = useGMQuery<AreaQuery>(AREA_SELECTOR_QUERY);

  return {
    areasLoading,
    areas,
    areaError,
  };
};

const useProcessQuery = (selectedAreaId: string) => {
  const {
    data: processes,
    loading: processesLoading,
    error: processesError,
  } = useGMQuery<ProcessQuery, ProcessQueryVariables>(PROCESS_SELECTOR_QUERY, {
    variables: { input: { areaId: selectedAreaId } },
    skip: selectedAreaId.length === 0 || selectedAreaId === AREA_ALL_OPTION,
  });

  return {
    processes,
    processesLoading,
    processesError,
  };
};
