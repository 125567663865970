import { styled, ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material";

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  backgroundColor: theme.palette.background.default,
  color: "black",
}));

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  ".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));
