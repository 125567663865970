import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";
import { calculatePercentage } from "../utils";

import { SisterMouldPreventiveMaintenance } from "./SisterMouldPreventiveMaintenance";
import { SisterMouldSpringChangeRow_springChange$key } from "./__generated__/SisterMouldSpringChangeRow_springChange.graphql";

const ActualComponent: FC<{
  mould: SisterMouldSpringChangeRow_springChange$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { shotsSinceLastSpringChange, springChangeInterval } = useFragment(
    graphql`
      fragment SisterMouldSpringChangeRow_springChange on Mould {
        shotsSinceLastSpringChange
        springChangeInterval
      }
    `,
    mouldRef,
  );

  const springChangePercentage = useMemo(() => {
    if (hasValue(springChangeInterval) && hasValue(shotsSinceLastSpringChange)) {
      return calculatePercentage(springChangeInterval, shotsSinceLastSpringChange);
    }
    return 0;
  }, [shotsSinceLastSpringChange, springChangeInterval]);

  if (!hasValue(springChangeInterval) || springChangeInterval === 0) {
    return null;
  }

  return (
    <Grid2 container direction="row">
      <SisterMouldPreventiveMaintenance
        title={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.SPRING_CHANGE", "Spring change")}
        interval={springChangeInterval}
        percentage={springChangePercentage}
      />
    </Grid2>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const SisterMouldSpringChangeRow = skeletonify("SisterMouldSpringChangeRow", ActualComponent, SkeletonComponent);
