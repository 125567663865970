import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { SisterMouldPreventiveMaintenance } from "./SisterMouldPreventiveMaintenance";
import { SisterMouldSysCleaningRow_cleaning$key } from "./__generated__/SisterMouldSysCleaningRow_cleaning.graphql";

const ActualComponent: FC<{
  mould: SisterMouldSysCleaningRow_cleaning$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { cleaningPercentage, cleaningInterval } = useFragment(
    graphql`
      fragment SisterMouldSysCleaningRow_cleaning on Mould {
        cleaningPercentage
        cleaningInterval
      }
    `,
    mouldRef,
  );

  if (!hasValue(cleaningInterval) || cleaningInterval === 0) {
    return null;
  }

  return (
    <Grid2 container direction="row" maxHeight={60}>
      {/* Max height is a hack to prevent blank spaces from flex layout black magic */}
      <SisterMouldPreventiveMaintenance
        title={translate("TICKET_DETAILS.SISTER_MOULDS_TAB.PREVENTIVE.SYS_CLEANING", "Sys. cleaning")}
        interval={cleaningInterval}
        percentage={cleaningPercentage ?? 0}
      />
    </Grid2>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const SisterMouldSysCleaningRow = skeletonify("SisterMouldSysCleaningRow", ActualComponent, SkeletonComponent);
