import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { Avatar, Grid2, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement, useCallback, useMemo } from "react";
import { useFragment, useMutation } from "react-relay";

import { ActivityIndicator } from "../../../components/shared/ActivityIndicator";
import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { DocumentRow_document$key, FileType } from "./__generated__/DocumentRow_document.graphql";
import { DocumentRow_getDocumentPresignedURLMutation } from "./__generated__/DocumentRow_getDocumentPresignedURLMutation.graphql";

const ActualComponent: FC<{ document: DocumentRow_document$key }> = (props) => {
  const { document: documentRef } = props;
  const data = useFragment(
    graphql`
      fragment DocumentRow_document on Document {
        id
        description
        type
        link
        name
      }
    `,
    documentRef,
  );

  const [commit, isInFlight] = useMutation<DocumentRow_getDocumentPresignedURLMutation>(graphql`
    mutation DocumentRow_getDocumentPresignedURLMutation($input: MutationCreateDocumentPresignedUrlInput!) {
      createDocumentPresignedUrl(input: $input)
    }
  `);

  const openLinkInNewTab = useCallback((link: string) => {
    const newWindow = window.open(link, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }, []);

  const handleDownloadPressed = useCallback(() => {
    if (data.type === "Html" && data.link) {
      openLinkInNewTab(data.link);
    } else {
      commit({
        variables: { input: { documentId: data.id } },
        onCompleted: (response) => {
          if (!response.createDocumentPresignedUrl) {
            return;
          }

          openLinkInNewTab(response.createDocumentPresignedUrl);
        },
      });
    }
  }, [commit, data.id, data.link, data.type, openLinkInNewTab]);

  const openDocumentIcon: ReactElement = useMemo(() => {
    if (data.type === "Html") {
      return <OpenInNewIcon />;
    }
    return isInFlight ? <ActivityIndicator /> : <DownloadIcon />;
  }, [data.type, isInFlight]);

  const fileInfo = useFileInfo(data.type);

  return (
    <ListItem
      data-cy={`DocumentRow:${data.id}`}
      secondaryAction={
        <IconButton
          data-cy={`DocumentRow-actionButton:${data.id}`}
          edge="end"
          size="large"
          onClick={handleDownloadPressed}
        >
          {openDocumentIcon}
        </IconButton>
      }
      divider
    >
      <ListItemAvatar>
        <Avatar>{fileInfo.icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={data.name} secondary={`${data.description} - ${fileInfo.fileLabel}`} />
    </ListItem>
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" alignItems="center" spacing={2} style={{ marginBottom: 48 }}>
    <Grid2 size={{ xs: "grow" }}>
      <Typography variant="h1">
        <Skeleton variant="text" width={660} />
      </Typography>
    </Grid2>
  </Grid2>
);

export const DocumentRow = skeletonify("DocumentRow", ActualComponent, SkeletonComponent);

const useFileInfo = (type: FileType) => {
  const { translate } = useTranslation();

  switch (type) {
    case "Pdf":
      return {
        icon: <PictureAsPdfIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.PDF", "PDF"),
      };
    case "Excel":
      return {
        icon: <FormatListNumberedIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.EXCEL", "Excel"),
      };
    case "Html":
      return {
        icon: <LinkIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.LINK", "Link"),
      };

    case "Image":
      return {
        icon: <ImageIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.IMAGE", "Image"),
      };

    case "Mail":
      return {
        icon: <EmailIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.MAIL", "Mail"),
      };

    case "Movie":
      return {
        icon: <LocalMoviesIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.MOVIE", "Movie"),
      };

    case "PowerPoint":
      return {
        icon: <SlideshowIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.POWERPOINT", "PowerPoint"),
      };

    case "Txt":
      return {
        icon: <FormatColorTextIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.TXT", "TXT"),
      };

    case "Word":
      return {
        icon: <FormatColorTextIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.WORD", "Word"),
      };
    case "Zip":
      return {
        icon: <FolderZipIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.Zip", "Zip"),
      };
    case "Unknown":
    default:
      return {
        icon: <HelpCenterIcon />,
        fileLabel: translate("DOCUMENT_LIST.FILE_TYPES.UNKNOWN", "Unknown"),
      };
  }
};
