import { gql } from "@apollo/client";
import { Card, Container, Divider, Grid2, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";

import {
  GetDataForRepairDescriptionFlowQuery,
  GetDataForRepairDescriptionFlowQueryVariables,
} from "../__apollo__/graphql";
import { useGMQuery } from "../apollo/customApolloHooks";
import { ARDHeader } from "../components/add-repair-documentation/ARDHeader";
import { ARDStepper } from "../components/add-repair-documentation/ARDStepper";
import { ARDSidebar, ARD_SIDEBAR_FRAGMENT } from "../components/add-repair-documentation/sidebar-widgets/ARDSidebar";
import { TDHeader } from "../components/ticket-details/TDHeader";
import { AddRepairDescriptionProvider } from "../contexts/add-repair-description/add-repair-description-context";
import { usePrefetchRepairDescriptionData } from "../contexts/add-repair-description/add-repair-description-gql-queries";
import { RouteRequiringPermission } from "../Router";
import { PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION } from "../utility/permissions/ticket";
import { usePermissions } from "../utility/permissions/usePermissions";

import { useTicketQueryTypeCheck } from "./TicketByIdTypecheckHook";

export const ADD_REPAIR_FLOW_QUERY = gql`
  query GetDataForRepairDescriptionFlow($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...AddRepairDocumentationSidebar
        ...CanAddRepairDescription
        repairDocumentation {
          id
          repairFlowVariant
        }
      }
    }
  }
  ${ARD_SIDEBAR_FRAGMENT}
  ${PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION}
`;

export const AddRepairDocumentation: FC = () => {
  const { id } = useParams() as { id: string };

  const { data, loading: headerAndSidebarLoading } = useGMQuery<
    GetDataForRepairDescriptionFlowQuery,
    GetDataForRepairDescriptionFlowQueryVariables
  >(ADD_REPAIR_FLOW_QUERY, {
    variables: { input: { id } },
  });

  const {
    ticket: { canAddRepairDescription },
  } = usePermissions();

  const checked = useTicketQueryTypeCheck<
    Extract<GetDataForRepairDescriptionFlowQuery["ticket"], { __typename?: "Ticket" }>
  >({
    data,
    loading: headerAndSidebarLoading,
  });

  if (checked.type === "errorOrLoadingComponent") {
    return checked.component;
  }

  const allowed = canAddRepairDescription(checked.data);

  return (
    <RouteRequiringPermission allowed={allowed}>
      <AddRepairDescriptionProvider>
        <TDHeader {...checked.data} showFab={false} />
        <InnerComponent data={checked.data} />
      </AddRepairDescriptionProvider>
    </RouteRequiringPermission>
  );
};

const InnerComponent: FC<{
  data: Extract<GetDataForRepairDescriptionFlowQuery["ticket"], { __typename?: "Ticket" }>;
}> = ({ data }) => {
  const theme = useTheme();
  const sideBySide = useMediaQuery(theme.breakpoints.up("lg"));
  usePrefetchRepairDescriptionData(data.id);

  return (
    <Container
      maxWidth={false}
      style={{
        overflow: "auto",
        width: "100%",
        display: "flex",
        marginTop: 110,
      }}
    >
      <Grid2
        container
        direction={sideBySide ? "row" : "column"}
        size="grow"
        spacing={2}
        style={{
          height: "100%",
          flexWrap: "nowrap",
        }}
      >
        {/* Add repairs */}
        <Grid2
          style={{
            overflow: sideBySide ? "auto" : undefined,
            flex: sideBySide ? 2 : 1,
          }}
        >
          <Card style={{ padding: 20 }}>
            <Grid2 container>
              <ARDHeader />
              <ARDStepper />
            </Grid2>
          </Card>
        </Grid2>

        {sideBySide && (
          <Divider
            orientation={"vertical"}
            style={{
              width: "3px",
            }}
          />
        )}

        {/* Sidebar with info */}
        <Grid2 style={{ overflow: sideBySide ? "auto" : undefined, flex: 1 }}>
          <ARDSidebar {...data} />
        </Grid2>
      </Grid2>
    </Container>
  );
};
