import { Grid2, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  RepairDescriptionDamagesQuery,
  RepairDescriptionDamagesQueryVariables,
  RepairDocumentationDamageOption,
} from "../../../__apollo__/graphql";
import { useGMQuery } from "../../../apollo/customApolloHooks";
import {
  MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION,
  useAddRepairDescriptionContext,
} from "../../../contexts/add-repair-description/add-repair-description-context";
import { REPAIR_DESCRIPTION_DAMAGES } from "../../../contexts/add-repair-description/add-repair-description-gql-queries";
import { useTranslation } from "../../../utility/i18n/translation";
import { FillWidthLoading } from "../../shared/FillWidthLoading";
import { ARDAutocomplete } from "../ARDAutocomplete";
import { ARDNavigationButtons } from "../ARDNavigationButtons";

export const ARDChooseDamage: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { chosenComponent },
  } = useAddRepairDescriptionContext();

  const { id: ticketId } = useParams() as { id: string };
  const { data, loading, error } = useGMQuery<RepairDescriptionDamagesQuery, RepairDescriptionDamagesQueryVariables>(
    REPAIR_DESCRIPTION_DAMAGES,
    {
      variables: {
        input: { ticketId, componentId: chosenComponent?.id },
      },
    },
  );

  const {
    state: { chosenDamage, currentStep, customDamageString },
    dispatch,
  } = useAddRepairDescriptionContext();

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data || error) {
    return (
      <Typography>
        {translate("ADD_REPAIR_DOCUMENTATION.CHOOSE_DAMAGE.NO_DAMAGES", "No damages to show, please refresh")}
      </Typography>
    );
  }

  const onValueSelected = (value: RepairDocumentationDamageOption | null) => {
    if (value) {
      dispatch({ type: "set_damage", chosenDamage: value });
      if (!value.requiresAdditionalInput) {
        dispatch({ type: "step_forward" });
      }
    }
  };

  if (!(currentStep === "damage")) {
    return (
      <Typography>
        {chosenDamage?.description}
        {chosenDamage?.requiresAdditionalInput && customDamageString ? ` - ${customDamageString}` : ""}
      </Typography>
    );
  }

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <Typography>
          {translate("ADD_REPAIR_DOCUMENTATION.CHOOSE_DAMAGE.CHOOSE_CODE", "Choose the appropriate damage code")}
        </Typography>
      </Grid2>
      <Grid2>
        <ARDAutocomplete<RepairDocumentationDamageOption>
          onChange={onValueSelected}
          options={data.repairDescriptionDamageOptions ?? []}
          chosenValue={chosenDamage ?? null}
          cyIdentifier="damage"
        />
        {chosenDamage?.requiresAdditionalInput && <CustomDamageInputComponent />}
      </Grid2>
    </Grid2>
  );
};

const CustomDamageInputComponent: FC = () => {
  const { translate } = useTranslation();
  const [text, setText] = useState("");

  const {
    state: { customDamageString },
    dispatch,
  } = useAddRepairDescriptionContext();

  useEffect(() => {
    if (customDamageString && customDamageString.length > 0 && text.length === 0) {
      setText(customDamageString);
    }
  }, [customDamageString, text.length]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    dispatch({ type: "set_custom_damage", customInput: newValue });
  };

  return (
    <Grid2 container direction="column" spacing={3} style={{ marginTop: 20 }}>
      <Grid2>
        <TextField
          defaultValue={customDamageString}
          variant="outlined"
          onChange={onChange}
          fullWidth
          inputProps={{ maxLength: MAX_LENGTH_FOR_CUSTOM_FIELD_DESCRIPTION }}
          label={translate("ADD_REPAIR_DOCUMENTATION.CHOOSE_DAMAGE.CUSTOM_INPUT_PLACEHOLDER", "Damage description")}
        />
      </Grid2>
      <Grid2>
        <ARDNavigationButtons />
      </Grid2>
    </Grid2>
  );
};
