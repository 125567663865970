import { Buffer } from "buffer";

import fuzzysort from "fuzzysort";
import { Location } from "react-router";

import { AppRoutes } from "../Router";
import { TicketStatus } from "../__apollo__/graphql";

export const getEquipmentIdFromUrl = (location: Location) => {
  // Example pathname: "/equipment/2518004/info/details"
  const locationSplit = location.pathname.split("/");
  const equipmentRootIndex = locationSplit.findIndex((x) => x === AppRoutes.equipment.root.replace("/", ""));

  if (equipmentRootIndex) {
    const id = locationSplit[equipmentRootIndex + 1];

    if (isValidEquipmentId(id)) {
      return id;
    }
  }
};

export const isSuccessType = (type: {
  __typename: `${string}Success` | `${string}Error` | "%other";
}): type is { __typename: `${string}Success` } => type.__typename.endsWith("Success");

export const isValidEquipmentId = (id: string) => {
  if (id.length > 8 || id.length < 6) {
    return false;
  }

  return isValidId(id);
};

export const isValidSparePartId = (id: string) => {
  if (id.length > 18) {
    return false;
  }

  return isValidId(id);
};

const isValidId = (id: string) => {
  const n = Math.floor(Number(id));
  return n !== Infinity && String(n) === id && n >= 0;
};

export const isTicketOpen = (ticketStatus: TicketStatus): boolean => {
  return ticketStatus === "NotStarted" || ticketStatus === "InProgress" || ticketStatus === "Released";
};

export const decodeGlobalID = (globalID: string) => {
  const [typename, id] = Buffer.from(globalID, "base64").toString().split(":");

  if (!typename || !id) {
    throw new TypeError(`Invalid global ID: ${globalID}`);
  }

  return { typename, id };
};

export const fuzzySearch = <T, K extends keyof T>(searchString: string, values: T[] = [], searchFields: K[]): T[] => {
  if (searchString.length > 0) {
    const filtered = fuzzysort.go(searchString, values, {
      keys: searchFields.map((s) => s.toString()),
    });

    return filtered.map((res) => res.obj);
  }

  return values;
};

export const calculatePercentage = (total: number, value: number, scale = 2) => {
  const percentage = Math.round((value / total + Number.EPSILON) * Math.pow(10, 2 + scale)) / Math.pow(10, scale);

  return Number.isFinite(percentage) ? percentage : 0;
};
