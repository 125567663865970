import { hasValue } from "@lego/mst-error-utilities";
import { Chip, Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { CollapsibleWidget } from "../../components/shared/CollapsibleWidget";
import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from "../../utility/date";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { ImageRow } from "../components/ImageRow";

import { TicketRepairDocumentationStandardRow_ActualComponent_damage$key } from "./__generated__/TicketRepairDocumentationStandardRow_ActualComponent_damage.graphql";
import { TicketRepairDocumentationStandardRow_comments$key } from "./__generated__/TicketRepairDocumentationStandardRow_comments.graphql";
import { TicketRepairDocumentationStandardRow_damage$key } from "./__generated__/TicketRepairDocumentationStandardRow_damage.graphql";

export const TicketRepairDocumentationStandardRow: FC<{
  damage: TicketRepairDocumentationStandardRow_damage$key;
  isCollapsible?: boolean;
  padding?: number;
}> = ({ damage: damageRef, isCollapsible = true, padding }) => {
  const damage = useFragment(
    graphql`
      fragment TicketRepairDocumentationStandardRow_damage on Damage {
        description
        ...TicketRepairDocumentationStandardRow_ActualComponent_damage
      }
    `,
    damageRef,
  );

  if (isCollapsible) {
    return (
      <CollapsibleWidget componentWhenClosed={<RowHeader title={damage.description} />} padding={padding ?? 8}>
        <ActualComponent damage={damage} />
      </CollapsibleWidget>
    );
  } else {
    return (
      <Grid2 container direction="column" spacing={1}>
        <Grid2>
          <RowHeader title={damage.description} />
        </Grid2>
        <Grid2 spacing={2}>
          <ActualComponent damage={damage} />
        </Grid2>
      </Grid2>
    );
  }
};

const ActualComponent: FC<{
  damage: TicketRepairDocumentationStandardRow_ActualComponent_damage$key;
}> = ({ damage: damageRef }) => {
  const { translate } = useTranslation();
  const { activities, causes, comments, images } = useFragment(
    graphql`
      fragment TicketRepairDocumentationStandardRow_ActualComponent_damage on Damage {
        description
        activities {
          description
        }
        causes {
          description
        }
        comments {
          ...TicketRepairDocumentationStandardRow_comments
        }
        images {
          ...ImageRowImages_imageInformation
        }
      }
    `,
    damageRef,
  );

  return (
    <Grid2 container style={{ paddingLeft: 20, paddingRight: 40 }} spacing={2}>
      <Grid2>
        <Grid2 container direction="column" spacing={1} borderLeft={1} style={{ paddingLeft: 15 }}>
          <Grid2>
            <CausesOrActivities
              title={translate("CLOSE_TICKET.REPAIR_STEP.ROW.CAUSES_HEADER", "Cause")}
              items={causes.slice()}
            />
          </Grid2>
          {activities.length > 0 && (
            <Grid2>
              <CausesOrActivities
                title={translate("CLOSE_TICKET.REPAIR_STEP.ROW.ACTIVITIES_HEADER", "Activities")}
                items={activities.slice()}
              />
            </Grid2>
          )}

          <Grid2 size={{ xs: 12 }}>
            <Description comments={comments} />
          </Grid2>
          {hasValue(images) && (
            <Grid2 size={{ xs: 12 }}>
              <ImageRow images={images} />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const CausesOrActivities: FC<{
  title: string;
  items: Array<{ description: string }>;
}> = ({ title, items }) => {
  return (
    <Grid2 container spacing={1}>
      <Grid2>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
      </Grid2>
      <Grid2 container spacing={2}>
        {items.map((item, index) => {
          return (
            <Grid2 key={index}>
              <Chip variant="outlined" label={item.description} />
            </Grid2>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

const Description: FC<{
  comments: TicketRepairDocumentationStandardRow_comments$key;
}> = ({ comments: commentsRef }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();
  const comments = useFragment(
    graphql`
      fragment TicketRepairDocumentationStandardRow_comments on Comment @relay(plural: true) {
        description
        timestamp {
          date
          time
        }
      }
    `,
    commentsRef,
  );

  return (
    <Grid2 container direction="column" spacing={1}>
      <Grid2>
        <Typography variant="caption" color="textSecondary">
          {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_HEADER", "Description")}
        </Typography>
      </Grid2>
      <Grid2 container>
        {comments.length === 0 ? (
          <Grid2>
            <Typography color="textSecondary">
              {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_EMPTY", "(no description)")}
            </Typography>
          </Grid2>
        ) : (
          comments.map((comment, index) => {
            return (
              <Grid2 container key={`${index}:${comment}`} direction="column">
                <Grid2>
                  <Typography>{comment.description}</Typography>
                </Grid2>
                <Grid2>
                  <Typography color="textSecondary" variant="caption">
                    {translate("CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_CREATED_PREFIX", "Created {{date}}", {
                      date: format(`${comment.timestamp.date}T${comment.timestamp.time}`, CARD_DATE_FORMAT),
                    })}
                  </Typography>
                </Grid2>
              </Grid2>
            );
          })
        )}
      </Grid2>
    </Grid2>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid2 container spacing={2} style={{ flex: 1 }}>
      <Grid2>
        <Icons.Hardhat />
      </Grid2>
      <Grid2>
        <Typography>{title}</Typography>
      </Grid2>
    </Grid2>
  );
};
