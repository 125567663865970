import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Divider, Grid2 } from "@mui/material";
import { FC, useCallback, useEffect } from "react";

import {
  SisterMouldListQuery,
  SisterMouldListQueryVariables,
  UpdateMouldAndSisterCleaningIntervalsMutation,
  UpdateMouldAndSisterCleaningIntervalsMutationVariables,
} from "../../__apollo__/graphql";
import { useGMMutation, useGMQuery } from "../../apollo/customApolloHooks";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { FillWidthLoading } from "../shared/FillWidthLoading";

import { canUpdateCleaningInterval } from "./cleaningInterval";
import { useMouldCleaningContext } from "./MouldCleaningContext";
import { SISTER_MOULD_ROW_FRAGMENT, SisterMouldRow } from "./SisterMouldRow";

/**
 * ! `allSisterMoulds` does _not_ include sister moulds with a different construction type.
 * ! The new (relay) graphql service _does_ include other construction types in the same field
 */
const QUERY = gql`
  query SisterMouldList($input: EquipmentByIdInput!) {
    equipment(input: $input) {
      ... on EquipmentValue {
        value {
          id
          ... on Mould {
            ...SisterMouldRow
            allSisterMoulds(input: { includeOtherConstructionTypes: false }) {
              id
              ...SisterMouldRow
            }
          }
        }
      }
    }
  }
  ${SISTER_MOULD_ROW_FRAGMENT}
`;

/**
 * ! `allSisterMoulds` does _not_ include sister moulds with a different construction type.
 * ! The new (relay) graphql service _does_ include other construction types in the same field
 */
export const MUTATION_UPDATE_MOULD_AND_SISTER_CLEANING_INTERVAL = gql`
  mutation UpdateMouldAndSisterCleaningIntervals($input: UpdateMouldCleaningIntervals!) {
    equipmentUpdateCleaningIntervals(input: $input) {
      id
      ... on Mould {
        cleaningInterval
        allSisterMoulds(input: { includeOtherConstructionTypes: false }) {
          id
          cleaningInterval
        }
      }
    }
  }
`;

export const useUpdateCleaningIntervalsMutation = () => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();

  return useGMMutation<
    UpdateMouldAndSisterCleaningIntervalsMutation,
    UpdateMouldAndSisterCleaningIntervalsMutationVariables
  >(MUTATION_UPDATE_MOULD_AND_SISTER_CLEANING_INTERVAL, {
    onCompleted: () => {
      showSnack({
        message: translate(
          "SISTER_MOULD_LIST.ON_UPDATE_COMPLETE_SNACK",
          "Successfully updated cleaning intervals for the selected moulds",
        ),
        variant: "success",
      });
    },
  });
};

export const SisterMouldList: FC<{
  mouldNumber: string;
  disableRowSelect: boolean;
}> = ({ mouldNumber, disableRowSelect }) => {
  const {
    dispatch,
    state: {
      cleaningInterval,
      cleaningIntervalDirty,
      cleaningIntervalUpdate: { sisterIdsToUpdate },
    },
  } = useMouldCleaningContext();

  const { data, loading } = useSisterMouldQuery(mouldNumber);

  const onRowPressed = useCallback(
    (mouldId: string, currentlySelected: boolean) => {
      if (disableRowSelect) {
        return;
      }

      dispatch({
        type: "sisterMouldSelected",
        id: Number.parseInt(mouldId),
        selected: !currentlySelected,
      });
    },
    [disableRowSelect, dispatch],
  );

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data || data.equipment.__typename !== "EquipmentValue" || data.equipment.value.__typename !== "Mould") {
    return null;
  }

  const sisterMoulds = data.equipment.value.allSisterMoulds;
  const unwrappedEquipment = data.equipment.value;

  return (
    <Grid2 container flexDirection="column">
      {/* The current mould */}
      <Grid2>
        <SisterMouldRow
          data={data.equipment.value}
          variant="own"
          newCleaningInterval={cleaningIntervalDirty ? cleaningInterval : undefined}
          canBeSelected={false}
          selected
        />
        <Divider />
      </Grid2>
      {/* The sisters */}
      {sisterMoulds.map((sister, index) => {
        const sisterIntervalCannotBeUpdated =
          !canUpdateCleaningInterval(sister) || !canUpdateCleaningInterval(unwrappedEquipment);

        const selected = sisterIdsToUpdate.includes(Number.parseInt(sister.id));

        const getCleaningInterval = () => {
          if (cleaningIntervalDirty) {
            return cleaningInterval;
          }
          if (unwrappedEquipment.cleaningInterval !== sister.cleaningInterval) {
            return unwrappedEquipment.cleaningInterval ?? undefined;
          }
          return undefined;
        };

        const onClick = () => {
          if (sisterIntervalCannotBeUpdated) {
            return;
          }
          onRowPressed(sister.id, selected);
        };

        return (
          <Grid2 key={sister.id} onClick={onClick} width={"100%"}>
            {index > 0 && <Divider />}
            <SisterMouldRow
              data={sister}
              variant="sister"
              selected={selected}
              newCleaningInterval={getCleaningInterval()}
              canBeSelected={!sisterIntervalCannotBeUpdated}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
};

/**
 * Sister moulds on same plant that can be edited should be pre-selected
 */
const usePreselectSisters = () => {
  const { dispatch } = useMouldCleaningContext();

  const preselectSistersOnSamePlant = (data: SisterMouldListQuery) => {
    if (
      data.equipment.__typename !== "EquipmentValue" ||
      data.equipment.value.__typename !== "Mould" ||
      !canUpdateCleaningInterval(data.equipment.value)
    ) {
      return;
    }

    const { allSisterMoulds, plantId } = data.equipment.value;

    allSisterMoulds.forEach((sisterMould) => {
      if (sisterMould.plantId === plantId && canUpdateCleaningInterval(sisterMould)) {
        dispatch({
          type: "sisterMouldSelected",
          id: Number.parseInt(sisterMould.id),
          selected: true,
        });
      }
    });

    dispatch({ type: "setDidPreselectSisters" });
  };

  return { preselectSistersOnSamePlant };
};

export const useSisterMouldQuery = (mouldId?: string) => {
  const { preselectSistersOnSamePlant } = usePreselectSisters();
  const res = useGMQuery<SisterMouldListQuery, SisterMouldListQueryVariables>(QUERY, {
    variables: { input: { id: mouldId ?? "" } },
    skip: !hasValue(mouldId),
  });

  const {
    dispatch,
    state: { didPreselectSisters },
  } = useMouldCleaningContext();

  useEffect(() => {
    if (res.data && !didPreselectSisters) {
      preselectSistersOnSamePlant(res.data);
    }
  }, [didPreselectSisters, dispatch, preselectSistersOnSamePlant, res.data]);

  return res;
};
