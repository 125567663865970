import uniq from "lodash/uniq";
import concat from "lodash/concat";
import without from "lodash/without";

import { MouldCleaningState } from "./MouldCleaningContext";

export type MouldCleaningActions =
  | { type: "setCleaningInterval"; newCleaningInterval: number; dirty: boolean }
  | { type: "setDidPreselectSisters" }
  | { type: "sisterMouldSelected"; id: number; selected: boolean };

export const MouldCleaningReducer = (state: MouldCleaningState, action: MouldCleaningActions): MouldCleaningState => {
  switch (action.type) {
    case "setCleaningInterval":
      return {
        ...state,
        cleaningInterval: action.newCleaningInterval,
        cleaningIntervalDirty: action.dirty,
        cleaningIntervalUpdate: {
          ...state.cleaningIntervalUpdate,
          newCleaningInterval: action.newCleaningInterval,
        },
      };
    case "setDidPreselectSisters":
      return {
        ...state,
        didPreselectSisters: true,
      };
    case "sisterMouldSelected":
      const ids = action.selected
        ? uniq(concat(state.cleaningIntervalUpdate.sisterIdsToUpdate, action.id))
        : without(state.cleaningIntervalUpdate.sisterIdsToUpdate, action.id);

      return {
        ...state,
        cleaningIntervalUpdate: {
          ...state.cleaningIntervalUpdate,
          sisterIdsToUpdate: ids.map((id) => id),
        },
      };
  }
};
