import { gql } from "@apollo/client";
import { Grid2, Skeleton } from "@mui/material";
import { FC } from "react";

import { TdCurrentLocationQuery, TdCurrentLocationQueryVariables } from "../../../__apollo__/graphql";
import { useGMQuery } from "../../../apollo/customApolloHooks";
import { CardWithTitleSkeleton } from "../../../components/shared/CardWithTitle";
import { TDCurrentLocationOld, TD_CURRENT_LOCATION } from "../../../components/ticket-details/TDCurrentLocation";

const QUERY = gql`
  query TDCurrentLocation($input: EquipmentByIdInput!) {
    equipment(input: $input) {
      ... on EquipmentValue {
        value {
          id
          ...TicketDetailsLocation
        }
      }
    }
  }
  ${TD_CURRENT_LOCATION}
`;

export const TDCurrentLocation: FC<{ equipmentNumber: string }> = ({ equipmentNumber }) => {
  const { data, loading } = useGMQuery<TdCurrentLocationQuery, TdCurrentLocationQueryVariables>(QUERY, {
    variables: { input: { id: equipmentNumber } },
  });

  if (loading && !data) {
    return <TDCurrentLocationSkeleton />;
  }

  if (!data || data.equipment.__typename !== "EquipmentValue") {
    return null;
  }

  return <TDCurrentLocationOld {...data.equipment.value} />;
};

export const TDCurrentLocationSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton showTopRightButton>
      <Grid2 container direction="column" spacing={1}>
        {[1, 2, 3, 4].map((val) => {
          const width = val % 2 !== 0 ? 200 : 100;
          return (
            <Grid2 key={val}>
              <Skeleton variant="text" width={width} sx={{ borderRadius: 1 }} />
            </Grid2>
          );
        })}
      </Grid2>
    </CardWithTitleSkeleton>
  );
};
