import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { RepairDocumentationSubEquipmentFragment } from "../../__apollo__/graphql";
import { Icons } from "../../utility/icons";
import { CollapsibleWidget } from "../shared/CollapsibleWidget";
import { TDPackingRepairRowContent } from "../ticket-details/TDRepairDescription";

export const CTRepairDocumentationRowPacking: FC<RepairDocumentationSubEquipmentFragment & { padding?: number }> = ({
  components,
  description,
  padding,
}) => {
  return (
    <CollapsibleWidget componentWhenClosed={<RowHeader title={description} />} padding={padding ?? 8}>
      <Grid2 container style={{ paddingLeft: 45, paddingRight: 40, marginBottom: 20 }} spacing={2}>
        <TDPackingRepairRowContent components={components} />
      </Grid2>
    </CollapsibleWidget>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid2 container spacing={2} style={{ flex: 1 }}>
      <Grid2>
        <Icons.SubEquipment />
      </Grid2>
      <Grid2>
        <Typography>{title}</Typography>
      </Grid2>
    </Grid2>
  );
};
