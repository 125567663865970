import { Grid2 } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { skeletonify } from "../../skeleton";
import { CommentList } from "../CommentList";
import { ImageRow } from "../ImageRow";

import { CodingChip } from "./CodingChip";
import { TicketLocation } from "./TicketLocation";
import { TicketErrorDescription_ticket$key } from "./__generated__/TicketErrorDescription_ticket.graphql";

const ActualComponent: FC<{ ticket: TicketErrorDescription_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;
  const ticket = useFragment(
    graphql`
      fragment TicketErrorDescription_ticket on Ticket {
        __typename
        errorDescription {
          ...CommentList_comment
        }
        ...TicketLocation_ticket
        ...CodingChip_ticket
        images {
          ...ImageRowImages_imageInformation
        }
      }
    `,
    ticketRef,
  );

  return (
    <Grid2 container direction="column" spacing={2} alignItems={"start"}>
      {ticket.__typename === "MouldTicket" && (
        <Grid2 style={{ marginBottom: 8 }}>
          <CodingChip ticket={ticket} />
        </Grid2>
      )}
      {ticket.errorDescription && (
        <Grid2>
          <CommentList comments={ticket.errorDescription} />
        </Grid2>
      )}
      {ticket.images && (
        <Grid2>
          <ImageRow images={ticket.images} />
        </Grid2>
      )}
      <Grid2>
        <TicketLocation ticket={ticket} />
      </Grid2>
    </Grid2>
  );
};

// TODO: Add skeleton components for ImageRow and TicketLocation
const SkeletonComponent: FC = () => <CommentList.Skeleton />;

export const TicketErrorDescription = skeletonify("TicketErrorDescription", ActualComponent, SkeletonComponent);
