import { Box, Button, Grid2, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router";

import { AppRoutes } from "../Router";
import { Figures } from "../utility/figures";
import { useTranslation } from "../utility/i18n/translation";
import { Icons } from "../utility/icons";

export const NotFoundPage: FC<{ containerStyleOverrides?: SxProps<Theme> }> = ({ containerStyleOverrides }) => {
  const { translate } = useTranslation();

  const textColor = "white";

  return (
    <Box
      sx={{
        backgroundColor: "#FAACA8",
        backgroundImage: `linear-gradient(45deg, #5B3A8C 0%, #734CAD 100%)`,
        ...containerStyleOverrides,
      }}
    >
      <Grid2 container direction="row" sx={{ height: "100%" }} spacing={1}>
        <Grid2 size={{ xs: "grow" }}>
          <Box>
            <Figures.Electrician fill={"#A27ED9"} fillOpacity={0.4} />
          </Box>
        </Grid2>
        <Grid2 container size={{ xs: "grow" }} direction="column" justifyContent="center" spacing={6} ml={3}>
          <Grid2>
            <Typography sx={{ fontSize: 100 }} color={textColor}>
              {translate("NOT_FOUND_PAGE.404", "404")}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="h3" color={textColor}>
              {translate("NOT_FOUND_PAGE.TITLE", "Billund, we have a problem.")}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="body1" color={textColor}>
              {translate("NOT_FOUND_PAGE.BODY", "That link may be broken or the page may have been moved.")}
            </Typography>
          </Grid2>
          <Grid2>
            <Button
              variant="outlined"
              component={NavLink}
              to={AppRoutes.root}
              sx={{ color: textColor, borderColor: textColor }}
              startIcon={<Icons.Home fill={textColor} />}
            >
              {translate("NOT_FOUND_PAGE.BUTTON", "Take me home")}
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
