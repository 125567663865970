import { Divider, Grid2, Typography } from "@mui/material";
import { FC, useMemo } from "react";

import { TimeRegistrationOverviewFragment } from "../../../../__apollo__/graphql";
import { useMachineHoursContext } from "../../../../contexts/add-machine-hours/add-machine-hours-context";
import { useTranslation } from "../../../../utility/i18n/translation";
import { AddTimeTotalTimeSpentRow } from "../AddTimeTotalTimeSpentRow";

import { MachineHoursOverviewRow } from "./MachineHoursOverviewRow";

export const AddMachineHoursOverview: FC<TimeRegistrationOverviewFragment & { interactive?: boolean }> = ({
  totalMachineHoursInMinutes,
  machineHours,
  interactive = true,
}) => {
  const { translate } = useTranslation();

  const {
    state: { additions, deletions },
  } = useMachineHoursContext();

  const totalTimeAfterChanges = useMemo(() => {
    const addedMinutes = additions.map((val) => val.minutesSpent).reduce((prev, curr) => prev + curr, 0);

    const removedMinutes = deletions.map((val) => val.minutes).reduce((prev, curr) => prev + curr, 0);

    return (totalMachineHoursInMinutes ?? 0) + addedMinutes - removedMinutes;
  }, [additions, deletions, totalMachineHoursInMinutes]);

  return (
    <Grid2 container direction="column" spacing={2}>
      {interactive && (
        <Grid2>
          <Typography variant="subtitle2">{translate("ADD_TIME.OVERVIEW.HEADER", "Overview")}</Typography>
        </Grid2>
      )}
      <Grid2 container direction="column" spacing={2}>
        {machineHours?.map((machineHour) => {
          return (
            <Grid2 key={`AddMachineHoursOverview-${machineHour.id}`} style={{ width: "100%" }}>
              <MachineHoursOverviewRow {...machineHour} interactive={interactive} />
              <Divider />
            </Grid2>
          );
        })}
        <Grid2
          style={{
            marginRight: 48,
          }}
        >
          <AddTimeTotalTimeSpentRow
            totalTimeSpentInMinutes={totalMachineHoursInMinutes ?? 0}
            totalTimeAfterChanges={totalTimeAfterChanges}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
