import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid2, Skeleton } from "@mui/material";
import { ComponentProps, FC } from "react";

import { CardWithTitleSkeleton } from "../../../components/shared/CardWithTitle";

type Props = Pick<ComponentProps<typeof CardWithTitleSkeleton>, "showTopRightButton">;

export const TDWidgetWithCollapsibleRowsSkeleton: FC<Props> = ({ showTopRightButton }) => {
  return (
    <CardWithTitleSkeleton showTopRightButton={showTopRightButton}>
      <Grid2 container direction="column" spacing={1}>
        {[1, 2, 3].map((val) => {
          return (
            <Grid2 key={val} container direction="row">
              <Grid2 size={{ xs: 10 }}>
                <Skeleton variant="rectangular" width="95%" sx={{ borderRadius: 1 }} />
              </Grid2>
              <Grid2 size={{ xs: 2 }}>
                <ExpandMoreIcon color="disabled" />
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
    </CardWithTitleSkeleton>
  );
};
