import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useAreaAndProcessContext } from "../../contexts/area";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";
import { isSuccessType, isValidEquipmentId } from "../utils";

import { CreateTicketEquipmentLabelQuery as CreateTicketEquipmentLabelQueryType } from "./__generated__/CreateTicketEquipmentLabelQuery.graphql";
import { useEquipmentValidator } from "./useEquipmentValidator";

const ActualComponent: FC<{ equipmentSearchString: string }> = ({ equipmentSearchString }) => {
  const [error, setError] = useState<string | undefined>();
  const { translate } = useTranslation();
  const context = useAreaAndProcessContext();
  const validator = useEquipmentValidator();

  const { equipment } = useLazyLoadQuery<CreateTicketEquipmentLabelQueryType>(
    graphql`
      query CreateTicketEquipmentLabelQuery($input: QueryEquipmentInput!, $skip: Boolean!) {
        equipment(input: $input) @skip(if: $skip) {
          __typename
          ... on QueryEquipmentSuccess {
            data {
              id
              isActive
              equipmentNumber
              description
              plant {
                id
                plantNumber
              }
            }
          }
        }
      }
    `,
    {
      input: {
        equipmentNumber: Number.parseInt(equipmentSearchString),
      },
      skip: !hasValue(equipmentSearchString) || !isValidEquipmentId(equipmentSearchString),
    },
  );

  const setErrorMessage = useCallback(
    (message: "inactive" | "onOtherPlant") => {
      if (hasValue(equipment) && equipment.__typename === "QueryEquipmentSuccess" && hasValue(equipment.data?.id)) {
        if (message === "inactive") {
          setError(
            translate("CREATE_TICKET.EQUIPMENT_INACTIVE", "Equipment with id {{id}} is no longer active", {
              id: equipment.data.equipmentNumber,
            }),
          );
        }
        if (message === "onOtherPlant") {
          setError(
            translate("CREATE_TICKET.EQUIPMENT_ON_ANOTHER_PLANT", "Equipment with id {{id}} is on another plant", {
              id: equipment.data.equipmentNumber,
            }),
          );
        }
      }
    },
    [equipment, translate],
  );

  useEffect(() => {
    if (equipment?.__typename === "QueryEquipmentSuccess" && hasValue(equipment.data?.id)) {
      const equipmentStatus = validator(equipment.data.isActive, equipment.data.plant?.plantNumber ?? null);
      if (equipmentStatus && equipmentStatus !== "valid") {
        setErrorMessage(equipmentStatus);
      } else {
        setError(undefined);
      }
    }
  }, [context.selectedArea.plantId, equipment?.__typename, equipment, translate, setErrorMessage, validator]);

  if (isValidEquipmentId(equipmentSearchString)) {
    if (hasValue(equipment) && isSuccessType(equipment)) {
      return (
        <Typography data-cy="CreateTicketEquipmentLabel" color={error ? "error" : "black"}>
          {error ? error : equipment.data.description}
        </Typography>
      );
    } else {
      return <Typography>{translate("CREATE_TICKET.EQUIPMENT_NOT_FOUND", "Equipment not found")}</Typography>;
    }
  }
  return null;
};

const SkeletonComponent = () => <Skeleton width={190} height={48} variant="text" />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StructureComponent: FC<{ children: any }> = ({ children }) => <Grid2 ml={2}>{children}</Grid2>;

export const CreateTicketEquipmentLabel = skeletonify(
  "CreateTicketEquipmentLabel",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);
