import { hasValue } from "@lego/mst-error-utilities";
import { Chip, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../../utility/i18n/translation";
import { skeletonify } from "../../skeleton";

import { DimmingsCountChip_equipment$key } from "./__generated__/DimmingsCountChip_equipment.graphql";

const ActualComponent: FC<{
  equipment: DimmingsCountChip_equipment$key | null | undefined;
}> = (props) => {
  const { equipment: equipmentRef } = props;
  const data = useFragment(
    graphql`
      fragment DimmingsCountChip_equipment on Equipment {
        ... on Mould {
          dimmings {
            count
          }
        }
      }
    `,
    equipmentRef ?? null,
  );

  const { translate } = useTranslation();

  const count = data?.dimmings?.count;

  if (!hasValue(count)) {
    return null;
  }

  const label = translate("TICKET_CARD.MOULD_CHIPS.DIMMINGS_COUNT", "{{count}} Dimmings", { count });

  return <Chip data-cy="DimmingsCountChip" label={label} />;
};

const SkeletonComponent: FC = () => (
  <Skeleton>
    <Chip sx={{ width: 100 }} />
  </Skeleton>
);

export const DimmingsCountChip = skeletonify("DimmingsCountChip", ActualComponent, SkeletonComponent);
