import { hasValue } from "@lego/mst-error-utilities";
import { FC, useCallback, useState } from "react";

import { EquipmentLocationFragment, UpdateLocationFragment } from "../../__apollo__/graphql";
import { CloseTicketState } from "../../contexts/close-ticket/close-ticket-context";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { EquipmentLocationSection, useUpdateEquipmentLocationMutations } from "../shared/EquipmentLocationSection";
import { SimpleDialog } from "../shared/SimpleDialog";

export type LocationUpdate = CloseTicketState["location"];

type ChangeLocationComponentProps = {
  data: EquipmentLocationFragment;
  currentLocationUpdateState?: LocationUpdate;
  saveLocationCallback: (newLocationInput: LocationUpdate) => void;
};
export const ChangeLocationComponent: FC<ChangeLocationComponentProps> = ({
  data,
  saveLocationCallback,
  currentLocationUpdateState,
}) => {
  const onSublocationTextChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newSublocationText: newValue,
      sublocationInputValid: isEquipmentSublocationUpdateValid({
        isSublocationMandatory: data.sublocationMetaInfo.isSublocationMandatory,
        sublocationData: data.subLocation,
        newSublocationText: newValue,
        sublocationDirty: true,
      }),
      sublocationDirty: true,
    });
  };

  const onSublocationIdChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newLocationId:
        data.location?.__typename === "Location" && !hasValue(currentLocationUpdateState?.newLocationId)
          ? data.location.id
          : currentLocationUpdateState?.newLocationId,
      newSublocationId: newValue,
      sublocationInputValid: true,
      sublocationDirty: true,
    });
  };

  const onLocationIdChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newLocationId: newValue,
      sublocationInputValid: !data.sublocationMetaInfo.isSublocationMandatory,
      sublocationDirty: true,
      newSublocationText: undefined,
      newSublocationId: undefined,
    });
  };

  return (
    <EquipmentLocationSection
      {...data}
      onLocationSelected={onLocationIdChanged}
      onSublocationTextUpdated={onSublocationTextChanged}
      onSublocationSelected={onSublocationIdChanged}
      currentLocationUpdateState={currentLocationUpdateState}
    />
  );
};

export const TDUpdateLocationDialog: FC<{
  data: EquipmentLocationFragment;
  open: boolean;
  onDismiss: () => void;
}> = ({ open, onDismiss, data }) => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const { loading, updateEquipmentLocationData } = useUpdateEquipmentLocationMutations(data.id);
  const [locationUpdate, setLocationUpdate] = useState<LocationUpdate | undefined>();

  const onSavePressed = async () => {
    if (hasValue(locationUpdate) && locationUpdate.sublocationInputValid) {
      await updateEquipmentLocationData({
        ...locationUpdate,
        sublocationInputValid: locationUpdate.sublocationInputValid,
      });
      setLocationUpdate(undefined);
      onDismiss();
    } else {
      showSnack({
        message: translate("TICKET_DETAILS.CARDS.UPDATE_DIALOG.INPUT_INVALID_SNACK", "Input not valid"),
        variant: "warning",
      });
    }
  };

  const onDialogDismiss = useCallback(() => {
    setLocationUpdate(undefined);
    onDismiss();
  }, [onDismiss]);

  const isSublocationValid =
    !data.sublocationMetaInfo.isSublocationMandatory ||
    hasValue(locationUpdate?.newSublocationId) ||
    hasValue(locationUpdate?.newSublocationText);

  const isSaveDisabled = loading || !hasValue(locationUpdate?.newLocationId) || !isSublocationValid;

  return (
    <SimpleDialog
      onDismiss={onDialogDismiss}
      open={open}
      title={translate("TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.TITLE", "Change Location")}
      secondaryAction={{
        secondaryActionLabel: translate("TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.CANCEL_BUTTON", "Cancel"),
        secondaryActionPressed: onDialogDismiss,
        secondaryActionDisabled: loading,
      }}
      primaryAction={{
        primaryActionLabel: translate("TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.SAVE_BUTTON", "Save"),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
        primaryActionDisabled: isSaveDisabled,
      }}
      content={{
        type: "node",
        node: (
          <ChangeLocationComponent
            currentLocationUpdateState={locationUpdate}
            data={data}
            saveLocationCallback={setLocationUpdate}
          />
        ),
      }}
      maxWidth={"lg"}
    />
  );
};

export const isEquipmentSublocationUpdateValid = ({
  isSublocationMandatory,
  sublocationData,
  newSublocationText,
  sublocationDirty,
}: {
  isSublocationMandatory: boolean;
  sublocationData:
    | Extract<UpdateLocationFragment["equipment"], { __typename?: "EquipmentValue" }>["value"]["subLocation"]
    | null;
  sublocationDirty: boolean;
  newSublocationText?: string;
}): boolean => {
  if (!isSublocationMandatory) {
    return true;
  }

  if (sublocationDirty) {
    return (newSublocationText?.length ?? 0) > 0;
  }

  const emptyInLocalState = !hasValue(newSublocationText) || newSublocationText.length === 0;

  if (hasValue(sublocationData) && emptyInLocalState) {
    return true;
  }

  return !emptyInLocalState;
};
