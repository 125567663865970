import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useMutation } from "react-relay";

import { SimpleDialog } from "../../../components/shared/SimpleDialog";
import { useTranslation } from "../../../utility/i18n/translation";

import { HallMonitorSetupDeleteDialogMutation } from "./__generated__/HallMonitorSetupDeleteDialogMutation.graphql";

export const HallMonitorSetupDeleteDialog: FC<{
  closeDialog: () => void;
  idToDelete?: string;
}> = ({ closeDialog, idToDelete }) => {
  const { translate } = useTranslation();
  const [deleteVariant, inFlight] = useMutation<HallMonitorSetupDeleteDialogMutation>(graphql`
    mutation HallMonitorSetupDeleteDialogMutation(
      $deleteHallMonitorVariantInput: MutationDeleteHallMonitorScreenInput!
    ) {
      deleteHallMonitorScreen(input: $deleteHallMonitorVariantInput) @deleteRecord
    }
  `);

  const onConfirm = () => {
    if (hasValue(idToDelete)) {
      deleteVariant({
        variables: { deleteHallMonitorVariantInput: { id: idToDelete } },
        onCompleted: () => {
          closeDialog();
        },
      });
    }
  };

  return (
    <SimpleDialog
      onDismiss={closeDialog}
      open={hasValue(idToDelete)}
      title={translate("HALL_MONITOR.DELETE_DIALOG.TITLE", "Are you sure?")}
      content={{
        type: "text",
        body: translate("HALL_MONITOR.DELETE_DIALOG.BODY", "Are you sure you want to delete this variant?"),
      }}
      primaryAction={{
        primaryActionLabel: translate("HALL_MONITOR.DELETE_DIALOG.CONFIRM", "Confirm"),
        primaryActionLoading: inFlight,
        primaryActionPressed: onConfirm,
      }}
      secondaryAction={{
        secondaryActionLabel: translate("HALL_MONITOR.DELETE_DIALOG.CANCEL", "Cancel"),
        secondaryActionPressed: closeDialog,
      }}
    />
  );
};
