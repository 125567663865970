import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { usePaginationFragment } from "react-relay";
import { Grid2 } from "@mui/material";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { skeletonify } from "../skeleton";
import { TicketList } from "../ticket-list/TicketList";

import { RecentlyClosedTicketsList_equipment$key } from "./__generated__/RecentlyClosedTicketsList_equipment.graphql";

const ActualComponent: FC<{
  equipment: RecentlyClosedTicketsList_equipment$key;
}> = (props) => {
  const { equipment: equipmentRef } = props;

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    graphql`
      fragment RecentlyClosedTicketsList_equipment on Equipment
      @refetchable(queryName: "RecentlyClosedTicketsListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "ID" }
        completedDate: { type: "LocalTimestampFilterInput" }
      ) {
        recentlyClosedTickets: tickets(
          first: $first
          after: $after
          input: { status: Closed, completedDate: $completedDate }
        ) @connection(key: "RecentlyClosedTickets_recentlyClosedTickets") {
          edges {
            node {
              ...TicketList_ticket
            }
          }
        }
      }
    `,
    equipmentRef,
  );

  const { recentlyClosedTickets } = data;

  const tickets = recentlyClosedTickets?.edges.filter(hasValue).map(({ node }) => node);

  return (
    <TicketList
      disableOverscrolling
      tickets={tickets}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" spacing={2}>
    <Grid2 size={{ xs: "grow" }} alignSelf="center">
      <ActivityIndicator />
    </Grid2>
  </Grid2>
);

export const RecentlyClosedTicketsList = skeletonify("RecentlyClosedTicketsList", ActualComponent, SkeletonComponent);
