import { Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useState } from "react";
import { useFragment } from "react-relay";

import { CardWithTitle, CardWithTitleSkeleton } from "../../../components/shared/CardWithTitle";
import { TDWidgetDialogButton } from "../../../components/ticket-details/TDWidgetAddButton";
import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";
import { useTicketPermissions } from "../../../utility/permissions/useTicketPermissions";
import { TicketErrorDescription } from "../../components/ticket/TicketErrorDescription";
import { skeletonify } from "../../skeleton";
import { AddErrorDescriptionDialog } from "../AddErrorDescriptionDialog";

import { TDErrorDescription_ticket$key } from "./__generated__/TDErrorDescription_ticket.graphql";

const ActualComponent: FC<{ ticket: TDErrorDescription_ticket$key }> = ({ ticket: ticketRef }) => {
  const { translate } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const ticket = useFragment(
    graphql`
      fragment TDErrorDescription_ticket on Ticket {
        ...TicketErrorDescription_ticket
        ...AddErrorDescriptionDialog_ticket
        ...useTicketPermissions_ticket
      }
    `,
    ticketRef ?? null,
  );

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const { canAddErrorDescription } = useTicketPermissions(ticket);

  if (!ticket) {
    return null;
  }

  const rightItem = canAddErrorDescription ? (
    <TDWidgetDialogButton
      icon={<Icons.Plus />}
      title={translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_BUTTON", "Description")}
      onClick={openDialog}
    />
  ) : undefined;

  return (
    <CardWithTitle
      title={translate("TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_TITLE", "Error description")}
      rightItem={rightItem}
      data-cy="TDErrorDescription"
    >
      <TicketErrorDescription ticket={ticket} />
      <AddErrorDescriptionDialog ticket={ticket} open={dialogOpen} closeDialog={closeDialog} />
    </CardWithTitle>
  );
};

const SkeletonComponent: FC = () => {
  return (
    <CardWithTitleSkeleton showTopRightButton>
      <Grid2 container direction="column">
        <Grid2>
          <Skeleton variant="rectangular" width={100} height={25} sx={{ borderRadius: 4 }} />
        </Grid2>
        {[1, 2].map((val) => {
          const width = val % 2 !== 0 ? 400 : 100;
          const height = val % 2 !== 0 ? 150 : 25;
          return (
            <Grid2 key={val}>
              <Skeleton variant="text" width={width} height={height} sx={{ borderRadius: 1 }} />
            </Grid2>
          );
        })}
      </Grid2>
    </CardWithTitleSkeleton>
  );
};

export const TDErrorDescription = skeletonify("TDErrorDescription", ActualComponent, SkeletonComponent);
