import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Skeleton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, ReactElement, useMemo } from "react";
import { useFragment } from "react-relay";

import { CardWithTitle, CardWithTitleSkeleton } from "../../../components/shared/CardWithTitle";
import { TextWithLabel } from "../../../components/shared/TextWithLabel";
import { useFormatDateWithLocale } from "../../../utility/date";
import { useTranslation } from "../../../utility/i18n/translation";
import { Icons } from "../../../utility/icons";
import { useCloseFlowStatusTranslation } from "../../components/mould/CloseFlowStatusTranslationHook";
import { skeletonify } from "../../skeleton";

import { TDMouldStatus_equipment$key } from "./__generated__/TDMouldStatus_equipment.graphql";

const ICON_SIZE = 32;
const TEST_STATUS_TO_SHOW = [
  "YellowToQAApproved",
  "YellowToQARejected",
  "YellowToMPEERejected",
  "YellowToProductionRejected",
];

const ActualComponent: FC<{
  equipment: TDMouldStatus_equipment$key;
}> = ({ equipment: equipmentRef }) => {
  const { translate, locale } = useTranslation();
  const translateCloseFlowStatus = useCloseFlowStatusTranslation();

  const { actualShots, budgetShots, statusCode, closeFlowStatus } = useFragment(
    graphql`
      fragment TDMouldStatus_equipment on Equipment {
        ... on Mould {
          actualShots
          budgetShots
          statusCode
          closeFlowStatus {
            status
            lastUpdatedDate
          }
        }
      }
    `,
    equipmentRef,
  );

  const shotsPercentage = useMemo(() => {
    if (hasValue(budgetShots) && hasValue(actualShots)) {
      return ((actualShots / budgetShots) * 100 || 0).toFixed(2);
    }
  }, [actualShots, budgetShots]);

  const testStatusText =
    hasValue(closeFlowStatus) && TEST_STATUS_TO_SHOW.includes(closeFlowStatus.status)
      ? translateCloseFlowStatus(closeFlowStatus.status)
      : null;
  const testStatusLastUpdated = useFormatDateWithLocale(closeFlowStatus?.lastUpdatedDate, "dd/MM/yyyy, H.mm");

  return (
    <CardWithTitle title={translate("TICKET_DETAILS.CARDS.MOULD_STATUS.TITLE", "Mould Status")}>
      <Grid2 container direction="row" rowSpacing={3}>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.InfoInCircle style={{ fontSize: ICON_SIZE }} />}
            text={statusCode?.toString()}
            label={translate("TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.STATUS", "Current status")}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.TestStatus style={{ fontSize: ICON_SIZE }} />}
            text={testStatusText}
            label={translate("TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.LAST_TEST_STATUS", "Last test status")}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.TestStatus style={{ fontSize: ICON_SIZE }} />}
            text={hasValue(testStatusText) ? testStatusLastUpdated : null}
            label={translate(
              "TICKET_DETAILS.CARDS.MOULD_DETAILS.LABELS.LAST_TEST_STATUS_UPDATE",
              "Last test status update",
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.Cavities style={{ fontSize: ICON_SIZE }} />}
            text={shotsPercentage ? shotsPercentage + " %" : undefined}
            label={translate("TICKET_DETAILS.CARDS.MOULD_STATUS.LABELS.SHOTS_PERCENTAGE", "of budgeted shots")}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.Cavities style={{ fontSize: ICON_SIZE }} />}
            text={budgetShots?.toLocaleString(locale)}
            label={translate("TICKET_DETAILS.CARDS.MOULD_STATUS.LABELS.BUDGET_SHOTS", "Budget shots")}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItem
            icon={<Icons.Cavities style={{ fontSize: ICON_SIZE }} />}
            text={actualShots?.toLocaleString(locale)}
            label={translate("TICKET_DETAILS.CARDS.MOULD_STATUS.LABELS.ACTUAL_SHOTS", "Actual shots")}
          />
        </Grid2>
      </Grid2>
    </CardWithTitle>
  );
};

const SkeletonComponent = () => {
  return <MouldStatusSkeleton />;
};

export const TDMouldStatus = skeletonify("TDMouldStatus", ActualComponent, SkeletonComponent);

const MouldStatusItem: FC<{
  icon: ReactElement;
  text: string | undefined | null;
  label: string;
}> = ({ icon, text, label }) => {
  return (
    <Grid2 container direction="row" alignItems="center" spacing={1}>
      <Grid2
        sx={{
          mr: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {icon}
      </Grid2>
      <Grid2>
        <TextWithLabel label={label} text={text ?? "---"} />
      </Grid2>
    </Grid2>
  );
};

const MouldStatusSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid2 container direction="row" rowSpacing={3}>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <MouldStatusItemSkeleton />
        </Grid2>
      </Grid2>
    </CardWithTitleSkeleton>
  );
};

const skeletonTextWidth = 80;
const skeletonIconSize = 50;
const MouldStatusItemSkeleton: FC = () => {
  return (
    <Grid2 container direction="row">
      <Grid2 sx={{ mr: 1 }}>
        <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" height={skeletonIconSize} width={skeletonIconSize} />
      </Grid2>
      <Grid2>
        <Grid2>
          <Skeleton variant="text" width={skeletonTextWidth} />
        </Grid2>
        <Grid2>
          <Skeleton variant="text" width={skeletonTextWidth} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
