import { Box } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useEffect, useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { RouteTypes } from "../../Router";
import { TDCostsTab } from "../../components/ticket-details/TDCostsTab";
import { TDDocumentsTab } from "../../components/ticket-details/TDDocumentsTab";
import { useAreaAndProcessContext } from "../../contexts/area";
import { NotFoundPage } from "../NotFoundPage";
import { ErrorPage, PageErrorBoundary } from "../PageErrorBoundary";
import { SparePartBomTab } from "../dashboard/spare-part-bom/SparePartBom";
import { EquipmentHistoryTab } from "../history-tab/EquipmentHistoryTab";
import { EquipmentMtsLogTab } from "../mts-log-tab/EquipmentMtsLogTab";
import { OpenTicketsTabContainer } from "../open-tickets-tab/OpenTicketsTabContainer";
import { QADetailsPane } from "../qa-tab/QADetailsPane";
import { SisterMouldsTabList } from "../sister-moulds-tab/SisterMouldsTabList";
import { skeletonify } from "../skeleton";

import { EquipmentDetailsHeader } from "./EquipmentDetailsHeader";
import { EquipmentDetailsPane, useApolloEquipmentDetailsQuery } from "./EquipmentDetailsPane";
import EquipmentDetailsQuery, {
  EquipmentDetailsQuery$data,
  EquipmentDetailsQuery as EquipmentDetailsQueryType,
} from "./__generated__/EquipmentDetailsQuery.graphql";

export const EquipmentDetails: FC = () => {
  const { id } = useParams() as RouteTypes["equipment"];

  const [queryRef, loadQuery] = useQueryLoader<EquipmentDetailsQueryType>(EquipmentDetailsQuery);

  const input = useMemo(() => {
    return { input: { equipmentNumber: Number.parseInt(id) } };
  }, [id]);

  useEffect(() => loadQuery(input, { fetchPolicy: "store-and-network" }), [loadQuery, input]);

  // Preload Apollo query as well until fully migrated
  useApolloEquipmentDetailsQuery(id);

  return (
    <PageErrorBoundary onReset={() => loadQuery(input)}>
      {queryRef ? <EquipmentDetailsContainer.Suspense query={queryRef} /> : <EquipmentDetailsContainer.Skeleton />}
    </PageErrorBoundary>
  );
};

const ActivePane: FC<{
  tab: RouteTypes["equipment"]["tab"];
  data: EquipmentDetailsQuery$data;
}> = ({ tab, data }) => {
  const { relayProcessId } = useAreaAndProcessContext();
  if (data.equipment.__typename !== "QueryEquipmentSuccess") {
    return null;
  }

  const equipment = data.equipment.data;
  switch (tab) {
    case "details":
      return <EquipmentDetailsPane.Suspense equipment={equipment} />;
    case "history":
      return <EquipmentHistoryTab />;
    case "costs":
      return <TDCostsTab />;
    case "open-tickets":
      return <OpenTicketsTabContainer />;
    case "documents":
      return <TDDocumentsTab />;
    case "mts-log":
      return <EquipmentMtsLogTab />;
    case "sister-moulds":
      return equipment?.__typename === "Mould" ? <SisterMouldsTabList mouldId={equipment.id} /> : <ErrorPage />;
    case "spareparts":
      // TODO: hide tab or show error without process id
      return relayProcessId ? <SparePartBomTab processId={relayProcessId} /> : null;
    case "qa":
      return <QADetailsPane.Suspense equipment={equipment} />;
  }
};

const ActualComponent = (props: { query: PreloadedQuery<EquipmentDetailsQueryType> }) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query EquipmentDetailsQuery($input: QueryEquipmentInput!) {
        equipment(input: $input) {
          __typename
          ... on Error {
            message
          }
          ... on NotFoundError {
            id
          }
          ... on QueryEquipmentSuccess {
            data {
              id
              __typename
              ...EquipmentDetailsHeaderFragment
              ...EquipmentDetailsPane_equipment #@defer
              ...QADetailsPane_equipment #@defer
            }
          }
        }
      }
    `,
    queryRef,
  );

  const { tab } = useParams() as RouteTypes["equipment"];
  if (data.equipment.__typename === "NotFoundError") {
    return {
      header: <div />,
      pane: <NotFoundPage containerStyleOverrides={{ height: "105%", mt: -2 }} />,
    };
  }

  if (data.equipment.__typename !== "QueryEquipmentSuccess") {
    // Gets caught with PageErrorBoundary
    throw new Error(data.equipment.message ?? "Unknown equipment return type");
  }

  return {
    header: <EquipmentDetailsHeader details={data.equipment.data ?? null} />,
    pane: <ActivePane tab={tab} data={data} />,
  };
};

const SkeletonComponent = () => ({
  header: <EquipmentDetailsHeader.Skeleton />,
  pane: <EquipmentDetailsPane.Skeleton />,
});

const StructureComponent = ({ header, pane }: { header: JSX.Element; pane: JSX.Element }) => {
  return (
    <Box display="flex" height="100%" flexDirection="column">
      {header}
      <Box flex={1} overflow="scroll" pt={2}>
        {pane}
      </Box>
    </Box>
  );
};

const EquipmentDetailsContainer = skeletonify(
  "EquipmentDetailsPage",
  ActualComponent,
  SkeletonComponent,
  StructureComponent,
);
