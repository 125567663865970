import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid2,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import debounce from "lodash/debounce";
import { Dispatch, FC, useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";
import { skeletonify } from "../skeleton";

import { TicketSearchHeader_ticketsConnection$key } from "./__generated__/TicketSearchHeader_ticketsConnection.graphql";

const ActualComponent: FC<{
  ticketsConnection: TicketSearchHeader_ticketsConnection$key;
}> = (props) => {
  const { ticketsConnection: ticketsRef } = props;

  const { count } = useFragment(
    graphql`
      fragment TicketSearchHeader_ticketsConnection on TicketsConnection {
        count
      }
    `,
    ticketsRef,
  );

  return <Typography>{count} results</Typography>;
};

const SkeletonComponent = () => <Skeleton width={100} />;

const Results = skeletonify("TicketResults", ActualComponent, SkeletonComponent);

export const TicketSearchHeader = (props: {
  onChange?: Dispatch<string>;
  initialSearchTerm?: string;
  ticketsConnection: TicketSearchHeader_ticketsConnection$key | null | undefined;
  loading?: boolean;
  disabled: boolean;
}) => {
  const { ticketsConnection: ticketsRef, onChange, initialSearchTerm, loading = false, disabled } = props;

  const { translate } = useTranslation();
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(initialSearchTerm ?? "");
  const { palette } = useTheme();

  const handleSearch = useMemo(() => {
    if (!onChange) {
      return undefined;
    }

    const debouncedChange = debounce(onChange, 500, {
      maxWait: 1500,
    });
    return (input: string) => {
      setValue(input);
      debouncedChange(input);
    };
  }, [onChange]);

  const handleChange = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      handleSearch?.(event.target.value);
    },
    [handleSearch],
  );

  const handleClear = useCallback(() => {
    handleSearch?.("");
  }, [handleSearch]);

  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);

  return (
    <Grid2 container alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid2>{ticketsRef ? <Results.Suspense ticketsConnection={ticketsRef} /> : <Results.Skeleton />}</Grid2>
      <Grid2 size={{ xs: "grow" }} display="flex" justifyContent="flex-end">
        <TextField
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          sx={{
            backgroundColor: palette.background.paper,
            transition: "width 0.5s",
            width: focus ? "100%" : 300,
          }}
          value={value}
          placeholder={translate("TICKET_LIST.SEARCH_PLACEHOLDER", "Search")}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icons.Search fill={palette.text.disabled} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {loading && <ActivityIndicator size={24} />}
                <IconButton aria-label="clear search" onClick={handleClear} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid2>
    </Grid2>
  );
};
