import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    ...theme.typography.body1,
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiStepLabel-label.Mui-active": {
    ...theme.typography.body1,
    fontWeight: 900,
    fontSize: "22px",
    color: theme.palette.primary.main,
  },
  "& .MuiStepLabel-label.Mui-completed": {
    ...theme.typography.body1,
    fontWeight: 900,
    fontSize: "22px",
    color: theme.palette.primary.main,
  },
}));
