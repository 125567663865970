import { gql } from "@apollo/client";

import {
  PERMISSIONS_ALLOWED_TO_MANAGE_TICKET,
  PERMISSIONS_CAN_ASSIGN_FRAGMENT,
  PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION,
  PERMISSIONS_CAN_UNSASSIGN,
} from "../../utility/permissions/ticket";
import { EQUIPMENT_LOCATION_FRAGMENT } from "../shared/EquipmentLocationSection";

export const TD_ASSIGNMENT_DIALOG_FRAGMENT = gql`
  fragment TDAssignmentDialog on Ticket {
    id
    ...IsAllowedToManageTicket
    ...CanAssignTicket
    ...CanUnassign
    equipment {
      ... on EquipmentValue {
        value {
          id
          ...EquipmentLocation
          ...CanEditEquipmentLocation
        }
      }
    }
  }
  ${EQUIPMENT_LOCATION_FRAGMENT}
  ${PERMISSIONS_ALLOWED_TO_MANAGE_TICKET}
  ${PERMISSIONS_CAN_ASSIGN_FRAGMENT}
  ${PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION}
  ${PERMISSIONS_CAN_UNSASSIGN}
`;
