import { MenuItem, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { FC, useState } from "react";

import { useUserPreferencesContext } from "../../contexts/UserPreferencesContext";
import { Locale } from "../../utility/i18n/i18n";
import { useTranslation } from "../../utility/i18n/translation";
import { CardWithTitle } from "../shared/CardWithTitle";
import { GMDropdown } from "../shared/GMDropdown";

export const ChangeLanguage: FC = () => {
  const { translate, locale: currentLocaleSelectedInPreferences, changeLanguage } = useTranslation();

  const { languageLoading } = useUserPreferencesContext();

  const [locale, setLocale] = useState<Locale>(currentLocaleSelectedInPreferences);

  const localizedNames = useLocalizedLocaleNames();

  const handleChange = (event: SelectChangeEvent<string>) => {
    setLocale(event.target.value as Locale);
    changeLanguage(event.target.value as Locale);
  };

  return (
    <CardWithTitle title={translate("PROFILE.PREFERENCES_TILE.TITLE", "Personal Preferences")} halfSize>
      <Typography style={{ marginBottom: 8 }}>
        {translate("PROFILE.PREFERENCES_TILE.LANGUAGE_HEADER", "Language")}
      </Typography>
      <GMDropdown onChange={handleChange} value={locale} disabled={languageLoading}>
        {localizedNames.map((val) => {
          return (
            <MenuItem key={`${val.locale}`} value={val.locale}>
              {val.translatedString}
            </MenuItem>
          );
        })}
      </GMDropdown>
    </CardWithTitle>
  );
};

const useLocalizedLocaleNames = () => {
  const { translate } = useTranslation();

  const englishLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.ENGLISH", "English");
  const danishLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.DANISH", "Danish");
  const hungarianLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.HUNGARIAN", "Hungarian");
  const chineseLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.CHINESE", "Chinese");
  const spanishLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.SPANISH", "Spanish");
  const czechLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.CZECH", "Czech");
  const vietnameseLocaleName = translate("PROFILE.PREFERENCES_TILE.LANGUAGES.VIETNAMESE", "Vietnamese");

  const res: { locale: Locale; translatedString: string }[] = [
    { locale: Locale.CHINESE, translatedString: chineseLocaleName },
    { locale: Locale.CZECH, translatedString: czechLocaleName },
    { locale: Locale.DANISH, translatedString: danishLocaleName },
    { locale: Locale.ENGLISH, translatedString: englishLocaleName },
    { locale: Locale.HUNGARIAN, translatedString: hungarianLocaleName },
    { locale: Locale.SPANISH, translatedString: spanishLocaleName },
    { locale: Locale.VIETNAMESE, translatedString: vietnameseLocaleName },
  ];

  return res;
};
