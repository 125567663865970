import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, Suspense, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";

import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { ErrorPage, PageErrorBoundary } from "../PageErrorBoundary";

import { SisterMouldCardsList } from "./SisterMouldCardsList";
import SisterMouldsTabListQuery, {
  SisterMouldsTabListQuery as SisterMouldsTabListQueryType,
} from "./__generated__/SisterMouldsTabListQuery.graphql";

const Body: FC<{
  query: PreloadedQuery<SisterMouldsTabListQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query SisterMouldsTabListQuery($mouldId: ID!) {
        node(id: $mouldId) {
          ... on Mould {
            ...SisterMouldCardsList_mould
          }
        }
      }
    `,
    queryRef,
  );

  if (!data.node) {
    return <ErrorPage />;
  }

  return <SisterMouldCardsList mould={data.node} />;
};

export const SisterMouldsTabList: FC<{
  mouldId: string;
}> = ({ mouldId }) => {
  const [queryRef, loadQuery] = useQueryLoader<SisterMouldsTabListQueryType>(SisterMouldsTabListQuery);

  useEffect(() => {
    loadQuery({ mouldId });
  }, [loadQuery, mouldId]);

  return (
    <Container maxWidth="lg" sx={{ height: "100%" }}>
      <PageErrorBoundary onReset={() => loadQuery({ mouldId })}>
        <Suspense fallback={<FillWidthLoading />}>{queryRef ? <Body query={queryRef} /> : null}</Suspense>
      </PageErrorBoundary>
    </Container>
  );
};
