import { Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../../utility/icons";

export const EquipmentIdWithIcon: FC<{ equipmentId: string }> = ({ equipmentId }) => {
  return (
    <Grid2 container direction="row">
      <Icons.EquipmentId style={{ marginRight: 8 }} />
      <Typography variant="body1">{equipmentId}</Typography>
    </Grid2>
  );
};
