import { createContext, Dispatch, FC, ReactElement, useContext, useReducer } from "react";

import { AREA_ALL_OPTION } from "../../components/profile/Area";
import { PROCESSES_ALL_OPTION } from "../../components/profile/Process";

import { ConfidentialitySearchActions, confidentialitySearchReducer } from "./confidentiality-search-reducer";

export interface ConfidentialitySearchState {
  today: Date;
  selectedAreaId: string;
  selectedProcessId: string;
  createdFrom: Date | null;
  createdTo: Date | null;
  usernameSearchTerm: string;
}

export interface ConfidentialitySearchContext {
  state: ConfidentialitySearchState;
  dispatch: Dispatch<ConfidentialitySearchActions>;
}

export const initialConfidentialitySearchState: ConfidentialitySearchState = {
  today: new Date(),
  selectedAreaId: AREA_ALL_OPTION,
  selectedProcessId: PROCESSES_ALL_OPTION,
  createdFrom: null,
  createdTo: null,
  usernameSearchTerm: "",
};

const Context = createContext<ConfidentialitySearchContext | undefined>(undefined);

export const useConfidentialitySearchContext = (): ConfidentialitySearchContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Missing ConfidentialitySearchProvider");
  }

  return context;
};

export const ConfidentialitySearchProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(confidentialitySearchReducer, initialConfidentialitySearchState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
