import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import uniqBy from "lodash/uniqBy";
import { FC, useCallback, useEffect } from "react";
import { useFragment, usePaginationFragment } from "react-relay";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { useEquipmentSearchContext } from "../../contexts/equipment-search/equipment-search-context";
import { ExtractNodeFromConnection } from "../../utility-types";
import { Figures } from "../../utility/figures";
import { useTranslation } from "../../utility/i18n/translation";
import { InfiniteList } from "../components/InfiniteList";
import { skeletonify } from "../skeleton";

import { EquipmentCard } from "./EquipmentCard";
import { EquipmentList_equipment$key } from "./__generated__/EquipmentList_equipment.graphql";
import { EquipmentList_plant$data, EquipmentList_plant$key } from "./__generated__/EquipmentList_plant.graphql";

type Item = ExtractNodeFromConnection<EquipmentList_plant$data["equipmentSearch"]>;

const ActualComponent: FC<{
  plant?: EquipmentList_plant$key | null;
  equipment?: EquipmentList_equipment$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { dispatch } = useEquipmentSearchContext();

  const { plant: listRef, equipment: singularRef } = props;
  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment EquipmentList_plant on Plant
      @refetchable(queryName: "EquipmentListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "ID" }
        equipmentListInput: { type: "PlantEquipmentSearchInput!" }
      ) {
        equipmentSearch(first: $first, after: $after, input: $equipmentListInput)
          @connection(key: "EquipmentList_equipmentSearch") {
          count
          edges {
            node {
              id
              ...EquipmentCard_equipment
            }
          }
        }
      }
    `,
    listRef ?? null,
  );

  const singularEquipment = useFragment(
    graphql`
      fragment EquipmentList_equipment on Equipment {
        id
        ...EquipmentCard_equipment
      }
    `,
    singularRef ?? null,
  );

  const filtered = data?.equipmentSearch?.edges.filter(hasValue).map(({ node }) => node) ?? [];

  const combined = hasValue(singularEquipment) ? [...filtered, singularEquipment] : filtered;

  // Make sure that if the query is fired as both singular and list,
  // that we only show each equipment once
  const unique = uniqBy(combined, (item) => item.id);

  useEffect(() => {
    if (singularEquipment?.id) {
      dispatch({ type: "setCount", count: unique.length });
    } else {
      dispatch({
        type: "setCount",
        count: data?.equipmentSearch?.count ?? 0,
      });
    }
  }, [combined.length, data?.equipmentSearch?.count, dispatch, singularEquipment?.id, unique.length]);

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);
  const itemRender = useCallback((item: Item) => <EquipmentCard equipment={item} />, []);

  if (!data && !singularEquipment) {
    return <NoQuery />;
  }

  const emptyLabel = translate("EQUIPMENT_LIST.EMPTY", "No equipment found");

  const errorLabel = translate("EQUIPMENT_LIST.ERROR", "Error fetching equipment, please refresh to try again.");

  return (
    <InfiniteList
      {...pagination}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      items={unique}
      itemKeyExtractor={itemKeyExtractor}
      itemRender={itemRender}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" spacing={2}>
    <Grid2 size={{ xs: "grow" }} alignSelf="center">
      <ActivityIndicator />
    </Grid2>
  </Grid2>
);

export const EquipmentList = skeletonify("EquipmentList", ActualComponent, SkeletonComponent);

const NoQuery: FC = () => {
  const { translate } = useTranslation();
  return (
    <Grid2 container direction="column" spacing={2} alignItems="center">
      <Grid2>
        <Typography fontWeight={700}>
          {translate("EQUIPMENT_LIST.PLEASE_INPUT_SEARCH_PARAMS", "Please search or filter to see list of equipment")}
        </Typography>
      </Grid2>
      <Grid2>
        <Figures.SearchingUpwards />
      </Grid2>
    </Grid2>
  );
};
