import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Alert, Card, CardContent, Container, Grid2, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useParams } from "react-router";

import {
  CostForEquipmentFragment,
  CostFragment,
  CostTabForEquipmentQuery,
  CostTabForTicketQuery,
  CostTabForTicketQueryVariables,
  TicketCostFragment,
} from "../../__apollo__/graphql";
import { useGMQuery } from "../../apollo/customApolloHooks";
import { FullWidthGrid } from "../../migration/ticket-details/TicketDetailsPane";
import { useRouteRootType } from "../../Router";
import { useTranslation } from "../../utility/i18n/translation";
import { CardWithTitle } from "../shared/CardWithTitle";
import { FillWidthLoading } from "../shared/FillWidthLoading";

const COST_FRAGMENT = gql`
  fragment Cost on CostType {
    amountInLocalCurrency
    localCurrency
    amountInEuro
  }
`;

const COST_FOR_EQUIPMENT_FRAGMENT = gql`
  fragment CostForEquipment on EquipmentValue {
    value {
      id
      cost {
        acquisitionCost {
          ...Cost
        }
        maintenanceCost {
          ...Cost
        }
        totalMaintenanceCostPercentage
      }
    }
  }
  ${COST_FRAGMENT}
`;

const COST_FOR_TICKET_FRAGMENT = gql`
  fragment TicketCost on Ticket {
    id
    cost {
      sparePartCost {
        ...Cost
      }
      timeSpentCost {
        ...Cost
      }
      totalCost {
        ...Cost
      }
    }
  }
  ${COST_FRAGMENT}
`;

const COST_QUERY_FOR_EQUIPMENT = gql`
  query CostTabForEquipment($input: EquipmentByIdInput!) {
    equipment(input: $input) {
      ... on EquipmentValue {
        ...CostForEquipment
      }
    }
  }
  ${COST_FOR_EQUIPMENT_FRAGMENT}
`;

const COST_QUERY_FOR_TICKET = gql`
  query CostTabForTicket($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        equipment {
          ... on EquipmentValue {
            ...CostForEquipment
          }
        }
        ...TicketCost
      }
    }
  }
  ${COST_FRAGMENT}
  ${COST_FOR_EQUIPMENT_FRAGMENT}
  ${COST_FOR_TICKET_FRAGMENT}
`;

export const TDCostsTab: FC = () => {
  const { id } = useParams() as { id: string };
  const routeRootType = useRouteRootType();

  const { translate } = useTranslation();
  const { data: ticketData, loading: ticketQueryLoading } = useGMQuery<
    CostTabForTicketQuery,
    CostTabForTicketQueryVariables
  >(COST_QUERY_FOR_TICKET, {
    variables: { input: { id } },
    skip: routeRootType === "equipment",
  });

  const { data: equipmentData, loading: equipmentDataLoading } = useGMQuery<
    CostTabForEquipmentQuery,
    CostTabForTicketQueryVariables
  >(COST_QUERY_FOR_EQUIPMENT, {
    variables: { input: { id } },
    skip: routeRootType === "ticket",
  });

  const loading = ticketQueryLoading || equipmentDataLoading;

  const equipmentCost: CostForEquipmentFragment | undefined = useMemo(() => {
    if (routeRootType === "equipment") {
      return equipmentData?.equipment.__typename === "EquipmentValue" ? equipmentData.equipment : undefined;
    } else {
      return ticketData?.ticket.__typename === "Ticket" && ticketData.ticket.equipment?.__typename === "EquipmentValue"
        ? ticketData.ticket.equipment
        : undefined;
    }
  }, [equipmentData?.equipment, routeRootType, ticketData?.ticket]);

  const ticketCost = useMemo(() => {
    if (routeRootType === "ticket") {
      return ticketData?.ticket.__typename === "Ticket" ? ticketData.ticket : undefined;
    } else {
      return undefined;
    }
  }, [routeRootType, ticketData?.ticket]);

  if (loading) {
    return <FillWidthLoading />;
  }

  const showTicketCostCard =
    routeRootType === "ticket" &&
    (hasValue(ticketCost?.cost.sparePartCost) ||
      hasValue(ticketCost?.cost.timeSpentCost) ||
      hasValue(ticketCost?.cost.totalCost));
  const showEquipmentCostCard =
    hasValue(equipmentCost?.value.cost.acquisitionCost) ||
    hasValue(equipmentCost?.value.cost.maintenanceCost) ||
    hasValue(equipmentCost?.value.cost.totalMaintenanceCostPercentage);

  const nothingToShow = !showTicketCostCard && !showEquipmentCostCard;
  if (nothingToShow) {
    return (
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            <Alert severity="warning">
              {translate("TICKET_DETAILS.COST_TAB.TICKET_COST.NO_DATA", "No cost info to show yet")}
            </Alert>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid2 container spacing={2} direction="column">
        {showEquipmentCostCard && equipmentCost && (
          <Grid2 sx={{ width: "100%" }}>
            <EquipmentCostCard {...equipmentCost} />
          </Grid2>
        )}
        {showTicketCostCard && ticketCost && (
          <Grid2 sx={{ width: "100%" }}>
            <TicketCostCard {...ticketCost} />
          </Grid2>
        )}
      </Grid2>
    </Container>
  );
};

const TicketCostCard: FC<TicketCostFragment> = ({ cost: { sparePartCost, timeSpentCost, totalCost } }) => {
  const { translate } = useTranslation();
  return (
    <CardWithTitle title={translate("TICKET_DETAILS.COST_TAB.TICKET_COST.CARD_TITLE", "Ticket cost")}>
      <Grid2 container direction="column" spacing={2}>
        <TableRow
          label={translate("TICKET_DETAILS.COST_TAB.TICKET_COST.SPARE_PARTS", "Spare parts")}
          costs={sparePartCost}
        />
        <TableRow
          label={translate("TICKET_DETAILS.COST_TAB.TICKET_COST.TIME_SPENT", "Time spent")}
          costs={timeSpentCost}
        />
        <TableRow
          label={translate("TICKET_DETAILS.COST_TAB.TICKET_COST.TOTAL_COST", "Total cost")}
          costs={totalCost}
          bold
        />
      </Grid2>
    </CardWithTitle>
  );
};

const EquipmentCostCard: FC<CostForEquipmentFragment> = ({
  value: {
    cost: { acquisitionCost, maintenanceCost, totalMaintenanceCostPercentage },
  },
}) => {
  const { translate } = useTranslation();

  return (
    <CardWithTitle title={translate("TICKET_DETAILS.COST_TAB.EQUIPMENT_COST.CARD_TITLE", "Equipment cost")}>
      <Grid2 container direction="column" spacing={2}>
        <TableRow
          label={translate("TICKET_DETAILS.COST_TAB.EQUIPMENT_COST.ACQUISITION_COST", "Acquisition cost")}
          costs={acquisitionCost}
        />
        <TableRow
          label={translate("TICKET_DETAILS.COST_TAB.EQUIPMENT_COST.MAINTENANCE_COST", "Maintenance cost")}
          costs={maintenanceCost}
        />
        {hasValue(totalMaintenanceCostPercentage) && (
          <FullWidthGrid>
            <Grid2 container size={{ xs: "grow" }}>
              <Grid2 flex={1}>
                <Typography fontWeight={700}>
                  {translate("TICKET_DETAILS.COST_TAB.EQUIPMENT_COST.TOTAL_MAINTENANCE", "Total maintenance cost(%)")}
                </Typography>
              </Grid2>
              <Grid2>
                <Typography fontWeight={700}>{totalMaintenanceCostPercentage}%</Typography>
              </Grid2>
            </Grid2>
          </FullWidthGrid>
        )}
      </Grid2>
    </CardWithTitle>
  );
};

const TableRow: FC<{
  label: string;
  costs: CostFragment | null | undefined;
  bold?: boolean;
}> = ({ label, costs, bold = false }) => {
  const { formatAmount } = useAmountFormatter();

  if (!costs) {
    return null;
  }
  const { amountInEuro, amountInLocalCurrency, localCurrency } = costs;
  const fontWeight = bold ? 700 : undefined;

  return (
    <FullWidthGrid>
      <Grid2 container size={{ xs: "grow" }} maxHeight={25}>
        <Grid2 flex={2}>
          <Typography fontWeight={fontWeight}>{label}</Typography>
        </Grid2>
        <Grid2 flex={1}>
          <Typography fontWeight={fontWeight} textAlign="end">
            {formatAmount(amountInLocalCurrency)} {localCurrency}
          </Typography>
        </Grid2>
        <Grid2 flex={1}>
          <Typography fontWeight={fontWeight} textAlign="end">
            {formatAmount(amountInEuro)} EUR
          </Typography>
        </Grid2>
      </Grid2>
    </FullWidthGrid>
  );
};

export const useAmountFormatter = (): {
  formatAmount: (amount: number) => string;
} => {
  const { locale } = useTranslation();
  return {
    formatAmount: (amount: number) =>
      new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(amount),
  };
};
