import { AddTimeAddition, AddTimeDeletion, AddTimeState } from "./add-time-context";

export type AddEmployeeActionPayload = Omit<AddTimeAddition, "minutesSpent" | "comment">;

export type AddTimeActions =
  | {
      type: "add_employee_to_additions_array";
      addition: AddEmployeeActionPayload;
    }
  | {
      type: "update_time_reg_for_employee";
      employeeId: string;
      minutesSpent: number;
    }
  | {
      type: "update_comment_for_addition";
      employeeId: string;
      comment?: string;
    }
  | { type: "remove_employee_from_additions"; employeeId: string }
  | { type: "set_show_empty_row"; showEmptyRow: boolean }
  | {
      type: "mark_registration_for_deletion";
      manHourId: string;
      timeRegistrationNumber: number;
      minutes: number;
    };

export const addTimeReducer = (state: AddTimeState, action: AddTimeActions): AddTimeState => {
  switch (action.type) {
    case "add_employee_to_additions_array":
      return {
        ...state,
        additions: addEmployeeToAdditions({
          addition: action.addition,
          currentArray: state.additions,
        }),
        showEmptyRow: false,
      };
    case "update_time_reg_for_employee":
      return {
        ...state,
        additions: updateTimeForEmployee({
          employeeId: action.employeeId,
          minutes: action.minutesSpent,
          currentArray: state.additions,
        }),
      };
    case "remove_employee_from_additions":
      return {
        ...state,
        additions: removeEmployeeFromAdditions(action.employeeId, state.additions),
      };
    case "mark_registration_for_deletion": {
      return {
        ...state,
        deletions: updateDeletionStateForTimeReg(
          action.manHourId,
          action.timeRegistrationNumber,
          action.minutes,
          state.deletions,
        ),
      };
    }
    case "update_comment_for_addition": {
      return {
        ...state,
        additions: updateCommentForAddition(state.additions, action.employeeId, action.comment),
      };
    }
    case "set_show_empty_row": {
      return {
        ...state,
        showEmptyRow: action.showEmptyRow,
      };
    }

    default:
      return state;
  }
};

const updateCommentForAddition = (
  currentArray: AddTimeAddition[],
  employeeId: string,
  comment?: string,
): AddTimeAddition[] => {
  const index = findIndexForEmployee(employeeId, currentArray);
  if (index === -1) {
    return currentArray;
  }
  const res = [...currentArray];
  res[index] = { ...res[index], comment };

  return res;
};

const updateDeletionStateForTimeReg = (
  manHourId: string,
  timeRegistrationNumber: number,
  minutes: number,
  currentArray: AddTimeDeletion[],
): AddTimeDeletion[] => {
  const res = [...currentArray];
  const index = currentArray.findIndex(
    (val) => val.timeRegistrationNumber === timeRegistrationNumber && val.manHourId === manHourId,
  );
  if (index === -1) {
    res.push({ manHourId, timeRegistrationNumber, minutes });
  } else {
    res.splice(index, 1);
  }
  return res;
};

const removeEmployeeFromAdditions = (employeeId: string, currentArray: AddTimeAddition[]): AddTimeAddition[] => {
  const res = [...currentArray];
  const index = findIndexForEmployee(employeeId, currentArray);
  if (index !== -1) {
    res.splice(index, 1);
  }
  return res;
};

const addEmployeeToAdditions = ({
  addition,
  currentArray,
}: {
  addition: AddEmployeeActionPayload;
  currentArray: AddTimeAddition[];
}): AddTimeAddition[] => {
  const currentIndex = findIndexForEmployee(addition.employeeId, currentArray);

  const res = [...currentArray];

  if (currentIndex === -1) {
    res.push({ ...addition, minutesSpent: 0 });
    return res;
  }

  res[currentIndex] = {
    ...currentArray[currentIndex],
    ...addition,
  };

  return res;
};

const updateTimeForEmployee = ({
  employeeId,
  currentArray,
  minutes,
}: {
  employeeId: string;
  minutes: number;
  currentArray: AddTimeAddition[];
}) => {
  const currentIndex = findIndexForEmployee(employeeId, currentArray);

  if (currentIndex === -1) {
    return currentArray;
  }

  const res = [...currentArray];

  res[currentIndex] = {
    ...currentArray[currentIndex],
    minutesSpent: minutes,
  };

  return res;
};

const findIndexForEmployee = <T extends { employeeId: string }>(employeeId: string, array: T[]): number => {
  return array.findIndex((val) => val.employeeId === employeeId);
};
