import { gql } from "@apollo/client";
import { hasValue } from "@lego/mst-error-utilities";
import { Grid2, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";

import { SpringChangeInfoFragment } from "../../__apollo__/graphql";
import { initialCloseTicketState, useCloseTicketContext } from "../../contexts/close-ticket/close-ticket-context";
import { useTranslation } from "../../utility/i18n/translation";
import { stringIsPositiveInteger } from "../../utility/numbers";
import { ToggleButton, ToggleButtonGroup } from "../shared/ToggleButtons";

import { CTWarningMessage } from "./CTNoManhoursOrRepairDocs";

export const CLOSE_MOULD_SPRING_FRAGMENT = gql`
  fragment SpringChangeInfo on Ticket {
    id
    coding {
      ... on Coding {
        number
      }
    }
    equipment {
      ... on EquipmentValue {
        value {
          id
          ... on Mould {
            id
            shotsSinceLastSpringChange
            springChangeInterval
          }
        }
      }
    }
  }
`;

export const CTMouldSpringChange: FC<SpringChangeInfoFragment> = ({ equipment }) => {
  const { translate } = useTranslation();

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <Typography variant="subtitle2">
          {translate("CLOSE_TICKET.SPRING_STEP.CARD_HEADER", "Spring change")}
        </Typography>
      </Grid2>
      <Grid2>
        <DidChangeSpringSelector />
      </Grid2>
      <Grid2>
        <CleaningInterval equipment={equipment} />
      </Grid2>
    </Grid2>
  );
};

const CleaningInterval: FC<{
  equipment: SpringChangeInfoFragment["equipment"] | null;
}> = ({ equipment }) => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { springInterval, springIntervalDirty },
  } = useCloseTicketContext();

  const initialValue = useMemo(() => {
    if (springIntervalDirty) {
      return `${springInterval}`;
    }

    if (
      equipment &&
      equipment.__typename === "EquipmentValue" &&
      equipment.value.__typename === "Mould" &&
      hasValue(equipment.value.springChangeInterval)
    ) {
      return `${equipment.value.springChangeInterval}`;
    }

    return "";
  }, [equipment, springInterval, springIntervalDirty]);

  const [localTextValue, setLocalTextValue] = useState(initialValue);

  if (!equipment || !(equipment.__typename === "EquipmentValue") || !(equipment.value.__typename === "Mould")) {
    return (
      <Typography>
        {translate(
          "CLOSE_TICKET.SPRING_STEP.INVALID_EQUIPMENT",
          "The equipment for the ticket could either not be found, or was not a mould",
        )}
      </Typography>
    );
  }

  const onSpringIntervalChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const sanityMaxCharLimit = 15;
    const newValue = e.target.value;

    const isPositiveInt = stringIsPositiveInteger(newValue) && newValue.length <= sanityMaxCharLimit;

    if (isPositiveInt || newValue === "") {
      setLocalTextValue(newValue);
    }

    if (isPositiveInt) {
      dispatch({
        type: "setSpringInterval",
        newSpringInterval: Number.parseInt(newValue),
        dirty: true,
      });
    }

    if (newValue === "") {
      dispatch({
        type: "setSpringInterval",
        newSpringInterval: initialCloseTicketState.springInterval,
        dirty: false,
      });
    }
  };

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography>
          {translate("CLOSE_TICKET.SPRING_STEP.CLEANING_INTERVAL_QUESTION", "Is the spring change interval correct?")}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          {translate("CLOSE_TICKET.SPRING_STEP.SHOTS_SINCE_LAST", "Shots since last spring change")}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography color="textSecondary">
          {equipment.value.shotsSinceLastSpringChange ??
            translate("CLOSE_TICKET.SPRING_STEP.SHOTS_SINCE_LAST_SPRING_CHANGE_UNKNOWN", "Unknown")}
        </Typography>
      </Grid2>
      <Grid2 style={{ width: 400 }}>
        <Grid2 container justifyContent="space-between">
          <Grid2>
            <Typography>{translate("CLOSE_TICKET.SPRING_STEP.SPRING_INTERVAL", "Spring change interval")}</Typography>
          </Grid2>
          <Grid2>
            <Typography variant="caption" color="text.secondary">
              {translate("CLOSE_TICKET.SPRING_STEP.OPTIONAL", "(optional)")}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 container>
          <Grid2 size={{ xs: 12 }}>
            <TextField
              variant="outlined"
              sx={{ width: "100%" }}
              value={localTextValue}
              onChange={onSpringIntervalChanged}
              type="numeric"
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const DidChangeSpringSelector: FC = () => {
  const { translate } = useTranslation();

  const {
    dispatch,
    state: { didSpringChange, showSpringWarning },
  } = useCloseTicketContext();

  const onApprovalChanged = useCallback(
    (_: unknown, value: boolean) => {
      dispatch({ type: "setDidSpringChange", didSpringChange: value });
    },
    [dispatch],
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2>
        <Typography>{translate("CLOSE_TICKET.SPRING_STEP.QUESTION", "Did you change the spring?")}</Typography>
      </Grid2>

      <Grid2 container>
        <ToggleButtonGroup color="primary" value={didSpringChange} exclusive onChange={onApprovalChanged}>
          <ToggleButton value>{translate("CLOSE_TICKET.SPRING_STEP.APPROVED", "Yes")}</ToggleButton>
          <ToggleButton value={false}>{translate("CLOSE_TICKET.SPRING_STEP.NOT_APPROVED", "No")}</ToggleButton>
        </ToggleButtonGroup>
      </Grid2>
      {showSpringWarning && (
        <CTWarningMessage message={translate("CLOSE_TICKET.CLEANING_STEP.CLEAN_WARNING", "Please select yes or no")} />
      )}
    </Grid2>
  );
};
