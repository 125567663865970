import { Grid2, Typography, TypographyTypeMap } from "@mui/material";
import { DatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { FC } from "react";

import { useTranslation } from "../../utility/i18n/translation";

export const GMDatePicker: FC<
  Omit<DesktopDatePickerProps<Date>, "renderInput"> & {
    title: string;
    showOptional?: boolean;
    titleVariant?: TypographyTypeMap["props"]["variant"];
  }
> = ({ title, showOptional = true, titleVariant, ...rest }) => {
  return (
    <Grid2 container direction="column" spacing={1} flex={1}>
      <Grid2>
        <InputTitle title={title} showOptionalLabel={showOptional} titleVariant={titleVariant} />
      </Grid2>
      <Grid2>
        <DatePicker {...rest} slotProps={{ textField: { style: { width: "100%" } } }} />
      </Grid2>
    </Grid2>
  );
};

export const InputTitle: FC<{
  title: string;
  showOptionalLabel?: boolean;
  titleVariant?: TypographyTypeMap["props"]["variant"];
}> = ({ title, showOptionalLabel = true, titleVariant }) => {
  const { translate } = useTranslation();

  return (
    <Grid2 container direction="row" justifyContent="space-between" alignItems="center">
      <Grid2>
        <Typography variant={titleVariant}>{title}</Typography>
      </Grid2>
      <Grid2>
        {showOptionalLabel && (
          <Typography variant="caption" color="text.secondary">
            {translate("IMAGE_SEARCH.SEARCH_FILTERS.DATE_OPTIONAL", "(optional)")}
          </Typography>
        )}
      </Grid2>
    </Grid2>
  );
};
