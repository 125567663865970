import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Fab } from "@mui/material";
import { FC } from "react";

export const ScrollToTopFab: FC = () => {
  const { scrollToTop } = useScrollToTop();

  return (
    <Fab color="primary" aria-label="add" onClick={scrollToTop} sx={{ position: "absolute", right: "15%", bottom: 50 }}>
      <ArrowUpwardIcon />
    </Fab>
  );
};

export const useScrollToTop = (): { scrollToTop: () => void } => {
  const scrollToTop = () => {
    document.querySelector("#root > div > main")?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return {
    scrollToTop,
  };
};
