import { Button, Grid2, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback } from "react";
import { useFragment } from "react-relay";

import { EquipmentIdWithIcon } from "../../../components/ticket-card/components/EquipmentIdWithIcon";
import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { SearchEquipmentDialogRow_equipment$key } from "./__generated__/SearchEquipmentDialogRow_equipment.graphql";

export type SearchEquipmentDialogRowProps = {
  equipment: SearchEquipmentDialogRow_equipment$key;
  onEquipmentPressed: (equipmentNumber: number) => void;
};

const ActualComponent: FC<SearchEquipmentDialogRowProps> = (props) => {
  const { equipment: equipmentRef, onEquipmentPressed } = props;
  const data = useFragment(
    graphql`
      fragment SearchEquipmentDialogRow_equipment on Equipment {
        description
        equipmentNumber @required(action: THROW)
        location {
          locationNumber
          description
        }
      }
    `,
    equipmentRef,
  );

  const onPress = useCallback(() => {
    onEquipmentPressed(data.equipmentNumber);
  }, [data.equipmentNumber, onEquipmentPressed]);

  const { palette } = useTheme();

  return (
    <Button onClick={onPress} fullWidth sx={{ pl: 0.5, py: 0, mt: 0.5 }}>
      <Paper elevation={2} style={{ width: "100%" }}>
        <Grid2 container flexDirection={"column"} spacing={2} padding={1}>
          <Grid2 container direction={"row"} spacing={2} alignItems="center">
            <Grid2>
              <EquipmentIdWithIcon equipmentId={data.equipmentNumber.toString()} />
            </Grid2>
            <Grid2>
              <Typography>{data.description}</Typography>
            </Grid2>
          </Grid2>
          <Grid2 container alignItems={"center"}>
            <Icons.Location fontSize="small" opacity={0.5} />
            <Typography variant="body2" color={palette.text.secondary}>
              {data?.location?.description} {data?.location?.locationNumber}
            </Typography>
          </Grid2>
        </Grid2>
      </Paper>
    </Button>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={100} />;
};

export const SearchEquipmentDialogRow = skeletonify("SearchEquipmentDialogRow", ActualComponent, SkeletonComponent);
