import { hasValue } from "@lego/mst-error-utilities";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback } from "react";
import { LoadMoreFn, useFragment, usePaginationFragment } from "react-relay";
import { OperationType } from "relay-runtime";
import { Grid2 } from "@mui/material";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { useTranslation } from "../../utility/i18n/translation";
import { InfiniteList } from "../components/InfiniteList";
import { skeletonify } from "../skeleton";

import { EquipmentHistoryCard } from "./EquipmentHistoryCard";
import { EquipmentHistoryList_equipment$key } from "./__generated__/EquipmentHistoryList_equipment.graphql";
import {
  EquipmentHistoryList_ticket$data,
  EquipmentHistoryList_ticket$key,
} from "./__generated__/EquipmentHistoryList_ticket.graphql";

export const HistoryListLoader: FC<{
  equipment: EquipmentHistoryList_equipment$key;
}> = ({ equipment: equipmentRef }) => {
  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    graphql`
      fragment EquipmentHistoryList_equipment on Equipment
      @refetchable(queryName: "EquipmentHistoryListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "ID" }
        input: { type: "EquipmentTicketsInput!" }
      ) {
        tickets(first: $first, after: $after, input: $input)
          @connection(key: "EquipmentHistoryList_equipment_tickets") {
          edges {
            node {
              ...EquipmentHistoryList_ticket
            }
          }
        }
      }
    `,
    equipmentRef,
  );

  const tickets = data.tickets?.edges.filter(hasValue).map(({ node }) => node);

  return <HistoryList tickets={tickets} hasNext={hasNext} isLoadingNext={isLoadingNext} loadNext={loadNext} />;
};

type Item = EquipmentHistoryList_ticket$data[number];

const HistoryList: FC<{
  tickets: EquipmentHistoryList_ticket$key | undefined | null;
  hasNext: boolean;
  loadNext: LoadMoreFn<OperationType>;
  isLoadingNext: boolean;
}> = (props) => {
  const { translate } = useTranslation();
  const { tickets: ticketsRef, ...rest } = props;
  const tickets = useFragment(
    graphql`
      fragment EquipmentHistoryList_ticket on Ticket @relay(plural: true) {
        id
        ...EquipmentHistoryCard_ticket #@defer(label: "ticket")
      }
    `,
    ticketsRef ?? null,
  );

  const emptyLabel = translate("TICKET_LIST.EMPTY", "No tickets found");
  const errorLabel = translate("TICKET_LIST.ERROR", "Error fetching tickets, please refresh to try again.");

  const itemRender = useCallback((item: Item) => <EquipmentHistoryCard ticket={item} />, []);
  const itemKeyExtractor = useCallback((item: Item) => item.id, []);
  return (
    <InfiniteList
      {...rest}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      items={tickets}
      itemRender={itemRender}
      itemKeyExtractor={itemKeyExtractor}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid2 container direction="column" spacing={2}>
    <Grid2 size={{ xs: "grow" }} alignSelf="center">
      <ActivityIndicator />
    </Grid2>
  </Grid2>
);

export const EquipmentHistoryList = skeletonify("EquipmentHistoryList", HistoryListLoader, SkeletonComponent);
