import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router";

import { FillWidthLoading } from "../../components/shared/FillWidthLoading";
import { PageErrorBoundary } from "../PageErrorBoundary";
import { skeletonify } from "../skeleton";

import { SparePartsTabConsumed } from "./SparePartsTabConsumed";
import SparePartsTabContainerQuery, {
  SparePartsTabContainerQuery as SparePartsTabContainerQueryType,
} from "./__generated__/SparePartsTabContainerQuery.graphql";

const ActualComponent: FC<{
  query: PreloadedQuery<SparePartsTabContainerQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query SparePartsTabContainerQuery($input: QueryTicketInput!, $sparePartsInput: TicketSparePartsInput!) {
        ticket(input: $input) {
          ... on QueryTicketSuccess {
            data {
              ...SparePartsTabConsumed_ticket @arguments(spareParts: $sparePartsInput)
            }
          }
        }
      }
    `,
    queryRef,
  );

  return <SparePartsTabConsumed.Suspense ticket={data.ticket.data} />;
};

const SkeletonComponent: FC = () => <FillWidthLoading />;

const SparePartsTabContainerPage = skeletonify("SparePartsTabContainerPage", ActualComponent, SkeletonComponent);

export const SparePartsTabContainer: FC<{
  marginTop?: number;
}> = ({ marginTop = 20 }) => {
  const { id } = useParams() as { id: string };
  const [queryRef, loadQuery] = useQueryLoader<SparePartsTabContainerQueryType>(SparePartsTabContainerQuery);

  const load = useCallback(() => {
    loadQuery({
      input: { ticketNumber: Number.parseInt(id) },
      sparePartsInput: {
        type: "CONSUMED",
      },
    });
  }, [id, loadQuery]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Container maxWidth="xl" sx={{ mt: marginTop }}>
      <PageErrorBoundary onReset={() => load()}>
        {queryRef ? <SparePartsTabContainerPage.Suspense query={queryRef} /> : <SparePartsTabContainerPage.Skeleton />}
      </PageErrorBoundary>
    </Container>
  );
};
