import { Button, Grid2 } from "@mui/material";
import { FC } from "react";

import { useAddRepairDescriptionContext } from "../../contexts/add-repair-description/add-repair-description-context";
import { useTranslation } from "../../utility/i18n/translation";

export const ARDNavigationButtons: FC = () => {
  const { translate } = useTranslation();

  const {
    dispatch,
    state: { currentStep },
  } = useAddRepairDescriptionContext();

  const onNextPressed = () => {
    dispatch({ type: "step_forward" });
  };

  const onBackPressed = () => {
    dispatch({ type: "step_backward" });
  };

  const showBackButton = currentStep !== "damage";
  const showNextButton = currentStep !== "summary";

  return (
    <Grid2 container spacing={2}>
      {showBackButton && (
        <Grid2>
          <Button variant="text" color="primary" onClick={onBackPressed}>
            {translate("GENERIC_BUTTON_TEXTS.BACK", "Back")}
          </Button>
        </Grid2>
      )}
      {showNextButton && (
        <Grid2>
          <Button variant="outlined" color="primary" onClick={onNextPressed}>
            {translate("GENERIC_BUTTON_TEXTS.NEXT", "Next")}
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};
