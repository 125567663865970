import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { TFunction } from "i18next";
import { FC, ReactNode, useCallback } from "react";
import * as i18next from "react-i18next";

import { useUserPreferencesContext } from "../../contexts/UserPreferencesContext";

import { Locale, localeToDateFnsLocale } from "./i18n";

type UseTranslationResult = {
  translate: TFunction;
  changeLanguage(lng: Locale): void;
  locale: Locale;
};

export const useTranslation = (): UseTranslationResult => {
  const { t: translate, i18n } = i18next.useTranslation();

  const { setLocalePreference } = useUserPreferencesContext();

  const changeLanguage = useCallback(
    (lng: Locale): void => {
      i18n.changeLanguage(lng);
      setLocalePreference(lng);
    },
    [i18n, setLocalePreference],
  );

  return {
    translate,
    changeLanguage,
    locale: i18n.language as Locale,
  };
};

export const DatePickerLocalizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeToDateFnsLocale(locale)}>
      {children}
    </LocalizationProvider>
  );
};
