import { gql } from "@apollo/client";
import { Container } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";

import { GetDataForAddMachineTimeFlowQuery, GetDataForAddMachineTimeFlowQueryVariables } from "../__apollo__/graphql";
import { useGMQuery } from "../apollo/customApolloHooks";
import { AddMachineHoursRoot } from "../components/add-time/AddMachineHoursRoot";
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from "../components/add-time/overview/time-registration-gql-fragments";
import { TDHeader } from "../components/ticket-details/TDHeader";
import { AddMachineHoursProvider } from "../contexts/add-machine-hours/add-machine-hours-context";
import { RouteRequiringPermission } from "../Router";
import { useTranslation } from "../utility/i18n/translation";
import { PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION } from "../utility/permissions/ticket";
import { usePermissions } from "../utility/permissions/usePermissions";
import { useGMSnackbar } from "../utility/snackbar";

import { useTicketQueryTypeCheck } from "./TicketByIdTypecheckHook";

export const ADD_TIME_FLOW_QUERY = gql`
  query GetDataForAddMachineTimeFlow($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...TimeRegistrationOverview
        ...CanAddMachineTimeRegistration
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
  ${PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION}
`;

export const AddMachineHoursToTicket: FC = () => {
  const { id } = useParams() as { id: string };
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const navigate = useNavigate();

  const { data, loading: headerLoading } = useGMQuery<
    GetDataForAddMachineTimeFlowQuery,
    GetDataForAddMachineTimeFlowQueryVariables
  >(ADD_TIME_FLOW_QUERY, {
    variables: { input: { id } },
  });

  const {
    ticket: { canAddMachineTimeRegistration },
  } = usePermissions();

  const checked = useTicketQueryTypeCheck<
    Extract<GetDataForAddMachineTimeFlowQuery["ticket"], { __typename?: "Ticket" }>
  >({
    data,
    loading: headerLoading,
  });

  if (checked.type === "errorOrLoadingComponent") {
    return checked.component;
  }

  if (!checked.data.isCmsTicket) {
    showSnack({
      message: translate("ADD_MACHINE_HOURS.ROOT.NOT_CMS_TICKET", "Cannot add machine hours to a non-CMS ticket"),
      variant: "warning",
    });
    navigate(-1);
  }

  const allowed = canAddMachineTimeRegistration(checked.data);

  return (
    <RouteRequiringPermission allowed={allowed}>
      <AddMachineHoursProvider>
        <TDHeader {...checked.data} showFab={false} />
        <Container maxWidth="lg" style={{ marginTop: 110 }}>
          <AddMachineHoursRoot {...checked.data} />
        </Container>
      </AddMachineHoursProvider>
    </RouteRequiringPermission>
  );
};
