import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "typeface-lato";
import { App } from "./App";
import "./index.css";
import { MicrosoftADInstance } from "./msal/config";
import { reportWebVitals } from "./reportWebVitals";
import { theme } from "./utility/theme";

// Initialize the MicrosoftADInstance before rendering the app to avoid uninitialized_public_client_application errors
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#uninitialized_public_client_application
MicrosoftADInstance.initialize().then(() => {
  const container = document.getElementById("root");

  const root = createRoot(container!);
  root.render(
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={5} preventDuplicate>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
